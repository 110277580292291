<template>
    <div class="user">
        <v-scroll :on-refresh="onRefresh">
            <div class="user-infos">
                <el-row>
                    <el-col :span="24"><h4 align="center">我的</h4></el-col>
                </el-row>
                <el-row>
                    <el-col :span="18">
                        <div class="user-infos-left">
                            <div class="block">
                                <div class="user-avatar">
                                    <el-avatar :size="50" :src="userAvatar"></el-avatar>
                                </div>
                                <div class="user-status">
                                    <div><span>{{ userInfos.M | hiddenString(3, 7)}}</span></div>
                                    <!-- 是否实名认证 -->
                                    <div v-if="userInfos.RST == 0">
                                        <div class="state-icon">
                                            <el-tag type="info" size="mini">
                                                <span class="iconfont icon-shimingrenzheng-weirenzheng"></span>
                                            </el-tag>
                                        </div>
                                        <div class="state-text">
                                            <el-tag type="info" size="mini">
                                                <span>未实名</span>
                                            </el-tag>
                                        </div>
                                    </div>
                                    <div v-else-if="userInfos.RST == 2">
                                        <div class="state-icon">
                                            <el-tag type="success" size="mini">
                                                <span class="iconfont icon-shimingrenzheng"></span>
                                            </el-tag>
                                        </div>
                                        <div class="state-text">
                                            <el-tag type="success" size="mini">
                                                <span>已实名：{{ userInfos.RN }}</span>
                                            </el-tag>
                                        </div>
                                    </div>
                                    <div v-else>
                                            
                                        <div class="state-icon">
                                            <el-tag type="warning" size="mini">
                                                <span class="iconfont icon-shenhezhong1"></span>
                                            </el-tag>
                                        </div>
                                        <div class="state-text">
                                            <el-tag type="warning" size="mini">
                                                <span>审核中</span>
                                            </el-tag>
                                        </div>
                                    </div>
                                    <!-- 是否银行签约 -->
                                    <div v-if="userInfos.CS == 0">
                                        <div class="state-icon">
                                            <el-tag type="info" size="mini">
                                                <span class="iconfont icon-weiqianyue"></span>
                                            </el-tag>
                                        </div>
                                        <div class="state-text">
                                            <el-tag type="info" size="mini">
                                                <span>未签约</span>
                                            </el-tag>
                                        </div>
                                    </div>
                                    <div v-else-if="userInfos.CS == 1">
                                        <div class="state-icon">
                                            <el-tag type="success" size="mini">
                                                <span class="iconfont icon-yiqianyue"></span>
                                            </el-tag>
                                        </div>
                                        <div class="state-text">
                                            <el-tag type="success" size="mini">
                                                <span>已签约：{{ userInfos.RBI }}</span>
                                            </el-tag>
                                        </div>
                                    </div>
                                    <div v-else-if="userInfos.CS == 2">
                                        <div class="state-icon">
                                            <el-tag type="warning" size="mini">
                                                <span class="iconfont icon-weiqianyue"></span>
                                            </el-tag>
                                        </div>
                                        <div class="state-text">
                                            <el-tag type="warning" size="mini">
                                                <span>已解约</span>
                                            </el-tag>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="state-icon">
                                            <el-tag type="danger" size="mini">
                                                <span class="iconfont icon-weiqianyue"></span>
                                            </el-tag>
                                        </div>
                                        <div class="state-text">
                                            <el-tag type="danger" size="mini">
                                                <span>已销户</span>
                                            </el-tag>
                                        </div>
                                    </div>
                                </div>
                            </div>        
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="user-infos-right">
                            <router-link to="/user/information">
                                <span class="iconfont icon-shezhi">&nbsp;</span><span>设置</span>
                            </router-link>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div class="user-main">
                <el-descriptions title="" direction="horizontal" :column="2" border size="small">
                    <el-descriptions-item label="会员等级">{{ userExtendInfos.level_name }}</el-descriptions-item>
                    <el-descriptions-item label="我的仓量">{{ userExtendInfos.position }}</el-descriptions-item>
                    <el-descriptions-item label="上次签到">{{ userExtendInfos.sign_time }}</el-descriptions-item>
                    <el-descriptions-item label="我的仓单">{{ userExtendInfos.warrant }}</el-descriptions-item>
                    <el-descriptions-item label="签到状态">
                        <el-tag v-if="userExtendInfos.sign_state == 0" size="mini" type="success">活跃</el-tag>
                        <el-tag v-else-if="userExtendInfos.sign_state == 1" size="mini" type="info">非活跃</el-tag>
                        <el-tag v-else size="mini" type="danger">已清空</el-tag>
                        <span style="color: #F56C6C; font-weight: 700;">&nbsp;&nbsp;{{ userExtendInfos.message }}</span>
                    </el-descriptions-item>
                </el-descriptions>
                
                <!-- 提示信息滚动 -->
                <div class="newsList" v-if="newsList.length>0">
                    <el-carousel direction="vertical" indicator-position="none" :autoplay="true" :interval="4000" height="30px">
                        <el-carousel-item v-for="item in newsList" :key="item.id">
                            <router-link :to="item.toUrl">
                                <el-link :underline="false" type="danger" style="text-decoration: none;padding-left: 1rem;font-size: 10px;">
                                    {{ item.title }}
                                </el-link>
                            </router-link>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                    
                <el-card class="box-cards">
                    <el-row class="el-row-twos">
                        <el-col :span="6">
                            <div class="icon"><span class="">{{ userMoneyInfo.UF | keepTwoDecimal }}</span></div>
                            <div class="icon-text">可用资金</div>
                        </el-col>
                        <el-col :span="6">
                            <div class="icon"><span class="">{{ (userMoneyInfo.AF - userMoneyInfo.DF) | keepTwoDecimal }}</span></div>
                            <div class="icon-text">冻结资金</div>
                        </el-col>
                        <el-col :span="6">
                            <div class="icon"><span class="">{{ userMoneyInfo.AF | keepTwoDecimal }}</span></div>
                            <div class="icon-text">资金总额</div>
                        </el-col>
                        <el-col :span="6">
                            <div class="icon"><span class="">{{ userMoneyInfo.MV | keepTwoDecimal }}</span></div>
                            <div class="icon-text">持有市值</div>
                        </el-col>
                    </el-row>
                </el-card>
                <el-card class="box-card">
                    <el-row class="el-row-cols">
                        <el-col :sm="24" :lg="12">
                            <div>资金信息</div>
                        </el-col>
                    </el-row>
                    <el-row class="el-row-twos">
                        <el-col :span="6">
                            <router-link to="/money/deposit">
                                <div class="icon"><span class="iconfont icon-APPiconxinban-"></span></div>
                                <div class="icon-text">入金</div>
                            </router-link>
                        </el-col>
                        <el-col :span="6">
                            <router-link to="/money/withdrawal">
                                <div class="icon"><span class="iconfont icon-chujin"></span></div>
                                <div class="icon-text">出金</div>
                            </router-link>
                        </el-col>
                        <el-col :span="6">
                            <router-link to="/money/depositrecord">
                                <div class="icon"><span class="iconfont icon-churujin"></span></div>
                                <div class="icon-text">出入金记录</div>
                            </router-link>
                        </el-col>
                        <el-col :span="6">
                            <router-link to="/trade/dealflow">
                                <div class="icon"><span class="iconfont icon-jiaoyiliushuichaxun"></span></div>
                                <div class="icon-text">资金流水</div>
                            </router-link>
                        </el-col>
                        <!-- 第5个子项 -->
                        <!-- <el-col :span="6" style="margin-top: 1.5rem" v-if="userInfos.BI=='f9799'">
                            <router-link to="/money/transferfunds">
                                <div class="icon"><span class="iconfont icon-zijinmingxi"></span></div>
                                <div class="icon-text">资金划转</div>
                            </router-link>
                        </el-col> -->
                    </el-row>
                </el-card>
                <el-card class="box-card">
                    <el-row class="el-row-cols">
                        <el-col :sm="24" :lg="12">
                            <div>交易情况</div>
                        </el-col>
                    </el-row>
                    <el-row class="el-row-twos">
                        <el-col :span="6">
                            <router-link to="/trade/positionsummary">
                                <div class="icon"><span class="iconfont icon-cunhuo"></span></div>
                                <div class="icon-text">存货汇总</div>
                            </router-link>
                        </el-col>
                        <el-col :span="6">
                            <router-link to="/trade/positionlists">
                                <div class="icon"><span class="iconfont icon-huabanfuben"></span></div>
                                <div class="icon-text">存货明细</div>
                            </router-link>
                        </el-col>
                        <el-col :span="6">
                            <router-link to="/trade/orderrecord">
                                <div class="icon"><span class="iconfont icon-paimaijilu"></span></div>
                                <div class="icon-text">交易记录</div>
                            </router-link>
                        </el-col>
                        <el-col :span="6">
                            <router-link to="/trade/dealrecord">
                                <div class="icon"><span class="iconfont icon-chengjiaojilu"></span></div>
                                <div class="icon-text">成交记录</div>
                            </router-link>
                        </el-col>
                    </el-row>
                    <el-row class="el-row-twos">
                        <!-- <el-col :span="6">
                            <router-link to="/trade/dealflow">
                                <div class="icon"><span class="iconfont icon-jiaoyiliushuichaxun"></span></div>
                                <div class="icon-text">交易流水</div>
                            </router-link>
                        </el-col> -->
                        <!-- <el-col :span="6">
                            <router-link to="">
                                <div class="icon"><span class="iconfont icon-paimaijilu1"></span></div>
                                <div class="icon-text">预拍记录</div>
                            </router-link>
                        </el-col> -->
                        <el-col :span="6">
                            <router-link to="">
                                <div class="icon"><span class=""></span></div>
                                <div class="icon-text"></div>
                            </router-link>
                        </el-col>
                        <el-col :span="6">
                            <router-link to="">
                                <div class="icon"><span class=""></span></div>
                                <div class="icon-text"></div>
                            </router-link>
                        </el-col>
                    </el-row>
                </el-card>
                <el-card class="box-card">
                    <el-row class="el-row-cols">
                        <el-col :sm="24" :lg="12">
                            <div>我要提货</div>
                        </el-col>
                    </el-row>
                    <el-row class="el-row-twos">
                        <el-col :span="6">
                            <router-link to="/delivery/useraddress">
                                <div class="icon"><span class="iconfont icon-dizhi"></span></div>
                                <div class="icon-text">地址管理</div>
                            </router-link>
                        </el-col>
                        <el-col :span="6">
                            <router-link to="/delivery/delivery">
                                <div class="icon"><span class="iconfont icon-tihuo"></span></div>
                                <div class="icon-text">申请提货</div>
                            </router-link>
                        </el-col>
                        <el-col :span="6">
                            <router-link to="/delivery/deliverylist">
                                <div class="icon"><span class="iconfont icon-tihuojilu"></span></div>
                                <div class="icon-text">提货记录</div>
                            </router-link>
                        </el-col>
                        <el-col :span="6">
                            <router-link to="">
                                <div class="icon"><span class=""></span></div>
                                <div class="icon-text"></div>
                            </router-link>
                        </el-col>
                    </el-row>
                </el-card>
                <el-card class="box-card">
                    <el-row class="el-row-cols">
                        <el-col :sm="24" :lg="12">
                            <div>我的仓量</div>
                        </el-col>
                    </el-row>
                    <el-row class="el-row-twos">
                        <el-col :span="6">
                            <router-link to="/user/mypositionrecord">
                                <div class="icon"><span class="iconfont icon-jifenjilu"></span></div>
                                <div class="icon-text">仓量明细</div>
                            </router-link>
                        </el-col>
                        <!-- <el-col :span="6">
                            <router-link to="/delivery/delivery">
                                <div class="icon"><span class="iconfont icon-tihuo"></span></div>
                                <div class="icon-text">申请提货</div>
                            </router-link>
                        </el-col>
                        <el-col :span="6">
                            <router-link to="/delivery/deliverylist">
                                <div class="icon"><span class="iconfont icon-tihuojilu"></span></div>
                                <div class="icon-text">提货记录</div>
                            </router-link>
                        </el-col>
                        <el-col :span="6">
                            <router-link to="">
                                <div class="icon"><span class=""></span></div>
                                <div class="icon-text"></div>
                            </router-link>
                        </el-col> -->
                    </el-row>
                </el-card>
                <el-card class="box-card">
                    <el-row class="el-row-cols">
                        <el-col :sm="24" :lg="12">
                            <div>我的团队</div>
                        </el-col>
                    </el-row>
                    <el-row class="el-row-twos">
                        <el-col :span="6">
                            <router-link to="/team">
                                <div class="icon"><span class="iconfont icon-tuandui"></span></div>
                                <div class="icon-text">基本信息</div>
                            </router-link>
                        </el-col>
                        <el-col :span="6" v-if="isQueryTeam=='1'">
                            <router-link to="/team/teamdetails">
                                <div class="icon"><span class="iconfont icon-tuanduijiegou"></span></div>
                                <div class="icon-text">团队详情</div>
                            </router-link>
                        </el-col>
                    </el-row>
                </el-card>
            </div>
        </v-scroll>
        <FooterNavigation />
    </div>
</template>

<script>
    import { loginApi, noLoginApi } from "@/config/env"
    import { mapState } from 'vuex'
    import FooterNavigation from '@/components/common/footer/FooterNavigation'
    import { isFailureLogin } from '@/config/mUtils'
    import Scroll from '@/components/common/dropdown/DropdownScroll.vue'
    export default {
        name: 'User',
        components: {
            FooterNavigation,
            'v-scroll': Scroll
        },
        data() {
            return {
                userAvatar: '/images/default_avatar.png',
                //查询用户信息参数
                queryUserParams: {
                    "name": "userinfo_query",
                    "U": "",
                    "SI": ""
                },
                //查询用户扩展信息参数
                queryUserExtendParams: {
                    "name": "userInfo",
                    "U": "",
                    "SI": ""
                },
                queryMoney: {
                    "name":"firm_sum_info",
                    "U": "",
                    "SI": ""
                },
                userMoneyInfo: {},
                userInfos: {},
                userExtendInfos: {},
                newsList: [],
                //是否有权限查看团队结构
                isQueryTeam: ''
            }
        },
        beforeCreate() {

        },
        created() {
            
        },
        beforeMount() {},
        mounted() {
            //获取用户信息
            this.getUserInfos()
            //获取用户资金信息
            this.queryUserMoney()
            //获取团队结构查看权限
            this.getTeamAuthInfos()
        },
        computed: {
            
        },
        methods: {
            onRefresh(done) {
                // //获取用户信息
                this.getUserInfos()
                //获取用户资金信息
                this.queryUserMoney()
                //获取团队结构查看权限
                this.getTeamAuthInfos()
                //console.log(457878)
                done() // call done
            },
            // onInfinite(done) {
            //     console.log(done, '@@@', 2145544)
            // },
            //交易商总资金查询
            async queryUserMoney() {
                const { data: res } = await this.$http.post(loginApi.moneyQueryApi, this.queryMoney)
                //判断是否登录失效，以及返回错误信息
                if (isFailureLogin(res.retcode)) {
                    this.userMoneyInfo = res.result
                } else {
                    return this.$message.warning(res.message)
                }
            },
            //获取用户信息
            async getUserInfos() {
                const { data: res } = await this.$http.post(loginApi.userInfoApi, this.queryUserParams)
                if (isFailureLogin(res.retcode)) {
                    this.userInfos = res.result
                    //实名信息提示
                    if (res.result.RST == 0) {
                        this.newsList.push({ title: '您还未实名，请尽快申请实名认证！', toUrl: '/user/realnameauth' })
                    }
                    //签约信息提示
                    if (res.result.CS == 0) {
                        this.newsList.push({ title: '您还未签约绑卡，请尽快进行签约绑卡！', toUrl: '/user/bindbankcard' })
                    } else if (res.result.CS == 2) {
                        this.newsList.push({ title: '您已解除签约绑卡，点击我即可重新签约！', toUrl: '/user/bindbankcard' })
                    }
                    this.getUserExtendInfos()
                } else {
                    this.$message.warning(res.message)
                    return
                }
            },
            //获取用户扩展信息
            async getUserExtendInfos() {
                const { data: res } = await this.$http.post(loginApi.userInfoExtendApi, this.queryUserExtendParams)
                if (res.code !== 'E000000') {
                    this.$message.warning(res.message)
                    return
                } else {
                    this.userExtendInfos = res.data
                    if (res.data.receipt > 0) {
                        this.newsList.push({ 
                            title: '您有'+res.data.receipt+'个认购单，需要￥'+res.data.receipt*735+'可用资金认购，请尽快入金！',
                            toUrl: '/money/deposit'
                        })
                    }
                    //将用户信息保存到vuex中
                    this.$store.dispatch('getUserInfos', {...this.userInfos, ...res.data})
                }
            },
            //获取团队结构查询权限
            async getTeamAuthInfos() {
                const { data: res } = await this.$http.post(loginApi.queryTeamAuthApi)
                if(res.code === 'E000000') {
                    this.isQueryTeam = res.data.team_view_auth
                } else {
                    return this.$message.warning(res.message)
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .user {
        width: 100%;
        height: 100%;
        padding: 0;
    }
    .user-infos {
        background-color: #E33B38;
        min-height: 120px;
        color: #FFFFFF;
    }
    .user-infos-left {
        width: 100%;
        height: 60px;
        padding: 0.1rem;
        .block {
            margin-left: 5%;
            height: 60px;
            display: flex;
            flex-direction: row;
            align-items: center;
            .user-avatar {
                width: 20%;
                height: 100%;
                .el-avatar {
                    margin-top: 1rem;
                }
            }
            .user-status {
                width: 100%;
                margin-left: 10px;
                div:nth-child(2) {
                    display: flex;
                    flex-direction: row;
                    flex-flow: nowrap;
                    justify-content: flex-start;
                    align-content: center;
                    .state-icon {
                        width: 3rem;
                        height: 100%;
                    }
                    .state-text {
                        width: 100%;
                        height: 100%;
                    }
                }
                div:nth-child(3) {
                    display: flex;
                    flex-direction: row;
                    flex-flow: nowrap;
                    justify-content: flex-start;
                    align-content: center;
                    .state-icon {
                        width: 3rem;
                        height: 100%;
                    }
                    .state-text {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
    .user-infos-right {
        width: 100%;
        height: 60px;
        text-align: right;
        a {
            text-decoration: none;
            color: #FFFFFF;
            display: block;
            margin-right: 20%;
            line-height: 60px;
        }
    }
    .user-main {
        margin: 0 auto;
        width: 95%;
        height: 100%;
        margin-bottom: 10rem;
        .el-descriptions {
            margin-bottom: 5px;
        }
        .el-card {
            margin-bottom: 5px;
        }
    }
    .el-row-cols {
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: bolder;
    }
    .el-row-twos {
        font-size: 14px;
        margin-bottom: 10px;
        .el-col {
            text-align: center;
            a {
                text-decoration: none;
                color: #303133;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                div {
                    margin-bottom: 5px;
                }
            }
            
        }
    }
    .newsList {
        width: 100%;
        height: 3rem;
        background-color: #FFFFFF;
        margin-bottom: 0.5rem;
        border-radius: 0.3rem;
        .el-carousel {
            width: 100%;
            height: 100%;
            line-height: 3rem;
            a:hover {
                text-decoration: none;
            }
        }
    }
</style>