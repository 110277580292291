<template>
    <div class="position-lists">
        <div class="head-delivery">
            <el-page-header @back="goBack" content="提货记录" style="font-weight: bolder;">
            </el-page-header>
            <el-divider></el-divider>
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane label="待发货" name="first"></el-tab-pane>
                <el-tab-pane label="待收货" name="second"></el-tab-pane>
                <el-tab-pane label="已收货" name="third"></el-tab-pane>
                <!-- <el-tab-pane label="已转换" name="four"></el-tab-pane> -->
            </el-tabs>
        </div>
        
        <div class="infinite-list-wrapper" v-if="list && list.length > 0">
            <ul class="list">
                <li v-for="(item, index) in list" :key="index" class="list-item">
                    <el-descriptions  title="" direction="vertical" :column="1" border :colon="true" size="small">
                        <el-descriptions-item label="单号">{{ item.order_id }}</el-descriptions-item>
                        <el-descriptions-item label="名称">[{{ item.commodity_id }}] {{ item.commodity_name }}</el-descriptions-item>
                        <el-descriptions-item label="数量">{{ item.commodity_num }}</el-descriptions-item>
                        <el-descriptions-item label="收货人姓名">{{ item.name }}</el-descriptions-item>
                        <el-descriptions-item label="收货人电话">{{ item.phone }}</el-descriptions-item>
                        <el-descriptions-item label="收货人地址">{{ item.address=='NULL'?'暂无':item.address }}</el-descriptions-item>
                        <el-descriptions-item v-if="item.delivery_state == 1 || item.delivery_state == 2" label="物流详情">
                            <el-button @click.native="queryLogisticInfos(item.express_id)" type="warning" size="mini" style="width: 100%">点击查看更多物流信息&nbsp;&nbsp;<i class="el-icon-arrow-down"></i></el-button>
                        </el-descriptions-item>
                        <el-descriptions-item v-if="item.delivery_state == 1" label="快递公司">{{ item.express_company }}</el-descriptions-item>
                        <el-descriptions-item v-if="item.delivery_state == 1" label="快递单号">{{ item.express_id }}</el-descriptions-item>
                        <el-descriptions-item label="提货时间">{{ item.apply_time }}</el-descriptions-item>
                        <el-descriptions-item label="发货时间" v-if="item.delivery_state == 1 || item.delivery_state == 2">{{ item.delivery_time }}</el-descriptions-item>
                        <el-descriptions-item v-if="item.delivery_state == 2" label="收货时间">{{ item.receive_time }}</el-descriptions-item>
                        <el-descriptions-item label="当前状态">
                            <el-tag size="mini" v-if="item.delivery_state == 2" type="success">已收货</el-tag>
                            <el-tag size="mini" v-else-if="item.delivery_state == 1" type="warning">已发货</el-tag>
                            <!-- <el-tag size="mini" v-else-if="item.delivery_state == 3">已转换为通兑点数</el-tag> -->
                            <el-tag size="mini" v-else type="danger">已申请提货等待发货</el-tag>
                        </el-descriptions-item>
                        <!-- <el-descriptions-item v-if="item.delivery_state == 0" label="操作">
                            <el-button @click="exchangePoint(item)" size="mini" style="width: 100%" type="success">兑换为通兑点数</el-button>
                        </el-descriptions-item> -->
                        <el-descriptions-item v-if="item.delivery_state == 1" label="操作">
                            <el-button @click="confirmBtn(item)" size="mini" style="width: 100%" type="success">确认收货</el-button>
                        </el-descriptions-item>
                        
                    </el-descriptions>
                </li>
            </ul>

            
        </div>

        <el-empty class="infinite-list-wrapper" v-else :image-size="150"></el-empty>

        <!-- 查看物流 -->
        <el-drawer
            title="物流详细信息"
            size="75%"
            :modal-append-to-body="false"
            :with-header="true"
            :visible.sync="drawerDetailVisible"
            direction="btt">
            <div class="drawer-logistic">
                <div class="title">运单编号：{{ logisticNo }}</div>
                <div class="steps-wrap" v-if="detailData && detailData.length">
                    <el-steps class="steps" direction="vertical" process-status="finish" :active="0">
                        <el-step 
                        v-for="(item, index) in detailData" 
                            :icon="!index ? 'el-icon-document-checked' : 'el-icon-success'" 
                            :key="index" 
                            :title="item.site">
                        <div slot="description">
                            <p class="row-remark">{{ item.description }}</p>
                            <p class="row-time">{{ item.time }}</p>
                        </div>
                        </el-step>
                    </el-steps>
                </div>
            </div>
        </el-drawer>
        <!-- 分页 -->
        <div class="foot-delivery">
            <el-pagination v-if="num_none" background small
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange" 					
                layout="prev, pager, next"
                :current-page="current_page"
                :page-size="pagesize"
                :page-count="pagecount"
                :pager-count="5"
            ></el-pagination>
        </div>
        
    </div>
</template>

<script>
    import { loginApi } from '@/config/env'
    import { isFailureLogin } from '@/config/mUtils'
    export default {
        name: 'DeliveryList',
        data() {
            return {
                queryParams: {
                    page: {
                        "pageNo": 1,
                        "pageSize": 10
                    },
                    filter: {
                        "delivery_state": "0"
                    }
                },
                total: 0,
                current_page: 1,
                pagecount: 0,
                num_none: true,
                pagesize: 5,
                activeName: 'first',
                //收货订单ID
                order_id: '',
                list: [], //后端返回的数组
                //物流
                drawerDetailVisible: false, // 查看物流弹窗
                logisticNo: '', // 查看的物流单号
                detailData: [], // 物流详情
            }
        },
        mounted() {
            this.getDeliveryLists()
        },
        methods: {
            //返回
            goBack() {
                this.$router.push('/user')
            },
            //每页数据条数发生改变时触发
            handleSizeChange() {},
            //当前页发生改变时触发
            handleCurrentChange(val) {
                this.queryParams.page.pageNo = val
                this.getDeliveryLists()
            },
            async getDeliveryLists() {
                const { data: res } = await this.$http.post(loginApi.deliveryRecordApi, this.queryParams)
                //判断是否登录失效，以及返回错误信息
                if (res.code == 'E000000') {
                    this.total = res.data.totalCount
                    this.pagecount = res.data.totalPage
                    this.current_page = res.data.pageNo
                    this.pagesize = res.data.pageSize
                    this.list = res.data.data
                } else {
                    return this.$message.warning(res.message)
                }
            },
            //确认收货
            async confirmDelivery() {
                const { data: res } = await this.$http.post(loginApi.confirmDeliveryApi+"?order_id="+this.order_id)
                if (res.code !== 'E000000') {
                    return this.$message.error(res.message)
                } else {
                    this.$message.success(res.message)
                }
            },
            //确认收货单转换通兑点数
            async orderExchangePoint() {
                const { data: res } = await this.$http.post(loginApi.exchangePointApi+"?order_id="+this.order_id)
                if (res.code !== 'E000000') {
                    return this.$message.error(res.message)
                } else {
                    this.$message.success(res.message)
                }
            },
            //查看物流详情按钮
            queryLogisticInfos(logisticId) {
                this.logisticNo = logisticId
                this.drawerDetailVisible = true
                this.getLogisticInfos()
            },
            //获取物流详细信息
            async getLogisticInfos() {
                const { data: res } = await this.$http.post(loginApi.logisticsDetailsApi+`?delivery_id=`+this.logisticNo)
                if (res.code !== "E000000") {
                    return this.$message.error(res.message)
                } else {
                    this.detailData = res.data.result.responseParam.trace_list
                }
            },
            //通兑点数按钮
            exchangePoint(rows) {
                const h = this.$createElement
                this.$msgbox({
                    title: '通兑转换提示',
                    message: h('p', null, [
                        h('span', null, '单号：'),
                        h('i', { style: 'color: red' }, rows.order_id),
                        h('br'),
                        h('span', null, '商品名称：'),
                        h('i', { style: 'color: teal' }, rows.commodity_name),
                        h('br'),
                        h('span', null, '商品数量：'),
                        h('i', { style: 'color: teal' }, rows.commodity_num)
                    ]),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true
                            instance.confirmButtonText = '执行中...'
                            setTimeout(() => {
                                this.order_id = rows.order_id
                                this.orderExchangePoint()
                                done()
                                setTimeout(() => {
                                    instance.confirmButtonLoading = false
                                }, 300)
                            }, 2000)
                        } else {
                            instance.cancelButtonLoading = true
                            instance.cancelButtonText = '取消中...'
                            setTimeout(() => {
                                done()
                                setTimeout(() => {
                                    instance.cancelButtonLoading = false
                                }, 300)
                            }, 1000)
                        }
                    }
                }).then(action => {
                    this.activeName = 'first'
                    this.list = []
                    this.queryParams.page.pageNo = 1
                    this.queryParams.filter.delivery_state = 0
                    setTimeout(() => {
                        this.getDeliveryLists()
                    }, 0)
                }).catch(err => {
                    this.$message.info('已取消')
                })
            },
            //确认收货按钮触发
            confirmBtn(rows) {
                const h = this.$createElement
                this.$msgbox({
                    title: '确认收货提示',
                    message: h('p', null, [
                        h('span', null, '单号：'),
                        h('i', { style: 'color: red' }, rows.order_id),
                        h('br'),
                        h('span', null, '商品名称：'),
                        h('i', { style: 'color: teal' }, rows.commodity_name),
                        h('br'),
                        h('span', null, '商品数量：'),
                        h('i', { style: 'color: teal' }, rows.commodity_num)
                    ]),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true
                            instance.confirmButtonText = '执行中...'
                            setTimeout(() => {
                                this.order_id = rows.order_id
                                this.confirmDelivery()
                                done()
                                setTimeout(() => {
                                    instance.confirmButtonLoading = false
                                }, 300)
                            }, 2000)
                        } else {
                            instance.cancelButtonLoading = true
                            instance.cancelButtonText = '取消中...'
                            setTimeout(() => {
                                done()
                                setTimeout(() => {
                                    instance.cancelButtonLoading = false
                                }, 300)
                            }, 1000)
                        }
                    }
                }).then(action => {
                    this.activeName = 'second'
                    this.list = []
                    this.queryParams.page.pageNo = 1
                    this.queryParams.filter.delivery_state = 1
                    setTimeout(() => {
                        this.getDeliveryLists()
                    }, 0)
                }).catch(err => {
                    this.$message.info('已取消')
                })
            },
            handleClick(tab, event) {
                switch (tab._props.name) {
                    case 'first':
                        this.list = []
                        this.queryParams.page.pageNo = 1
                        this.queryParams.filter.delivery_state = 0
                        this.getDeliveryLists()
                        break;
                    case 'second':
                        this.list = []
                        this.queryParams.page.pageNo = 1
                        this.queryParams.filter.delivery_state = 1
                        this.getDeliveryLists()
                        break;
                    case 'third':
                        this.list = []
                        this.queryParams.page.pageNo = 1
                        this.queryParams.filter.delivery_state = 2
                        this.getDeliveryLists()
                        break;
                    case 'four':
                        this.list = []
                        this.queryParams.page.pageNo = 1
                        this.queryParams.filter.delivery_state = 3
                        this.getDeliveryLists()
                        break;
                    default:
                        break;
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .position-lists {
        width: 100%;
        height: 100%;
        padding: 1rem;
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        .head-delivery {
            position: fixed;
            padding: 1rem;
            top: 1rem;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
        }
        .foot-delivery {
            width: 100%;
            text-align: center;
            position: fixed;
            bottom: 0;
            width: 100%;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
        }
    }
    .infinite-list-wrapper {
        width: 100%;
        // height: 75%;
        position: absolute;
        padding: 1rem;
        top: 14rem;
        bottom: 5rem;
        left: 50%;
        transform: translateX(-50%);
        overflow-y: scroll;
        overflow-x: hidden;
        .list {
            width: 100%;
            height: 100%;
            padding: 0;
            .list-item {
                margin-bottom: 1rem;
            }
        }
    }
    .drawer-logistic {
        width: 90%;
        padding: 0.5rem;
        margin: 0 auto;
        .title {
            font-size: 14px;
            margin-bottom: 10px;
        }
        
    }
    /deep/ .el-step__title {
        font-size: 14px;
        font-weight: 600;
    }
</style>