//引入路由
import VueRouter from 'vue-router'
//引入js-cookies
import Cookies from 'js-cookie'
import Home from '@/components/pages/home/Home'
import Login from '@/components/pages/login/Login'
import Messages from '@/components/pages/messages/Messages'
import Details from '@/components/pages/messages/children/Details'
import Register from '@/components/pages/register/Register'
import User from '@/components/pages/user/User'
import ForgetPassword from '@/components/pages/user/children/ForgetPassword'
import Information from '@/components/pages/user/children/Information'
import ModifyPassword from '@/components/pages/user/children/ModifyPassword'
import RealnameAuth from '@/components/pages/user/children/RealnameAuth'
import Market from '@/components/pages/market/Market'
import AuctionDetails from '@/components/pages/market/children/AuctionDetails'
import MoneyPassword from '@/components/pages/user/children/MoneyPassword'
import EditMoneyPwd from '@/components/pages/user/children/EditMoneyPwd'
import ResetMoneyPwd from '@/components/pages/user/children/ResetMoneyPwd'
import Trade from '@/components/pages/trade/Trade'
import PositionSummary from '@/components/pages/trade/children/PositionSummary'
import PositionLists from '@/components/pages/trade/children/PositionLists'
import DealRecord from '@/components/pages/trade/children/DealRecord'
import CancelOrder from '@/components/pages/trade/children/CancelOrder'
import DelistingBuy from '@/components/pages/trade/children/DelistingBuy'
import DelistingSell from '@/components/pages/trade/children/DelistingSell'
import EntrustBuy from '@/components/pages/trade/children/EntrustBuy'
import EntrustSell from '@/components/pages/trade/children/EntrustSell'
import UserAddress from '@/components/pages/delivery/UserAddress'
import AddUserAddress from '@/components/pages/delivery/children/AddUserAddress'
import EditUserAddress from '@/components/pages/delivery/children/EditUserAddress'
import Delivery from '@/components/pages/delivery/Delivery'
import DeliveryList from '@/components/pages/delivery/children/DeliveryList'
import SpotRegisterRules from '@/components/pages/spotrules/SpotRegisterRules'
import SpotAuctionRules from '@/components/pages/spotrules/SpotAuctionRules'
import SpotRiskRules from '@/components/pages/spotrules/SpotRiskRules'
import OrderRecord from '@/components/pages/trade/children/OrderRecord'
import Money from '@/components/pages/money/Money'
import DepositRecord from '@/components/pages/money/children/DepositRecord'
import BindBankCard from '@/components/pages/user/children/BindBankCard'
import Deposit from '@/components/pages/money/children/Deposit'
import Withdrawal from '@/components/pages/money/children/Withdrawal'
import DealFlow from '@/components/pages/trade/children/DealFlow'
import Mall from '@/components/pages/mall/Mall'
import GoodsLists from '@/components/pages/mall/children/GoodsLists'
import GoodsDetails from '@/components/pages/mall/children/GoodsDetails'
import GoodsExchange from '@/components/pages/mall/children/GoodsExchange'
import OrderLists from '@/components/pages/mall/children/OrderLists'
import ExchangeLists from '@/components/pages/mall/children/ExchangeLists'
import MyPositionRecord from '@/components/pages/user/children/MyPositionRecord'
import ProfileInfo from '@/components/pages/mall/children/ProfileInfo'
import Team from '@/components/pages/team/Team'
import TeamDetails from '@/components/pages/team/children/TeamDetails'
import ErrorMsg from '@/components/common/msg/ErrorMsg'
import About from '@/components/pages/about/About'
import AboutOne from '@/components/pages/about/children/AboutOne'
import AboutTwo from '@/components/pages/about/children/AboutTwo'
import AboutThree from '@/components/pages/about/children/AboutThree'
import Activity from '@/components/pages/activity/Activity'
import Settlement from '@/components/pages/activity/children/Settlement'
import ActivityList from '@/components/pages/activity/children/ActivityList'
import Results from '@/components/common/results/Results'
import AcOrderLists from '@/components/pages/activity/children/AcOrderLists'
import MallIndex from '@/components/pages/mall/MallIndex'
import ProductDetails from '@/components/pages/mall/children/ProductDetails'

//创建路由，配置路由规则
const router = new VueRouter({
    routes: [
        { 
            path : '/', 
            component: Home,
            meta: {
                isLogin: true
            },
        },
        //首页
        { 
            path: '/home', 
            component: Home,
            meta: {
                isLogin: true
            },
            children: [
                
            ]
        },
        //用户中心页
        {
            path: '/user',
            component: User,
            meta: {
                isLogin: true
            },
        },
        //忘记登录密码页
        {
            path: '/user/forgetpassword',
            component: ForgetPassword,
            meta: {
                isLogin: false
            },
        },
        //修改登录密码页
        {
            path: '/user/modifypassword',
            component: ModifyPassword,
            meta: {
                isLogin: true
            },
        },
        //初始化资金密码页
        {
            path: '/user/moneypassword',
            component: MoneyPassword,
            meta: {
                isLogin: true
            },
        },
        //重置资金密码页
        {
            path: '/user/resetmoneypwd',
            component: ResetMoneyPwd,
            meta: {
                isLogin: true
            },
        },
        //修改资金密码页
        {
            path: '/user/editmoneypwd',
            component: EditMoneyPwd,
            meta: {
                isLogin: true
            },
        },
        //用户签约绑卡页
        {
            path: '/user/bindbankcard',
            component: BindBankCard,
            meta: {
                isLogin: true,
                keepAlive: true
            },
        },
        //个人信息页
        {
            path: '/user/information',
            component: Information,
            meta: {
                isLogin: true
            },
        },
        //实名认证页
        {
            path: '/user/realnameauth',
            component: RealnameAuth,
            meta: {
                isLogin: true
            },
        },
        //登录页
        {
            path: '/login',
            component: Login,
            meta: {
                isLogin: false
            },
        },
        //注册页
        {
            path: '/register',
            component: Register,
            meta: {
                isLogin: false,
                keepAlive: true
            },
        },
        //消息中心页
        {
            path: '/messages',
            component: Messages,
            meta: {
                isLogin: true
            },
            children: [
                //消息详情页
                {
                    path: 'details',
                    component: Details,
                    meta: {
                        isLogin: true
                    },
                }
            ]
        },
        //市场交易页
        {
            path: '/market',
            component: Market,
            meta: {
                isLogin: true
            },
            children: [

            ]
        },
        //拍品（商品）详情页
        {
            path: '/market/auctiondetails',
            component: AuctionDetails,
            meta: {
                isLogin: true
            }
        },
        //交易页
        {
            path: '/trade',
            component: Trade,
            redirect: '/trade/delistingbuy',
            meta: {
                isLogin: true
            },
            children: [
                //委托撤单页
                { 
                    path: 'cancelorder', 
                    component: CancelOrder, 
                    meta: {
                        isLogin: true
                    } 
                },
                //摘牌买入页
                { 
                    path: 'delistingbuy', 
                    component: DelistingBuy, 
                    meta: {
                        isLogin: true
                    } 
                },
                //摘牌卖出页
                { 
                    path: 'delistingsell', 
                    component: DelistingSell, 
                    meta: {
                        isLogin: true
                    } 
                },
                //委托买入页
                { 
                    path: 'entrustbuy', 
                    component: EntrustBuy, 
                    meta: {
                        isLogin: true
                    } 
                },
                //委托卖出页
                { 
                    path: 'entrustsell', 
                    component: EntrustSell, 
                    meta: {
                        isLogin: true
                    } 
                }
            ]
        },
        //交易持仓汇总页
        {
            path: '/trade/positionsummary',
            component: PositionSummary,
            meta: {
                isLogin: true
            }
        },
        //订单记录页
        {
            path: '/trade/orderrecord',
            component: OrderRecord,
            meta: {
                isLogin: true
            }
        },
        //交易持仓明细页
        {
            path: '/trade/positionlists',
            component: PositionLists,
            meta: {
                isLogin: true
            }
        },
        //成交记录页
        {
            path: '/trade/dealrecord',
            component: DealRecord,
            meta: {
                isLogin: true
            }
        },
        //仓量明细页
        {
            path: '/user/mypositionrecord',
            component: MyPositionRecord,
            meta: {
                isLogin: true
            }
        },
        //交易流水页
        {
            path: '/trade/dealflow',
            component: DealFlow,
            meta: {
                isLogin: true
            }
        },
        //用户地址管理列表页
        {
            path: '/delivery/useraddress',
            component: UserAddress,
            meta: {
                isLogin: true
            },
            children: [
                
            ]
        },
        //新增地址页
        {
            path: '/delivery/useraddress/adduseraddress',
            component: AddUserAddress,
            meta: {
                isLogin: true
            }
        },
        //修改地址页
        {
            path: '/delivery/useraddress/edituseraddress',
            component: EditUserAddress,
            meta: {
                isLogin: true
            }
        },
        //我要提货页
        {
            path: '/delivery/delivery',
            component: Delivery,
            meta: {
                isLogin: true
            }
        },
        //提货记录查询页
        {
            path: '/delivery/deliverylist',
            component: DeliveryList,
            meta: {
                isLogin: true
            }
        },
        //物流轨迹查询页
        // {
        //     path: '/delivery/logistictrack',
        //     component: LogisticTrack,
        //     meta: {
        //         isLogin: true
        //     }
        // },
        //平台拍卖注册页
        {
            path: '/spotregisterrules',
            component: SpotRegisterRules,
            meta: {
                isLogin: false
            }
        },
        //平台拍卖规则页
        {
            path: '/spotauctionrules',
            component: SpotAuctionRules,
            meta: {
                isLogin: false
            }
        },
        //平台风险提示页
        {
            path: '/spotriskrules',
            component: SpotRiskRules,
            meta: {
                isLogin: false
            }
        },
        //资金管理页
        {
            path: '/money',
            component: Money,
            meta: {
                isLogin: true
            },
            children: [
                {
                    path: 'depositrecord',
                    component: DepositRecord,
                    meta: {
                        isLogin: true
                    }
                }
            ]
        },
        //入金页
        {
            path: '/money/deposit',
            component: Deposit,
            meta: {
                isLogin: true
            }
        },
        //出金页
        {
            path: '/money/withdrawal',
            component: Withdrawal,
            meta: {
                isLogin: true
            }
        },
        //商城首页
        {
            path: '/mall',
            component: Mall,
            redirect: '/mall/profileinfo',
            meta: {
                isLogin: true
            },
            children: [
                //商品列表页
                {
                    path: 'goodslists',
                    component: GoodsLists,
                    meta: {
                        isLogin: true
                    }
                },
                //订单记录页
                {
                    path: 'orderlists',
                    component: OrderLists,
                    meta: {
                        isLogin: true
                    }
                },
                //通兑记录页
                {
                    path: 'exchangelists',
                    component: ExchangeLists,
                    meta: {
                        isLogin: true
                    }
                },
                //商城个人信息页
                {
                    path: 'profileinfo',
                    component: ProfileInfo,
                    meta: {
                        isLogin: true
                    }
                }
            ]
        },
        //重构商城页
        {
            path: '/mall/index',
            component: MallIndex,
            meta: {
                isLogin: true
            },
            children: [
                
            ]
        },
        //重构商品详情页
        {
            path: '/mall/productdetails',
            component: ProductDetails,
            meta: {
                isLogin: true
            }
        },
        //商品详情页
        {
            path: '/mall/goodslists/goodsdetails',
            component: GoodsDetails,
            meta: {
                isLogin: true
            }
        },
        //商品购买确认页
        {
            path: '/mall/goodsexchange',
            name: 'GoodsExchange',
            component: GoodsExchange,
            meta: {
                isLogin: true
            }
        },
        //团队基本信息页
        {
            path: '/team',
            name: '/team',
            component: Team,
            meta: {
                isLogin: true
            }
        },
        //团队结构详情页
        {
            path: '/team/teamdetails',
            name: '/team/teamdetails',
            component: TeamDetails,
            meta: {
                isLogin: true
            }
        },
        //关于公司简介页
        {
            path: '/about',
            component: About,
            redirect: '/about/aboutthree',
            meta: {
                isLogin: false
            },
            children: [
                //关于现代人介绍页
                {
                    path: 'aboutone',
                    name: 'aboutone',
                    component: AboutOne,
                    meta: {
                        isLogin: false
                    }
                },
                //关于爱酒汇介绍页
                {
                    path: 'abouttwo',
                    name: 'abouttwo',
                    component: AboutTwo,
                    meta: {
                        isLogin: false
                    }
                },
                //关于元均国际介绍页
                {
                    path: 'aboutthree',
                    name: 'aboutthree',
                    component: AboutThree,
                    meta: {
                        isLogin: false
                    }
                }
            ]
        },
        //活动页
        {
            path: '/activity',
            component: Activity,
            meta: {
                isLogin: true
            },
        },
        //活动列表页
        {
            path: '/activity/activitylist',
            name: 'activitylist',
            component: ActivityList,
            meta: {
                isLogin: true
            }
        },
        //活动结算页
        {
            path: '/activity/settlement',
            name: 'settlement',
            component: Settlement,
            meta: {
                isLogin: true
            }
        },
        //活动订单页
        {
            path: '/activity/orderlists',
            name: 'orderlists',
            component: AcOrderLists,
            meta: {
                isLogin: true
            }
        },
        //错误404页
        {
            path: '*',
            component: ErrorMsg
        },
        //执行结果提示页
        {
            path: '/results',
            name: 'results',
            component: Results,
            meta: {
                isLogin: true
            }
        }
    ]
})

//挂载路由导航守卫
router.beforeEach((to, from, next) => {
    //从cookie中获取登录信息
    const access_sid = Cookies.get('SI')
    const access_uid = Cookies.get('U')
    //to要访问的路径，from从哪个路径跳转而来，next函数表示放行
    if (to.meta.isLogin) {
        if (access_uid == null || access_uid == undefined || access_uid == '' || access_sid == null || access_sid == undefined || access_sid == '') {
            return next('/login')
        } else {
            return next()
        }
    } else {
        return next()
    }
})

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export default router