<template>
    <div class="forget-pwd">
        <el-page-header @back="goBack" content="修改资金密码" style="font-weight: bolder;">
        </el-page-header>
        <el-divider></el-divider>
        <el-form ref="passwordForm" :model="passwordForm" :rules="rules" label-width="100px">
            <el-form-item label="原密码" prop="OP">
                <el-input v-model="passwordForm.OP" placeholder="请输入原密码" show-password></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="NP">
                <el-input v-model="passwordForm.NP" placeholder="请输入新支付密码" show-password></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="confirmPwd">
                <el-input v-model="passwordForm.confirmPwd" placeholder="确认支付密码" show-password></el-input>
            </el-form-item>
            <div class='forgetmoneypwd'>
                <el-link type="danger" :underline="false" @click="toSetMoneyPwd">忘记密码？</el-link>
            </div>
            <el-button class="submit-btn" type="success" @click="onSubmit">确认</el-button>
        </el-form>
    </div>
</template>

<script>
    import { loginApi } from '@/config/env'
    import { isFailureLogin } from '@/config/mUtils'
    export default {
        name: 'MoneyPassword',
        data() {
            //自定义规则
            var validateConfirmPass = (rule, value, callback) => {
                if (value === '') {
                    return callback(new Error('请再次输入密码'))
                } else if (value !== this.passwordForm.NP) {
                    return callback(new Error('两次输入密码不一致!'))
                } else {
                    callback()
                }
            }
            return {
                passwordForm: {
                    "name": "change_funds_password",
                    "U": "",
                    "SI": "",
                    "OP": "",
                    "NP": "",
                    "confirmPwd": ""
                },
                rules: {
                    OP: [
                        { required: true, message: '请输入原密码', trigger: 'blur' }
                    ],
                    NP: [
                        { required: true, message: '请输入新的支付密码', trigger: 'blur' }
                    ],
                    confirmPwd: [
                        { validator: validateConfirmPass, trigger: 'blur'}
                    ]
                }
            }
        },
        methods: {
            //返回
            goBack() {
                this.$router.go(-1)
            },
            //提交设置新密码
            onSubmit() {
                this.$refs.passwordForm.validate( async (valid) => {
                    if(!valid) return false
                    const { data: res } = await this.$http.post(loginApi.editMoneyPwdApi, this.passwordForm)
                    if (isFailureLogin(res.retcode)) {
                        this.$message.success(res.message)
                        this.$router.push('/user')
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },
            //忘记密码去重置
            toSetMoneyPwd() {
                this.$router.push('/user/resetmoneypwd')
            }
        }
    }
</script>

<style lang="less" scoped>
    .forget-pwd {
        width: 100%;
        padding: 1rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        form {
            margin-top: 5%;
        }
    }
    .submit-btn {
        width: 100%;
    }
    .forgetmoneypwd {
        width: 100%;
        height: 2.5rem;
        text-align: right;
        line-height: 2.5rem;
        margin-bottom: 1rem;
        a {
            text-decoration: none;
        }
    }
</style>