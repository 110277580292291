<template>
    <div class="mall">
        <div class="mall-title">
            <h4>我的商城</h4>
            <el-divider></el-divider>
        </div>
        <div class="mall-main">
            <el-container class="mall-item">
                <el-aside style="width: 12rem;overflow-x: hidden;overflow-y: auto;background-color: #FFFFFF;">
                    <el-menu :default-active="activeMenu" @select="handleSelect" unique-opened>
                        <!-- <el-submenu index="1">
                            <template slot="title"><i class="el-icon-menu"></i>分类</template>
                            <el-menu-item-group>
                                <el-menu-item @click="queryGoodsByCate()" index="-1">全部</el-menu-item>
                                <el-menu-item @click="queryGoodsByCate(item.type_id)" 
                                    :index="item.type_id" v-for="(item, index) in cateLists" 
                                    :key="index">{{ item.type_name }}
                                </el-menu-item>
                            </el-menu-item-group>
                        </el-submenu>
                        <el-submenu index="2">
                            <template slot="title"><i class="el-icon-goods"></i>特色</template>
                            <el-menu-item-group>
                                <el-menu-item index="2-1">农产品1</el-menu-item>
                                <el-menu-item index="2-2">农产品2</el-menu-item>
                                <el-menu-item index="2-3">农产品3</el-menu-item>
                            </el-menu-item-group>
                        </el-submenu> -->
                        <el-submenu index="1">
                            <template slot="title"><i class="el-icon-menu"></i>全部</template>
                            <el-menu-item-group>
                                <el-menu-item @click="selectGoods()" index="-1">去购物</el-menu-item>
                            </el-menu-item-group>
                        </el-submenu>
                        <el-submenu index="3">
                            <template slot="title"><i class="el-icon-setting"></i>订单</template>
                            <el-menu-item-group>
                                <el-menu-item index="3-1">订单记录</el-menu-item>
                                <el-menu-item index="3-2">订单地址</el-menu-item>
                            </el-menu-item-group>
                        </el-submenu>
                        <el-submenu index="4">
                            <template slot="title"><i class="el-icon-user"></i>我的</template>
                            <el-menu-item-group>
                                <el-menu-item index="4-1">兑换记录</el-menu-item>
                                <el-menu-item index="4-2">个人信息</el-menu-item>
                            </el-menu-item-group>
                        </el-submenu>
                    </el-menu>
                </el-aside>
                <el-container>
                    <!-- 路由占位符 -->
                    <router-view></router-view>
                </el-container>
            </el-container>
        </div>
        <FooterNavigation />
    </div>
</template>

<script>
    import FooterNavigation from '@/components/common/footer/FooterNavigation.vue'
import { noLoginApi } from '@/config/env'
    
    export default {
        components: { FooterNavigation },
        name: 'Mall',
        data() {
            return {
                cateLists: [],
                activeMenu: '',
            }
        },
        mounted() {
            this.getCateLists()
        },
        methods: {
            //选中菜单触发事件
            handleSelect(key, keyPath) {
                console.log(key, keyPath)
                this.activeMenu = key
                switch (key) {
                    case '3-2':
                        this.$router.push('/delivery/useraddress')
                        break;
                    case '3-1':
                        this.$router.push('orderlists')
                        break;
                    case '4-1':
                        this.$router.push('exchangelists')
                        break;
                    case '4-2':
                        this.$router.push('profileinfo')
                        break;
                    default:
                        break;
                }
            },
            //获取分类
            async getCateLists() {
                const { data: res } = await this.$http.post(noLoginApi.mallCateListsApi)
                if (res.code !== 'E000000') {
                    return this.$message.warning(res.message)
                } else {
                    this.cateLists = res.data
                }
            },
            handleClick() {

            },
            queryGoodsByCate(type_id) {
                this.$router.push({ path: 'goodslists', query: {
                    tid: type_id
                }})
                this.$bus.$emit('getCateId', type_id)
            },
            //去购物
            selectGoods() {
                this.$router.push('/mall/index')
            }
        }    
    }
</script>

<style lang="less" scoped>
    .mall {
        width: 100%;
        height: 100%;
        position: absolute;
        overflow: hidden;
        .mall-title {
            color: #333333;
            text-align: center;
            width: 100%;
            position: fixed;
            top: 0;
            left: 0;
            h4 {
                line-height: 3rem;
                font-weight: bolder;
            }
            /deep/ .el-divider--horizontal {
                margin: 0;
            }
        }
        .mall-main {
            width: 100%;
            height: 80%;
            position: absolute;
            top: 6rem;
            .mall-item {
                width: 100%;
                height: 100%;
                display: flex;
                flex-wrap: row nowrap;
                justify-content: space-between;
            }
            .shop-item {
                width: 100%;
                background-color: #FFFFFF;
                margin: 0 0 0.5rem 0;
                border-radius: 0.5rem;
                display: flex;
                flex-wrap: row nowrap;
                justify-content: space-between;
                padding: 0.3rem;
                cursor: pointer;
                .item-left {
                    width: 50%;
                    height: auto;
                }
                .item-right {
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    padding: 1rem;
                    font-weight: 600;
                    justify-content: space-around;
                    align-items: flex-start;
                    font-weight: 600;
                    div:first-child {
                        font-size: 1.5rem;
                        color: #FF0000;
                    }
                }
            }
        }
    }
        
</style>