<template>
    <div class="forget-pwd">
        <el-page-header @back="goBack" content="忘记密码" style="font-weight: bolder;">
        </el-page-header>
        <el-divider></el-divider>
        <el-form ref="forgetData" :model="forgetData" :rules="rules" label-width="100px">
            <el-form-item label="手机号" prop="MO">
                <el-input v-model="forgetData.MO" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="IC">
                <el-input v-model="forgetData.IC" placeholder="请输入验证码">
                    <el-button type="default" :disabled="isSend" slot="append" @click="sendMsg">
                        <span>{{ codeText }}</span>
                    </el-button>
                </el-input>
            </el-form-item>
            <el-form-item label="设置新密码" prop="NPWD">
                <el-input v-model="forgetData.NPWD" placeholder="请输入新密码" show-password></el-input>
            </el-form-item>
            <el-form-item label="确认新密码" prop="confirmPwd">
                <el-input v-model="forgetData.confirmPwd" placeholder="确认密码" show-password></el-input>
            </el-form-item>
            <el-button class="submit-btn" type="success" @click="onSubmit">确认</el-button>
        </el-form>
    </div>
</template>

<script>
import { noLoginApi } from '@/config/env'
    export default {
        name: 'ForgetPassword',
        data() {
            //校验手机号
            var checkPhone = (rule, value, callback) => {
                var phoneReg = /^1[3456789]\d{9}$/
                if (!value) {
                    return callback(new Error('手机号不能为空！'))
                } else if (!phoneReg.test(value)) {
                    return callback(new Error('手机号不合法！'))
                } else {
                    callback()
                }
            }
            var validateConfirmPass = (rule, value, callback) => {
                if (value === '') {
                    return callback(new Error('请再次输入密码'))
                } else if (value !== this.forgetData.NPWD) {
                    return callback(new Error('两次输入密码不一致!'))
                } else {
                    callback()
                }
            }
            var checkNumber = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('短信验证码不能为空！'))
                }
                var result = parseInt(value)
                if (isNaN(result)) {
                    callback(new Error('验证码格式不合法！'))
                } else {
                    callback()
                }
            }
            return {
                forgetData: {
                    "name":"reset_userpwd",
                    "MO": "",
                    "IC": "",
                    "NPWD": "",
                    "confirmPwd": ""
                },
                //短信验证码
                sendPhoneMsg: {
                    "name":"get_resetpwd_icode",
                    "MO": ""
                },
                count: 300,
                codeText: '获取验证码',
                isSend: false,
                lock: null,
                timer: null,
                rules: {
                    MO: [
                        { required: true, message: '请输入手机号', trigger: 'blur' },
                        { validator: checkPhone, trigger: 'blur' }
                    ],
                    IC: [
                        { required: true, message: '请输入短信验证码', trigger: 'blur' },
                        { validator: checkNumber, trigger: 'change' }
                    ],
                    NPWD: [
                        { required: true, message: '请输入新密码', trigger: 'blur' },
                        { min: 6, max: 30, message: "密码长度不符合要求：必须6~30个字符长度之间！", trigger: 'blur'}
                    ],
                    confirmPwd: [
                        { required: true, message: '请再次输入新密码', trigger: 'blur' },
                        { validator: validateConfirmPass, trigger: 'blur' }
                    ]
                }
            }
        },
        beforeDestroy() {
            clearInterval(this.lock)
            this.timer = null
        },
        methods: {
            //返回
            goBack() {
                this.$router.go(-1)
            },
            //提交设置新密码
            onSubmit() {
                this.$refs.forgetData.validate(async (valid) => {
                    if(!valid) return
                    const { data: res } = await this.$http.post(noLoginApi.resetPasswordApi, this.forgetData)
                    if (res.retcode < 0) {
                        return this.$message.warning(res.message)
                    }
                    this.$message.success('密码重置成功，请重新登录！')
                    this.$router.push('/login')
                })
            },
            //发送短信验证
            async sendMsg() {
                //判断是否手机号为空
                if (this.forgetData.MO == '') {
                    return this.$message.error('手机号不能为空！')
                }
                this.sendPhoneMsg.MO = this.forgetData.MO
                const { data: res } = await this.$http.post(noLoginApi.forgetPasswordSendMsgApi, this.sendPhoneMsg)
                if (res.retcode < 0) {
                    return this.$message.warning(res.message)
                }
                this.$message.success(res.message)
                this.codeText = this.count + 's后重新发送'
                this.lock = setInterval( () => {
                    this.isSend = true
                    this.count--
                    this.codeText = this.count + 's后重新发送'
                    if (this.count < 0) {  //当倒计时小于0时清除定时器
                        clearInterval(this.lock)
                        this.codeText = '重新发送验证码'
                        this.count = 300
                        this.isSend = false
                    }
                }, 1000)
            }
        }
    }
</script>

<style lang="less" scoped>
    .forget-pwd {
        width: 100%;
        padding: 1rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        form {
            margin-top: 5%;
        }
    }
    .submit-btn {
        width: 100%;
    }
</style>