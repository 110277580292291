<template>
    <div>
        <!-- 子路由占位符 -->
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: 'Money',
        data() {
            return {}
        },
        methods: {
            
        }    
    }
</script>

<style lang="less" scoped>
    
</style>