<template>
    <div class="spot-rules">
        <div class="rules-title">
            <el-page-header @back="goBack" content="注册协议" title="返回">
            </el-page-header>
        </div>
        <div class="rules-content">
            <h2 style="margin: 5px 0px; text-align: center; line-height: 21px;">
                <strong><span style="font-family: &quot;arial black&quot;, &quot;avant garde&quot;; font-size: 20px;">拍卖客户注册协议</span></strong>
            </h2>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
                <strong><span style=";font-family:微软雅黑;font-size:14px">签约前重要提醒：</span></strong>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">【审慎阅读】</span><strong><span style="text-decoration:underline;"><span style="font-family: 微软雅黑;font-size: 14px">注册申请人在点击同意本协议之前，应当认真阅读本协议。请申请人务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以下划线标识及加粗，申请人应重点阅读，如申请人对本协议免、限责任条款及协议中其他内容有任何疑问，可联系拍卖人客服咨询，我们将竭力向您进行讲解、说明，如果不予接受的，申请人应立即停止注册，注册成功的，视为已经对本协议免、限责任条款充分的理解，并予以接受。</span></span></strong>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">【签约动作】</span><strong><span style="text-decoration:underline;"><span style="font-family: 微软雅黑;font-size: 14px"><span style="font-family:微软雅黑">当申请人按照页面提示填写信息、阅读并同意本协议且完成全部程序后，即表示其已充分阅读、理解并接受本协议的全部内容，并与拍卖人达成一致，成为</span>“拍卖客户”。阅读本协议的过程中，如果申请人不同意本协议或其中任何条款约定，申请人应立即停止注册。</span></span></strong>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <strong><span style=";font-family:微软雅黑;font-size:14px">1、协议内容相关提示</span></strong>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">1.1签约主体：</span><strong><span style="text-decoration:underline;"><span style="font-family: 微软雅黑;font-size: 14px"><span style="font-family:微软雅黑">本协议由申请人与拍卖人缔结，本协议对申请人和拍卖人均具有合同效力。本协议是申请人与拍卖人就申请人参加产品的网络拍卖等相关事宜所订立的契约。申请人在注册时应当仔细阅读本协议，申请人点击</span>“阅读并确认”后，本协议即具有合同效力，对双方均具有法律约束力。</span></span></strong>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">1.2 根据《拍卖规则》等相关规定，申请人可申请成为拍卖客户，享有拍卖人为其提供的所有服务（包括拍卖人作为拍卖产品的保管方，为拍卖产品所有权人代为保管产品的服务）、接受对其进行的全部管理行为，并可进行拍卖、提货等行为。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">1.3 协议范围：</span><strong><span style="text-decoration:underline;"><span style="font-family: 微软雅黑;font-size: 14px"><span style="font-family:微软雅黑">本协议、《拍卖风险揭示书》、《拍卖规则》等通过网络展示方式向申请人、拍卖客户呈现的各类文书以及针对某些服务通过各种方式（包括但不限于网页</span>/公众号公告、拍卖软件通知、私信、短信提醒等）作出的任何声明、通知（包括但不限于调整相关手续费用等）、警示等内容视为本协议的一部分，如果申请人、拍卖客户对上述全部内容有异议的，可以向客服咨询，不予接受的，可以选择停止注册或注销拍卖账户，申请人、拍卖客户如使用服务，视为您同意上述各项管理制度及声明、通知、警示等内容。</span></span></strong>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">1.4协议形式：根据《中华人民共和国民法典》、《中华人民共和国电子签名法》关于民事合同使用数据电文、电子签名的规定，采用电子签名签订的电子合同具有与纸质合同同等的法律效力。以此作为法律依据，本着提高合同签署效率的目的，申请人同意在参与产品拍卖行为的过程中使用电子合同、电子签名。本协议仅在表明拍卖人与拍卖客户二者之间的权利义务关系，对于拍卖客户通过拍卖人与任何第三方建立的拍卖、买卖等关系以及由此产生的权利义务等，由拍卖客户与第三方自行解决。但拍卖人保留在中华人民共和国大陆地区施行之法律允许的范围内独立决定拒绝服务、冻结用户账户等权利。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">1.5法律地位声明：</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <strong><span style="text-decoration:underline;"><span style="font-family: 微软雅黑;font-size: 14px">拍卖人是仅为拍卖过程提供拍卖服务的服务商，仅负责网络拍卖软件的运行及维护，以及现场拍卖业务的组织，不干涉拍卖客户之间的拍卖交易行为，不是任何拍卖客户的交易对手，与拍卖客户之间不建立、不成立就拍卖产品买卖的法律关系。</span></span></strong><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">拍卖人不对申请人及</span>/或参与拍卖的其他用户作出的任何口头、书面陈述或承诺以及发布的信息及拍卖行为的真实性、合法性、准确性、及时性、有效性等作任何明示或暗示的保证，亦不承担任何法律责任。若因您与参与拍卖的其他用户之间的拍卖行为引起的任何纠纷，包括但不限于投诉、起诉、举报及税赋等，均由参与拍卖的双方解决，与拍卖人无关。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <strong><span style="text-decoration:underline;"><span style="font-family: 微软雅黑;font-size: 14px">1.6拍卖客户在参与网络拍卖过程中，与买受人或出让人达成买卖交易的，拍卖软件系统将拍品成交信息自动发送至产品实际保管方，此环节视为拍卖客户双方达成拍卖产品网络转让电子协议。保管方根据出让人的网络指令将产品按指示交付交于买受人。买受人根据上述指示交付方式获得拍卖产品的所有权。</span></span></strong>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <strong><span style=";font-family:微软雅黑;font-size:14px">2.客户权利与义务：</span></strong>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">2.1 资格条件：申请注册的自然人应在整个阶段（包括但不限于注册账户时）需年满18周岁,具有完全民事行为和民事责任能力；申请注册的机构客户需是在中华人民共和国境内依法注册且合法存续的法人、其他经济组织等。若您不具备前述主体资格及行为能力，您应立即停止注册或参与拍卖活动，否则您及您的监护人或设立人应承担因此而导致的一切后果，拍卖人不承担任何责任且有权向您的监护人或设立人追偿。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">2.2拍卖客户权利</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>1）申请人自愿成为拍卖客户，并可以自主进行拍卖等行为。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>2）拍卖客户有权自行修改网络拍卖账户密码，拍卖客户应定期修改拍卖软件的登录密码和资金密码。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>3）拍卖客户有权注销其网络拍卖账户并承担由此带来的一切后果。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>4）拍卖客户如对拍卖人服务有疑问、建议或意见，可以联系客服、登录网站、公众号或拍卖软件或到拍卖人营业场所进行咨询。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>5）对拍卖人作出的关于不同意申请人注册成为拍卖客户或其他申请的，申请人可以向拍卖人予以咨询，并可以在条件符合时重新提出申请。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">2.3拍卖客户义务</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>1）申请人保证已阅读《拍卖规则》，并且符合该标准要求，其自身不存在任何法律、法规、拍卖规则、管理制度所规定的禁止或限制其成为拍卖客户并从事拍卖的情形。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>2）申请人保证已阅读拍卖规则、管理制度及其他相关文件，知晓操作方法并同意遵守上述规定。申请人已阅读并理解</span><span style="text-decoration:underline;"><span style="font-family: 微软雅黑;font-size: 14px">《拍卖风险揭示书》</span></span><span style=";font-family:微软雅黑;font-size:14px">，知晓拍卖风险并自愿承担拍卖风险。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>3）申请人必须提供真实、最新、有效及完整的资料,并且授予拍卖人基于提供拍卖服务的目的,对您提供的资料及数据信息拥有永久的、免费的使用权利。您有义务维持并更新注册的用户资料,确保其为真实、最新、有效及完整的资料。 如因您未及时更新资料导致拍卖人无法提供服务或提供服务时发生任何错误时，您不得将此作为取消交易或拒绝付款的理由，拍卖人亦不承担任何责任，所有后果应由您自行承担。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>4）拍卖客户保证资金的来源合法，非走私犯罪、毒品犯罪、黑社会性质组织犯罪及其他违法犯罪所得。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>5）</span><strong><span style=";font-family:微软雅黑;font-size:14px">申请人注册成功后，应妥善保管用户名和密码等账户信息，并对其账户项下的一切行为负责。一切以拍卖软件账号登陆的操作行为皆视为申请人的自主行为，</span></strong><span style=";font-family:微软雅黑;font-size:14px">申请人不得将账户提供给他人使用，否则申请人应承担由此产生的全部责任，并与实际使用人承担连带责任，与拍卖人无关。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>6）为保障拍卖客户的资金安全性，拍卖客户完成账户注册后，应及时与存管银行资金账户完成关联绑定，并签署相关协议。拍卖客户在办理银商关联业务后须遵守用户与存管银行之间签订的相关协议，违反相关协议导致的损失由拍卖客户自行承担。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>7）拍卖客户须到拍卖人指定的官方渠道登录个人拍卖帐户，下载拍卖软件，不得通过其他链接进行登录及下载。因拍卖客户通过其他网站提供的链接登录个人帐户、下载拍卖软件而产生的一切风险及责任，由拍卖客户自行承担。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>8）拍卖客户提交、发布或显示的信息将对其他拍卖客户及第三方服务及网站可见；对于通过拍卖客户的帐户、密码而发出的拍卖指令，全部视为其本人发出，拍卖客户承诺对上述拍卖指令承担全部责任。拍卖系统一旦执行拍卖客户通过帐户、密码提交的拍卖指令后，拍卖客户无权要求变更或撤销该拍卖交易指令。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>9）拍卖客户不得赠与、借用、租用、转让或买卖拍卖账户。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>10）</span><strong><span style="text-decoration:underline;"><span style="font-family: 微软雅黑;font-size: 14px"><span style="font-family:微软雅黑">拍卖客户必须遵守拍卖人对其所提供服务的相关规则和收费标准，拍卖人对相关服务规则（包括但不限于拍卖规则等各项涉及提供各项服务对应的规则）和收费标准进行变更的，只需按本协议规定在指定网络媒体或软件上进行公告即对申请人、拍卖客户生效，无需单独通知，如申请人</span>/拍卖客户不接受的，可以选择不参与拍卖交易并注销账户，不明之处，可以向客服咨询。</span></span></strong>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>11）</span><strong><span style="text-decoration:underline;"><span style="font-family: 微软雅黑;font-size: 14px">拍卖人可根据业务发展的需要，对提供给拍卖客户的服务内容进行增加、调整或停止，或对拍卖软件进行升级和优化，同时将在拍卖软件或指定网络媒体上予以公告。如拍卖客户不予接受内容变更的，可以选择注销账户；如果继续使用的，视为接受上述各项变更。如果有不明之处或进一步解释的需求，可以向客服沟通。</span></span></strong>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <strong><span style="text-decoration:underline;"><span style="font-family: 微软雅黑;font-size: 14px">对于本条上款情况，如拍卖人要求申请人、拍卖客户办理确认手续的，拍卖人可通过电话、短信、邮件、拍卖软件、指定网络媒体发布公布等途径告知申请人、拍卖客户，申请人、拍卖客户应当予以配合，如确认不同意的，应当立即注销账户，未注销账户的，视同确认同意。</span></span></strong>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>12）拍卖客户应按照拍卖规则进行拍卖交易，不得诋毁拍卖人声誉以及恶意攻击拍卖人网站及拍卖系统。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>13）因拍卖软件出现故障、错账等原因而导致拍卖客户获得不当得利，拍卖客户同意从其账户扣划不当得利及其孳息，或暂停对其提供的相关服务。如果无法通过账户扣划方式补救的或其账户内资金不足以用于补救的，拍卖人将通过司法途径向涉事拍卖客户主张相关权利，因此产生的相关费用包括但不限于诉讼费、律师费、交通费等以及造成拍卖人损失的，均由获得不当得利的拍卖客户承担。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>14）拍卖客户须按拍卖人规定缴纳拍卖手续费及其他相关费用。拍卖客户同意资金及需要交纳的费用均由第三方存管银行代收、代付、代扣。拍卖客户同意向拍卖人支付拍卖系统账户管理费，作为拍卖客户使用电子拍卖账户、拍卖人维护账户管理系统的专项费用。</span><strong><span style="text-decoration:underline;"><span style="font-family: 微软雅黑;font-size: 14px">账户管理费为：拍卖客户的拍卖账户存续期内资金于第三方存管银行账户内产生的全部利息。</span></span></strong>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>15）拍卖客户在使用拍卖人提供的服务过程中，必须遵循以下原则：</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">a.不得违反中华人民共和国法律法规及相关国际条约或规则；</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">b.不得违反与网络服务、拍卖人服务有关的网络协议、规定、程序及行业规则；</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">c.不得违反法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等要求；</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">d.不得进行任何可能对互联网或移动网正常运转造成不利影响的行为；</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">e.不得以任何方式侵犯其他任何人依法享有的专利权、著作权、商标权等知识产权，或姓名权、名称权、名誉权、荣誉权、肖像权、隐私权等人身权益，或其他任何合法权益；</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">f.不得以任何形式侵犯拍卖人的权利和利益或作出任何不利于拍卖人的行为；</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">g.不得从事其他任何影响拍卖人正常运营、破坏拍卖人经营模式或其他有害拍卖人的行为；</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">h.不得为其他任何非法目的而使用拍卖人服务。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>16）拍卖客户充分理解并同意：拍卖人及服务方仅为拍卖客户提供产品拍卖、信息存储、产品保管等服务，拍卖客户必须为自己注册帐号下发生的一切行为负责，包括因您所传送至拍卖人的任何内容、信息等所导致的不利后果，该等不利后果包括但不限于赔偿、罚款、司法/仲裁程序费用、律师费、合理支出、给拍卖人造成的损害等。拍卖客户应对其他注册客户发布的内容自行加以判断，并承担因使用该内容而给自己、他人及社会造成的法律责任，包括但不限于因对内容的准确性、真实性、完整性或实用性等的依赖而产生的风险。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>17）拍卖客户不得利用拍卖人进行任何形式的洗钱活动。拍卖人有权配合主管机关的调查，向主管机关提交申请人、拍卖客户的身份资料、有关账户的记录等信息。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>18）拍卖客户开设拍卖账户时，应按要求填写开户资料，如拍卖客户变更其基本信息资料时，应及时通知拍卖人办理变更手续。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>19）拍卖客户在选择提货邮寄时，应仔细确认提货拍品、提货数量、提货日期、联系电话、提货人、配送地址等重要信息，如因上述信息不明确或错误而导致无法配送或延迟配送的，由此产生的损失由拍卖客户自行负责，拍卖人不承担任何责任。拍卖客户7日内未提出书面文件异议的，则默认拍品符合双方约定，后续拍卖人不再接收拍卖客户关于收货产品的疑问事项。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>20）拍卖客户选择自提的，在收到拍品时应当场主动验收拍品种类、数量及外观等重要信息，确认无误后方可收货，如拍卖客户在收货时未进行验收或未提出异议则视为其默认拍品符合双方约定，由此产生的损失由其自行承担。有关拍品所引发的争议，由委托人和拍卖客户自行协商解决，拍卖人对此不承担任何责任。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>21）拍卖客户在选择自提或邮寄时，收货人与本人不一致的，收货人的行为和意思表示视为拍卖客户本人的行为和意思表示，拍卖客户应对收货人的行为及意思表示的法律后果承担连带责任，拍卖人对此不承担任何责任。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>22）拍卖客户参与拍卖活动产生相关税费的应自行申报缴纳。依法纳税是每一个公民、企业应尽的义务，拍卖客户应对其所得额超过法定免征额部分及时、足额地向税务主管机关申报纳税。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>23）拍卖客户应当自行备份存储在系统中的数据。如果拍卖人不再提供服务的，您授权及同意拍卖人将有关的数据永久删除，服务终止后拍卖人没有继续储存您的数据的义务。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">2.4拍卖客户承诺与声明：</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>1）申请人自愿申请成为拍卖客户，符合拍卖客户标准，不存在法律法规和拍卖人相关业务规则禁止或限制的情形。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>2）申请人保证其提供的所有证件以及资料的真实性、完整性、有效性，并承担全部法律责任。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>3）申请人同意并遵守相关拍卖规则和细则的修订或增补，并清楚可以在拍卖软件或指定网络媒体上获取相关内容的公告和通知。申请人如不认同修订或增补意见的，有权注销其拍卖账户，不再参与拍卖交易。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>4）申请人授权拍卖人为登记结算机构，为其办理拍卖账户与其他账户之间的转让登记和资金划付。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>5）申请人委托拍卖人在其行使提货权之前，对其所购拍卖产品代为仓储保管。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>6）拍品按其现状进行买卖，申请人知晓并认可因摄影、显示等造成描述拍品的色调等与原物有误差的，以原物为准。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>7）申请人已详细阅读本协议所有条款，并准确理解其含义，特别是其中有关规则和免责条款。</span><strong><span style="text-decoration:underline;"><span style="font-family: 微软雅黑;font-size: 14px">产品拍卖是市场行为，拍卖客户在拍卖过程中的拍卖风险以及可能产生的损失，均由拍卖客户自行承担，与拍卖人无关，可能面临的拍卖风险请参考《拍卖风险揭示书》。</span></span></strong>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <strong><span style=";font-family:微软雅黑;font-size:14px">3.拍卖人权利与义务：</span></strong>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">3.1拍卖人权利</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>1）拍卖人有权根据其对拍卖客户的要求以及申请人的个人资产、信用等情况，作出是否同意申请人的申请的决定。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>2）</span><strong><span style="text-decoration:underline;"><span style="font-family: 微软雅黑;font-size: 14px">拍卖人披露的产品信息均来源于委托人提供的材料，不是对拍卖客户的购买建议或产品信息保证，拍卖人对其真实性和准确性不承担责任，拍卖客户参与拍卖时，须了解确认拍卖产品真实情况后作出判断，如出现不可归责于委托人及拍卖人的风险或损失，由拍卖客户自行承担。</span></span></strong><span style=";font-family:微软雅黑;font-size:14px">拍卖人应通过网站及相关软件及时发布有关产品信息，但该产品信息仅作为参考之用，不构成对拍卖客户进行产品拍卖的任何要求、指导，据此进行拍卖发生的风险由拍卖客户自行承担。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>3）达到拍卖产品预定的拍卖截止日期，拍卖人有权终止拍卖，但应在其指定网络媒体或拍卖软件上进行公示。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>4）拍卖人有权对拍卖客户使用拍卖人服务的行为及信息进行审查、监督及处理，包括但不限于产品拍卖（金额、数量、买卖双方信息等）、客户信息（账号信息、个人信息等）等范畴。如拍卖人发现、或收到第三方举报或投诉拍卖客户在使用服务时违反本协议拍卖客户使用规则相关规定，拍卖人或其授权的主体有权依据其合理判断要求拍卖客户：</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">a.限期改正;</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">b.不经通知直接采取一切必要措施以减轻或消除拍卖客户不当行为造成的影响，并将尽可能在处理之后对拍卖客户进行通知。上述必要措施包括但不限于冻结账号、限制或禁止违规账号部分或全部功能、暂停或终止拍卖客户使用拍卖人服务的权利等。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>5）发生下列情况时，拍卖人可冻结拍卖客户帐户，并有权根据实际情况作出终止拍卖客户资格等决定，以保障拍卖人作为经营者的合法权利：</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">a、拍卖客户账户连续六个月未登录拍卖软件。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">b、拍卖客户恶意操作，损害或威胁拍卖人及其他人的合法利益的。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">c、其他违反法律、法规或拍卖规则，对拍卖人及其他人的合法利益造成损害的行为。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">d、恶意诉讼，对拍卖人声誉产生不良影响。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:Calibri;font-size:14px">e</span><span style=";font-family:微软雅黑;font-size:14px">、政府主管机关因各种原因要求拍卖客户停止参拍业务的，或要求拍品停止拍卖的。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <strong><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>6）对于通过拍卖帐户、密码而发出的指令，全部视为拍卖客户本人发出。拍卖人按照上述指令行事，产生的一切结果均由拍卖客户本人承担。</span></strong>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>7）</span><strong><span style="text-decoration:underline;"><span style="font-family: 微软雅黑;font-size: 14px"><span style="font-family:微软雅黑">因下列行为未能及时、正确地执行拍卖客户的指令时，拍卖人不承担任何责任</span>:</span></span></strong>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:14px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">&nbsp; &nbsp;a、任何原因造成的拍卖客户指令资讯不明、不完整等情况。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">&nbsp;b、拍卖客户帐户存在被冻结、被扣划等强制措施（包括行政、司法机关作出的强制措施）。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">&nbsp; &nbsp;&nbsp;&nbsp;c、拍卖客户帐户资金不足，或资金来源非法。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:14px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">&nbsp; &nbsp;d、拍卖客户的指令出于任何非法目的。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">&nbsp; &nbsp;&nbsp;&nbsp;e、拍卖客户未按照规定正确操作。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">&nbsp; &nbsp;&nbsp;&nbsp;f、不可抗力、网络攻击或病毒攻击、系统故障、通讯设施故障、电力设施故障或其他不可归责于拍卖人的情况。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>8）</span><strong><span style="text-decoration:underline;"><span style="font-family: 微软雅黑;font-size: 14px">拍卖人需要定期或不定期地对提供服务的平台（如互联网网站等）或相关的设备进行检修或者维护，如因此类情况而造成服务在合理时间内的中断，拍卖人无需为此承担任何责任，但应尽可能事先进行通告。</span></span></strong>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px">
                <span style=";font-family:微软雅黑;font-size:14px">拍卖人在对拍卖软件系统进行维护、升级、更换，将提前于拍卖人的指定网络媒体或拍卖软件上发布公告，无需单独通知拍卖客户。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>9）如出现涉及拍卖客户财产继承权或财产归属的纠纷，拍卖人均按公证机关出具的公证书或司法机关的裁决办理。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">&nbsp;3.2拍卖人义务</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>1）在系统及指令均不存在异常时，应准确、及时地执行拍卖客户的拍卖交易指令。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>2）对于拍卖客户提出的与拍卖客户服务相关的咨询，拍卖人应认真、及时地做出解答。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>3）拍卖人应认真维护拍卖系统的安全及稳定运作。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>4）拍卖人将对拍卖客户使用服务过程中涉及的客户隐私信息加以保护。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（</span>5）拍卖人可在法律法规授权、可在行政法规许可、监管部门要求以及拍卖客户同意的前提下，使用客户资料及帐户拍卖记录，但是需对拍卖客户的资料和帐户拍卖记录尽保密义务，但法律另有规定或监管部门另有要求的除外。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <strong><span style=";font-family:微软雅黑;font-size:14px">4.个人信息保护</span></strong>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">4.1依据《中华人民共和国网络安全法》、《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网客户个人信息保护规定》、《中华人民共和国个人信息保护法》、《最高人民法院、最高人民检察院关于办理侵犯公民个人信息刑事案件适用法律若干问题的解释》、《中华人民共和国刑法修正案（九）》以及《最高人民法院关于审理利用信息网络侵害人身权益民事纠纷案件适用法律若干问题的规定》等法律法规规定，拍卖人将切实做到保护拍卖客户个人信息安全，秉持合法、正当和必要的原则使用客户个人信息。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">4.2保护客户个人信息是拍卖人的一项基本政策，拍卖人保证不会将全部或部分拍卖客户的注册资料及其在使用服务时存储在服务方或其指定第三方APP的非公开内容用于任何非法的用途，且保证将拍卖客户的注册资料进行商业上的利用时应事先获得客户的同意，但下列情况除外：</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">a.事先获得拍卖客户的明确授权；</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">b.为维护社会公共利益；</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">c.学校、科研机构等基于公共利益为学术研究或统计的目的，经拍卖客户书面同意，且公开方式不足以识别特定自然人；</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">d.拍卖客户自行在网络上公开的信息或其他已合法公开的个人信息；</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">e.以合法渠道获取的个人信息；</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">f.拍卖客户侵害拍卖人合法权益，为维护前述合法权益且在必要范围内；</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">g.根据相关政府主管部门、司法机关的要求；</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">h.根据相关法律法规或政策的要求；</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">i.其他必要情况。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">4.3</span><strong><span style="text-decoration:underline;"><span style="font-family: 微软雅黑;font-size: 14px">为提升拍卖人服务的质量，拍卖人可能会与第三方合作共同向拍卖客户提供拍卖相关的各项服务，此类合作可能需要包括但不限于拍卖客户数据与第三方合作机构共享、数据互通。同时，第三方合作机构也将签署拍卖客户数据保密协议，履行与拍卖人相同的拍卖客户保密义务。将尽一切合理努力保护拍卖客户个人信息的安全性。在此情况下，拍卖客户知晓并同意上述行为。</span></span></strong>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <strong><span style=";font-family:微软雅黑;font-size:14px">5.纠纷解决条款</span></strong><span style=";font-family:微软雅黑;font-size:14px">：</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">5.1&nbsp;本协议适用中华人民共和国相关法律。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">5.2&nbsp;协议中未尽事宜，参照相关法律、制度规定或业内通行的惯例解决。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">5.3&nbsp;</span><strong><span style="text-decoration:underline;"><span style="font-family: 微软雅黑;font-size: 14px">如因执行本协议发生纠纷，双方应进行协商。协商不成的，可向拍卖人所在地有管辖权的法院提起诉讼。</span></span></strong>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <strong><span style=";font-family:微软雅黑;font-size:14px">6.协议的变更或终止：</span></strong>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">6.1&nbsp;拍卖人通过拍卖软件或指定网络媒体发布的与拍卖及客户服务相关的公告，为本协议不可分割的一部分。如公告与本协议有冲突之处，以公告内容为准。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">6.2&nbsp;</span><strong><span style="text-decoration:underline;"><span style="font-family: 微软雅黑;font-size: 14px">因拍卖客户申请注销账户、撤销或其他原因导致的拍卖客户账户被注销，本协议即终止。</span></span></strong>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">6.3拍卖人有权根据法律、法规、相关政策及实际拍卖情况修改本协议的任何条款，一旦本协议的内容发生变动，将会在拍卖软件或指定网络媒体上公布修改之后的协议内容，若拍卖客户不同意上述修改，则可以选择停止使用服务。如果拍卖客户继续使用服务，则视为其接受拍卖人对本协议相关条款所做的修改。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">&nbsp; &nbsp;&nbsp;6.4在拍卖客户违法、不正当操作或者存在其他违反法律、法规、本协议或相关业务规定的情况下，拍卖人可单方面中止或终止本协议。在本协议中止或终止后，拍卖客户之前发出的拍卖交易指令仍然有效，拍卖人根据其拍卖交易指令行事的后果由拍卖客户承担。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">6.5&nbsp;本协议任何条款因任何原因被确认无效，均不影响其他条款的效力。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <span style=";font-family:微软雅黑;font-size:14px">6.6本协议内容中以加粗及下划线方式显著标识的条款，请申请人着重阅读。申请人点击“阅读并确认”按钮即视为申请人完全接受本协议，在点击之前请申请人再次确认已知悉并完全理解本协议的全部内容。</span>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph">
                <strong><span style="text-decoration:underline;"><span style="font-family: 微软雅黑;font-size: 14px">以上内容本人已详细阅读并同意。</span></span></strong>
            </p>
            <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0">
                <span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
            </p>
            <p>
                <span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
            </p>
            <p>
                <br/>
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SpotRegisterRules',
        data() {
            return {}
        },
        methods: {
            //返回
            goBack() {
                this.$router.go(-1)
            }
        }
    }
</script>

<style lang="less" scoped>
    .spot-rules {
        width: 100%;
        height: 100%;
        padding: 0.5rem;
        position: absolute;
        top: 0;
        left: 0;
        overflow-y: hidden;
        overflow-x: hidden;
        .rules-title {
            width: 100%;
            height: 3rem;
            position: fixed;
        }
        .rules-content {
            width: 100%;
            height: 80rem;
            position: absolute;
            margin-top: 4rem;
            overflow: auto;
            padding: 1rem;
        }
    }
</style>