<template>
  <div class="contianer-main">
    <!-- 路由占位符 -->
    <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import { loginApi, noLoginApi } from "@/config/env"
import { isFailureLogin } from '@/config/mUtils'
//引入js-cookies
import Cookies from 'js-cookie'
export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true,
      timer: null,
      //查询用户信息参数
      queryUserParams: {
          "name": "userinfo_query",
          "U": "",
          "SI": ""
      },
      //查询用户扩展信息参数
      queryUserExtendParams: {
          "name": "userInfo",
          "U": "",
          "SI": ""
      },
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      const access_sid = Cookies.get('SI')
      const access_uid = Cookies.get('U')
      if (access_uid == null || access_uid == undefined || access_uid == '' || access_sid == null || access_sid == undefined || access_sid == '') {
        return
      } else {
        this.getUserInfos()
      }
    }, 1000*60*3)
  },
  methods: {
    //获取用户信息
    async getUserInfos() {
        const { data: res } = await this.$http.post(loginApi.userInfoApi, this.queryUserParams)
        if (isFailureLogin(res.retcode)) {
            this.userInfos = res.result
            this.getUserExtendInfos()
        } else {
            //this.$message.warning(res.message)
            return
        }
    },
    //获取用户扩展信息
    async getUserExtendInfos() {
        const { data: res } = await this.$http.post(loginApi.userInfoExtendApi, this.queryUserExtendParams)
        if (res.code !== 'E000000') {
            //this.$message.warning(res.message)
            return
        } else {
            this.userExtendInfos = res.data
            //将用户信息保存到vuex中
            this.$store.dispatch('getUserInfos', {...this.userInfos, ...res.data})
        }
    },
    reload() {
      this.isRouterAlive = false
      this.$nextTick( () => {
        this.isRouterAlive = true
      })
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  }
}
</script>

<style lang="less">
  html, body, #app {
    margin: 0;
    padding: 0;
    height: 100%;
    min-width: none;
    background-color: #EFEFF4 !important;
    overflow: auto;
  }
  .contianer-main {
    width: 100%;
    margin: 0 auto;
    overflow: auto;
  }
  [v-cloak] {
    display:none !important;
  }
  /deep/ .el-message-box__wrapper {
    position: fixed !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    /deep/ .el-message-box {
      width: 60%;
    }
  }
</style>
