<template>
    <div class="settlement-main">
        <el-page-header @back="goBack" content="活动详情" style="font-weight: bolder;">
        </el-page-header>
        <el-divider></el-divider>
        <!-- 活动信息 -->
        <el-card class="box-card" v-if="salonDetails!=null">
            <el-divider content-position="left" class="divider-context">活动信息</el-divider>
            <p>活动标题：{{ salonDetails.salon_title }}</p>
            <p>活动组织者：{{ salonDetails.salon_user_name }}</p>
            <p>活动时间：{{ salonDetails.salon_start_time }} ~ {{ salonDetails.salon_end_time }}</p>
            <p>活动地址：{{ salonDetails.salon_location }}</p>
            <p>活动说明：{{ salonDetails.salon_remark }}</p>
        </el-card>
        <el-card class="box-card" v-else>
            <el-divider content-position="left" class="divider-context">活动信息</el-divider>
            <el-empty description="暂无任何信息" :image-size="30"></el-empty>
        </el-card>
        <!-- 收货地址 -->
        <el-card class="box-card">
            <el-divider content-position="left" class="divider-context">收货地址</el-divider>
            <el-row :gutter="20" v-if="JSON.stringify(itemAddress) !== '{}'" >
                <el-col :span="18">
                    <div class="addr-name"><span>{{ itemAddress.RVN }}</span>&nbsp;&nbsp;&nbsp;<span>{{ itemAddress.RVP }}</span></div>
                    <div class="addr-info">{{ itemAddress.RVAD }}</div>
                </el-col>
                <el-col :span="6" style="text-align: right;font-size: 15px;" @click.native="isShowWarp"><span>修改</span><span class="iconfont icon-youbianjiantou"></span></el-col>
            </el-row>
            <el-row :gutter="20" v-else @click.native="toAddUserAddr">
                <el-col :span="18">
                    <div class="addr-name"><span>您还没有收货地址，请先添加地址</span></div>
                </el-col>
                <el-col :span="6" style="text-align: right;font-size: 15px;"><span>添加</span><span class="iconfont icon-youbianjiantou"></span></el-col>
            </el-row>
            <!-- 选择地址弹窗 -->
            <el-drawer
                size="60%"
                direction="btt"
                title="请选择收货地址"
                :visible.sync="isShowAddr"
                :modal-append-to-body="false"
                :with-header="true"
                >
                <el-card :id="'addrId_'+item.ADI" class="box-card" v-for="item in addrLists" :key="item.ADI" @click.native="selectMe(item)">
                    <el-row :gutter="10">
                        <el-col :span="3">
                            <span class="iconfont " style="color: #DF2625">&nbsp;</span>
                        </el-col>
                        <el-col :span="21">
                            <div style="font-weight: 600;font-size: 14px;margin-bottom: 10px;"><span>{{ item.RVN }}</span>&nbsp;&nbsp;&nbsp;<span>{{ item.RVP }}</span></div>
                            <div style="font-size: 12px;"><p>{{ item.RVAD }}</p></div>
                        </el-col>
                    </el-row>
                </el-card>
            </el-drawer>
        </el-card>
        <!-- 商品信息 -->
        <el-card class="box-card">
            <el-divider content-position="left" class="divider-context">商品信息</el-divider>
            <div class="goods-infos">
                <div class="goods-image">
                    <el-image :src="imgUrl" alt="现代人图片" style="width: 100%; height: 90%;" fit="fill"></el-image>
                </div>
                <div class="goods-content">
                    <p>商品：【979999】五粮液现代人</p>
                    <p>价格：￥1400.00</p>
                    <p>数量：3瓶</p>
                    <p>总金额：￥1400.00</p>
                </div>
            </div>
        </el-card>
        <!-- 支付方式 -->
        <el-card class="box-card">
            <el-divider content-position="left" class="divider-context">支付方式</el-divider>
            <div class="pay-info">
                <el-radio-group v-model="payment" size="mini">
                    <!-- <el-radio :label="weixin" border><span class="iconfont icon-weixinzhifu"></span>&nbsp;&nbsp;<span>微信</span></el-radio> -->
                    <el-radio label="alipay" border><span class="iconfont icon-zhifubaozhifu"></span>&nbsp;&nbsp;<span>支付宝</span></el-radio>
                </el-radio-group>
            </div>
        </el-card>
        <!-- 提交按钮 -->
        <el-button class="submit-btn" type="danger" @click="onSubmit">提交并支付</el-button>
    </div>
    
</template>

<script>
import { noLoginApi, loginApi } from '@/config/env'
import { isFailureLogin, formatTime } from '@/config/mUtils'
import { mapState } from 'vuex'
import imgUrl from '@/assets/images/xiandairen.jpg'

export default {
    name: 'Settlement',
    data() {
        return {
            queryAddrList: {
                "name": "delivery_address_query",
                "U": "",
                "SI": "",
                "QF": "2"
            },
            addrLists: [],
            itemAddress: {},
            isShowAddr: false,
            isSelClass: '',
            imgUrl: imgUrl,
            payment: 'alipay',
            salon_id: '',
            salonDetails: null,
        }
    },
    computed: {
        ...mapState(['addressInfos'])
    },
    created() {
        this.salon_id = this.$route.params.sid ? this.$route.params.sid : ''
    },
    mounted() {
        this.getSalonDetails()
        this.selectAddress()
    },
    methods: {
        goBack() {
            this.$router.push('/activity/activitylist')
        },
        //是否显示地址容器
        isShowWarp() {
            this.isShowAddr = this.isShowAddr ? false : true
        },
        //查询地址
        async selectAddress() {
            const { data: res } = await this.$http.post(loginApi.queryUserAddressApi, this.queryAddrList)
            if (res.retcode >= 0) {
                this.addrLists = res.resultList.REC
                for (let i = 0; i < res.resultList.REC.length; i++) {
                    if (parseInt(res.resultList.REC[i].IDAD) == 1) {
                        this.itemAddress = res.resultList.REC[i]
                    }
                }
            }
        },
        //选中
        selectMe(value) {  
            $('.iconfont').removeClass("icon-honggou")
            $('#addrId_' + value.ADI).find(".iconfont").addClass("icon-honggou")
            //保存地址信息到vuex中
            this.$store.commit('GET_ADDR_INFOS', JSON.stringify(value))
            this.isShowAddr = false
            this.itemAddress = this.$store.state.addressInfos
        },
        //地址为空时去添加地址
        toAddUserAddr() {
            this.$router.push('/delivery/useraddress/adduseraddress')
        },
        //获取沙龙详情
        async getSalonDetails() {
            const { data: res } = await this.$http.post(loginApi.getSalonDetailsApi+"?salon_id="+this.salon_id)
            if(res.code==="E000000") {
                this.salonDetails = res.data
            } else {
                return this.$message.warning(res.message)
            }
        },
        //提交订单按钮
        onSubmit() {
            //提交之前参数判断
            if(JSON.stringify(this.itemAddress) === '{}') {
                return this.$message.warning("地址信息不能为空！")
            }
            if(this.salon_id == '' || this.salon_id == undefined || this.salon_id == null) {
                return this.$message.warning("缺少必要参数！")
            }
            if(this.payment == '') {
                return this.$message.warning("请选择支付方式！")
            }
            //?salon_id=a123&salon_delivery_name=123&salon_delivery_phone=123&salon_delivery_address=123
            window.open(loginApi.createSalonOrderApi+"?salon_id="+this.salon_id+"&salon_delivery_name="+this.itemAddress.RVN+"&salon_delivery_phone="+this.itemAddress.RVP+"&salon_delivery_address="+this.itemAddress.RVAD)
        }
    }
}
</script>

<style lang="less" scoped>
    .settlement-main {
        width: 100%;
        height: 100%;
        padding: 10px;
    }
    .el-card {
        margin-bottom: 0.5rem;
    }
    /deep/ .el-divider--horizontal {
        margin: 5px 0;
    }
    .addr-name {
        font-family: "Microsoft YaHei";
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    .addr-info {
        font-size: 12px;
    }
    .submit-btn {
        width: 100%;
    }
    .goods-infos {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        .goods-image {
            width: 30%;
            border-radius: 5px;
            
        }
        .goods-content {
            width: 66%;
            p:first-child {
                font-weight: 600;
                font-size: 14px;
            }
        }
    }
    .divider-context {
        margin-bottom: 2rem;
    }
    /deep/ .el-card__body {
        padding: 10px;
    }
</style>