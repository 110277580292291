<template>
    <div class="team">
        <div class="team-head">
            <el-page-header @back="goBack" content="团队信息" style="font-weight: bolder;">
            </el-page-header>
            <el-divider></el-divider>
        </div>
        <div class="team-main" v-loading="loading" element-loading-text="努力加载中...">
            <el-card>
                <el-descriptions title="我的团队信息" direction="horizontal" :column="1" border>
                    <el-descriptions-item label="我的推荐人">
                        <el-row>
                            <el-col :span="8">
                                <el-tag size="medium">{{ teamTotalInfos.intro_user_id||teamTotalInfos.intro_phone?teamTotalInfos.intro_user_name+'('+teamTotalInfos.intro_phone+')':'暂无' }}</el-tag>
                            </el-col>
                            <el-col :span="16" style="text-align: right">
                                <el-button v-if="teamTotalInfos.intro_user_id==null&&teamTotalInfos.intro_phone==''||teamTotalInfos.intro_phone==null" type="primary" icon="el-icon-plus" size="mini" @click.native="dialogVisible1 = true">添加推荐人</el-button>
                            </el-col>
                        </el-row>
                    </el-descriptions-item>
                    <el-descriptions-item label="我的服务人">
                        <el-row>
                            <el-col :span="8">
                                <el-tag size="medium">{{ teamTotalInfos.server_user_id||teamTotalInfos.server_phone?teamTotalInfos.server_user_name+'('+teamTotalInfos.server_phone+')':'暂无' }}</el-tag>
                            </el-col>
                            <el-col :span="16" style="text-align: right">
                                <el-button v-if="teamTotalInfos.server_user_id==null&&teamTotalInfos.server_phone==''||teamTotalInfos.server_phone==null" type="primary" icon="el-icon-plus" size="mini" @click.native="dialogVisible2 = true">添加服务人</el-button>
                            </el-col>
                        </el-row>
                    </el-descriptions-item>
                    <el-descriptions-item label="我的提货总量">{{ teamTotalInfos.user_commodity_count }}</el-descriptions-item>
                    <el-descriptions-item label="团队提货总量">{{ teamTotalInfos.team_commodity_count }}</el-descriptions-item>
                    <el-descriptions-item label="团队总人数">{{ teamTotalInfos.team_count }}</el-descriptions-item>
                </el-descriptions>
            </el-card>
            <el-card>
                <div class="intro-title">
                    我推荐的会员
                </div>
                <div class="intro-info">
                    <el-table height="300" :data="introLists" style="width: 100%">
                        <el-table-column label="姓名">
                            <template slot-scope="scope">
                                <el-tag size="mini" v-if="scope.row.user_name">{{ scope.row.user_name }}</el-tag>
                                <el-tag size="mini" type="danger" v-else>未实名</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="phone" label="手机号"></el-table-column>
                        <el-table-column label="会员等级">
                            <template slot-scope="scope">
                                <el-tag type="warning" size="mini">{{ scope.row.level_name }}</el-tag>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
            <el-card>
                <div class="intro-title">
                    我服务的会员
                </div>
                <div class="intro-info">
                    <el-table height="300" :data="serviceLists" style="width: 100%">
                        <el-table-column label="姓名">
                            <template slot-scope="scope">
                                <el-tag size="mini" v-if="scope.row.user_name">{{ scope.row.user_name }}</el-tag>
                                <el-tag size="mini" type="danger" v-else>未实名</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="phone" label="手机号"></el-table-column>
                        <el-table-column label="会员等级">
                            <template slot-scope="scope">
                                <el-tag type="warning" size="mini">{{ scope.row.level_name }}</el-tag>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
            <!-- 添加推荐人对话框 -->
            <el-dialog title="推荐人信息" :visible.sync="dialogVisible1" width="70%">
                <el-form :model="introForm">
                    <el-form-item label="手机号" label-width="auto">
                        <el-input type="number" v-model.number="introForm.intro_phone" placeholder="请输入推荐人手机号"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button size="small" @click="dialogVisible1 = false">取 消</el-button>
                    <el-button size="small" type="primary" @click="addIntroUser">确 定</el-button>
                </div>
            </el-dialog>
            <!-- 添加服务人对话框 -->
            <el-dialog title="服务人信息" :visible.sync="dialogVisible2" width="70%">
                <el-form :model="serverForm">
                    <el-form-item label="手机号" label-width="auto">
                        <el-input type="number" v-model.number="serverForm.server_phone" placeholder="请输入服务人手机号"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button size="small" @click="dialogVisible2 = false">取 消</el-button>
                    <el-button size="small" type="primary" @click="addServerUser">确 定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { loginApi } from '@/config/env'
    export default {
        name: 'Team',
        data() {
            return {
                params: {
                    name: 'team_detail',
                    U: '',
                    SI: ''
                },
                teamTotalInfos: {
                    'team_commodity_count': 0, //团队提货总数量
                    'team_count': 0,           //团队总人数
                    'user_commodity_count': 0, //当前会员提货总数量
                    'intro_phone': "",
                    'intro_user_id': "",
                    'intro_user_name': "",
                    'server_phone': "",
                    'server_user_id': "",
                    'server_user_name': "",
                    'user_id': "",  //会员id
                },
                introLists: [],  //推荐人列表
                serviceLists: [], //服务人列表
                loading: true,
                dialogVisible1: false,
                dialogVisible2: false,
                //添加推荐人表单
                introForm: {
                    intro_phone: '',  //推荐人电话
                },
                //添加服务人表单
                serverForm: {
                    server_phone: '',  //服务人电话
                }
            }
        },
        mounted() {
            this.getTeamLists()
        },
        methods: {
            //返回
            goBack() {
                this.$router.push('/user')
            },
            //获取团队详情
            async getTeamLists() {
                const { data: res } = await this.$http.post(loginApi.queryTeamDetailApi, this.params)
                if (res.code !== 'E000000') {
                    return this.$message.error(res.message)
                }
                if (res.data != null) {
                    this.loading = false
                    this.teamTotalInfos.team_commodity_count = res.data.team_commodity_count
                    this.teamTotalInfos.team_count = res.data.team_count
                    this.teamTotalInfos.user_commodity_count = res.data.user_commodity_count
                    this.teamTotalInfos.user_id = res.data.user_id
                    this.teamTotalInfos.intro_phone = res.data.intro_phone
                    this.teamTotalInfos.intro_user_id = res.data.intro_user_id
                    this.teamTotalInfos.intro_user_name = res.data.intro_user_name
                    this.teamTotalInfos.server_phone = res.data.server_phone
                    this.teamTotalInfos.server_user_id = res.data.server_user_id
                    this.teamTotalInfos.server_user_name = res.data.server_user_name
                    this.introLists = res.data.intro_users
                    this.serviceLists = res.data.server_users
                }
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                .then(_ => {
                    done()
                })
                .catch(_ => {})
            },
            //添加推荐人
            async addIntroUser() {
                if (!this.introForm.intro_phone) {
                    return this.$message.warning('推荐人手机号不能为空！')
                }
                const { data: res } = await this.$http.post(loginApi.userAddIntroApi+'?intro_phone='+this.introForm.intro_phone)
                if (res.code !== 'E000000') {
                    return this.$message.error(res.message)
                } else {
                    this.$message.success(res.message)
                    this.dialogVisible1 = false
                    this.$router.go(0)
                }
            },
            //添加服务人
            async addServerUser() {
                if (!this.serverForm.server_phone) {
                    return this.$message.warning('服务人手机号不能为空！')
                }
                const { data: res } = await this.$http.post(loginApi.userAddServerApi+'?server_phone='+this.serverForm.server_phone)
                if (res.code !== 'E000000') {
                    return this.$message.error(res.message)
                } else {
                    this.$message.success(res.message)
                    this.dialogVisible2 = false
                    this.$router.go(0)
                }
            },
        }  
    }
</script>

<style lang="less" scoped>
    .team {
        width: 100%;
        overflow-y: hidden;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        .team-head {
            width: 100%;
            position: fixed;
            padding: 1rem;
        }
        .intro-title {
            width: 100%;
            font-size: 16px;
            font-weight: 700;
        }
        .intro-info {
            width: 100%;
            margin-top: 1rem;
        }
    }
    .team-main {
        width: 100%;
        position: absolute;
        top: 6rem;
        overflow-y: auto;
        height: 90%;
        padding: 1rem;
        .el-card {
            margin-bottom: 0.5rem;
        }
    }
    /deep/ .el-card__body, .el-main {
        padding: 0.5rem;
    }
</style>