<template>
    <div class="userAddr">
        <el-page-header @back="goBack" content="地址管理" style="font-weight: bolder;">
        </el-page-header>
        <el-divider></el-divider>
        <el-card class="box-card" v-for="item in userAddrList" :key="item.ADI">
            <div class="addr-main">
                <el-row class="addr-title">
                    <el-col :span="6"><span>{{ item.RVN }}</span></el-col>
                    <el-col :span="12"><span>{{ item.RVP }}</span></el-col>
                    <el-col :span="6"><el-tag v-if="item.IDAD == 1" type="warning" size="mini">默认</el-tag></el-col>
                </el-row>
                <el-row class="addr-content">
                    <el-col :span="24">
                        <p>{{ item.RVAD }}</p>
                    </el-col>
                </el-row>
                <el-divider></el-divider>
                <el-row class="addr-action">
                        <el-col :span="12">
                            <el-switch
                                inactive-text="设为默认" 
                                active-value="1"
                                inactive-value="2"
                                v-model="item.IDAD" 
                                @change="changeDefault(item.ADI, item.IDAD)"
                            >
                            </el-switch>
                        </el-col>
                    <el-col :span="12">
                        <el-button type="primary" size="mini" @click="editAddr(item)">编辑</el-button>
                        <el-button type="danger" size="mini" @click="delAddr(item.ADI)">删除</el-button>
                    </el-col>
                </el-row>
            </div>
        </el-card>
        <el-empty v-if="userAddrList.length <= 0" description="暂无任何地址记录"></el-empty>
        <button v-on:click="addUserAddress" type="button" class="btn btn-success btn-lg btn-block">新增地址</button>
    </div>
</template>

<script>
    import { loginApi } from '@/config/env'
    import { isFailureLogin } from '@/config/mUtils'
    let fromUrl = ''
    export default {
        name: 'UserAddress',
        inject: ["reload"],
        data() {
            return {
                queryAddr: {
                    "name": "delivery_address_query",
                    "U": "",
                    "SI": "",
                    "QF": "2"
                },
                userAddrList: [],
                setAddrInfo: {
                    "name": "delivery_address_def",
                    "U": "",
                    "SI": "",
                    "OPR": "",
                    "ADI": ""
                },
                checked: false,
                fromPage: ''
            }
        },
        mounted() {
            this.getUserAddrList()
        },
        methods: {
            goBack() {
                this.$router.push(this.fromPage)
            },
            //获取用户地址列表
            async getUserAddrList() {
                const { data: res } = await this.$http.post(loginApi.queryUserAddressApi, this.queryAddr)
                //判断是否登录失效，以及返回错误信息
                if (isFailureLogin(res.retcode)) {
                    this.userAddrList = res.resultList.REC
                } else {
                    return this.$message.warning(res.message)
                }
            },
            //新增地址
            addUserAddress() {
                this.$router.push('/delivery/useraddress/adduseraddress')
            },
            //监听开关
            async changeDefault(addrId, isDef) {
                this.setAddrInfo.OPR = isDef==1 ? 2 : 3
                this.setAddrInfo.ADI = addrId
                const { data: res } = await this.$http.post(loginApi.setOrDelAddressApi, this.setAddrInfo)
                if (isFailureLogin(res.retcode)) {
                    this.$router.go(0)
                } else {
                    return this.$message.error(res.message)
                }
            },
            //修改地址
            editAddr(Obj) {
                let itemObj = JSON.stringify(Obj)
                this.$store.dispatch('getAddrInfos', itemObj)
                this.$router.push({ path: '/delivery/useraddress/edituseraddress', query: { addrId: Obj.ADI } })
            },
            //删除地址
            delAddr(addrId) {
                this.setAddrInfo.OPR = '1'
                this.setAddrInfo.ADI = addrId
                this.$confirm('您确认删除该地址?', '确认提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.post(loginApi.setOrDelAddressApi, this.setAddrInfo)
                    setTimeout(() => {this.getUserAddrList()}, 500)
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    })        
                })
            }
        },
        //从哪个路径跳转而来
        beforeRouteEnter(to, from, next) {
            if (from.matched[0] !== undefined) {
                if (from.matched[0].path === '/mall') {
                    next(vm => {
                        vm.fromPage = '/mall'
                    })
                } else {
                    next(vm => {
                        vm.fromPage = '/user'
                    })
                }
            } else {
                next(vm => {
                    vm.fromPage = '/user'
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .userAddr {
        width: 100%;
        margin: 0 auto;
        padding: 10px;
    }
    .box-card {
        margin-bottom: 0.5rem;
    }
    .addr-main {
        width: 100%;
        min-height: 16rem;
        padding: 0;
        .addr-title {
            width: 100%;
            min-height: 2rem;
            display: flex;
            flex-direction: row;
            align-items: baseline;
            .el-col:nth-child(1) {
                color: #313131;
                font-size: 1.8rem;
                font-weight: bolder;
            }
            .el-col:last-child {
                text-align: right;
            }
        }
    }
    .addr-content {
        font-size: 1.4rem;
        font-family: 'SimSun';
        padding: 0.8rem;
    }
    .addr-action {
        .el-col:last-child {
            text-align: right;
        }
    }
    .btn-block {
        margin-top: 3rem;
    }
</style>