<template>
    <div class="deal-flow">
        <div class="top-title">
            <el-page-header @back="goBack" content="交易流水" style="font-weight: bolder;">
            </el-page-header>
            <el-divider></el-divider>
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane label="当前流水" name="first"></el-tab-pane>
                <el-tab-pane label="历史流水" name="second"></el-tab-pane>
            </el-tabs>
        </div>
        <div class="box-scroll" v-if="list.length > 0">
            <div class="infinite-list-wrapper" v-infinite-scroll="load" infinite-scroll-disabled="disabled" style="overflow:auto">
                <el-card class="box-card">
                    <ul class="list">
                        <li class="list-item" v-for="(item, index) in list" :key="index">
                            <div>
                                <el-tag size="mini" v-if="item.SNM == 351001">交易手续费</el-tag>
                                <el-tag type="success" size="mini" v-else-if="item.SNM == 351003">交易盈利</el-tag>
                                <el-tag type="danger" size="mini" v-else-if="item.SNM == 351004">交易亏损</el-tag>
                                <el-tag type="warning" size="mini" v-else-if="item.SNM == 351006">付用户货款</el-tag>
                                <el-tag type="danger" size="mini" v-else-if="item.SNM == 351005">收用户货款</el-tag>
                            </div>
                            <div>￥{{ item.PID | keepTwoDecimal }}</div>
                            <div>
                                {{ item.CRT }}
                            </div>
                        </li>
                    </ul>
                </el-card>
            </div>
        </div>
        <div class="info-message" v-if="list.length > 0">
            <p v-if="loading">
                <el-alert :closable="false"
                    title="加载中..."
                    type="warning"
                    center
                    show-icon>
                </el-alert>
            </p>
            <p v-if="noMore">
                <el-alert :closable="false"
                    title="没有更多了"
                    type="warning"
                    center
                    show-icon>
                </el-alert>
            </p>
        </div>
        <div class="info-empty" v-else>
            <el-empty :image-size="200"></el-empty>
        </div>
    </div>
</template>

<script>
    import { loginApi } from '@/config/env'
    import { isFailureLogin } from '@/config/mUtils'
    export default {
        name: 'DealFlow',
        data() {
            return {
                //当前流水查询参数
                queryCurrentParams: {
                    "name": "fundsflow_query",
                    "U": "",
                    "LTI": 0,
                    "RECCNT": 20,
                    "SI": ""
                },
                //历史流水查询参数
                queryHistoryParams: {
                    "name": "history_fundsflow_query",
                    "U": "",
                    "LTI": 0,
                    "RECCNT": 20,
                    "SI": ""
                },
                activeName: 'first',
                totalNum: 0,
                loading: false,
                list: []
            }
        },
        computed: {
            noMore () {
                return this.totalNum <= 19
            },
            disabled () {
                return this.loading || this.noMore
            }
        },
        mounted() {
            this.getCurrentDealFlow()
        },
        methods: {
            //返回
            goBack() {
                this.$router.push('/user')
            },
            handleClick(tab, event) {
                switch (tab._props.name) {
                    case 'first':
                        this.list = []
                        this.queryCurrentParams.LTI = 0
                        this.queryCurrentParams.RECCNT = 20
                        this.getCurrentDealFlow()
                        break;
                    case 'second':
                        this.list = []
                        this.queryHistoryParams.LTI = 0
                        this.queryHistoryParams.RECCNT = 20
                        this.getHistoryDealFlow()
                        break;
                    default:
                        break;
                }
            },
            //获取当前流水
            async getCurrentDealFlow() {
                const { data: res } = await this.$http.post(loginApi.queryCurrentDealFlowApi, this.queryCurrentParams)
                if (isFailureLogin(res.retcode)) {
                    if (res.result.TC > 0) {
                        this.queryCurrentParams.LTI = res.result.MINID
                        this.list = this.list.concat(res.resultList.REC)
                        this.totalNum = res.result.TC
                    } else {
                        this.loading = false
                        this.totalNum = 0
                    }
                } else {
                    return this.$message.error(res.message)
                }
            },
            //获取历史流水
            async getHistoryDealFlow() {
                const { data: res } = await this.$http.post(loginApi.queryHistoryDealFlowApi, this.queryHistoryParams)
                if (isFailureLogin(res.retcode)) {
                    if (res.result.TC > 0) {
                        this.queryHistoryParams.LTI = res.result.MINID
                        this.list = this.list.concat(res.resultList.REC)
                        this.totalNum = res.result.TC
                    } else {
                        this.loading = false
                        this.totalNum = 0
                    }
                } else {
                    return this.$message.error(res.message)
                }
            },
            load() {
                console.log('滚动加载被触发')
                this.loading = true
                setTimeout(() => {
                    this.queryHistoryParams.RECCNT = -20
                    this.getHistoryDealFlow()
                    this.loading = false
                }, 2000)
            }
        }    
    }
</script>

<style lang="less" scoped>
    .deal-flow {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        .top-title {
            width: 100%;
            position: fixed;
            padding: 1rem;
        }
        .box-scroll {
            width: 100%;
            height: 75%;
            position: absolute;
            top: 14rem;
            left: 0;
            overflow-y: scroll;
            overflow-x: hidden;
            .list {
                width: 100%;
                height: 100%;
                padding: 0;
                .list-item {
                    margin-bottom: 2rem;
                    height: 4rem;
                    line-height: 4rem;
                    border-bottom: 1px solid #DCDFE6;
                    display: flex;
                    flex-wrap: row nowrap;
                    justify-content: space-between;
                }
            }
        }
    }
    .infinite-list-wrapper {
        width: 100%;
        height: 100%;
    }
    .info-message {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    .info-empty {
        width: 100%;
        position: absolute;
        top: 14rem;
        left: 0;
    }
</style>