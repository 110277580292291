<template>
    <div class="deal-record">
        <div class="top-title">
            <el-page-header @back="goBack" content="交易记录" style="font-weight: bolder;">
            </el-page-header>
            <el-divider></el-divider>
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane label="当前交易" name="first"></el-tab-pane>
                <el-tab-pane label="历史交易" name="second"></el-tab-pane>
            </el-tabs>
        </div>
        <div class="box-scroll" v-if="list.length > 0">
            <div class="infinite-list-wrapper" v-infinite-scroll="load" infinite-scroll-disabled="disabled" style="overflow:auto">
                <ul class="list">
                    <li class="list-item" v-for="(item, index) in list" :key="index">
                        <el-card class="box-card">
                            <el-descriptions  title="" direction="vertical" :column="2" border :colon="true">
                                <el-descriptions-item label="单号">{{ item.ON }}</el-descriptions-item>
                                <el-descriptions-item label="名称">[{{ item.COI }}] {{ item.CON }}</el-descriptions-item>
                                <el-descriptions-item label="价格">￥{{ item.OP || keepTwoDecimal }}</el-descriptions-item>
                                <el-descriptions-item label="数量">{{ item.OQ }}</el-descriptions-item>
                                <el-descriptions-item label="买卖方向">
                                    <el-tag v-if="item.BS == 1" type="success" size="mini">买</el-tag>
                                    <el-tag v-else type="danger" size="mini">卖</el-tag>
                                </el-descriptions-item>
                                <el-descriptions-item label="挂/摘类型">
                                    <el-tag v-if="item.OT == 1" type="success" size="mini">挂</el-tag>
                                    <el-tag v-else type="danger" size="mini">摘</el-tag>
                                </el-descriptions-item>
                                <el-descriptions-item label="剩余量">{{ item.BAL }}</el-descriptions-item>
                                <el-descriptions-item label="状态">
                                    <el-tag v-if="item.OS == 1" size="mini">已委托</el-tag>
                                    <el-tag v-else-if="item.OS == 2" type="success" size="mini">已成交</el-tag>
                                    <el-tag v-else type="info" size="mini">已撤单</el-tag>
                                </el-descriptions-item>
                                <el-descriptions-item label="创建时间">{{ item.OT }}</el-descriptions-item>
                            </el-descriptions>
                        </el-card>
                    </li>
                </ul>
            </div>
        </div>
        <div class="info-message" v-if="list.length > 0">
            <p v-if="loading">
                <el-alert :closable="false"
                    title="加载中..."
                    type="warning"
                    center
                    show-icon>
                </el-alert>
            </p>
            <p v-if="noMore">
                <el-alert :closable="false"
                    title="没有更多了"
                    type="warning"
                    center
                    show-icon>
                </el-alert>
            </p>
        </div>
        <div class="info-empty" v-else>
            <el-empty :image-size="200"></el-empty>
        </div>
    </div>
</template>

<script>
    import { loginApi } from '@/config/env'
    import { isFailureLogin } from '@/config/mUtils'
    export default {
        name: 'OrderRecord',
        data() {
            return {
                activeName: 'first',
                count: 10,
                totalNum: 0,
                loading: false,
                //当前订单记录查询
                queryTradeParams: {
                    "name": "order_query_mobile",
                    "U": "",
                    "LTI": 0,
                    "COI": 979999,
                    "RECCNT": 20,   //要多少条记录大于0向上查最新的记录,小于0向下查之前的记录
                    "SI": ""
                },
                //历史订单记录查询
                queryHistoryParams: {
                    "name": "order_history_query",
                    "U": "",
                    "SI": "",
                    "COI": 979999,
                    "LTI": 0,
                    "RECCNT": 20,
                },
                list: []
            }
        },
        computed: {
            noMore () {
                return this.totalNum <= 19 
            },
            disabled () {
                return this.loading || this.noMore
            }
        },
        mounted() {
            this.getCurrentTradeLists()
        },
        methods: {
            //返回
            goBack() {
                this.$router.go(-1)
            },
            handleChange(val) {
                console.log(val);
            },
            //获取当前的订单记录
            async getCurrentTradeLists() {
                const { data: res } = await this.$http.post(loginApi.queryEntrustRecordApi, this.queryTradeParams)
                if (isFailureLogin(res.retcode)) {
                    this.list = this.list.concat(res.resultList.REC)
                } else {
                    return this.$message.error(res.message)
                }
                
            },
            //获取历史订单记录
            async getHistoryTradeLists() {
                const { data: res } = await this.$http.post(loginApi.queryEntrustHisRecordApi, this.queryHistoryParams)
                if (isFailureLogin(res.retcode)) {
                    if (res.result.TC > 0) {
                        this.queryHistoryParams.LTI = res.result.MINID
                        this.list = this.list.concat(res.resultList.REC)
                        this.totalNum = res.result.TC
                    } else {
                        this.loading = false
                        this.totalNum = 0
                    }
                } else {
                    return this.$message.error(res.message)
                }
            },
            load () {
                this.loading = true
                setTimeout(() => {
                    if (this.activeName == 'first') {
                        this.queryTradeParams.RECCNT = -20
                        this.getCurrentTradeLists()
                        this.loading = false
                    }else if (this.activeName == 'second') {
                        this.queryHistoryParams.RECCNT = -20
                        this.getHistoryTradeLists()
                        this.loading = false
                    }
                }, 2000)
            },
            handleClick(tab, event) {
                switch (tab._props.name) {
                    case 'first':
                        this.list = []
                        this.getCurrentTradeLists()
                        break;
                    case 'second':
                        this.list = []
                        this.queryHistoryParams.LTI = 0
                        this.queryHistoryParams.RECCNT = 20
                        this.getHistoryTradeLists()
                        break;
                    default:
                        break;
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .deal-record {
        width: 100%;
        height: 100%;
        padding: 1rem;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        .top-title {
            width: 100%;
            position: fixed;
            top: 1rem;
            left: 50%;
            transform: translateX(-50%);
        }
        .box-scroll {
            width: 100%;
            height: 75%;
            position: absolute;
            top: 14rem;
            left: 50%;
            transform: translateX(-50%);
            overflow-y: scroll;
            overflow-x: hidden;
            .list {
                width: 100%;
                height: 100%;
                padding: 0;
                .list-item {
                    margin-bottom: 0.5rem;
                }
            }
        }
    }
    .infinite-list-wrapper {
        width: 100%;
        height: 100%;
    }
    .info-message {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    .info-empty {
        width: 100%;
        position: absolute;
        top: 14rem;
        left: 0;
    }
</style>