/**配置图片所在的域名地址
 * 配置请求的接口地址
*/
const env="product";
//const env = "test";
var baseUrl = (env=="test") ? '/api' : "";
//无需要登录接口
const noLoginApi = {
    //公告详情查询
    announcementDetailApi: baseUrl+'/guest/broadcast_detail_query',
    //手机端公告查询
    mobileCommentApi: baseUrl+'/guest/mobile_notice_query',
    //用户登录
    userLoginApi: baseUrl+'/guest/logon',
    //用户注册
    userRegisterApi: baseUrl+'/guest/user_regist',
    //忘记密码获取验证码
    forgetPasswordSendMsgApi: baseUrl+'/guest/get_resetpwd_icode',
    //重置用户密码
    resetPasswordApi: baseUrl+'/guest/reset_userpwd',
    //上传图片
    //uploadImageApi: baseUrl+'/guest/base64',
    uploadImageApi: baseUrl+'/guest/upload_pic',
    //登录状态是否失效
    isLoginInvalid: baseUrl+'/guest/firm_sum_info',
    //拍场列表记录查询
    auctionListApi: baseUrl+'/guest/commodity_list',
    //委托买卖列表查询
    orderOnceListApi: baseUrl+'/guest/order_one_query',
    //商城分类列表
    mallCateListsApi: baseUrl+'/guest/mall_type_list',
    //商城商品列表
    mallGoodsListsApi: baseUrl+'/guest/mall_type_product_list',
    //商品详情查询
    queryGoodsDetailsApi: baseUrl+'/guest/mall_product_detail',
    //团队树形结构查询
    queryTeamTreesApi: baseUrl+'/guest/treeSearch',
    //获取商品详情页的轮播图接口
    getGoodsBannerApi: baseUrl+'/guest/mall_product_pic_scroll',
    //获取商品详情页的详细图接口
    getGoodsDetailsImgApi: baseUrl+'/guest/mall_product_pic_detail'
}

//需要登录接口
const loginApi = {
    //用户退出登录
    userLogoutApi: baseUrl+'/user/logoff',
    //用户信息查询
    userInfoApi: baseUrl+'/user/userinfo_query',
    //用户仓量、等级、签到查询
    userInfoExtendApi: baseUrl+'/user/userInfo',
    //用户（交易商）总资金查询
    moneyQueryApi: baseUrl+'/user/firm_sum_info',
    //用户实名认证申请
    realnameAuthApi: baseUrl+'/user/userauth_apply',
    //用户实名认证申请（新）
    realnameAuthNewApi: baseUrl+'/user/userauth_apply_new',
    //用户实名状态查询
    realnameAuthQueryApi: baseUrl+'/user/authstatus_query',
    //用户通过输入原密码验证修改密码
    modifyPasswordApi: baseUrl+'/user/change_user_password',
    //拍品详情查询
    auctionDetailsApi: baseUrl+'/user/commodity_query',
    //是否需要设置资金密码
    setMoneyPasswordApi: baseUrl+'/user/isneed_init_fundspwd',
    //初始化资金密码
    initMoneyPasswordApi: baseUrl+'/user/init_fundspwd',
    //修改资金密码
    editMoneyPwdApi: baseUrl+'/user/change_funds_password',
    //重置资金密码获取验证码
    resetMoneyPwdCodeApi: baseUrl+'/user/reset_fundspwd_icode',
    //重置资金密码
    resetMoneyPasswordApi: baseUrl+'/user/reset_fundspwd',
    //交易界面行情信息查询
    commodityDataQueryApi: baseUrl+'/user/commodity_data_query',
    //获取持仓汇总信息
    positionTotalApi: baseUrl+'/user/hold_query_mobile',
    //退回提货
    returnGoodsApi: baseUrl+'/user/commodity_back',
    //获取持仓明细信息
    positionListsApi: baseUrl+'/user/holddetail_list_query_mobile',
    //入委托单（委托挂单或者摘单）
    entrustOrderApi: baseUrl+'/user/order',
    //交易系统状态查询
    tradeSystemStatus: baseUrl+'/user/sysstatus_query',
    //查询当前订单记录
    queryEntrustRecordApi: baseUrl+'/user/order_query_mobile',
    //查询历史订单记录
    queryEntrustHisRecordApi: baseUrl+'/user/order_history_query',
    //撤销委托单
    cancelEntrustOrderApi: baseUrl+'/user/order_wd',
    //查询全部地址
    queryUserAddressApi: baseUrl+'/user/delivery_address_query',
    //添加或修改地址
    saveUserAddressApi: baseUrl+'/user/delivery_address_update',
    //删除、设置默认地址
    setOrDelAddressApi: baseUrl+'/user/delivery_address_def',
    //商品提货仓库查询
    queryDeliveryWarehouseApi: baseUrl+'/user/issue_warehouse_query',
    //配送费查询
    queryShippingFeeApi: baseUrl+'/user/delivery_fee_query',
    //提货申请
    deliveryApplyApi: baseUrl+'/user/delivery_special',
    //提货记录查询
    deliveryRecordApi: baseUrl+'/user/delivery_record',
    //确认收货
    confirmDeliveryApi: baseUrl+'/user/delivery_receive',
    //通兑点数转换
    exchangePointApi: baseUrl+'/user/delivery_trans',
    //当前成交记录查询
    queryCurrentTradeApi: baseUrl+'/user/trade_query_mobile',
    //历史成交记录查询
    queryHistoryTradeApi: baseUrl+'/user/trade_history_query',
    //出入金记录查询
    queryDepositRecordApi: baseUrl+'/user/invest_draw_query',
    //交易商总资金查询
    queryTotalFundsApi: baseUrl+'/user/totalfunds_query',
    //资金池信息查询
    queryFundspoolApi: baseUrl+'/user/fundspool_info_query',
    //资金划转
    fundsTransferApi: baseUrl+'/user/funds_transfer',
    //银行信息查询
    queryBankInfoApi: baseUrl+'/user/banks_info_query',
    //签约信息查询
    querySignBankApi: baseUrl+'/user/regest_info_query',
    //资金池资金情况查询
    queryFundspoolInfoApi: baseUrl+'/user/fundspool_fundinfo_query',
    //执行出金操作
    doWithdrawalApi: baseUrl+'/user/out_funds',
    //当前交易流水查询
    queryCurrentDealFlowApi: baseUrl+'/user/fundsflow_query',
    //历史交易流水查询
    queryHistoryDealFlowApi: baseUrl+'/user/history_fundsflow_query',
    //商城通兑点数购买商品
    userMallBuyApi: baseUrl+'/user/mall_buy_point',
    //商城订单记录
    mallOrderRecordApi: baseUrl+'/user/mall_order_record',
    //商城订单详情查询
    queryMallOrderDetail: baseUrl+'/user/mall_order_detail',
    //通兑点数记录
    mallExchangeRecordApi: baseUrl+'/user/mall_point_record',
    //通兑点数购买校验
    mallExchangeCheckApi: baseUrl+'/user/mall_buy_point_check',
    //商城仓量兑换积分
    mallPositionExInteApi: baseUrl+'/user/position_to_mall_point',
    //仓量记录
    positionRecordApi: baseUrl+'/user/position_record',
    //通兑点数查询
    queryPointExchangeApi: baseUrl+'/user/mall_point',
    //团队详情
    queryTeamDetailApi: baseUrl+'/user/team_detail',
    //用户添加推荐人
    userAddIntroApi: baseUrl+'/user/set_user_intro',
    //用户添加服务人
    userAddServerApi: baseUrl+'/user/set_user_server',
    //查询德邦物流轨迹
    logisticsDetailsApi: baseUrl+'/user/delivery_query',
    //商城确认收货
    mallConfirmReceiptApi: baseUrl+'/user/mall_receive',
    //创建沙龙
    createSalonApi: baseUrl+'/salon/create_salon',
    //获取沙龙详情
    getSalonDetailsApi: baseUrl+'/salon/get_salon',
    //获取我的沙龙信息
    getMySalonApi: baseUrl+'/salon/get_salon_mine',
    //获取所有沙龙
    getSalonAllApi: baseUrl+'/salon/get_salon_all',
    //获取当前沙龙
    getCurrentSalonApi: baseUrl+'/salon/get_salon_all_in_time',
    //创建沙龙订单并支付
    createSalonOrderApi: baseUrl+'/salon/create_order_alipay',
    //沙龙订单状态接口
    getSalonOrderStateApi: baseUrl+'/salon/get_salon_order',
    //沙龙订单列表接口
    getSalonOrderListsApi: baseUrl+'/salon/get_v_salon_order_mine',
    //查看沙龙订单详情接口
    getSalonOrderDetailsApi: baseUrl+'/salon/get_salon_order_VO',
    //沙龙订单重新发起支付接口
    repaySalonApi: baseUrl+'/salon/order_alipay_repay',
    //沙龙订单确认收货接口
    confirmSalonOrderApi: baseUrl+'/salon/salon_delivery_receive',
    //创建沙龙时获取领导人姓名
    getSalonLeaderApi: baseUrl+'/salon/get_salon_leader',
    //团队结构权限查询接口
    queryTeamAuthApi: baseUrl+'/user/team_view_auth'
}

export {
    noLoginApi,
    loginApi
}