<template>
    <div class="delivery">
        <el-page-header @back="goBack" content="我要提货" style="font-weight: bolder;">
        </el-page-header>
        <el-divider></el-divider>
        <div class="delivery-address">
            <div @click="isShowWarp" v-if="JSON.stringify(itemAddress) !== '{}'">
                <el-row>
                    <el-col :span="24"><span>{{ itemAddress.RVN }}</span><span>{{ itemAddress.RVP }}</span></el-col>
                </el-row>
                <el-row>
                        <el-col :span="20"><p>{{ itemAddress.RVAD }}</p></el-col>
                        <el-col :span="4"><span class="iconfont icon-youbianjiantou"></span></el-col>
                </el-row>
            </div>
            <div @click="toAddUserAddr" v-else>
                <el-row class="addr-empty">
                    <el-col :span="20"><span>请先添加收货地址</span></el-col>
                    <el-col :span="4"><span class="iconfont icon-youbianjiantou"></span></el-col>
                </el-row>
            </div>
            <el-drawer
                size="50%"
                direction="btt"
                title="请选择收货地址"
                :visible.sync="isShowAddr"
                :modal-append-to-body="false"
                :with-header="true"
                >
                <el-card :id="'addrId_'+item.ADI" class="box-card" v-for="item in addrLists" :key="item.ADI" @click.native="selectMe(item)">
                    <el-row :gutter="10">
                        <el-col :span="2">
                            <span class="iconfont " style="color: #DF2625"></span>
                        </el-col>
                        <el-col :span="22">
                            <div><span>{{ item.RVN }}</span><span>{{ item.RVP }}</span></div>
                            <div><p>{{ item.RVAD }}</p></div>
                        </el-col>
                    </el-row>
                </el-card>
            </el-drawer>
        </div>
        <div class="delivery-goods">
            <el-form ref="deliveryFrom" :model="deliveryFrom" :rules="rules" label-width="auto">
                <el-form-item label="商品代码" prop="COI">
                    <el-select @change="selDeliveryStore" v-model="deliveryFrom.COI" placeholder="请选择商品代码">
                        <el-option v-for="item1 in positionData" :key="item1.COI" :label="item1.COI" :value="item1.COI"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="商品名称">
                    <el-input :disabled="true" v-model="goodName"></el-input>
                </el-form-item>
                <el-form-item label="提货仓库" prop="WI">
                    <el-select @focus="isSelStoreId" @change="deliveryNum" :disabled="isSelDelivery" v-model="deliveryFrom.WI" placeholder="请选择提货仓库">
                        <el-option v-for="item2 in deliveryWarehouseData" :key="item2.WHI" :label="item2.WHN" :value="item2.WHI"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="提货数量" prop="DQ">
                    <el-input type="number" v-model="deliveryFrom.DQ" :placeholder="deliveryNumStr"></el-input>
                </el-form-item>
            </el-form>
            <!-- <el-alert :title="shippingFeeNotice" type="warning" :closable="false"></el-alert> -->
        </div>
        <el-button class="submit-btn" type="danger" @click="onSubmit">确认提货</el-button>
    </div>
</template>

<script>
    import { noLoginApi, loginApi } from '@/config/env'
    import { isFailureLogin, formatTime } from '@/config/mUtils'
    import { mapState } from 'vuex'
    export default {
        name: 'Delivery',
        data() {
            return {
                //验证规则
                rules: {
                    DQ: [
                        { required: true, message: '请输入提货数量', trigger: 'blur' },
                    ],
                    COI: [
                        { required: true, message: '请选择商品代码', trigger: 'change' }
                    ],
                    WI: [
                        { required: true, message: '请选择提货仓库', trigger: 'change' }
                    ]
                },
                drawer: false,
                deliveryFrom: {
                    "name": "delivery_special",
                    "U": "",
                    "COI": "",  //商品统一代码
                    "WI": "",   //交货仓库ID
                    "DQ": "",   //交收件数
                    "DD": "",   //提货日期
                    "DA": "",   //收货地址
                    "DHF": "",  //配送费
                    "DPN": "",  //收货人名称
                    "DPM": "",  //收货人手机号
                    "SI": ""
                },
                queryPositionTotal: {
                    "name": "hold_query_mobile",
                    "U": "",
                    "COI": "",
                    "SI": ""
                },
                queryAddrList: {
                    "name": "delivery_address_query",
                    "U": "",
                    "SI": "",
                    "QF": "2"
                },
                //商品提货仓库查询参数
                queryDeliveryStore: {
                    "name": "issue_warehouse_query",
                    "U": "",
                    "SI": "",
                    "COI": ""
                },
                //查询配送费
                queryShipingFee: {
                    "name": "delivery_fee_query",
                    "U": "",
                    "SI": "",
                    "DQ": "",
                    "DD": "",
                    "COI": ""
                },
                addrLists: [],
                itemAddress: {},
                isShowAddr: false,
                isSelClass: '',
                positionData: [], //持仓汇总数据
                deliveryWarehouseData: [],
                goodName: '', //商品名称
                isSelDelivery: false,
                deliveryNumStr: '',
                shippingFeeData: {},
                // shippingFeeNotice: '注意：您当前选择商品所需的配送费为￥',
                shippingFee: ''
            }
        },
        computed: {
            ...mapState(['addressInfos'])
        },        
        mounted() {
            this.selectAddress()
            this.getPositionTotal()
        },
        methods: {
            //返回
            goBack() {
                this.$router.push('/user')
            },
            //是否显示地址容器
            isShowWarp() {
                this.isShowAddr = this.isShowAddr ? false : true
            },
            //查询地址
            async selectAddress() {
                const { data: res } = await this.$http.post(loginApi.queryUserAddressApi, this.queryAddrList)
                if (res.retcode >= 0) {
                    this.addrLists = res.resultList.REC
                    for (let i = 0; i < res.resultList.REC.length; i++) {
                        if (parseInt(res.resultList.REC[i].IDAD) == 1) {
                            this.itemAddress = res.resultList.REC[i]
                        }
                    }
                }
            },
            //持仓汇总查询
            async getPositionTotal() {
                const { data: res } = await this.$http.post(loginApi.positionTotalApi, this.queryPositionTotal)
                if (isFailureLogin(res.retcode)) {
                    this.positionData = res.resultList.REC
                } else {
                    return this.$message.error(res.message)
                }
            },
            //商品提货仓库查询
            async selectDeliveryStore() {
                const { data: res } = await this.$http.post(loginApi.queryDeliveryWarehouseApi, this.queryDeliveryStore)
                if (isFailureLogin(res.retcode)) {
                    this.deliveryWarehouseData = res.resultList.REC
                } else {
                    return this.$message.error(res.message)
                }
            },
            //配送费查询
            async selectShippingFee() {
                this.queryShipingFee.DQ = this.deliveryFrom.DQ
                this.queryShipingFee.DD = formatTime(new Date().getTime())
                this.queryShipingFee.COI = this.deliveryFrom.COI
                const { data: res } = await this.$http.post(loginApi.queryShippingFeeApi, this.queryShipingFee)
                if (isFailureLogin(res.retcode)) {
                    this.shippingFeeData = res.result
                    this.shippingFeeNotice += res.result.DHF
                    this.shippingFee = res.result.DHF
                } else {
                    return this.$message.error(res.message)
                }
            },
            //提货申请
            async deliveryApply() {
                const { data: res } = await this.$http.post(loginApi.deliveryApplyApi, this.deliveryFrom)
                if (isFailureLogin(res.retcode)) {
                    this.$message.success(res.message)
                    this.$router.push('/user')
                } else {
                    return this.$message.error(res.message)
                }
            },
            //提交提货申请按钮
            onSubmit() {
                this.$refs.deliveryFrom.validate( valid => {
                    if (!valid) {
                        return
                    }
                    //this.selectShippingFee()
                    var timeStamp = formatTime(new Date().getTime())
                    const h = this.$createElement
                    this.$msgbox({
                        title: '提货信息',
                        message: h('p', null, [
                            h('span', null, '商品名称：'),
                            h('i', { style: 'color: teal' }, this.goodName),
                            h('br'),
                            h('span', null, '商品代码：'),
                            h('i', { style: 'color: teal' }, this.deliveryFrom.COI),
                            h('br'),
                            h('span', null, '提货数量：'),
                            h('i', { style: 'color: teal' }, this.deliveryFrom.DQ),
                            h('br'),
                            h('span', null, '提货时间：'),
                            h('i', { style: 'color: teal' }, timeStamp)
                        ]),
                        showCancelButton: true,
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        beforeClose: (action, instance, done) => {
                            if (action === 'confirm') {
                                instance.confirmButtonLoading = true
                                instance.confirmButtonText = '执行中...'
                                setTimeout(() => {
                                    this.deliveryFrom.DD = timeStamp
                                    this.deliveryFrom.DA = JSON.stringify(this.itemAddress) == '{}' ? this.$store.state.addressInfos.RVAD : this.itemAddress.RVAD
                                    this.deliveryFrom.DHF = this.shippingFee
                                    this.deliveryFrom.DPN = JSON.stringify(this.itemAddress) == '{}' ? this.$store.state.addressInfos.RVN : this.itemAddress.RVN
                                    this.deliveryFrom.DPM = JSON.stringify(this.itemAddress) == '{}' ? this.$store.state.addressInfos.RVP : this.itemAddress.RVP
                                    this.deliveryApply()
                                    done()
                                    setTimeout(() => {
                                        instance.confirmButtonLoading = false
                                    }, 300)
                                }, 2000)
                            } else {
                                instance.cancelButtonLoading = true
                                instance.cancelButtonText = '取消中...'
                                setTimeout(() => {
                                    done()
                                    setTimeout(() => {
                                        instance.cancelButtonLoading = false
                                    }, 300)
                                }, 1000)
                            }
                        }
                    }).then(action => {
                        console.log(action)
                    }).catch(err => {
                        this.$message({
                            type: 'info',
                            message: '已取消'
                        })
                    })
                })
            },
            //选中
            selectMe(value) {  
                $('.iconfont').removeClass("icon-honggou")
                $('#addrId_' + value.ADI).find(".iconfont").addClass("icon-honggou")
                //保存地址信息到vuex中
                this.$store.commit('GET_ADDR_INFOS', JSON.stringify(value))
                this.isShowAddr = false
                this.itemAddress = this.$store.state.addressInfos
            },
            //选择商品代码触发事件
            selDeliveryStore(goodId) {
                console.log(goodId)
                this.positionData.forEach((val, index) => {
                    if (val.COI == goodId) {
                        this.goodName = val.CON
                    }
                })
                this.isSelDelivery = false
                this.queryDeliveryStore.COI = goodId
                this.selectDeliveryStore()
            },
            //选择仓库
            isSelStoreId(data) {
                console.log(data)
                if (this.deliveryFrom.COI == '') {
                    this.isSelDelivery = true
                    return this.$message.warning('请先选择商品代码')
                }
            },
            //选中仓库时触发事件
            deliveryNum(item) {
                console.log(item)
                this.deliveryWarehouseData.forEach((val, index) => {
                    if (val.WHI == item) {
                        this.deliveryNumStr = '可提数量' + val.CDQ + val.DUT
                    }
                })
            },
            //地址为空时去添加地址
            toAddUserAddr() {
                this.$router.push('/delivery/useraddress/adduseraddress')
            },
            //打开抽屉默认选中
            setDef(adi) {
                //$('.iconfont').removeClass("icon-honggou")
                //$('#addrId_' + adi).find(".iconfont").addClass("icon-honggou")
                // $('#addrId_' + adi + '.iconfont').setAttribute('class', 'iconfont icon-honggou')
            }
        }
    }
</script>

<style lang="less" scoped>
    .delivery {
        width: 100%;
        padding: 10px;
        margin: 0 auto;
    }
    .delivery-address {
        width: 100%;
        cursor: pointer;
        padding: 0.5rem;
        background-color: #FFFFFF;
        border-radius: 0.5rem 0.5rem;
        overflow: hidden;
        position: relative;
        .el-row:nth-child(1) {
            color: #333333;
            font-size: 1.85rem;
            font-weight: bolder;
            margin-bottom: 0.8rem;
            span:first-child {
                margin-right: 2rem;
            }
        }
        .el-row:nth-child(2) {
            color: #666666;
            font-size: 1.5rem;
            .el-col:first-child {
                text-align: left;
            }
            .el-col:last-child {
                text-align: right;
            }
        }
    }
    .delivery-goods {
        width: 100%;
        min-height: 10rem;
        padding: 0;
        background-color: #FFFFFF;
        border-radius: 0.5rem 0.5rem;
        margin-top: 0.8rem;
        overflow: hidden;
        .el-form {
            width: 100%;
            padding: 1rem;
            box-sizing: border-box;
        }
    }
    .box-card {
        width: 100%;
        margin: 0.5rem;
        max-height: 10rem;
        background-color: #F7F7F7;
        .el-row {
            width: 100%;
            height: 100%;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            padding: 0;
            .el-col:first-child {
                margin-right: 1rem;
            }
            .el-col:last-child {
                font-size: 1.6rem;
                color: #333333;
                div:first-child {
                    width: 100%;
                    height: 2.5rem;
                }
                div:last-child {
                    width: 100%;
                    height: 5rem;
                    padding-left: 0.5rem;
                    padding-top: 0.5rem;
                    font-size: 1.5rem;
                }
            }
        }
        
    }
    .el-card__body {
        padding: 1.1rem !important;
    }
    .el-card.is-always-shadow {
        box-shadow: 0;
    }
    .el-select {
        display: block;
    }
    .el-button {
        width: 100%;
        margin-top: 5rem;
    }
    .addr-empty {
        width: 100%;
        height: 10rem !important;
        display: flex !important;
        flex-flow: row nowrap;
        align-items: center;
        font-size: 1.6rem !important;
        padding: 1.5rem;
        div:last-child {
            text-align: right !important;
        }
    }
</style>