<template>
    <div class="bind-card">
        <div class="bind-title">
            <div class="title-txt">
                <span>自助注册签约</span>
            </div>
        </div>
        <el-divider></el-divider>
        <el-alert title="温馨提示：" show-icon description="签约时间为交易日上午9点~下午5点，具体时间请以平安银行签约时间为准！" type="warning" :closable="false"></el-alert>
        <div class="bind-main">
            <el-form :action="url" method="POST" ref="bindForm" :model="bindForm" :rules="rules" label-width="100px">
                <el-form-item label="会员姓名：" prop="clubName">
                    <el-input type="text" name="clubName" v-model="bindForm.clubName" placeholder="请输入您的真实姓名"></el-input>
                </el-form-item>
                <el-form-item label="签约号：" required>
                    <el-input type="text" name="ThirdCustId" v-model="bindForm.ThirdCustId" :readonly="true"></el-input>
                </el-form-item>
                <el-form-item label="证件号码：" prop="idnumber">
                    <el-input type="text" name="idnumber" v-model="bindForm.idnumber" placeholder="请输入您的身份证号码"></el-input>
                </el-form-item>
                <el-form-item label="银行卡号：" prop="cardNo">
                    <el-input type="text" name="cardNo" v-model="bindForm.cardNo" placeholder="请输入您的鉴权银行卡账号"></el-input>
                </el-form-item>
                <el-form-item label="手机号码：" prop="mobile" required>
                    <el-input type="text" name="mobile" v-model.number="bindForm.mobile" placeholder="请输入您预留的鉴权银行手机号码"></el-input>
                </el-form-item>
                <!-- 隐藏输入 -->
                <!-- 交易市场监管账号 -->
                <input type="hidden" id="MainAcctId" name="MainAcctId" v-model="bindForm.MainAcctId" />
                <!-- 注册类型 -->
                <input type="hidden" id="clubType" name="clubType" v-model="bindForm.clubType" />
                <!-- 证件类型 -->
                <input type="hidden" id="idtype" name="idtype" v-model="bindForm.idtype" />
                <!-- 联系人名称 -->
                <input type="hidden" id="CONTACTER_NAME" name="CONTACTER_NAME" v-model="bindForm.CONTACTER_NAME" />
                <!-- 平台回调地址 -->
                <input type="hidden" id="returnUrl" name="returnUrl" v-model="bindForm.returnUrl" />
                <button :type="btnType" @click="onSubmit" class="btn btn-danger btn-lg btn-block">注册</button>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
    export default {
        name: 'BindBankCard',
        data() {
            //验证手机号是否合法
            let checkPhone = (rule, value, callback) => {
                var phoneReg = /^1[3456789]\d{9}$/
                if (!value) {
                    return callback(new Error('注册手机号不能为空！'))
                } else if (!phoneReg.test(value)) {
                    return callback(new Error('手机号不合法！'))
                } else {
                    callback()
                }
            }
            return {
                url: 'https://my.orangebank.com.cn/corporplat/perRegedit_H5.do',
                btnType: 'button',
                calBgImage: '',
                //签约表单
                bindForm: {
                    clubName: '',  //会员姓名
                    idnumber: '',  //证件号码
                    mobile: '',    //手机号
                    ThirdCustId: this.$store.state.userInfos.FI, //会员代码，平台签约号
                    cardNo: '',     //银行卡号
                    MainAcctId: '11015802009004',
                    clubType: 'P',
                    idtype: '1',
                    CONTACTER_NAME: '',
                    returnUrl: 'http://ybregister.lnddart.com/'
                    //returnUrl: 'https://yuanjunguoji.com'
                },
                //表单验证规则
                rules: {
                    clubName: [
                        { required: true, message: '请输入您的真实姓名', trigger: 'blur' }
                    ],
                    idnumber: [
                        { required: true, message: '请输入您的有效身份证号码', trigger: 'blur' }
                    ],
                    cardNo: [
                        { required: true, message: '请输入您的银行卡卡号', trigger: 'blur' }
                    ],
                    mobile: [
                        { validator: checkPhone, trigger: 'blur' }
                    ]
                }
            }
        },
        mounted() {
            
        },
        computed: {
            ...mapState(['userInfos'])
        },
        methods: {
            //返回
            goBack() {
                this.$router.push('/user/information')
            },
            //注册提交
            onSubmit() {
                this.$refs.bindForm.validate( async valid => {
                    if (!valid) {
                        return false
                    }
                    let formData = new FormData()
                    formData.append("clubName", this.bindForm.clubName)
                    formData.append("idnumber", this.bindForm.idnumber)
                    formData.append("mobile", this.bindForm.mobile)
                    formData.append("ThirdCustId", this.userInfos.RBI)
                    formData.append("cardNo", this.bindForm.cardNo)
                    formData.append("MainAcctId", this.bindForm.MainAcctId)
                    formData.append("clubType", this.bindForm.clubType)
                    formData.append("idtype", this.bindForm.idtype)
                    formData.append("CONTACTER_NAME", this.bindForm.CONTACTER_NAME)
                    formData.append("returnUrl", this.bindForm.returnUrl)
                    this.btnType = 'submit'
                })
            }
        }    
    }
</script>

<style lang="less" scoped>
    .bind-card {
        width: 100%;
        height: 100%;
        .bind-title {
            width: 100%;
            height: 100%;
            background: url('../../../../assets/images/pingan_bank.png') no-repeat;
            background-position: left center;
            background-size: 100% 100%;
            z-index: 999;
            .title-txt {
                width: 100%;
                height: 100%;
                line-height: 4.6rem;
                font-weight: 600;
                color: #FFFFFF;
                font-size: 1.75rem;
                text-align: center;
            }
        }
        .bind-main {
            width: 100%;
            height: 100%;
            margin-top: 1rem;
            .el-form {
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                padding: 1rem;
            }
        }
    }
</style>