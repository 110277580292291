<template>
    <div class="forget-pwd">
        <el-page-header @back="goBack" content="修改地址" style="font-weight: bolder;">
        </el-page-header>
        <el-divider></el-divider>
        <el-form ref="oldForm" :model="oldForm" :rules="rules" label-width="100px">
            <el-form-item label="姓名" prop="RVN">
                <el-input v-model="oldForm.RVN" placeholder="请输入收货人姓名" :value="addressInfos.RVN"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="RVP">
                <el-input v-model="oldForm.RVP" placeholder="请输入联系电话"></el-input>
            </el-form-item>
            <!-- <el-form-item label="所在地区">
                <div class="selAddr" @click="selAddress">
                    <div>{{ selAddrTxt }}</div>
                    <div><span class="iconfont icon-youbianjiantou"></span></div>
                </div>
                <v-distpicker type="mobile" v-show="showAddr"
                    :province="selectItem_province"
                    :city="selectItem_city"
                    :area="selectItem_county"
                    @selected="onSelected">
                </v-distpicker>
            </el-form-item> -->
            <el-form-item label="详细地址" prop="RVAD">
                <el-input type="textarea" v-model="addressDetail"></el-input>
            </el-form-item>
            <el-form-item label="设为默认地址" prop="ISDEF">
                <el-switch v-model="oldForm.ISDEF"></el-switch>
            </el-form-item>
            <el-button class="submit-btn" type="success" @click="onSubmit">保存</el-button>
        </el-form>
    </div>
</template>

<script>
    import { noLoginApi, loginApi } from '@/config/env'
    import VDistpicker from 'v-distpicker'
    import { isFailureLogin } from '@/config/mUtils'
    import { mapState } from 'vuex'
    export default {
        name: 'EditUserAddress',
        data() {
            return {
                // editUserAddrForm: {
                //     "name": "delivery_address_update",
                //     "U": "",
                //     "SI": "",
                //     "OPR": "2",
                //     "ADI": "",
                //     "RVN": "",  //收货人姓名
                //     "RID": "",  //收货人身份证号
                //     "RVP": "",  //收货人电话号码
                //     "RVAD": "",  //收货人地址
                //     "RVPC": "",  //收货人邮编
                //     "ISDEF": 2,  //是否为默认 1：是 2：不是
                // },
                oldForm: {
                    "RVN": "",
                    "RID": "",
                    "RVP": "",
                    "RVAD": "",
                    "RVPC": "",
                    "ISDEF": "" 
                },
                rules: {
                    RVN: [
                        { required: true, message: '请输入姓名', trigger: 'blur' },
                    ],
                    RVP: [
                        { required: true, message: '请输入联系电话', trigger: 'blur' },
                    ]
                },
                selectItem_province:'',  /**省*/
				selectItem_city:'',      /**市*/
				selectItem_county:'',    /**县*/
				addressPCA: '',
                showAddr: false,  //是否显示
                selAddrTxt: '请选择地区',
                addressDetail: ''
            }
        },
        components: { VDistpicker },
        beforeCreate() {
            var addrId = this.$route.query.addrId
            if (addrId != this.$store.state.addressInfos.ADI) {
                this.$message.error('参数非法，即将返回...')
                setTimeout(() => {this.$router.go(-1)}, 500)
                return false
            }
        },
        computed: {
            ...mapState(['addressInfos'])
        },
        mounted() {
            this.oldForm.RVN = this.addressInfos.RVN
            this.oldForm.RVP = this.addressInfos.RVP
            this.oldForm.RVAD = this.addressInfos.RVAD
            this.oldForm.ISDEF = this.addressInfos.IDAD == 1 ? true : false
            this.addressDetail = this.addressInfos.RVAD
        },
        methods: {
            //返回
            goBack() {
                this.$router.push('/delivery/useraddress')
            },
            //提交修改地址保存
            onSubmit() {
                /* formData格式提交： */
                let formData = new FormData();
                formData['name'] = 'delivery_address_update'
                formData['U'] = ''
                formData['SI'] = ''
                formData['OPR'] = '2'
                formData['ADI'] = this.addressInfos.ADI
                formData['RVN'] = this.oldForm['RVN']
                formData['RID'] = this.oldForm['RID']
                formData['RVP'] = this.oldForm['RVP']
                formData['RVAD'] = this.addressDetail
                formData['RVPC'] = this.oldForm['RVPC']
                formData['ISDEF'] = this.oldForm['ISDEF'] ? '1' : '2'
                this.$refs.oldForm.validate(async (valid) => {
                    if(!valid) return
                    const { data: res } = await this.$http.post(loginApi.saveUserAddressApi, JSON.stringify(formData))
                    //判断是否登录失效，以及返回错误信息
                    if (isFailureLogin(res.retcode)) {
                        this.$alert('修改成功', '提示', {
                            confirmButtonText: '确定',
                            callback: action => {
                                setTimeout(() => {this.$router.push('/delivery/useraddress')}, 800)
                            }
                        })
                    } else {
                        return this.$message.error(res.message)
                    }
                })
            },
            //选择省市区
            onSelected(data) {
                this.showAddr = false
                this.selectItem_province = data.province.value
				this.selectItem_city = data.city.value
				this.selectItem_county = data.area.value
                this.selAddrTxt = data.province.value + ' ' + data.city.value +' ' + data.area.value
                this.addressPCA = data.province.value + ' ' + data.city.value +' ' + data.area.value
            },
            selAddress() {
                this.showAddr = true
            }
        }
    }
</script>

<style lang="less" scoped>
    .forget-pwd {
        width: 100%;
        padding: 1rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        form {
            margin-top: 5%;
        }
    }
    .submit-btn {
        width: 100%;
    }
    .selAddr {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        color: #c0c4cc;
        background-color: #FFFFFF;
        cursor: pointer;
        div:first-child {
            width: 80%;
            font-size: inherit;
            padding: 0 15px;
        }
        div:last-child {
            width: 20%;
            text-align: right;
            padding: 0 10px;
        }
    }
</style>