<template>
    <div>
        <el-page-header @back="goBack" content="简介"></el-page-header>
        <el-divider></el-divider>
        <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="元钧国际" name="first">
                <AboutThree />
            </el-tab-pane>
            <el-tab-pane label="爱酒汇" name="second">
                <AboutTwo />
            </el-tab-pane>
            <el-tab-pane label="现代人" name="third">
                <AboutOne />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import AboutThree from './children/AboutThree.vue'
import AboutTwo from './children/AboutTwo.vue'
import AboutOne from './children/AboutOne.vue'
export default {
    name: 'About',
    components: {
        AboutThree,
        AboutTwo,
        AboutOne
    },
    data() {
        return {
            activeName: 'first'
        }
    },
    mounted() {
        this.activeName = this.$route.query.type == 'undefined' ? 'first' : this.$route.query.type
    },
    methods: {
        //返回
        goBack() {
            this.$router.push('/login')
        },
        //切换tab触发事件
        handleClick(tab, event) {
            const tab_name = tab.name
            switch (tab_name) {
                case 'first':
                    this.$router.push('/about/aboutthree')
                    this.activeName = 'first'
                    break;
                case 'second':
                    this.$router.push('/about/abouttwo')
                    this.activeName = 'second'
                    break;
                case 'third':
                    this.$router.push('/about/aboutone')
                    this.activeName = 'third'
                    break
                default:
                    break;
            }
        }
    }
}
</script>

<style scoped lang="less">
.el-page-header {
    width: 100%;
    padding: 0.5rem;
}
.el-divider--horizontal /deep/ {
    margin: 10px 0;
}
</style>