<template>
    <div class="withdrawal">
        <div class="withdrawal-head">
            <el-page-header @back="goBack" content="出金" style="font-weight: bolder;">
            </el-page-header>
        </div>
        <!-- 分割线 -->
        <el-divider></el-divider>
        <div class="withdrawal-main">
            <el-form :model="withdrawalShowForm" ref="withdrawalShowForm" label-width="100px">
                <el-form-item label="签约银行" prop="">
                    <el-input v-model="bankInfos.BKN" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="银行名称" prop="">
                    <el-input v-model="signBankInfos.SBN" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="银行卡号" prop="">
                    <el-input v-bind:value="signBankInfos.BN | hiddenString(4,15)" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="可提金额" prop="">
                    <el-input v-bind:value="'￥' + withdrawalAmount" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="出金金额" required>
                    <el-input v-model="withdrawalPrice" placeholder="请输入出金金额">
                        <el-button slot="append" @click.native="allWithdrawal">全部出金</el-button>
                    </el-input>
                </el-form-item>
                <el-form-item label="资金密码" required>
                    <el-input v-model="moneyPassword" show-password placeholder="请输入您的资金密码"></el-input>
                </el-form-item>
                <button type="button" @click="onSubmit" class="btn btn-danger btn-lg btn-block">提交</button>
            </el-form>
        </div>
    </div>
</template>

<script>
    import { loginApi } from '@/config/env'
    import { isFailureLogin } from '@/config/mUtils'
    export default {
        name: 'Withdrawal',
        data() {
            return {
                //银行信息查询参数
                queryBankParams: {
                    "name": "banks_info_query",
                    "U": "",
                    "SI": ""
                },
                //签约信息查询参数
                querySignBankParams: {
                    "name": "regest_info_query",
                    "U": "",
                    "SI": ""
                },
                //资金池信息查询参数
                queryFundsInfoParams: {
                    "name": "fundspool_fundinfo_query",
                    "U": "",
                    "SI": "",
                    "IQA": "0", //是否查询所包含的附属账号的资金信息,0只查登录用户资金信息,1查询所有附属账号的资金信息
                    "PFID": "",  //资金池编号(传空表示查所有)
                    "FP": ""
                },
                //出金操作参数
                doWithdrawalParams: {
                    "name": "out_funds",
                    "U": "",
                    "SI": "",
                    "BI": "",  //银行编号
                    "FPI": "", //资金池编号
                    "B": "",   //所属人
                    "BT": "",  //所属人类型
                    "OM": "",  //出金金额
                    "FP": "",  //资金密码
                    "BP": "",  //银行密码
                },
                withdrawalShowForm: {},
                moneyPassword: '',
                withdrawalPrice: '',
                bankInfos: {},
                signBankInfos: {},
                fundsInfos: {},
                withdrawalAmount: ''
            }
        },
        created() {
            
        },
        mounted() {
            this.queryBankInfos()
            this.querySignBankInfos()
            this.selectFundsInfos()
        },
        methods: {
            //返回
            goBack() {
                this.$router.push('/user')
            },
            //全部出金按钮
            allWithdrawal() {
                this.withdrawalPrice = this.withdrawalAmount
            },
            //提交
            onSubmit() {
                if (this.withdrawalPrice == '') {
                    this.$message.error('请输入出金金额')
                    return
                }
                if (this.moneyPassword == '') {
                    this.$message.error('请输入您的资金密码')
                    return
                }
                
                this.doWithdrawalParams.BI = this.signBankInfos.BI
                this.doWithdrawalParams.FPI = this.fundsInfos.FPID
                this.doWithdrawalParams.B = this.fundsInfos.B
                this.doWithdrawalParams.BT = this.fundsInfos.BT
                this.doWithdrawalParams.OM = this.withdrawalPrice
                this.doWithdrawalParams.FP = this.moneyPassword
                this.$confirm('您确定要出金￥' + this.doWithdrawalParams.OM + '吗?', '确认提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then( async () => {
                    const { data: res } = await this.$http.post(loginApi.doWithdrawalApi, this.doWithdrawalParams)
                    if (isFailureLogin(res.retcode)) {
                        this.$message.success(res.message)
                        setTimeout(() => {
                            this.$router.push('/money/depositrecord')
                        }, 1000)
                    } else {
                        this.$message.error(res.message)
                        return
                    }
                }).catch(() => {
                    this.$message.warning('已取消')
                    return
                })
            },
            //查询银行信息
            async queryBankInfos() {
                const { data: res } = await this.$http.post(loginApi.queryBankInfoApi, this.queryBankParams)
                if (isFailureLogin(res.retcode)) {
                    for (let i = 0; i < res.resultList.REC.length; i++) {
                        if (res.resultList.REC[i].IR == 1) {
                            this.bankInfos = res.resultList.REC[i]
                        }
                    }
                } else {
                    this.$message.warning(res.message)
                    return
                }
            },
            //查询签约信息
            async querySignBankInfos() {
                const { data: res } = await this.$http.post(loginApi.querySignBankApi, this.querySignBankParams)
                if (isFailureLogin(res.retcode)) {
                    this.signBankInfos = res.resultList.REC[0]
                } else {
                    this.$message.warning(res.message)
                    return
                }
            },
            //查询登录用户资金池资金情况
            async selectFundsInfos() {
                const { data: res } = await this.$http.post(loginApi.queryFundspoolInfoApi, this.queryFundsInfoParams)
                if (isFailureLogin(res.retcode)) {
                    for (let i = 0; i < res.resultList.REC.length; i++) {
                        if (res.resultList.REC[i].FPID == '97') {
                            this.fundsInfos = res.resultList.REC[i]
                            this.withdrawalAmount = res.resultList.REC[i].OF
                        }
                    }
                } else {
                    this.$message.warning(res.message)
                    return
                }
            }
        },
        //监听输入值
        watch: {
            withdrawalPrice(newVal, oldVal) {
                if (newVal > this.withdrawalAmount) {
                    this.$message.warning('出金金额不能超过可提现金额')
                    return
                }
            }
        }    
    }
</script>

<style lang="less" scoped>
    .withdrawal {
        width: 100%;
        height: auto;
        .withdrawal-head {
            width: 100%;
            height: 100%;
            padding: 1rem;
        }
        .withdrawal-main {
            width: 100%;
            height: 100%;
            padding: 1rem;
            // 样式穿透
            .el-input /deep/ .el-input__inner {
                color: #333333;
                font-weight: 600;
            }
        }
    }
</style>