<template>
    <div class="market-main">
        <div class="market-title">
            <el-row>
                <el-col :span="24"><h4>商品市场</h4></el-col>
                <el-col :span="24">
                    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                        <el-tab-pane label="云直供" name="1080">
                            <div class="goods-info" @click="queryDetails(item.resultList.REC[0].COI)" v-for="(item, index) in auctionList" :key="index">
                                <div class="goods-images">
                                    <img :src="picUrl" alt="" class="img-thumbnail" style="width: 100%; height: 100%" />
                                </div>
                                <div class="goods-content">
                                    <el-row>
                                        <el-col :span="24" class="goods-title">
                                            <span>[{{ item.resultList.REC[0].COI }}]</span>
                                            <span>{{ item.resultList.REC[0].CON }}</span>
                                        </el-col>
                                        <el-col :span="24" class="goods-price">
                                            <span>￥{{ item.result.P }}</span>
                                            <span>{{ item.result.CHA }}%</span>
                                        </el-col>
                                        <el-col :span="24" class="goods-total">
                                            <div>成交量：{{ item.result.TLQTY }}</div>
                                            <div>金额：￥{{ item.result.TLAMT }}</div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-tab-pane>
                        <!-- <el-tab-pane label="文联通" name="1000">
                            <div class="goods-info">
                                <div class="goods-images">
                                    <img :src="picUrl" alt="" class="img-thumbnail" style="width: 100%; height: 100%" />
                                </div>
                                <div class="goods-content">
                                    <el-row>
                                        <el-col :span="24" class="goods-title">
                                            <span>[860001]</span>
                                            <span>现代人敬辉煌</span>
                                        </el-col>
                                        <el-col :span="24" class="goods-price">
                                            <span>￥1.00</span>
                                            <span>0.00%</span>
                                        </el-col>
                                        <el-col :span="24" class="goods-total">
                                            <div>成交量：50887</div>
                                            <div>金额：￥54562.00</div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="新零售" name="1001">
                            <div class="goods-info">
                                <div class="goods-images">
                                    <img :src="picUrl" alt="" class="img-thumbnail" style="width: 100%; height: 100%" />
                                </div>
                                <div class="goods-content">
                                    <el-row>
                                        <el-col :span="24" class="goods-title">
                                            <span>[860001]</span>
                                            <span>现代人敬辉煌</span>
                                        </el-col>
                                        <el-col :span="24" class="goods-price">
                                            <span>￥1.00</span>
                                            <span>0.00%</span>
                                        </el-col>
                                        <el-col :span="24" class="goods-total">
                                            <div>成交量：50887</div>
                                            <div>金额：￥54562.00</div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="艺术品" name="1060">
                            <div class="goods-info">
                                <div class="goods-images">
                                    <img :src="picUrl" alt="" class="img-thumbnail" style="width: 100%; height: 100%" />
                                </div>
                                <div class="goods-content">
                                    <el-row>
                                        <el-col :span="24" class="goods-title">
                                            <span>[860001]</span>
                                            <span>现代人敬辉煌</span>
                                        </el-col>
                                        <el-col :span="24" class="goods-price">
                                            <span>￥1.00</span>
                                            <span>0.00%</span>
                                        </el-col>
                                        <el-col :span="24" class="goods-total">
                                            <div>成交量：50887</div>
                                            <div>金额：￥54562.00</div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-tab-pane> -->
                    </el-tabs>
                </el-col>
            </el-row>
        </div>
        <FooterNavigation />
    </div>
</template>

<script>
    import FooterNavigation from '@/components/common/footer/FooterNavigation.vue'
    import { loginApi, noLoginApi } from '@/config/env'
    import picUrlImg from '@/assets/images/xiandairen.jpg'
    export default {
        name: 'Market',
        components: {
            FooterNavigation
        },
        data() {
            return {
                activeName: '1080',
                picUrl: picUrlImg,
                // queryAuction: {
                //     "name": "commodity_list_query",
                //     "U": "",
                //     "CGID": "1080",
                //     "SI": ""
                // },
                queryCateList: {
                    "name": "category_query",
                    "U": "",
                    "SI": ""
                },
                auctionList: {}
            }
        },
        computed: {},
        mounted() {
            this.getAuction()
        },
        methods: {
            //获取拍场列表信息
            async getAuction() {
                const { data: res } = await this.$http.post(noLoginApi.auctionListApi)
                if (res.code !== 'E000000') {
                    return this.$message.warning(res.message)
                }
                this.auctionList = res.data
            },
            //查看拍品详情
            queryDetails(gid) {
                const gId = parseInt(gid)
                if (isNaN(gId)) {
                    return
                }
                this.$router.push({ path: '/market/auctiondetails', query: { gid: gId} })
            },
            //点击tab标签回调
            handleClick(tab, event) {
                
            }
        }
    }
</script>

<style lang="less" scoped>
    .market-main {
        width: 95%;
        height: 100%;
        margin: 0 auto;
        padding: 10px;
        h4 {
            text-align: center;
            font-weight: bolder;
            margin-top: 1.2rem;
        }
    }
    .goods-info {
        width: 100%;
        height: 90px;
        max-height: 90px;
        background-color: #FFFFFF;
        border-radius: 5px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12);
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 0.8rem;
        cursor: pointer;
        .goods-images {
            width: 80px;
            min-width: 80px;
            height: 80px;
            max-height: 80px;
        }
        .goods-content {
            width: 100%;
            height: 80px;
            max-height: 80px;
            margin-left: 0.5rem;
            padding: 0.3rem;
            
        }
    }
    .goods-title {
        font-weight: 600;
        color: #333333;
        font-size: 16px;
        margin-bottom: 0.5rem;
        span {
            margin-right: 10px;
        }
    }
    .goods-price {
        margin-bottom: 0.5rem;
        span:nth-child(1) {
            color: #DF2625;
            font-size: 1.3rem;
        }
        span:nth-child(2) {
            margin-left: 1.56rem;
        }
    }
    .goods-total {
        color: #999999;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
</style>