<template>
    <div class="spot-rules">
        <div class="rules-title">
            <el-page-header @back="goBack" content="拍卖规则" title="返回">
            </el-page-header>
        </div>
        <div class="rules-content">
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:0;text-align:center;line-height:21px">
                <strong><span style="font-family: &#39;Arial Black&#39;;letter-spacing: 0;font-size: 27px"><span style="font-family:微软雅黑">拍卖规则</span></span></strong>
            </p>
            <p style=";text-indent: 0">
                <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第一章</span>&nbsp;总则</span></strong>
            </p>
            <p style=";text-indent: 0">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">&nbsp;&nbsp;&nbsp;&nbsp;第一条&nbsp;为加强对拍卖业务监督管理，维护市场秩序，拍卖人依据《中华人民共和国拍卖法》《拍卖监督管理办法》以及《网络拍卖规程》等相关国家法律、法规以及政策标准，制定本拍卖规则（以下称“本规则”）。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:24px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第二条</span>&nbsp;拍卖业务的参与人应在中华人民共和国法律和政策允许的范围内参与拍卖业务，应当仔细阅读并遵守本规则，并对自己参与拍卖业务的行为负责。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:24px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第三条</span>&nbsp;本规则适用于拍卖业务的所有参与人。</span>
            </p>
            <p style=";text-indent: 0">
                <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第二章</span>&nbsp;拍卖业务管理</span></strong>
            </p>
            <p style="margin-top: 0;margin-right: 0;margin-bottom: 0;text-indent: 16px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第一节</span>&nbsp;&nbsp;名词解释</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:24px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第四条</span>&nbsp;名词解释：</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">【拍卖人】</span></strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">指依照《中华人民共和国拍卖法》设立的从事拍卖活动的公司，即本公司。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">【服务方】</span></strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">指提供拍卖软件的开发、升级、日常运营维护等服务的法人或组织。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">【拍卖】</span></strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">包括网络拍卖和现场拍卖，统称拍卖。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">【网络拍卖】</span></strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">通过网络，以公开竞价的形式，将特定物品或财产权利转让给最高应价者的买卖方式。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">【现场拍卖】</span></strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">通过现场举牌等方式，以公开竞价的形式，将特定物品或者财产权利转让给最高应价者的买卖方式。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">【拍卖客户】</span></strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">指符合本拍卖业务规定条件，在网络拍卖软件注册或登记现场拍卖并参加拍卖业务的自然人、法人或其他组织。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">【竞买人】</span></strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">指在拍卖活动中参加竞购拍卖产品的拍卖客户。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">【买受人】指在拍卖活动中购得拍卖产品的拍卖客户。</span></strong>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">【委托人】</span></strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">是指委托拍卖人拍卖物品或者财产权利的公民、法人或者其他组织。</span>
            </p>
            <p style=";text-indent: 31px;line-height: 28px;background: rgb(255, 255, 255)">
                <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">【拍卖产品】</span></strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">指通过网络或现场拍卖的产品，亦称</span>&nbsp;“拍品”或“拍卖标的”。</span>
            </p>
            <p style=";text-indent: 31px;line-height: 28px;background: rgb(255, 255, 255)">
                <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">【起拍价】</span></strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">预展拍公示的拍品起始价格。</span>
            </p>
            <p style=";text-indent: 31px;line-height: 28px;background: rgb(255, 255, 255)">
                <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">【落槌价】</span></strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">系指买受人获得拍品的价格。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">【竞买保证金】</span></strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">指竞买人用以保证履行参与拍卖业务的资金。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">【保留价】</span></strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">委托人确定的拍卖产品的成交的最低成交价格。</span>
            </p>
            <p style="margin-top: 0;margin-right: 0;margin-bottom: 0;text-indent: 16px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第二节</span>&nbsp;拍品管理</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:24px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第五条</span>&nbsp;委托人应提供拍品名称、类型、材质、规格、颜色、数量、起拍价、保留价等信息。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:24px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第六条</span>&nbsp;参与拍卖的委托人应提供身份证明和拍卖人要求提供的拍卖标的的所有权证明或者依法可以处分拍卖标的的证明及其他资料。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:24px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第七条</span>&nbsp;拍卖人对需进行拍卖的拍品发布拍卖公告，拍品将在拍卖前进行线下或线上预展。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:24px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第八条</span>&nbsp;拍卖人是仅为委托人与买受人提供综合拍卖业务的服务型平台，所有在拍卖人平台展示拍卖的拍品并不视为拍卖人对委托人提交文件、材料的真实性、准确性、完整性、合法性作任何确认或承诺、担保。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第九条</span>&nbsp;所有拍品的品相均以实物为准，网络拍卖提供的图录仅做参考。各竞买人应于竞买前对欲竞买之拍卖产品的状态进行充分了解，并对自己的竞买行为负责。竞买人在竞拍时必须认同和接受拍卖标的的质量可能存在的瑕疵。拍卖人对拍品的真伪及品质不承担瑕疵担保责任。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:40px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">因印刷或摄影等技术原因造成拍品在图录或其他任何形式的图示、影像制品和宣传品中的色调、颜色、层次、形态等与原物存在误差的，以原物为准。拍卖人及其工作人员对任何拍品用口头或书面方式（包括证书、图录、状态说明、幻灯投影、新闻载体、网络媒体等）所作的介绍及评价，均为参考性意见，不构成对拍品的任何担保或承诺。拍卖人及其工作人员无需对上述介绍及评价中的不准确或遗漏之处负责。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:40px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第十条</span>&nbsp;拍卖人和服务方有权对委托人委托的任何拍品制作照片、图示、图录或其它形式的影像制品，并依法享有上述照片、图示、图录或其它形式的影像制品的著作权，有权对其依法加以使用。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:24px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">&nbsp;</span>
            </p>
            <p style=";text-indent: 0">
                <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第三章</span>&nbsp;拍卖管理</span></strong>
            </p>
            <p style="margin-top: 0;margin-right: 0;margin-bottom: 0;text-indent: 16px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第一节</span>&nbsp;拍卖客户</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第十一条</span>&nbsp;拍卖客户参与网络拍卖业务的，须事先按照相关规定申请成为注册用户；参与现场拍卖业务的，需前往现场完成现场报名登记。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第十二条</span>&nbsp;申请注册的自然人需年满18周岁,具有完全民事行为和民事责任能力；申请注册的机构客户需是在中华人民共和国境内依法注册且合法存续的法人、其他经济组织等。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第十三条</span>&nbsp;拍卖客户应按要求取得参与拍卖业务资格。如参与网络拍卖，需按网络注册要求于线上申请拍卖账户，同时线上签署《拍卖客户注册协议》并提供身份证件信息等其他资料；如参与现场拍卖的，需于拍卖人指定的经营场所现场签订拍卖相关协议，并按现场要求办理相关登记手续。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第十四条</span>&nbsp;拍卖客户网络签订《拍卖客户注册协议》，或现场办理参拍登记手续的，皆即视为同意遵守《拍卖规则》及其他管理制度等文件的规定，并自愿承担拍卖业务可能产生的各类风险。在拍卖业务过程中，如产生拍卖交易纠纷，拍卖人不承担相关法律责任。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第十五条</span>&nbsp;拍卖客户须对自身注册的网络账户安全负责。任何通过其用户名和密码登录拍卖软件进行产品拍卖业务和资金操作以及提货申请等行为，均视为拍卖客户本人的行为，该行为由拍卖客户本人负全部责任。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">拍卖客户不应将其帐号、密码转让或出借予他人</span>/组织使用。如拍卖客户发现其帐号遭他人非法使用，应立即通知拍卖人。因黑客行为或用户故意或者过失导致帐号、密码遭他人非法使用，拍卖人不承担任何责任。</span>
            </p>
            <p style="margin-top: 0;margin-right: 0;margin-bottom: 0;text-indent: 16px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第二节</span>&nbsp;委托人</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第十六条</span>&nbsp;申请成为委托人需按拍卖人的管理规则提交相关上拍资料，申请注册的委托人为拥有财产权利的自然人，需年满18周岁,具有完全民事行为和民事责任能力的自然人。申请注册的委托人作为法人或者其他组织的需是在中华人民共和国境内依法注册且合法存续的法人、其他经济组织等。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第十七条</span>&nbsp;委托人对申请拍卖的拍品应当拥有所有权、处置权以及其他无争议的相关权益，同时委托人对其提供的拍品负责，承担瑕疵担保责任。拍卖人只提供拍卖业务平台服务，不对拍品权属、属性、价值、真伪等负责。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">&nbsp;</span>
            </p>
            <p style="margin-top: 0;margin-right: 0;margin-bottom: 0;text-indent: 16px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第三节</span>&nbsp;拍卖行为</span>
            </p>
            <p style=";text-indent: 32px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第十八条</span>&nbsp;拍卖时间为周一到周五（上午9:30—11:30,下午13:00—15:00），法定节假日除外。根据市场需求，拍卖人可调整拍卖时间。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:40px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第十九条</span>&nbsp;&nbsp;网络拍卖业务采用的竞买/竞卖的拍卖方式，竞买是通过浏览拍品的出价列表中选定出价最低拍品进行购买，最终获得拍品；竞卖是通过浏览拍品的出价列表中选定出价最高者进行卖出，最终出让拍品的方式。</span>
            </p>
            <p style=";text-indent: 32px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">&nbsp;第二十条&nbsp;保证金是拍卖客户需要预先缴纳一定金额的资金，作为竞得拍品之后，需支付的拍品款及相关费用的保证。竞拍成功后，所缴纳的保证金自动转化为成交款。拍卖客户未竞拍成功的，将退还拍卖客户保证金。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第二十一条</span>&nbsp;拍卖成功后，拍品持有人转让拍卖产品的所有权，买受人获得拍卖产品的所有权，双方应承认拍卖产品已成交的事实，并享有法律规定及本规则规定的权利，承担法律规定和本规则规定的义务。同时拍卖成功即视为买受人认同并接受与拍卖人合作的服务方提供的物流、保价等相关服务，买受人在拍卖的过程中自行承担所有风险。&nbsp;</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:40px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第二十二条</span>&nbsp;&nbsp;买受人拍得的拍卖产品可再次进行拍卖。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:40px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第二十三条</span>&nbsp;买受人通过网络拍卖的在拍卖成交后，可在拍卖软件发出提货申请，提取拍卖产品；买受人通过现场拍卖成交的可在现场办理提货手续。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:40px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第二十四条</span>&nbsp;买受人可以选择对购得的拍品进行一次性或分次进行部分或者全部提货。具体可选择邮寄、自提等提货方式，产生的运费、保价费等相关费用由买受人自行承担。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:40px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第二十五条</span>&nbsp;买受人提货时选择邮寄的，应仔细确认拍品名称、提货数量、联系电话、提货地址等信息，如因上述信息不明确或错误导致无法配送或延迟配送的，由此产生的损失拍卖人不承担任何责任。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:40px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第二十六条</span>&nbsp;买受人选择自提的须现场验货，在签收提货确认单后即为确认货物无误；买受人选择邮寄提货的，在收到拍品时应当主动验收，确认无误后方可收货。超过7天买受人未提出书面文件异议的，则视为买受人自动放弃一切诉求，拍卖人不再接收买受人的任何疑问。</span>
            </p>
            <p style="margin: 0 0 0 5px;text-indent: 32px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第二十七条</span>&nbsp;如果拍卖标的截止拍卖的，买受人应于拍卖人发布拍卖结束公告之日起三十日内提取已成功竞拍的拍卖标的。委托人应于拍卖人发布拍卖结束公告之日起六十日内领取未拍卖成功的拍卖标的。未按期提货的，需按照拍卖人的规定缴纳延期仓储费、保险费等其他管理费，同时拍卖人依法取得该拍品的留置权。</span>
            </p>
            <p style="margin: 0 0 0 5px;text-indent: 32px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">&nbsp;</span>
            </p>
            <p style=";text-indent: 0">
                <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第四章</span>&nbsp;&nbsp;现场拍卖管理</span></strong>
            </p>
            <p style=";text-indent: 32px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第二十八条</span>&nbsp;登记</span>
            </p>
            <p style=";text-indent: 32px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">参加线下竞买应在拍卖日前，按照拍卖公告通知，按要求办理登记手续，以取得拍卖活动的竞买权，成为竞买人，领取竞买号牌。竞买人对其领取的竞买号牌应妥善保管，在竞买人未先行向拍卖人报失的情况下，竞买人对持有其竞买号牌的行为人的竞买行为承担责任。</span>
            </p>
            <p style="margin: 0 0 0 5px;text-indent: 32px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第二十九条</span>&nbsp;拍卖保证金</span>
            </p>
            <p style="margin: 0 0 0 5px;text-indent: 32px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">竞买人参加现场拍卖活动，应在领取竞买号牌前一个工作日交纳保证金，保证金的具体数额由拍卖人在拍卖日前公布。竞买人未能购得拍卖标的，则保证金在拍卖日后七个工作日内全额无息返还竞买人；竞买人购得拍卖标的，则保证金抵扣购买价款，差额部分在拍卖成交日后七个工作日内补齐。具体以拍卖公告为准。</span>
            </p>
            <p style="margin: 0 0 0 5px;text-indent: 32px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第三十条</span>&nbsp;成交</span>
            </p>
            <p style="margin: 0 0 0 5px;text-indent: 32px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">竞买人现场以最高应价经拍卖师落槌或者以其他方式确认成交时，则竞买人成功竞买拍卖标的，成为买受人，买受人须当场签署成交确认书。</span>
            </p>
            <p style="margin: 0 0 0 5px;text-indent: 32px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第三十一条</span>&nbsp;成交价款及佣金</span>
            </p>
            <p style="margin: 0 0 0 5px;text-indent: 32px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">成交后买受人须向拍卖人付清成交价款，并按要求支付对应服务费后领取拍卖物品或受让拍卖权利。买受人如不能当场付清成交价款及佣金，应当场支付不少于成交价百分之三十的预付款，其余款项于拍卖成交日后七日内一次付清。如果买受人未在规定日期内交付余款，既形成违约，已缴纳的保证金和预付款不予退回，用以支付委拍人的损失和拍卖人的管理服务费。具体以拍卖公告为准。</span>
            </p>
            <p style=";text-indent: 32px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第三十二条</span>&nbsp;流拍</span>
            </p>
            <p style="margin: 0 0 0 5px;text-indent: 32px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">如果拍卖标的现场未能成交，委托人应自拍卖结束之日起六十日内领取拍卖标的，并按保留价向拍卖人支付相应约定的未拍出手续费及其它各项费用。</span>
            </p>
            <p style="margin: 0 0 0 5px;text-indent: 32px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">&nbsp;</span>
            </p>
            <p style=";text-indent: 0">
                <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第五章</span>&nbsp;监督管理</span></strong>
            </p>
            <p style="margin: 0 0 0 5px;text-indent: 32px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第三十三条</span>&nbsp;拍卖人依据本规则及有关规定，对拍卖业务进行监督管理。</span>
            </p>
            <p style="margin: 0 0 0 5px;text-indent: 32px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第三十四条</span>&nbsp;如出现下列情况之一，拍卖人有权在拍卖过程中的任何时间中止任何拍卖产品的拍卖业务:</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:32px;text-indent:0;text-align:justify;text-justify:inter-ideograph;line-height:26px;background:rgb(255,255,255)">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">(一)&nbsp;拍卖人对拍卖产品的归属或资料的准确性持有异议；</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:32px;text-indent:0;text-align:justify;text-justify:inter-ideograph;line-height:26px;background:rgb(255,255,255)">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">(二)&nbsp;委托人在拍卖业务开始前书面通知拍卖人终止拍卖的；</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:32px;text-indent:0;text-align:justify;text-justify:inter-ideograph;line-height:26px;background:rgb(255,255,255)">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">(三)&nbsp;有证据表明委托人已经违反本规则的任何条款；</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:28px;text-align:justify;text-justify:inter-ideograph;line-height:26px;background:rgb(255,255,255)">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">(四)&nbsp;人民法院、仲裁机构或者有关行政机关认定委托人对拍卖产品无处分权并书面通知拍卖人的，或政府主管机关因其他原因要求拍品停止拍卖的；</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:32px;text-indent:0;text-align:justify;text-justify:inter-ideograph;line-height:26px;background:rgb(255,255,255)">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">(五)&nbsp;拍卖产品被认定为赃物的；</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:32px;text-indent:0;text-align:justify;text-justify:inter-ideograph;line-height:26px;background:rgb(255,255,255)">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">(六)&nbsp;发生不可抗力或意外事件致使拍卖业务无法进行的；</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:32px;text-indent:0;text-align:justify;text-justify:inter-ideograph;line-height:26px;background:rgb(255,255,255)">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">(七)&nbsp;拍卖产品在拍卖前毁损、灭失的；</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:32px;text-indent:0;text-align:justify;text-justify:inter-ideograph;line-height:26px;background:rgb(255,255,255)">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">(八)&nbsp;出现其他依法应当终止的情形的。</span>
            </p>
            <p style="margin: 7px 0;text-indent: 32px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">第三十五条委托人应承担以下责任：</span>
            </p>
            <p style="margin: 7px 0;text-indent: 24px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">&nbsp;(一)对其他任何权利人就拍卖产品的毁损、灭失向拍卖人提出的索赔或诉讼做出赔偿；</span>
            </p>
            <p style="margin: 7px 0;text-indent: 32px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">(二) 赔偿拍卖人或任何他方因任何原因造成拍卖产品毁损、灭失等所遭受的全部损失及所支出的全部费用。</span>
            </p>
            <p style="margin: 7px 0;text-indent: 32px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">(三)将本条所述的赔偿规定通知该拍卖产品的任何承保人。</span>
            </p>
            <p style="margin: 7px 0;text-indent: 32px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第三十六条</span>&nbsp;出现下列情形，拍卖人不承担任何责任：</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">(一)&nbsp;由于拍卖客户诚信问题造成损失的；</span>
            </p>
            <p style=";text-indent: 32px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">(二)&nbsp;由于拍卖产品权属、瑕疵、可能存在的纠纷等不可预料问题造成损失的；</span>
            </p>
            <p style=";text-indent: 32px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">(三)&nbsp;因拍卖客户发布的有关拍卖业务、拍卖产品的信息所产生争议的；</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:32px;text-indent:0;text-align:justify;text-justify:inter-ideograph;line-height:26px;background:rgb(255,255,255)">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">(四)&nbsp;人民法院、仲裁机构或者有关行政机关认定委托人对拍卖产品无处分权并书面通知拍卖人的，或政府主管机关因其他原因要求拍品停止拍卖的；</span>
            </p>
            <p style=";text-indent: 32px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">(五)&nbsp;由于拍卖客户填写的信息不真实、不准确或不完整；未及时关注与拍卖相关的信息及公告；因自身的终端设备和网络异常；因自身终端设备的时间与拍卖软件系统的时间不符；将个人密码告知他人或与他人共享拍卖账户，由此导致的任何损失的；</span>
            </p>
            <p style=";text-indent: 32px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">(六)&nbsp;因线路、设备及电力等故障或由于黑客攻击、计算机病毒侵入、因政府管制而造成的暂时性关闭等影响正常经营的。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第三十七条</span>&nbsp;对拍卖业务过程中发生的重大问题，由拍卖人协调各方妥善解决。</span>
            </p>
            <p style=";text-indent: 32px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第三十八条</span>&nbsp;各申请参与产品拍卖业务的相关主体相互之间发生争议时，可自行协商解决，也可向拍卖人申请协助协商。若协商不成，由争议各方依法解决。</span>
            </p>
            <p style=";text-indent: 32px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第三十九条</span>&nbsp;拍卖客户违反本规则规定给他人造成损害的，应当承担相应的法律责任。</span>
            </p>
            <p style=";text-indent: 32px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第四十条</span>&nbsp;本规则各项条款应依据中华人民共和国法律解释。对于本规则执行过程中如发生争议，应向拍卖人所在地法院提起诉讼。</span>
            </p>
            <p style=";text-indent: 32px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第四十一条</span>&nbsp;拍卖人为完善网络拍卖业务根据需要有权选择升级或更换拍卖软件，拍卖客户按照拍卖人要求及时升级或更换拍卖软件。如因拍卖客户未升级或更换拍卖软件而造成损失，拍卖人不承担任何赔偿责任。</span>
            </p>
            <p style=";text-indent: 0">
                <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第六章</span>&nbsp;附则</span></strong>
            </p>
            <p style=";text-indent: 32px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第四十二条</span>&nbsp;拍卖人对拍卖软件系统进行维护、升级、更换，将提前于拍卖人指定的网络媒体或拍卖软件发布公告，无需单独通知拍卖客户。</span>
            </p>
            <p style=";text-indent: 32px">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第四十三条</span>&nbsp;拍品拍卖过程中产生的各项税费由相关各参与方各自依法承担，拍卖人不承担代扣代缴。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第四十四条</span>&nbsp;拍卖人根据国家法律法规变化及运营需要，有权对本规则条款进行修订及变更，对业务模式等进行调整，并要求参与人予以配合，以保障业务健康、有序、持续地发展。&nbsp;修订及变更后的内容一经拍卖人公布后即生效，并以新修订及变更后的内容为准。若因参与人不予配合给自身造成损失的，拍卖人不承担任何责任。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">第四十五条</span>&nbsp;本规则自颁布之日起施行。</span>
            </p>
            <p>
                <span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
            </p>
            <p>
                <br/>
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SpotAuctionRules',
        data() {
            return {}
        },
        methods: {
            //返回
            goBack() {
                this.$router.go(-1)
            }
        }
    }
</script>

<style lang="less" scoped>
    .spot-rules {
        width: 100%;
        height: 100%;
        padding: 0.5rem;
        position: absolute;
        top: 0;
        left: 0;
        overflow-y: hidden;
        overflow-x: hidden;
        .rules-title {
            width: 100%;
            height: 3rem;
            position: fixed;
        }
        .rules-content {
            width: 100%;
            height: 90%;
            position: absolute;
            margin-top: 4rem;
            overflow: auto;
            padding: 2rem;
        }
    }
</style>