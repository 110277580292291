<template>
    <div class="user-accountinfos">
        <el-page-header @back="goBack" content="个人信息" style="font-weight: bolder;">
        </el-page-header>
        <el-divider></el-divider>
        <el-card class="box-card">
            <el-row>
                <el-col :span="12"><div>账号</div></el-col>
                <el-col :span="12"><div>{{ userInfos.U }}</div></el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row>
                <el-col :span="12"><div>签约号</div></el-col>
                <el-col :span="12"><div>{{ userInfos.RBI }}</div></el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row>
                <el-col :span="12"><div>用户名称</div></el-col>
                <el-col :span="12"><div>{{ userInfos.RN ? userInfos.RN : '' }}</div></el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row>
                <el-col :span="12"><div>实名认证</div></el-col>
                <el-col :span="12">
                    <div v-if="userInfos.RST == 1"><router-link to="">审核中<span class="iconfont icon-youbianjiantou"></span></router-link></div>
                    <div v-else-if="userInfos.RST == 0"><router-link to="/user/realnameauth">未实名<span class="iconfont icon-youbianjiantou"></span></router-link></div>
                    <div v-else>已实名</div>
                </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row>
                <el-col :span="12"><div>银行签约绑卡</div></el-col>
                <el-col :span="12">
                    <div v-if="userInfos.CS == 0"><router-link to="/user/bindbankcard">未绑卡<span class="iconfont icon-youbianjiantou"></span></router-link></div>
                    <div v-else-if="userInfos.CS == 1">已绑卡</div>
                    <div v-else-if="userInfos.CS == 2">
                        <router-link to="/user/bindbankcard">已解约，去重新签约<span class="iconfont icon-youbianjiantou"></span></router-link>
                    </div>
                    <div v-else>已销户</div>
                </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row v-if="userInfos.CS == 1">
                <el-col :span="12"><div>银行签约维护</div></el-col>
                <el-col :span="12">
                    <div>
                        <a href="https://my.orangebank.com.cn/corporplat/index_payb_H5.jsp" target="_blank">去维护<span class="iconfont icon-youbianjiantou"></span></a>
                    </div>
                </el-col>
            </el-row>
            <el-divider v-if="userInfos.CS == 1"></el-divider>
            <el-row>
                <el-col :span="12"><div>登录密码</div></el-col>
                <el-col :span="12">
                    <div><router-link to="/user/modifypassword">修改<span class="iconfont icon-youbianjiantou"></span></router-link></div>
                </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row>
                <el-col :span="12"><div>资金密码</div></el-col>
                <el-col :span="12">
                    <div v-if="isSetPwd == 1"><router-link to="/user/moneypassword">未设置<span class="iconfont icon-youbianjiantou"></span></router-link></div>
                    <div v-else><router-link to="/user/editmoneypwd">修改<span class="iconfont icon-youbianjiantou"></span></router-link></div>
                </el-col>
            </el-row>
            <el-divider></el-divider>
        </el-card>
        <Logout />
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import Logout from '@/components/pages/logout/Logout.vue'
    import { loginApi } from '@/config/env'
    export default {
        name: 'Information',
        data() {
            return {
                isSetMoneyPwd: {
                    "name": "isneed_init_fundspwd",
                    "U": "",
                    "SI": ""
                },
                isSetPwd: ''
            }
        },
        components: {
            Logout
        },
        computed: {
            ...mapState(['userInfos'])
        },
        methods: {
            //返回
            goBack() {
                this.$router.push('/user')
            },
            //是否需要设置资金密码
            async isSetMoneyPassword() {
                const { data: res} = await this.$http.post(loginApi.setMoneyPasswordApi, this.isSetMoneyPwd)
                if (res.retcode < 0) {
                    if (parseInt(res.retcode) == -102130040002) {
                        //登录身份失效，重新登录
                        //清空登录态
                        window.sessionStorage.removeItem('uid')
                        window.sessionStorage.removeItem('sid')
                        window.sessionStorage.removeItem('userInfos')
                        this.$router.push('/login')
                    }
                    return this.$message.warning(res.message)
                }
                this.isSetPwd = res.result.CFP
            } 
        },
        mounted() {
            this.isSetMoneyPassword()
        }
    }
</script>

<style lang="less" scoped>
    .user-accountinfos {
        width: 100%;
        padding: 1rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    .box-card {
        font-size: 14px;
        a {
            text-decoration: none;
            color: #303133;
        }
        .el-row {
            padding-left: 5px;
            padding-right: 5px;
        }
        .el-row > .el-col:nth-child(2){
            text-align: right;
        }
    }
</style>