<template>
    <div class="auction-details">
        <el-page-header @back="goBack" content="商品详情" style="font-weight: bolder;">
        </el-page-header>
        <el-divider></el-divider>
        <div class="auction-main">
            <div class="goods-img"><img class="img-responsive" :src="imageUrl" /></div>
            <div class="goods-base">
                <div class="jumbotron">
                    <h2 style="color: #DF2625">￥{{ goodsInfo.PRC}}</h2>
                    <p>{{ goodsInfo.COI }}</p>
                    <p>{{ goodsInfo.CON }}</p>
                </div>
            </div>
            <el-divider></el-divider>
            <div class="goods-details">
                <el-tag type="info">详细信息</el-tag>
                <el-card class="box-card">
                    <div class="text item">
                        <div class="media">
                            <div class="media-left media-middle">
                                <a href="#">
                                <img class="media-object" src="" alt="">
                                </a>
                            </div>
                            <div class="media-body">
                                <!-- <h4 class="media-heading">Middle aligned media</h4> -->
                                <img src="../../../../assets/images/trade02.jpg" class="img-responsive" alt="">
                                <!-- <img src="../../../../assets/images/detail01.jpg" class="img-responsive" alt=""> -->
                                <img src="../../../../assets/images/detail02.jpg" class="img-responsive" alt="">
                                <img src="../../../../assets/images/detail03.jpg" class="img-responsive" alt="">
                            </div>    
                        </div>
                    </div>
                </el-card>
            </div>
        </div>
        <div class="buy-btn">
            <el-button type="danger" @click="toBuyGoods">立即购买</el-button>
        </div>
    </div>
</template>

<script>
    import { loginApi } from '@/config/env'
    import imageUrl from '../../../../assets/images/xiandairen.jpg'
    export default {
        name: 'AuctionDetails',
        data() {
            return {
                queryDetails: {
                    "name": "commodity_query",
                    "COI": "",
                    "U": "",
                    "SI": ""
                },
                imageUrl: imageUrl,
                goodsInfo: {}
            }
        },
        computed: {},
        created() {
            this.queryDetails.COI = this.$route.query.gid
        },
        mounted() {
            this.getAuctionDetails()
            
        },
        methods: {
            goBack() {
                this.$router.go(-1)
            },
            //获取拍品（商品）详情
            async getAuctionDetails() {
                const { data: res } = await this.$http.post(loginApi.auctionDetailsApi, this.queryDetails)
                this.goodsInfo = res.resultList.REC[0]
            },
            //去购买
            toBuyGoods() {
                this.$router.push({ path: '/trade', query: { gid: this.$route.query.gid } })
            }
        },   
    }
</script>

<style lang="less" scoped>
    .auction-details {
        width: 100%;
        margin: 0 auto;
        padding: 10px;
        .buy-btn {
            width: 100%;
            margin-top: 1rem;
            .el-button {
                width: 100%;
            }
        }
    }
    .auction-main {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0.5rem;
        .goods-img {
            width: 100%;
            .el-image {
                width: 100%;
                height: 100%;
            }
            
        }
        .goods-base {
            width: 100%;
            .jumbotron {
                margin-top: 0 !important;
                margin-bottom: 0 !important;
                padding-top: 0 !important;
                padding-bottom: 0 !important;
            }
        }
        .goods-details {
            width: 100%;
            .box-card {
                width: 100%;
            }
        }
    }
</style>