//该文件用于创建store
import Vue from 'vue'
import Vuex from 'vuex'
import { loginApi } from "@/config/env01"

Vue.use(Vuex)

//准备actions
const actions = {
    //获取用户信息
    getUserInfos(context, data) {
        window.sessionStorage.setItem('userInfos', JSON.stringify(data))
        context.commit('GET_USER_INFOS', data)
    },
    //获取商品分类信息
    // getGoodsCategory(context, data) {
    //     context.commit('GET_GOODS_CATEGORY', data)
    // }
    //获取地址信息
    getAddrInfos(context, data) {
        window.localStorage.setItem('addressInfos', data)
        context.commit('GET_ADDR_INFOS', data)
    },
    //获取商品信息
    getGoodsInfos(context, data) {
        window.localStorage.setItem('goodsInfos', data)
        context.commit('GET_GOODS_INFOS', data)
    }
}

//准备mutations
const mutations = {
    //获取用户信息
    GET_USER_INFOS(state, data) {
        state.userInfos = data
    },
    //获取商品分类信息
    // GET_GOODS_CATEGORY(state, data) {
    //     state.goodsInfos = data
    // }
    GET_ADDR_INFOS(state, data) {
        state.addressInfos = JSON.parse(data)
    },
    GET_GOODS_INFOS(state, data) {
        state.goodsInfos = JSON.parse(data)
    }
}

//准备state
const state = {
    userInfos: JSON.parse(window.sessionStorage.getItem('userInfos')), //用户信息
    addressInfos: JSON.parse(window.localStorage.getItem('addressInfos')), //用户地址信息
    goodsInfos: JSON.parse(window.localStorage.getItem('goodsInfos')),  //商品信息
}

//准备getters
const getters = {}

export default new Vuex.Store({
    actions,
    mutations,
    state,
    getters
})