<template>
    <div>
        <div class="title">
            <p style="text-align:center;line-height:150%">
                <span style="font-size: 20px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;"><strong><span style="font-size: 20px; line-height: 150%;">五</span></strong><strong><span style="font-size: 20px; line-height: 150%;">粮液现代人</span></strong><strong><span style="font-size: 20px; line-height: 150%;">白</span></strong><strong><span style="font-size: 20px; line-height: 150%;">酒介绍</span></strong></span>
            </p>
            <p style="text-indent: 32px; line-height: 2em;">
                <span style="line-height: 150%; font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">五粮液为代表的中国白酒堪称世界最古老、最具神秘特色的食品制造产业之一，在独特的自然环境下酿造而成，具有3000多年的悠久历史。</span>
            </p>
            <p style="text-indent: 32px; line-height: 2em;">
                <span style="line-height: 150%; font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">现代人白酒是五粮液旗下的分支，汇聚高粱、大米、糯米、小麦、玉米等五种粮食的混合香气，具有香气悠久、香味醇厚、入口甘美、入喉劲爽、各味谐调的特点，堪称五粮力作，内外兼修，是送礼、自饮、宴请<span style="line-height: 150%; font-size: 16px; font-family: 宋体;">的佳酿，是“享受生活、品味人生、健康养生”的佳品。</span></span>
            </p>
            <p style="text-indent: 32px; line-height: 2em;">
                <span style="line-height: 150%; font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">现代人白酒的成分包括水、高粱、大米、糯米、小麦、玉米等，等级优级，规格为500ml/瓶，52度浓香型，生产企业为宜宾五粮液股份有限公司，条码号为6901382115075。</span>
            </p>
            <p style="text-indent: 32px; line-height: 2em;">
                <span style="line-height: 150%; font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">现代人，为梦想而生！</span>
            </p>
            <p>
                <br/>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutOne',
    data() {
        return {}
    }
}
</script>

<style scoped lang="less">
.title {
    width: 100%;
}
</style>