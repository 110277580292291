<template>
  <div class="footer-nav">
      <div class="footer-main">
        <div class="footer-item">
          <div class="grid-content bg-purple">
            <div :class="'nav-li' + home_active" @click="toHome">
                <span class="iconfont icon-home"></span>
                <div class="nav-label">首页</div>
            </div>
			    </div>
        </div>
        <!-- <div class="footer-item">
          <div class="grid-content bg-purple">
            <div :class="'nav-li' + market_active" @click="toMarket">
              <span class="iconfont icon-_shichangyingxiao"></span>
              <div class="nav-label">市场</div>
            </div>
          </div>
        </div> -->
        <div class="footer-item">
          <div class="grid-content bg-purple">
              <div :class="'nav-li' + trade_active" @click="toTrade">
                <span class="iconfont icon-jiaoyi"></span>
                <div class="nav-label">交易</div>
              </div>
          </div>
        </div>
        <div class="footer-item">
          <div class="grid-content bg-purple">
            <div :class="'nav-li' + activity_active" @click="toActivity">
              <span class="iconfont icon-huodong"></span>
              <div class="nav-label">活动</div>
            </div>
          </div>
        </div>
        <div class="footer-item">
          <div class="grid-content bg-purple">
              <div :class="'nav-li' + mall_active" @click="toMall">
                <span class="iconfont icon-shangcheng"></span>
                <div class="nav-label">商城</div>
              </div>
          </div>
        </div>
        <div class="footer-item">
          <div class="grid-content bg-purple">
              <div :class="'nav-li' + user_active" @click="toUser">
                <span class="iconfont icon-wode"></span>
                <div class="nav-label">我的</div>
              </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
    name: 'FooterNavigation',
    data() {
      return {
        home_active: ' active',
        market_active: '',
        trade_active: '',
        user_active: '',
        mall_active: '',
        activity_active: '',
      }
    },
    mounted() {
      if (this.$route.path === '/home') {
        this.home_active = ' active'
        this.market_active = ''
        this.trade_active = ''
        this.user_active = ''
        this.shopping_active = ''
        this.activity_active = ''
      } else if (this.$route.path === '/market') {
        this.home_active = ''
        this.market_active = ' active'
        this.trade_active = ''
        this.user_active = ''
        this.shopping_active = ''
        this.activity_active = ''
      } else if (this.$route.path.search('/trade') != -1) {
        this.home_active = ''
        this.market_active = ''
        this.trade_active = ' active'
        this.user_active = ''
        this.shopping_active = ''
        this.activity_active = ''
      } else if (this.$route.path === '/user') {
        this.home_active = ''
        this.market_active = ''
        this.trade_active = ''
        this.user_active = ' active'
        this.shopping_active = ''
        this.activity_active = ''
      } else if (this.$route.path.search('/mall/index') != -1) {
        this.home_active = ''
        this.market_active = ''
        this.trade_active = ''
        this.user_active = ''
        this.mall_active = ' active'
        this.activity_active = ''
      } else if (this.$route.path === '/activity') {
        this.home_active = ''
        this.market_active = ''
        this.trade_active = ''
        this.user_active = ''
        this.mall_active = ''
        this.activity_active = ' active'
      }
    },
    methods: {
      //切换到首页
      toHome() {
        this.$router.push('/home')
      },
      //切换到市场
      toMarket() {
        this.$router.push('/market')
      },
      //切换到活动
      toActivity() {
        this.$router.push('/activity')
      },
      //切换到交易
      toTrade() {
        this.$router.push('/trade')
      },
      //切换到我的会员中心
      toUser() {
        this.$router.push('/user')
      },
      //切换到商城
      toMall() {
        this.$router.push('/mall/index')
      }
    },
    watch: {
      $route(to, from) {

      }
    }
}
</script>

<style lang="less" scoped>
   .footer-nav {
    width: 100%;
    height: 60px;
    background-color: #FFFFFF;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
  }
    .footer-main {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: row nowrap;
      justify-content: space-between;
      .footer-item {
        width: 100%;
        height: 100%;
        /deep/ .iconfont {
          font-size: 20px;
        }
      }
    }
  .grid-content {
    height: 100%;
    width: 100%;
    text-align: center;
    padding-top: 2px;
  }
  .nav-li {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
  }
  .active {
    color: #009A61;
  }
</style>
