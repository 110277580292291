<template>
    <div class="user-login">
        <div class="login-box">
            <h2 align="center">爱酒汇现货交易市场</h2>
            <el-form ref="form" :model="form" label-width="0" :rules="rules">
                <el-form-item prop="U">
                    <el-input v-model="form.U" maxlength="11" placeholder="手机号" suffix-icon="el-icon-user-solid"></el-input>
                </el-form-item>
                <el-form-item prop="P">
                    <el-input v-model="form.P" type="password" placeholder="密码" suffix-icon="iconfont icon-tianchongxing-"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="warning" @click.native="onSubmit">登录</el-button>
                </el-form-item>
            </el-form>
            <div class="link-title">
                <el-link type="info" :underline="false" v-on:click="toRegister">注册账号</el-link>
                <el-link type="info" :underline="false" v-on:click="toForgetPwd">找回密码</el-link>
                <!-- <el-link type="info" :underline="false" v-on:click="selLanguage">选择语言</el-link> -->
            </div>
            <div class="about">
                <el-link type="danger" :underline="false" v-on:click="toYuanjun">元钧国际</el-link>
                <el-divider direction="vertical"></el-divider>
                <el-link type="danger" :underline="false" v-on:click="toAijiu">爱酒汇</el-link>
                <el-divider direction="vertical"></el-divider>
                <el-link type="danger" :underline="false" v-on:click="toXiandai">现代人</el-link>
            </div>
            <div class="beian-cs">
                <a target="_blank" href="https://beian.miit.gov.cn/">鄂ICP备2022007605号-1</a>
            </div>
        </div>
    </div>
</template>

<script>
import { noLoginApi } from "@/config/env"
import { parseRetCode } from "@/config/retcode"
import { loginApi } from "@/config/env"
export default ({
    name: 'Login',
    data() {
        return {
            form: {
                name: 'logon',
                U: '',
                P: '',
                LT: 'web'
            },
            rules: {
                U: [
                    { required: true, message: '请输入登录名', trigger: 'blur' }
                ],
                P: [
                    { required: true, message: '请输入登录密码', trigger: 'blur' }
                ]
            }
        }
    },
    mounted() {
        
    },
    methods: {
        //登录按钮
        onSubmit() {
            this.$refs.form.validate( async valid => {
                if(!valid) return
                const { data: res } = await this.$http.post(noLoginApi.userLoginApi, this.form)
                if (res.retcode < 0) {
                    return this.$message.error(res.message)
                } else {
                    //登录成功，存入sessionStorage
                    //window.sessionStorage.setItem('uid', res.result.U)
                    //window.sessionStorage.setItem('sid', res.result.RETCODE)
                    this.$router.push('/home')
                }
                
            })
        },
        //跳转到注册页面
        toRegister() {
            this.$router.push('/register')
        },
        toForgetPwd() {
            this.$router.push('/user/forgetpassword')
        },
        selLanguage() {
            
        },

        //跳转关于简介页
        toYuanjun() {
            this.$router.push({ path: '/about/aboutthree', query: {
                type: 'first'
            } })
        },
        toAijiu() {
            this.$router.push({ path: '/about/abouttwo', query: {
                type: 'second'
            } })
        },
        toXiandai() {
            this.$router.push({ path: '/about/aboutone', query: {
                type: 'third'
            } })
        }
    }   
})
</script>

<style lang="less" scoped>
    .user-login {
        width: 100%;
        height: 100%;
        background: url(../../../assets/images/login-back.png) no-repeat;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-size: 100% 100%;
    }
    .login-box {
        width: 600px;
        max-width: 650px;
        height: 500px;
        max-height: 550px;
        background-color: #FFF;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 20px;
        border-radius: 3px;
        box-shadow: 5px 5px 5px #888888;
    }
    .login-box > h2 {
        color: #777;
        font-family: 'FZLanTingHeiS-B-GB';
    }
    .login-box > .el-form {
        box-sizing: border-box;
        width: 44%;
        margin-top: 20px;
        position: absolute;
        transform: translate(57%, 0);
    }
    .el-button {
        width: 100%;
    }
    .link-title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 50%);
        a {
            margin: 1.2rem;
            font-size: 15px;
            color: #DF2625;
            text-decoration: none;
            font-weight: 600;
            font-family: '微软雅黑';
        }
    }
    .about {
        position: absolute;
        left: 50%;
        bottom: 5rem;
        transform: translateX(-50%);
        a {
            display: inline-block;
            width: 7rem;
            font-weight: 600;
            font-size: 1.5rem;
            text-align: center;
        }
    }
    .beian-cs {
        position: absolute;
        left: 50%;
        bottom: 2rem;
        transform: translateX(-50%);
        a {
            text-decoration: none;
            color: #999;
        }
        a:hover {
            color: #DF2625;
        }
    }
</style>
