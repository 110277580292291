<template>
    <div class="deal-record">
        <div class="top-title">
            <el-page-header @back="goBack" content="存货明细" style="font-weight: bolder;">
            </el-page-header>
            <el-divider></el-divider>
        </div>
        <div class="box-scroll" v-if="list.length > 0">
            <div class="infinite-list-wrapper" v-infinite-scroll="load" infinite-scroll-disabled="disabled" style="overflow:auto">
                <ul class="list">
                    <li class="list-item" v-for="(item, index) in list" :key="index">
                        <el-card class="box-card" v-if="item.COI=='979999'">
                            <el-descriptions  title="" direction="vertical" :column="2" border :colon="true">
                                <el-descriptions-item label="单号">{{ item.HN }}</el-descriptions-item>
                                <el-descriptions-item label="名称">[{{ item.COI }}] {{ item.CON }}</el-descriptions-item>
                                <el-descriptions-item label="价格">￥{{ item.P || keepTwoDecimal }}</el-descriptions-item>
                                <el-descriptions-item label="买入数量">{{ item.OQ }}</el-descriptions-item>
                                <el-descriptions-item label="存货数量">{{ item.HQ }}</el-descriptions-item>
                                <el-descriptions-item label="存货时间">{{ item.HT }}</el-descriptions-item>
                            </el-descriptions>
                        </el-card>
                    </li>
                </ul>
            </div>
        </div>
        <div class="info-message" v-if="list.length > 0">
            <p v-if="loading">
                <el-alert :closable="false"
                    title="加载中..."
                    type="warning"
                    center
                    show-icon>
                </el-alert>
            </p>
            <p v-if="noMore">
                <el-alert :closable="false"
                    title="没有更多了"
                    type="warning"
                    center
                    show-icon>
                </el-alert>
            </p>
        </div>
        <div class="info-empty" v-else>
            <el-empty :image-size="200"></el-empty>
        </div>
    </div>
</template>

<script>
    import { loginApi } from '@/config/env'
    import { isFailureLogin } from '@/config/mUtils'
    export default {
        name: 'PositionLists',
        data() {
            return {
                count: 10,
                totalNum: 0,
                loading: false,
                queryPositionLists: {
                    "name": "holddetail_list_query_mobile",
                    "U": "",
                    "LTI": 0,
                    "RECCNT": 20,
                    "SI": ""
                },
                list: []
            }
        },
        computed: {
            noMore () {
                return this.totalNum <= 19
            },
            disabled () {
                return this.loading || this.noMore
            }
        },
        mounted() {
            this.getPositionLists()
        },
        methods: {
            //返回
            goBack() {
                this.$router.go(-1)
            },
            handleChange(val) {
                console.log(val);
            },
            async getPositionLists() {
                const { data: res } = await this.$http.post(loginApi.positionListsApi, this.queryPositionLists)
                if (res.retcode >= 0) {
                    if (res.result.TC > 0) {
                        this.queryPositionLists.LTI = res.result.MINID
                        this.list = this.list.concat(res.resultList.REC)
                        this.totalNum = res.result.TC
                    } else {
                        this.loading = false
                        this.totalNum = 0
                    }
                }
            },
            load () {
                this.loading = true
                setTimeout(() => {
                    this.queryPositionLists.RECCNT = -20
                    this.getPositionLists()
                    this.loading = false
                }, 2000)
            }
        }
    }
</script>

<style lang="less" scoped>
    .deal-record {
        width: 100%;
        height: 100%;
        padding: 1rem;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        .box-scroll {
            width: 100%;
            height: 80%;
            position: absolute;
            top: 8rem;
            left: 50%;
            transform: translateX(-50%);
            overflow-y: scroll;
            overflow-x: hidden;
            .list {
                width: 100%;
                height: 100%;
                padding: 0;
                .list-item {
                    margin-bottom: 0.5rem;
                }
            }
        }
    }
    .infinite-list-wrapper {
        width: 100%;
        height: 100%;
    }
    .info-message {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    .info-empty {
        width: 100%;
        position: absolute;
        top: 14rem;
        left: 0;
    }
</style>