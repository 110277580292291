<template>
    <div class="contianer">
        <div class="goods-top">
            <div class="icon-back">
              <div class="back-infos">
                <span class="iconfont icon-fanhui1" @click="toBack"></span>
              </div>
            </div>
            <el-carousel :interval="5000" arrow="always" height="380px">
                <el-carousel-item v-for="(item,index) in goodsBannerList" :key="index">
                  <el-image :src="item.product_pic_scroll" style="max-width: 100%;height: auto;"></el-image>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="goods-content">
          <el-card class="box-card">
            <div class="box-price">
              <div style="">￥<em style="font-size: 22px;text-decoration: line-through;">{{ goodsInfo.product_price | keepTwoDecimal }}</em></div>
              <div>积分：<em style="font-size: 22px;font-weight: 600">{{ goodsInfo.product_price_point | keepTwoDecimal }}</em></div>
            </div>
            <div class="box-title">
              <div>{{ goodsInfo.product_name }}</div>
            </div>
          </el-card>
        </div>
        <div class="goods-infos">
          <el-collapse v-model="activeNames">
            <el-collapse-item title="商品介绍" name="1">
              <div v-html="goodsInfo.product_detail" style="padding: 1rem"></div>
            </el-collapse-item>
          </el-collapse>
          <div v-for="(items,indexs) in goodsDetailImags" :key="indexs">
              <el-image :src="items.product_pic_detail"></el-image>
          </div>
          <div class="empty-info"></div>
        </div>
        <div class="goods-footer-btn">
          <el-button round type="danger" style="width: 90%" v-on:click="toBuy">立即购买</el-button>
        </div>
    </div>
</template>

<script>
import banner1 from '@/assets/images/product_banner1.jpg'
import banner2 from '@/assets/images/product_banner2.jpg'
import banner3 from '@/assets/images/product_banner3.jpg'
import details from '@/assets/images/product_details.jpg'
import { loginApi, noLoginApi } from '@/config/env'
export default {
    name: 'ProductDetails',
    data() {
        return {
          image_url1: banner1,
          image_url2: banner2,
          image_url3: banner3,
          image_url4: details,
          product_id: '',
          goodsInfo: {},
          goodsBannerList: [],
          goodsDetailImags: [],
          activeNames: ['1']
        } 
    },
    created() {
      this.product_id = this.$route.query.goodsNo ? this.$route.query.goodsNo : ''
    },
    mounted() {
      this.getGoodsDetails()
      this.getGoodsBanner()
      this.getGoodsDetailsImg()
    },
    methods: {
      //获取商品详情信息
      async getGoodsDetails() {
          const { data: res } = await this.$http.post(noLoginApi.queryGoodsDetailsApi+'?product_id='+this.product_id)
          if (res.code !== 'E000000') {
              return
          }
          this.goodsInfo = res.data
      },
      //获取商品轮播图
      async getGoodsBanner() {
          const { data: res } = await this.$http.post(noLoginApi.getGoodsBannerApi+'?product_id='+this.product_id)
          if (res.code !== 'E000000') {
              return
          }
          this.goodsBannerList = res.data
      },
      //获取商品详情图
      async getGoodsDetailsImg() {
          const { data: res } = await this.$http.post(noLoginApi.getGoodsDetailsImgApi+'?product_id='+this.product_id)
          if (res.code !== 'E000000') {
              return
          }
          this.goodsDetailImags = res.data
      },
      // 去购买按钮
      toBuy() {
        this.$router.push({ path: '/mall/goodsexchange', query: { id: this.product_id }})
      },
      // 返回按钮
      toBack() {
        this.$router.push('/mall/index')
      }
    }
}
</script>

<style lang="less" scoped>
.contianer {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    position: absolute;
}

  .goods-top {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    .icon-back {
      width: 100%;
      position: fixed;
      min-height: 44px;
      z-index: 9999;
      .back-infos {
        cursor: pointer;
        margin-top: 0.3rem;
        margin-left: 0.3rem;
        span {
          font-size: 3rem;
        }
      }
    }
    .el-carousel {
      width: 100%;
      position: absolute;
      //min-height: 44px;
      top: 0;
    }
  }
  .goods-content {
    width: 100%;
    height: 14rem;
    position: absolute;
    top: 38rem;
    .box-price {
      color: #f2270c;
      display: flex;
      justify-content: space-between;
      flex-flow: nowrap;
    }
    .box-title {
      margin-top: 1rem;
      font-weight: 700;
      font-size: 18px;
      color: #262626;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      word-break: break-all;
      -webkit-box-orient: vertical;
    }
  }
  .empty-info {
    width: 100%;
    height: 60px;
  }
  .goods-infos {
    width: 100%;
    position: absolute;
    top: 49rem;
    
  }
  .goods-footer-btn {
    width: 100%;
    position: fixed;
    bottom: 0.5rem;
    text-align: center;
  }
  /deep/ .el-collapse-item__header.is-active {
    padding: 1rem;
  }
  /deep/ .el-collapse-item__header {
    padding: 1rem;
  }
</style>