<template>
    <div>
        <el-row class="buyaction-number">
            <el-col :span="24">
                <el-input-number v-model="buyNum" :min="0" label="请输入数量"></el-input-number>
            </el-col>
            <el-col :span="24"><el-button type="danger" @click="toBuyAction">{{ buyAction }}</el-button></el-col>
        </el-row>
    </div>
</template>

<script>
    import { loginApi } from '@/config/env'
    import { mapState } from 'vuex'
    export default {
        name: 'EntrustBuy',
        inject: ['reload'],
        data() {
            return {
                entrustOrder: {
                    "name": "order",
                    "U": "",
                    "PN": "", //挂单号(摘单时必传，挂单时不传)
                    "COI": "",
                    "BS": "1",  //买卖标志：1买；2卖
                    "OP": "",  //委托价
                    "OQ": "1",  //委托量
                    "OPT": "1", //委托操作类型:1挂单;2摘单
                    "TI": "",
                    "SI": ""
                },
                isNum: false,
                buyNum: '',  //委托买卖数量
                buyAction: '委托买入'
            }
        },
        created() {
            this.entrustOrder.COI = this.$store.state.goodsInfos.COI
            this.entrustOrder.OP = this.$store.state.goodsInfos.LAST
        },
        mounted() {
            this.$bus.$on('getGoodsInfo', (data) => {
                this.entrustOrder.COI = data.COI
                this.entrustOrder.OP = data.PRC
            })
        },
        //解绑自定义事件
        beforeDestroy() {
            this.$bus.$off('getGoodsInfo')
        },
        methods: {
            //点击按钮买入/卖出
            toBuyAction() {
                if (this.buyNum == '' || this.buyNum <= 0) {
                    return this.$message.error('请输入正确数量！')
                }
                this.$confirm('请确认您是否需要委托买入？', '提示', {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.entrustOrder.OQ = this.buyNum
                    this.entrustOrder.OP = this.$store.state.goodsInfos.LAST
                    this.placeOrder()
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    })
                })
            },
            //入委托挂单或摘单
            async placeOrder() {
                const { data: result } = await this.$http.post(loginApi.entrustOrderApi, this.entrustOrder)
                if (result.retcode < 0) {
                    return this.$message.warning(result.message)
                }
                setTimeout(() => {
                    this.$message.success(result.message)
                    this.reload()
                    this.$bus.$emit('isReload', true)
                }, 2000)
            }
        }
    }
</script>

<style lang="less" scoped>
    .buyaction-number {
        width: 100%;
        margin-top: 3rem;
        .el-col:nth-child(1) {
            width: 100%;
            margin-bottom: 2rem;
            .el-input-number {
                width: 100%;
            }
        }
        .el-button {
            width: 100%;
        }
    }
</style>