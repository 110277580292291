<template>
    <div class="register-form">
        <el-form :model="registerForm" :rules="rules" ref="registerForm" label-width="">
            <el-form-item label="手机号" prop="MO">
                <el-input v-model="registerForm.MO" placeholder="请填写手机号"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="PWD">
                <el-input v-model="registerForm.PWD" show-password placeholder="请设置登录密码"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="confirmPWD">
                <el-input v-model="registerForm.confirmPWD" show-password placeholder="再次确认密码"></el-input>
            </el-form-item>
            <el-form-item label="推荐人手机号（非必填项）" prop="introMobile">
                <el-input v-model="registerForm.introMobile" placeholder="请填写推荐人手机号"></el-input>
            </el-form-item>
            <el-form-item label="服务人手机号（非必填项）" prop="serverMobile">
                <el-input v-model="registerForm.serverMobile" placeholder="请填写服务人手机号"></el-input>
            </el-form-item>
            <el-form-item label="">
                <router-link to="/spotregisterrules">《拍卖客户注册协议》</router-link>
            </el-form-item>
            <el-form-item label="">
                <router-link to="/spotauctionrules">《拍卖规则》</router-link>
            </el-form-item>
            <el-form-item label="">
                <router-link to="/spotriskrules">《风险揭示书》</router-link>
            </el-form-item>
            <el-form-item label="">
                <el-checkbox-group v-model="isSel">
                <el-checkbox label="我已认真阅读并同意以上文件" name="type"></el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item>
                <el-button type="success" :disabled="!isSel" v-on:click="SubmitRegister()" style="width: 100%;">立即注册</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import { parseRetCode } from '@/config/retcode'
    import { noLoginApi } from "@/config/env"
    export default {
        name: 'Register',
        data() {
            let checkPhone = (rule, value, callback) => {
                var phoneReg = /^1[3456789]\d{9}$/
                if (!value) {
                    return callback(new Error('注册手机号不能为空！'))
                } else if (!phoneReg.test(value)) {
                    return callback(new Error('手机号不合法！'))
                } else {
                    callback()
                }
            }
            let validatePassword = (rule, value, callback) => {
                if (value === '') {
                    return callback(new Error('请输入密码！'))
                } else {
                    callback()
                }
            }
            let validateRePassword = (rule, value, callback) => {
                if (value === '') {
                    return callback(new Error('请再次输入密码！'))
                } else if (value !== this.registerForm.PWD) {
                    return callback(new Error('两次输入密码不一致！'))
                } else {
                    callback()
                }
            }
            var introCheck = (rule, value, callback) => {
                var phoneReg = /^1[3456789]\d{9}$/
                if (value !== '') {
                    if (!phoneReg.test(value)) {
                        return callback(new Error('手机号不合法！'))
                    } else {
                        callback()
                    }
                } else {
                    callback()
                }
            }
            return {
                registerForm: {
                    name: 'user_regist',
                    MO: '',
                    IC: '',
                    PWD: '',
                    confirmPWD: '',
                    introMobile: '',  //推荐人手机号
                    serverMobile: '', //服务人手机号
                },
                isSel: false,
                rules: {
                    MO: [
                        { required: true, message: '注册手机号不能为空！', trigger: 'blur' },
                        { validator: checkPhone, trigger: 'blur' }
                    ],
                    PWD: [
                        { required: true, message: '密码不能为空！', trigger: 'blur' },
                        { min: 6, max: 30, message: "密码长度不符合要求：必须6~30个字符长度之间！", trigger: 'blur'},
                        { validator: validatePassword, trigger: 'blur'}
                    ],
                    confirmPWD: [
                        { required: true, message: '确认密码不能为空！', trigger: 'blur' },
                        { validator: validateRePassword, trigger: 'blur'}
                    ],
                    introMobile: [
                        { validator: introCheck, trigger: 'blur'}
                    ],
                    serverMobile: [
                        { validator: introCheck, trigger: 'blur'}
                    ]
                }
            }
        },
        methods: {
            //提交注册信息
            SubmitRegister() {
                this.$refs.registerForm.validate( async (valid) => {
                    if (valid) {
                        const { data: res } = await this.$http.post(noLoginApi.userRegisterApi, this.registerForm)
                        if (res.retcode < 0) {
                            return this.$message.error(res.message)
                        }
                        this.$message.success('恭喜您注册成功！')
                        setTimeout(() => {
                            this.$router.push('/login')
                        }, 1000);
                    } else {
                        return this.$message.warning('注册信息填写有误，请重新填写！')
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .register-form {
        width: 80%;
        margin: 0 auto;
        margin-top: 20px;
        padding: 10px;
        form {
            box-sizing: border-box;
            a {
                font-size: 18px;
                font-weight: bolder;
                text-decoration: none;
                color: black;
            }
            a:hover {
                color: red;
            }
        }
    }
</style>