import { render, staticRenderFns } from "./Settlement.vue?vue&type=template&id=577da64e&scoped=true&"
import script from "./Settlement.vue?vue&type=script&lang=js&"
export * from "./Settlement.vue?vue&type=script&lang=js&"
import style0 from "./Settlement.vue?vue&type=style&index=0&id=577da64e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "577da64e",
  null
  
)

export default component.exports