import Vue from "vue"

/**
 * 定义一个插件
 */
export default {
    install(Vue) {
        //字符串过滤器
        Vue.filter('sliceString', function(oriStr, startPos, length) {
            if (!oriStr) {
                return ''
            }
            return oriStr.substr(startPos, length)
        })
        //字符隐藏过滤器
        Vue.filter('hiddenString', function(str, startNum, endNum) {
            if (!str) {
                return ''
            }
            var num = endNum - startNum
            var star = ''
            for (var i = 0; i < num; i++) {
                star += '*'
            }
            return str.replace(str.substring(startNum,endNum), star)
        })
        //保留两位小数
        Vue.filter('keepTwoDecimal', function(number) {
            const result = parseFloat(number)
            if (isNaN(result)) {
                return ''
            }
            return Number(number).toFixed(2)
        })
        //字符串截取过滤器
        Vue.filter('strCutout', function(str, startPos, num) {
            if (!str) {
                return ''
            }
            return str.substr(startPos, num)
        })
        //时间戳转换方法    date:时间戳数字
        Vue.filter('dateFilter', function(timestamp) {
            if(timestamp.toString().length == 10){
                var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
                var Y = date.getFullYear() + '-';
                var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
                var D = date.getDate() + ' ';
                var h = date.getHours() + ':';
                var m = (date.getMinutes()).toString().padStart(2,'0')+ ":" ;
                var s = (date.getSeconds()).toString().padStart(2,'0');
                return Y+M+D;
            } else if(timestamp.toString().length == 13){
                var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
                var Y = date.getFullYear() + '-';
                var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
                var D = date.getDate() + ' ';
                var h = date.getHours() + ':';
                var m = (date.getMinutes()).toString().padStart(2,'0')+ ":" ;
                var s = (date.getSeconds()).toString().padStart(2,'0');
                return Y+M+D;
            }
        })
    }
}