<template>
    <div>
        <div class="title">
            <p style="margin-top:10px;margin-bottom:10px;text-align:center;line-height:150%">
                <span style="font-size: 20px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;"><strong><span style="font-size: 20px; line-height: 150%;">爱酒汇简介</span></strong></span>
            </p>
            <p style="text-indent: 32px; line-height: 2em;">
                <span style="line-height: 150%; font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">爱酒汇是湖北元钧国际数字科技有限公司（以下简称元钧国际）的注册商标，是元钧国际现代人现货交易平台的互联网标志。具体说明如下：</span>
            </p>
            <p style="margin-left: 0px; text-indent: 32px; line-height: 2em;">
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;"><span style="font-family: 宋体; font-size: 16px;">一、&nbsp;</span><strong><span style="font-family: 宋体; font-size: 16px;">现代人白酒介绍</span></strong></span>
            </p>
            <p style="text-indent: 32px; line-height: 2em;">
                <span style="font-family: 宋体; line-height: 150%; font-size: 16px;"><span style="font-family: 宋体;">宜宾五粮液集团有限公司的产业园区面积为18平方公里，是国家AAAA级旅游景区，具有60万吨的原酒储存能力，每年白酒生产能力为20万吨。1990年，成为中国“十大驰名商标”之一；2002年，荣获巴拿马国际商展白酒类唯一金奖；2020年，荣获中国品牌价值白酒制造类第一品牌；2021年，创造总市值达8</span>500亿。</span>
            </p>
            <p style="text-indent: 32px; line-height: 2em;">
                <span style="line-height: 150%; font-size: 16px; font-family: 宋体;">现代人系列白酒是五粮液浓香系列产品的杰出代表作，具有香气悠久、香味醇厚、入口甘美、入喉劲爽的特点，堪称五粮力作，内外兼修，充分弘扬“现代人，为梦想而生”的品牌理念。</span>
            </p>
            <p style="text-indent: 32px; line-height: 2em;">
                <span style="line-height: 150%; font-size: 16px; font-family: 宋体;">五粮液现代人敬辉煌白酒的规格为每瓶500ml，52度浓香型，制造商为宜宾五粮液股份有限公司，商品条码号为6901382115075。</span>
            </p>
            <p style="margin-left: 0px; text-indent: 32px; line-height: 2em;">
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;"><span style="font-family: 宋体; font-size: 16px;">二、&nbsp;</span><strong><span style="font-family: 宋体; font-size: 16px;">现代人现货交易平台介绍</span></strong></span>
            </p>
            <p style="text-indent: 32px; line-height: 2em;">
                <span style="font-family: 宋体; line-height: 150%; font-size: 16px;"><span style="font-family: 宋体;">自1</span>997<span style="font-family: 宋体;">年5月1</span>7<span style="font-family: 宋体;">日起，我国商品现货交易的历史已有2</span>5<span style="font-family: 宋体;">年多的历史。2013年11月8日发布的商务部、中国人民银行、证券监督管理委员会令2013第3号《商品现货市场交易特别规定（试行）》和2019年2月12日12部委联合发布商建函〔2019〕61号《关于推进商品交易市场发展平台经济的指导意见》是商品现货交易的法律依据。</span></span>
            </p>
            <p style="text-indent: 32px; line-height: 2em;">
                <span style="font-family: 宋体; line-height: 150%; font-size: 16px;">2022<span style="font-family: 宋体;">年7月7日，爱酒汇现代人现货交易平台开市。交易平台为辽宁当代文化艺术品产权交易中心，监管单位为北京登记结算中心，通过五粮液供应链实现标的物确权和供应链管理。</span></span>
            </p>
            <p style="margin-left: 0px; text-indent: 32px; line-height: 2em;">
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;"><span style="font-family: 宋体; font-size: 16px;">三、&nbsp;</span><strong><span style="font-family: 宋体; font-size: 16px;">现代人现货交易步骤</span></strong></span>
            </p>
            <p style="text-indent: 32px; line-height: 2em;">
                <span style="line-height: 150%; font-size: 16px; font-family: 宋体;">现代人现货交易包括参购、释放、批发、零售等四个步骤。参购指会员通过买入现代人并配送仓量，释放包括静态、动态、直拼等三种释放方式，批发是会员认购仓单的过程，零售指会员根据T+5交易规则摘牌卖出并获利。</span>
            </p>
            <p style="text-indent: 32px; line-height: 2em;">
                <span style="line-height: 150%; font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">总之，爱酒汇既是五粮液现代人现货交易平台，也是全体会员文化交流的殿堂。爱酒汇，汇天下！</span>
            </p>
            <p>
                <br/>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutTwo',
    data() {
        return {}
    }
}
</script>

<style scoped lang="less">
.title {
    width: 100%;
}
</style>