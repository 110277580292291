<template>
    <div class="settlement-main">
        <div class="top-info">
            <el-page-header @back="goBack" content="沙龙列表" style="font-weight: bolder;">
            </el-page-header>
            <el-divider></el-divider>
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane label="当前" name="second"></el-tab-pane>
                <el-tab-pane label="所有" name="first"></el-tab-pane>
                <el-tab-pane label="我的" name="third"></el-tab-pane>
                <el-tab-pane label="订单" name="four"></el-tab-pane>
            </el-tabs>
        </div>
        <div class="content-info">
            <!-- 活动列表页 -->
            <el-card class="box-card" v-if="list.length>0" v-for="(item,index) in list" :key="index">
                <div class="box-father">
                    <div class="left-box">
                        <p style="font-weight: 600;font-size: 15px;">{{ item.salon_title }}</p>
                        <p>活动组织者：{{ item.salon_user_name }}</p>
                        <p>活动时间：{{ item.salon_start_time }} ~ {{ item.salon_end_time }}</p>
                        <p>活动地址：{{ item.salon_location }}</p>
                        <p>活动说明：{{ item.salon_remark==''?'暂无':item.salon_remark }}</p>
                    </div>
                    <div class="right-box">
                        <!-- 沙龙状态：0-创建，1-还未到时间，2-进行中，3-已结束，4-取消 -->
                        <el-tag style="width: 100%;text-align: center;" v-if="item.salon_state==0">已创建</el-tag>
                        <el-tag style="width: 100%;text-align: center;" v-else-if="item.salon_state==1">未开始</el-tag>
                        <el-button class="submit-btn-canjia" v-else-if="item.salon_state==2" style="width: 100%;" size="mini" type="danger" @click.native="toActivity(item.salon_id)">参加活动</el-button>
                        <el-tag style="width: 100%;text-align: center;" v-else-if="item.salon_state==3" type="info">已结束</el-tag>
                        <el-tag style="width: 100%;text-align: center;" v-else type="warning">已取消</el-tag>
                    </div>
                </div>
            </el-card>    
            <el-empty description="暂无任何信息" v-if="list.length==0"></el-empty>
        </div>
        <!-- 创建沙龙按钮 -->
        <div class="foot-info">
            <el-button class="submit-btn" type="warning" v-if="create_level_id>=0" @click="createSalon" icon="el-icon-plus">创建沙龙</el-button>
        </div>
        <!-- 创建沙龙对话框 -->
        <el-dialog :visible.sync="dialogVisible" width="100%" center top="5vh" @close="closeDlg">
            <el-form ref="form" :model="form" :rules="rules" size="mini" label-width="auto" label-position="top">
                <el-form-item label="活动标题：" prop="salon_title">
                    <el-input v-model="form.salon_title" disabled></el-input>
                </el-form-item>
                <el-form-item label="星级代理姓名：" prop="region_leader_name">
                    <el-input v-model="form.region_leader_name" disabled></el-input>
                </el-form-item>
                <el-form-item label="活动开始时间：" prop="salon_start_time">
                    <el-date-picker
                        v-model="form.salon_start_time"
                        type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="选择开始日期和时间" style="width: 100%;">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="活动结束时间：" required prop="salon_end_time">
                    <el-date-picker
                        v-model="form.salon_end_time"
                        type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="选择结束日期和时间" style="width: 100%;">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="活动地址：" prop="salon_location">
                    <el-input type="textarea" v-model="form.salon_location"></el-input>
                </el-form-item>
                <el-form-item label="活动说明（选填）：">
                    <el-input type="textarea" v-model="form.salon_remark"></el-input>
                </el-form-item>
                <el-button type="success" @click="onSubmit" style="width: 100%;">立即创建</el-button>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import { noLoginApi, loginApi } from '@/config/env'
import { isFailureLogin, formatTime } from '@/config/mUtils'
import { mapState } from 'vuex'
import imgUrl from '@/assets/images/xiandairen.jpg'

export default {
    name: 'ActivityList',
    data() {
        //自定义日期时间校验规则
        var checkDateTime1 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请选择日期和时间！'))
            } else if (value >= this.form.salon_end_time) {
                callback(new Error('开始时间必须小于结束时间！'))
            } else {
                callback()
            }
        }
        //自定义日期时间校验规则
        var checkDateTime2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请选择日期和时间！'))
            } else if (value <= this.form.salon_start_time) {
                callback(new Error('结束时间必须大于开始时间！'))
            } else {
                callback()
            }
        }
        return {
            activeName: 'second',
            list: [],
            dialogVisible: false,
            form: {
                salon_user_id: '',
                salon_title: '爱酒汇品鉴沙龙会',
                region_leader_name: '', 
                salon_start_time: '',
                salon_end_time: '',
                salon_location: '',
                salon_remark: ''
            },
            //查询用户信息参数
            queryUserParams: {
                "name": "userinfo_query",
                "U": "",
                "SI": ""
            },
            //查询用户扩展信息参数
            queryUserExtendParams: {
                "name": "userInfo",
                "U": "",
                "SI": ""
            },
            userInfos_case: null,
            create_level_id: '',
            //表单验证规则
            rules: {
                salon_title: [
                    { required: true, message: '请输入活动标题', trigger: 'blur' }
                ],
                region_leader_name: [
                    { required: true, message: '请填写星级代理姓名', trigger: 'blur' }
                ],
                salon_start_time: [
                    { required: true, message: '请选择开始日期和时间', trigger: 'change' }
                ],
                //自定义规则
                salon_end_time: [
                    { validator: checkDateTime2, trigger: 'change' }
                ],
                salon_location: [
                    { required: true, message: '请填写活动地址', trigger: 'blur' }
                ],
                // salon_remark: [
                //     { required: true, message: '请填写活动备注信息', trigger: 'blur' }
                // ]
            }
        }
    },
    computed: {
        ...mapState(['userInfos'])
    },
    created() {
        this.getUserInfos()
    },
    mounted() {
        this.getCurrentSalon()
    },
    methods: {
        goBack() {
            this.$router.push('/activity')
        },
        //获取所有沙龙
        async getAllSalon() {
            const { data: res } = await this.$http.post(loginApi.getSalonAllApi)
            if(res.code==='E000000') {
                this.list = res.data
            } else {
                return this.$message.warning(res.message)
            }
        },
        //获取当前沙龙
        async getCurrentSalon() {
            const { data: res } = await this.$http.post(loginApi.getCurrentSalonApi)
            if(res.code==='E000000') {
                this.list = res.data
            } else {
                return this.$message.warning(res.message)
            }
        },
        //获取我的沙龙
        async getMySalon() {
            const { data: res } = await this.$http.post(loginApi.getMySalonApi)
            if(res.code==='E000000') {
                this.list = res.data
            } else {
                return this.$message.warning(res.message)
            }
        },
        //参加活动按钮
        toActivity(val) {
            this.$router.push({ path: '/activity/settlement', name: 'settlement', params: {sid: val} })
        },
        //创建沙龙按钮
        createSalon() {
            this.getLeaderName()
            this.dialogVisible = true
        },
        //关闭dialog后执行回调
        closeDlg() {
            this.$refs['form'].resetFields()
            this.$data.form = JSON.parse(JSON.stringify(this.$options.data().form))
        },
        //提交沙龙
        onSubmit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    if(this.create_level_id < 0) {
                        return this.$message.error("对不起，您无权创建！")
                    } else {
                        this.createSalonRequest()
                    }
                } else {
                    return false;
                }
            })
        },
        handleClick(tab, event) {
            switch (tab._props.name) {
                case 'first':
                    this.list = []
                    this.getAllSalon()
                    break;
                case 'second':
                    this.list = []
                    this.getCurrentSalon()
                    break;
                case 'third':
                    this.list = []
                    this.getMySalon()
                    break;
                case 'four':
                    this.$router.push('/activity/orderlists')
                    break;
                default:
                    break;
            }
        },
        //创建沙龙请求
        async createSalonRequest() {
            this.form.salon_user_id = this.$store.state.userInfos.U
            const { data: res } = await this.$http.post(loginApi.createSalonApi, JSON.stringify(this.form))
            if(res.code !== "E000000") {
                return this.$message.error(res.message)
            } else {
                this.$message.success(res.message)
                this.dialogVisible = false
                this.$router.go(0)
            }
        },
        //获取用户信息
        async getUserInfos() {
            const { data: res } = await this.$http.post(loginApi.userInfoApi, this.queryUserParams)
            if (isFailureLogin(res.retcode)) {
                this.userInfos_case = res.result
                this.getUserExtendInfos()
            } else {
                //this.$message.warning(res.message)
                return
            }
        },
        //获取用户扩展信息
        async getUserExtendInfos() {
            const { data: res } = await this.$http.post(loginApi.userInfoExtendApi, this.queryUserExtendParams)
            if (res.code !== 'E000000') {
                //this.$message.warning(res.message)
                return
            } else {
                this.create_level_id = res.data.level_id
                //将用户信息保存到vuex中
                this.$store.dispatch('getUserInfos', {...this.userInfos_case, ...res.data})
            }
        },
        //获取星级领导人
        async getLeaderName() {
            const { data: res } = await this.$http.post(loginApi.getSalonLeaderApi)
            if (res.code === 'E000000') {
                this.form.region_leader_name = res.data.user_name
            } else {
                return this.$message.warning(res.message)
            }
        },
    }
}
</script>

<style lang="less" scoped>
    .settlement-main {
        width: 100%;
        height: 100%;
        padding: 5px;
        position: absolute;
    }
    .el-card {
        margin-bottom: 5px;
        border-radius: 10px;
    }
    /deep/ .el-divider--horizontal {
        margin: 10px 0;
    }
    /deep/ .el-card__body {
        padding: 0;
    }
    .box-father {
        width: 100%;
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
        .left-box {
            width: 68%;
            padding: 5px;
            p {
                word-wrap: break-word;
                font-size: 12px;
            }
        }
        .right-box {
            width: 26%;
            height: 40px;
            padding: 5px;
            .submit-btn-canjia {
                width: 100%;
                height: 100%;
            }
        }
    }
    .submit-btn {
        width: 100%;
    }
    .top-info {
        width: 100%;
        position: fixed;
        left: 0;
    }
    .content-info {
        width: 100%;
        height: 60rem;
        position: absolute;
        top: 10rem;
        left: 0;
        right: 0;
        overflow-y: auto;
    }
    .foot-info {
        width: 100%;
        position: fixed;
        bottom: 0.5rem;
        left: 0;
        right: 0;
    }
    /deep/ .el-dialog__header {
        padding: 0;
    }
    /deep/ .el-dialog--center .el-dialog__body {
        padding: 5px 10px 5px;
    }
    /deep/ .el-time-panel {
        width: 100%;
    }
</style>