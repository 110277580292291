<template>
    <div class="order-lists">
        <div class="order-content" v-if="list.length > 0">
            <el-card class="box-card" v-for="(item, index) in list" :key="index">
                <el-descriptions title="" size="mini" :column="1" direction="vertical" border>
                    <el-descriptions-item label="单号">{{ item.order_id }}</el-descriptions-item>
                    <el-descriptions-item label="商品">
                        <p><span>名称：{{ item.product_name }}</span></p>
                        <p>
                            <span v-if="item.order_type=='position'">仓量：{{ item.product_count }} x {{ item.product_price_position }}（仓量）</span>
                            <span v-if="item.order_type=='point'">积分：{{ item.product_count }} x {{ item.product_price_point }}（积分）</span>
                        </p>
                        <p>
                            <span v-if="item.order_type=='position'">总消耗：{{ item.product_count*item.product_price_position }}</span>
                            <span v-if="item.order_type=='point'">总消耗：{{ item.product_count*item.product_price_point }}</span>
                        </p>
                    </el-descriptions-item>
                    <el-descriptions-item label="地址">
                        <p><span>{{ item.name }}</span>&nbsp;&nbsp;<span>{{ item.phone }}</span></p>
                        <p><span>{{ item.address }}</span></p>
                    </el-descriptions-item>
                    <el-descriptions-item label="快递">
                        <p><span>快递公司：{{ item.express_company==''?'暂无':item.express_company }}</span></p>
                        <p><span>快递单号：{{ item.express_id==''?'暂无':item.express_id }}</span></p>
                    </el-descriptions-item>
                    <el-descriptions-item label="状态">
                        <el-tag type="warning" v-if="item.delivery_state==0" size="small">待发货</el-tag>
                        <el-tag type="danger" v-else-if="item.delivery_state==1" size="small">已发货</el-tag>
                        <el-tag type="success" v-else size="small">已收货</el-tag>
                    </el-descriptions-item>
                    <el-descriptions-item label="时间">{{ item.order_time }}</el-descriptions-item>
                    <el-descriptions-item label="操作">
                        <el-button v-if="item.delivery_state==1" type="primary" @click="confirmBtn(item)" size="mini">确认收货</el-button>
                        <el-button @click.native="showOrderDetails(item.order_id)" type="success" size="mini">查看详情</el-button>
                    </el-descriptions-item>
                </el-descriptions>
            </el-card>
            <!-- dialog对话框显示订单详情 -->
            <el-dialog
                :visible.sync="dialogVisible"
                :before-close="handleClose"
                center
                :show-close="false"
                :append-to-body="true"
                fullscreen
                top="10px"
                >
                <div class="deposit-head">
                    <el-page-header @back="dialogVisible = false" content=""></el-page-header>
                </div>
                <el-divider></el-divider>
                <el-descriptions title="" size="small" :column="1" direction="vertical" border>
                    <el-descriptions-item label="订单ID">{{ orderDetailsData.order_id }}</el-descriptions-item>
                    <el-descriptions-item label="商品信息">
                        <p><el-tag size="small">名称：{{ orderDetailsData.product_name }}</el-tag></p>
                        <p><el-tag size="small">价格：{{ orderDetailsData.product_price | keepTwoDecimal }}</el-tag></p>
                        <p><el-tag size="small">积分：{{ orderDetailsData.product_price_point | keepTwoDecimal }}</el-tag></p>
                        <p><el-tag size="small">数量：{{ orderDetailsData.product_count }}</el-tag></p>
                        <p><el-tag size="small">总消耗：{{ orderDetailsData.product_total_price | keepTwoDecimal }}（积分）</el-tag></p>
                    </el-descriptions-item>
                    <el-descriptions-item label="收货地址" :span="2">
                        <p><span>{{ orderDetailsData.name }}   {{ orderDetailsData.phone }}</span></p>
                        <p><span>{{ orderDetailsData.address }}</span></p>
                    </el-descriptions-item>
                    <el-descriptions-item label="快递信息">
                        <p><span>快递公司：{{ orderDetailsData.express_company==''?'暂无':orderDetailsData.express_company }}</span></p>
                        <p><span>快递单号：{{ orderDetailsData.express_id==''?'暂无':orderDetailsData.express_id }}</span></p>
                    </el-descriptions-item>
                    <el-descriptions-item label="订单状态">
                        <p>
                            <el-tag type="warning" v-if="orderDetailsData.delivery_state==0" size="small">待发货</el-tag>
                            <el-tag type="danger" v-else-if="orderDetailsData.delivery_state==1" size="small">已发货</el-tag>
                            <el-tag type="success" v-else size="small">已收货</el-tag>
                        </p>
                    </el-descriptions-item>
                    <el-descriptions-item label="发货时间">
                        <p><span>{{ orderDetailsData.delivery_time==''?'暂无':orderDetailsData.delivery_time }}</span></p>
                    </el-descriptions-item>
                    <el-descriptions-item label="收货时间">
                        <p><span>{{ orderDetailsData.receive_time==''?'暂无':orderDetailsData.receive_time }}</span></p>
                    </el-descriptions-item>
                    <el-descriptions-item label="创建时间">
                        <p><span>{{ orderDetailsData.order_time==''?'暂无':orderDetailsData.order_time }}</span></p>
                    </el-descriptions-item>
                </el-descriptions>
                <!-- <span slot="footer" class="dialog-footer">
                    <el-button style="width: 100%" type="danger" size="small" @click="dialogVisible = false">返回</el-button>
                </span> -->
            </el-dialog>
        </div>
        <div class="order-content" v-else>
            <el-empty :image-size="200"></el-empty>
        </div>
        <!-- 分页 -->
        <div class="paginate-goods">
            <el-pagination v-if="num_none" background small
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange" 					
                layout="prev, pager, next"
                :current-page="current_page"
                :page-size="pagesize"
                :page-count="pagecount"
                :pager-count="5">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    //时间日期格式化
    function dateFormat(timestamp) {
        if (!timestamp) return ''
        if(timestamp.toString().length == 13){
            var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + '-';
            var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
            var D = date.getDate() + ' ';
            var h = date.getHours() + ':';
            var m = (date.getMinutes()).toString().padStart(2,'0')+ ":" ;
            var s = (date.getSeconds()).toString().padStart(2,'0');
        }
        return Y+M+D+h+m+s;
    }
    import imgUrl from '@/assets/images/xiandairen.jpg'
    import { noLoginApi, loginApi } from '@/config/env'
    export default {
        name: 'OrderLists',
        data() {
            return {
                //查询参数
                params: {
                    filter: {},
                    page: {
                        pageNo: 1,
                        pageSize: 10
                    }
                },
                list: [],
                total: 0, //总数据条数
                current_page: 1, //当前页码
                pagecount: 0, //总页码数
                num_none: true, //是否显示分页
                pagesize: 10, //每页显示数据条数
                dialogVisible: false,
                orderDetailsData: {}  //订单详情
            }
        },
        mounted() {
            this.getOrderLists()
        },
        beforeDestroy() {
            
        },
        methods: {
            //每页数据条数发生改变时触发
            handleSizeChange() {},
            //当前页发生改变时触发
            handleCurrentChange(val) {
                this.params.page.pageNo = val
                this.getOrderLists()
            },
            handleClick() {

            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
            },
            //获取订单记录
            async getOrderLists() {
                const { data: res } = await this.$http.post(loginApi.mallOrderRecordApi, this.params)
                if (res.code !== 'E000000') {
                    return this.$message.warning(res.message)
                }
                this.total = res.data.totalCount
                this.current_page = res.data.pageNo
                this.pagesize = res.data.pageSize
                this.pagecount = res.data.totalPage
                this.list = res.data.data
            },
            //查看订单详情按钮
            async showOrderDetails(item) {
                const { data: res } = await this.$http.post(loginApi.queryMallOrderDetail+'?order_id='+item)
                if (res.code !== 'E000000') {
                    return this.$message.error(res.message)
                }
                this.orderDetailsData = res.data
                this.dialogVisible = true
            },
            //确认收货按钮触发
            confirmBtn(rows) {
                const h = this.$createElement
                this.$msgbox({
                    title: '确认收货提示',
                    message: h('p', null, [
                        h('span', null, '单号：'),
                        h('i', { style: 'color: red' }, rows.order_id),
                        h('br'),
                        h('span', null, '商品名称：'),
                        h('i', { style: 'color: red' }, rows.product_name),
                        h('br'),
                        h('span', null, '商品数量：'),
                        h('i', { style: 'color: red' }, rows.product_count),
                        h('br'),
                        h('span', null, '收货时间：'),
                        h('i', { style: 'color: red' }, dateFormat(Date.parse(new Date()))),
                    ]),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true
                            instance.confirmButtonText = '执行中...'
                            setTimeout(() => {
                                this.receveDelivery(rows.order_id)
                                done()
                                setTimeout(() => {
                                    instance.confirmButtonLoading = false
                                }, 300)
                            }, 2000)
                        } else {
                            instance.cancelButtonLoading = true
                            instance.cancelButtonText = '取消中...'
                            setTimeout(() => {
                                done()
                                setTimeout(() => {
                                    instance.cancelButtonLoading = false
                                }, 300)
                            }, 500)
                        }
                    }
                }).then(action => {
                    
                }).catch(err => {
                    this.$message.info('已取消')
                })
            },
            //执行确认收货操作
            async receveDelivery(orderId) {
                const { data: res } = await this.$http.post(loginApi.mallConfirmReceiptApi+"?order_id="+`${orderId}`)
                if (res.code !== 'E000000') {
                    return this.$message.error(res.message)
                } else {
                    this.$message.success(res.message)
                    this.getOrderLists()
                    this.$router.replace({ path: '/mall/orderlists', query: { t: Date.now() }})
                    this.$router.replace({ path: this.$route.path })
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .order-lists {
        width: 100%;
        height: 100%;
        .order-content {
            width: 100%;
            height: 95%;
            padding: 0.2rem;
            margin-bottom: 0.5rem;
            overflow-y: auto;
            .el-card {
                margin-bottom: 0.5rem;
            }
            /deep/ .el-card__body {
                padding: 0;
            }
        }
    }
    
    .paginate-goods {
        width: 100%;
        height: auto;
        text-align: center;
    }

    /deep/ .el-dialog__header {
        padding: 0;
    }
    /deep/ .el-dialog__body {
        padding: 1rem;
    }
    /deep/ .el-dialog__footer {
        padding: 1rem;
    }
    /deep/ .el-divider--horizontal {
        margin: 10px 0;
    }
</style>