<template>
    <div class="deposit-record">
        <div class="deposit-title">
            <el-page-header @back="goBack" content="出入金记录" style="font-weight: bolder;">
            </el-page-header>
            <el-divider></el-divider>
        </div>
        <div class="deposit-main">
            <div class="infinite-list-wrapper" style="width: 100%;height: 500px;overflow-y: auto;">
                <el-table v-infinite-scroll="load"
                        height="500"
                        :data="list"
                        stripe 
                        empty-text="暂无数据" 
                        style="width: 100%"
                        ref="loadTable">
                    <el-table-column prop="SNM" label="类型" align="left">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.SNM == 104087" type="danger" size="mini">出金</el-tag>
                            <el-tag v-else type="success" size="mini">入金</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="PID" label="金额" align="left"></el-table-column>
                    <el-table-column prop="CRT" label="时间" align="left"></el-table-column>
                </el-table>
            </div>
        </div>
        <!-- <div class="count-infos">
            <el-descriptions size="mini" :column="2">
                <el-descriptions-item label="入金笔数">{{ deposit_count }}</el-descriptions-item>
                <el-descriptions-item label="入金合计">
                    <el-tag type="success" size="mini">{{ deposit | keepTwoDecimal }}</el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="出金笔数">{{ cashout_count }}</el-descriptions-item>
                <el-descriptions-item label="出金合计">
                    <el-tag type="danger" size="mini">-{{ cashout | keepTwoDecimal }}</el-tag>
                </el-descriptions-item>
            </el-descriptions>
        </div> -->
        <div class="isno-more">
            <el-alert v-if="isflag" title="正在努力加载中..." type="success" center :closable="false" show-icon></el-alert>
            <el-alert v-if="isMore" title="没有更多啦！" type="warning" center show-icon></el-alert>
        </div>
    </div>
</template>

<script>
    import { loginApi } from '@/config/env'
    //局部注册表格table滚动加载插件
    import elTableInfiniteScroll from 'el-table-infinite-scroll'
    import { isFailureLogin, addFloat, subFloat } from '@/config/mUtils'
    export default {
        name: 'DepositRecord',
        directives: {
            'el-table-infinite-scroll': elTableInfiniteScroll
        },
        data() {
            return {
                queryRecordParams: {
                    "name": "invest_draw_query",
                    "U": "",
                    "LTI": 0,
                    "RECCNT": 20,
                    "SI": ""
                },
                totalNum: 0,
                loading: false,
                list: [],
                isflag: false,
                isMore: false,
                maxNum: 19
            }
        },
        computed: {
        },
        mounted() {
            this.getDepositLists()
        },
        methods: {
            //返回
            goBack() {
                this.$router.go(-1)
            },
            //获取出入金记录
            async getDepositLists() {
                const { data: res } = await this.$http.post(loginApi.queryDepositRecordApi, this.queryRecordParams)
                if (isFailureLogin(res.retcode)) {
                    if (res.result.TC > 0) {
                        this.list = this.list.concat(res.resultList.REC)
                        this.totalNum = res.result.TC
                        this.queryRecordParams.LTI = res.result.MINID
                        if(res.result.TC < 20) {
                            this.isMore = true
                        }
                    } else {
                        this.isflag = false
                        this.isMore = true
                        this.totalNum = 0
                    }
                }
            },
            load () {
                if (this.totalNum <= this.maxNum) {
                    return
                }
                this.isflag = true
                this.isMore = false
                
                    this.queryRecordParams.RECCNT = -20
                    this.getDepositLists()
                    this.isflag = false
                    this.isMore = true
                
            },
            // //滚动加载
            // load1() {
            //     if (this.totalNum <= this.maxNum) {
            //         return
            //     }
            //     this.isflag = true
            //     this.isMore = false
            //     setTimeout(() => {
            //         this.queryRecordParams.RECCNT = -20
            //         this.getDepositLists()
            //         this.isflag = false
            //         this.isMore = true
            //     }, 1500)
            // }  
        }
    }
</script>

<style lang="less" scoped>
    .deposit-record {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        .deposit-title {
            width: 100%;
            max-height: 6.5rem;
            position: fixed;
            left: 0;
            padding: 1rem;
        }
        .deposit-main {
            width: 100%;
            padding: 1rem;
            position: absolute;
            top: 6rem;
            left: 0;
        }
        .isno-more {
            width: 100%;
            position: absolute;
            bottom: 1rem;
            left: 0;
        }
        .count-infos {
            width: 100%;
            position: absolute;
            bottom: 6rem;
            left: 0;
            //border: 1px solid red;
        }
    }
    
</style>