<template>
    <div>
        <el-row>
            <el-col :span="24">
                <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                    height="400" size="mini" empty-text="暂无数据">
                    <el-table-column label="单号" prop="ON" width="150">
                    </el-table-column>
                    <el-table-column prop="CON" label="商品名称" width="120"></el-table-column>
                    <el-table-column prop="OP" label="委托价格" width="120"></el-table-column>
                    <el-table-column prop="OQ" label="委托数量" width="100"></el-table-column>
                    <el-table-column prop="BAL" label="剩余数量" width="100"></el-table-column>
                    <el-table-column prop="BS" label="委托类型" width="120">
                        <template slot-scope="scope">
                            <el-tag size="mini" :type="scope.row.BS == '1' ? 'primary' : 'success'"
                                disable-transitions>{{ scope.row.BS == '1' ? '买入' : '卖出' }}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" fixed="right" width="100">
                        <template slot-scope="scope">
                            <button type="button" @click="cancelEntrustOrder(scope.row)" class="btn btn-warning btn-block btn-xs">撤单</button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import { loginApi } from '@/config/env'
    import { isFailureLogin } from '@/config/mUtils'
    export default {
        name: 'CancelOrder',
        inject: ['reload'],
        data() {
            return {
                //查询委托挂单参数
                queryEntrustOrder: {
                    "name": "order_query_mobile",
                    "U": "",
                    "LTI": "0",
                    "RECCNT": "20",
                    "SI": "",
                    "OS": "1"   //状态：1：已委托2：已成交4：已撤单, （查询条件，可不传）
                },
                //撤销挂单参数
                cancelOrderParam: {
                    "name": "order_wd",
                    "U": "",
                    "ON": "",   //委托单号,多个用逗号隔开
                    "SI": ""
                },
                //挂单列表
                tableData: [],
            }
        },
        computed: {
            
        },
        mounted() {
            this.queryEntrustOrderLists()
        },
        methods: {
            //查询委托挂单记录
            async queryEntrustOrderLists() {
                const { data: res } = await this.$http.post(loginApi.queryEntrustRecordApi, this.queryEntrustOrder)
                if (isFailureLogin(res.retcode)) {
                    this.tableData = res.resultList.REC
                } else {
                    return this.$message.error(res.message)
                }
            },
            //撤销委托挂单按钮
            cancelEntrustOrder(rows) {
                const h = this.$createElement;
                this.$msgbox({
                    title: '撤单提示',
                    message: h('p', null, [
                        h('span', null, '单号：'),
                        h('i', { style: 'color: teal' }, rows.ON),
                        h('br'),
                        h('span', null, '数量：'),
                        h('i', { style: 'color: teal' }, rows.BAL)
                    ]),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true
                            instance.confirmButtonText = '执行中...'
                            setTimeout(() => {
                                this.cancelOrderParam.ON = rows.ON
                                this.cancelOrderEnter()
                                done()
                                setTimeout(() => {
                                    instance.confirmButtonLoading = false
                                }, 300)
                            }, 2000)
                        } else {
                            instance.cancelButtonLoading = true
                            instance.cancelButtonText = '取消中...'
                            setTimeout(() => {
                                done()
                                setTimeout(() => {
                                    instance.cancelButtonLoading = false
                                }, 300)
                            }, 1000)
                        }
                    }
                }).then(action => {
                    setTimeout(() => {
                        this.reload()
                        this.$bus.$emit('isReload', true)
                    }, 2000)
                }).catch(err => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    })
                })
            },
            //撤销挂单
            async cancelOrderEnter() {
                const { data: res } = await this.$http.post(loginApi.cancelEntrustOrderApi, this.cancelOrderParam)
                if (isFailureLogin(res.retcode)) {
                    this.$message.success('撤单成功')
                } else {
                    return this.$message.error(res.message)
                }
            }
        }  
    }
</script>

<style lang="less" scoped>

</style>