<template>
    <div class="auction-details">
        <el-page-header @back="goBack" content="商品详情" style="font-weight: bolder;">
        </el-page-header>
        <el-divider></el-divider>
        <div class="auction-main">
            <div class="goods-img"><el-image v-if="goodsInfo.product_pic_path" :src="goodsInfo.product_pic_path"></el-image></div>
            <div class="goods-base">
                <div class="jumbotron">
                    <h3 style="font-weight: 600">{{ goodsInfo.product_name }}</h3>
                    <span style="color: #DF2625">￥{{ goodsInfo.product_price | keepTwoDecimal }}</span>
                    <!-- <span style="color: #DF2625">仓量兑换：{{ goodsInfo.product_price_position | keepTwoDecimal }}</span> -->
                    <span style="color: #DF2625">积分兑换：{{ goodsInfo.product_price_point | keepTwoDecimal }}</span>
                    
                </div>
            </div>
            <el-divider></el-divider>
            <div class="goods-details">
                <el-tag type="info">详细信息</el-tag>
                <el-card class="box-card">
                    <div class="product-details" v-html="goodsInfo.product_detail"></div>
                </el-card>
            </div>
        </div>
        <div class="buy-btn">
            <el-button type="danger" @click="toExchange(goodsInfo)">立即兑换</el-button>
        </div>
    </div>
</template>

<script>
    import { loginApi, noLoginApi } from '@/config/env'
    import imageUrl from '../../../../assets/images/xiandairen.jpg'
    export default {
        name: 'GoodsDetails',
        data() {
            return {
                id: '',
                goodsInfo: {}
            }
        },
        computed: {},
        created() {
            this.id = this.$route.query.id ? this.$route.query.id : ''
        },
        mounted() {
            this.getGoodsDetails()
        },
        methods: {
            goBack() {
                this.$router.push('/mall/goodslists')
            },
            //获取商品详情信息
            async getGoodsDetails() {
                const { data: res } = await this.$http.post(noLoginApi.queryGoodsDetailsApi+'?product_id='+this.id)
                if (res.code !== 'E000000') {
                    return
                }
                this.goodsInfo = res.data
            },
            //去兑换按钮
            toExchange(item) {
                this.$router.push({ path: '/mall/goodsexchange', query: {
                    id: item.product_id
                }})
            }
        },   
    }
</script>

<style lang="less" scoped>
    .auction-details {
        width: 100%;
        margin: 0 auto;
        padding: 10px;
        .buy-btn {
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            .el-button {
                width: 100%;
            }
        }
    }
    .auction-main {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0.5rem;
        margin-bottom: 10rem;
        .goods-img {
            width: 100%;
            height: 20.5rem;
            margin-bottom: 1.5rem;
            .el-image {
                width: 100%;
                height: 100%;
            }
            
        }
        .goods-base {
            width: 100%;
            .jumbotron {
                margin-top: 0 !important;
                margin-bottom: 0 !important;
                padding-top: 0 !important;
                padding-bottom: 0 !important;
                span {
                    color: #DF2625;
                    margin-right: 1.5rem;
                    font-weight: 700;
                    font-size: 1.5rem;
                }
            }
        }
        .goods-details {
            width: 100%;
        }
    }
    
</style>