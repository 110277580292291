<template>
    <div class="deposit">
        <div class="deposit-head">
            <el-page-header @back="goBack" content="入金" style="font-weight: bolder;">
            </el-page-header>
        </div>
        <!-- 分割线 -->
        <el-divider></el-divider>
        <div class="deposit-main">
            <el-form :model="depositShowForm" :rules="rules" ref="depositShowForm" label-width="100px">
                <el-form-item label="可转金额" prop="AF">
                    <el-input v-model="depositShowForm.AF" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="转入金额" prop="AF">
                    <el-input v-model="depositShowForm.AF" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="资金密码" required>
                    <el-input v-model="moneyPassword" show-password placeholder="请输入您的资金密码"></el-input>
                </el-form-item>
                <button type="button" @click="onSubmit" class="btn btn-danger btn-lg btn-block">提交</button>
            </el-form>
        </div>
    </div>
</template>

<script>
    import { loginApi } from '@/config/env'
    import { isFailureLogin } from '@/config/mUtils'
    export default {
        name: 'Deposit',
        data() {
            return {
                rules: {},
                //资金密码
                moneyPassword: '',
                //查询交易商总资金信息
                totalMoneyParams: {
                    "name": "totalfunds_query",
                    "U": "",
                    "SI": ""
                },
                depositShowForm: {
                    "FPN": "",
                    "B": "",
                    "AF": "",
                    "FF": ""
                },
                //入金操作参数
                depositParams: {
                    name: "funds_transfer",
                    FFI: "",  //划出资金池编号
                    FP: "",  //资金密码
                    SI: "",
                    TFI: "", //划入资金池编号
                    TM: "", //划转金额
                    U: ""
                },
                //查询资金池信息
                queryFundspoolParams: {
                    "name": "fundspool_info_query",
                    "U": "",
                    "SI": ""
                },
                //资金池数据
                fundsData: []
            }
        },
        created() {
            
        },
        mounted() {
            //获取资金池信息
            this.getFundsInfos()
            //获取交易商总资金信息
            this.queryTotalMoneyInfos()
        },
        methods: {
            //返回
            goBack() {
                this.$router.push('/user')
            },
            //提交
            onSubmit() {
                if (this.moneyPassword == '') {
                    this.$message.error('请输入您的资金密码')
                    return
                }
                if (this.fundsData.length > 0) {
                    this.fundsData.forEach((val, index) => {
                        if (val.FPN === '资金系统') {
                            this.depositParams.FFI = val.FPID
                        } else if(val.FPN === '文拍系统'){
                            this.depositParams.TFI = val.FPID
                        }
                    })
                }
                this.depositParams.FP = this.moneyPassword
                this.depositParams.TM = this.depositShowForm.AF
                this.$confirm('您确定要入金￥' + this.depositParams.TM + '吗?', '确认提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then( async () => {
                    const { data: res } = await this.$http.post(loginApi.fundsTransferApi, this.depositParams)
                    if (isFailureLogin(res.retcode)) {
                        this.$message.success(res.message)
                        setTimeout(() => {
                            this.$router.push('/money/depositrecord')
                        }, 1000)
                    } else {
                        this.$message.error(res.message)
                        return
                    }
                }).catch(() => {
                    this.$message.warning('已取消')
                    return
                })
            },
            //查询交易商总资金信息
            async queryTotalMoneyInfos() {
                const { data: res } = await this.$http.post(loginApi.queryTotalFundsApi, this.totalMoneyParams)
                if (isFailureLogin(res.retcode)) {
                    if (res.resultList.REC.length > 0) {
                        res.resultList.REC.forEach((value, index) => {
                            if (value.FPN === '资金系统') {
                                this.depositShowForm.AF = value.AF
                            }
                        })
                    }
                } else {
                    this.$message.error(res.message)
                    return
                }
            },
            //获取资金池信息
            async getFundsInfos() {
                const { data: res } = await this.$http.post(loginApi.queryFundspoolApi, this.queryFundspoolParams)
                if (isFailureLogin(res.retcode)) {
                    this.fundsData = res.resultList.REC
                } else {
                    this.$message.error(res.message)
                    return
                }
            }
        }    
    }
</script>

<style lang="less" scoped>
    .deposit {
        width: 100%;
        height: auto;
        .deposit-head {
            width: 100%;
            height: 100%;
            padding: 1rem;
        }
        .deposit-main {
            width: 100%;
            height: 100%;
            padding: 1rem;
            // 样式穿透
            .el-input /deep/ .el-input__inner {
                color: #333333;
                font-weight: 600;
            }
        }
    }
</style>