<template>
    <div class="forget-pwd">
        <el-page-header @back="goBack" content="设置资金密码" style="font-weight: bolder;">
        </el-page-header>
        <el-divider></el-divider>
        <el-form ref="passwordForm" :model="passwordForm" :rules="rules" label-width="100px">
            <el-form-item label="真实姓名" prop="UN">
                <el-input v-model="passwordForm.UN" placeholder="请输入您的真实姓名"></el-input>
            </el-form-item>
            <el-form-item label="身份证号码" prop="CDN">
                <el-input v-model="passwordForm.CDN" placeholder="请输入您的证件号码"></el-input>
            </el-form-item>
            <el-form-item label="资金密码" prop="NPWD">
                <el-input v-model="passwordForm.NPWD" placeholder="设置您的支付密码" show-password></el-input>
            </el-form-item>
            <el-button class="submit-btn" type="success" @click="onSubmit">确认</el-button>
        </el-form>
    </div>
</template>

<script>
import { loginApi } from '@/config/env'
    export default {
        name: 'MoneyPassword',
        data() {
            //自定义规则
            const checkIdcard = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('证件号码不能为空！'))
                } else {
                    const regId = /^(\d{18,18}|\d{15,15}|\d{17,17}X)$/
                    if (!regId.test(value)) {
                        return callback(new Error('证件号码不合法！'))
                    } else {
                        callback()
                    }
                }
            }
            return {
                passwordForm: {
                    "name": "init_fundspwd",
                    "U": "",
                    "SI": "",
                    "UN": "",
                    "CDT": "1",
                    "CDN": "",
                    "NPWD": ""
                },
                rules: {
                    UN: [
                        { required: true, message: '请输入您的真实姓名', trigger: 'blur' }
                    ],
                    CDN: [
                        { required: true, message: '请输入您的有效证件号码', trigger: 'blur' },
                        { validator: checkIdcard, trigger: 'blur' }
                    ],
                    NPWD: [
                        { required: true, message: '请输入您的支付密码', trigger: 'blur' },
                        { min: 6, max: 30, message: "密码长度至少6位以上", trigger: 'blur'}
                    ]
                }
            }
        },
        methods: {
            //返回
            goBack() {
                this.$router.push('/user/information')
            },
            //提交设置新密码
            onSubmit() {
                this.$refs.passwordForm.validate( async (valid) => {
                    if(!valid) return false
                    const { data: res } = await this.$http.post(loginApi.initMoneyPasswordApi, this.passwordForm)
                    if (res.retcode < 0) {
                        if (parseInt(res.retcode) == -102130040002) {
                            //登录身份失效，重新登录
                            //清空登录态
                            window.sessionStorage.removeItem('uid')
                            window.sessionStorage.removeItem('sid')
                            window.sessionStorage.removeItem('userInfos')
                            this.$router.push('/login')
                        }
                        return this.$message.error(res.message)
                    }
                    console.log(res.code)
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .forget-pwd {
        width: 100%;
        padding: 1rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        form {
            margin-top: 5%;
        }
    }
    .submit-btn {
        width: 100%;
    }
</style>