<template>
    <div>
        <el-row>
            <el-col :span="24">
                <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                    height="400" size="mini" empty-text="暂无数据">
                    <el-table-column label="单号" prop="ON" width="150">
                    </el-table-column>
                    <el-table-column prop="P" label="价格" width="120"></el-table-column>
                    <el-table-column prop="SQT" label="数量" width="120"></el-table-column>
                    <el-table-column label="操作" fixed="right" width="150">
                        <template slot-scope="scope">
                            <div class="input-group input-group-sm">
                                <span class="input-group-btn">
                                    <button @click="minusDes(scope.row)" :disabled="isDisable" class="btn btn-default btn-xs" type="button"><span class="iconfont icon-jianhao"></span></button>
                                </span>
                                <input type="number" v-model.number="scope.row.SQE" class="form-control" placeholder="数量">
                                <span class="input-group-btn">
                                    <button @click="addDes(scope.row)" :disabled="isaddDisable" class="btn btn-default btn-xs" type="button"><span class="iconfont icon-jiahao"></span></button>
                                </span>
                            </div>
                            <button type="button" @click="isEnterBuy(scope.row)" class="btn btn-warning btn-block btn-xs">{{ btnContent }}</button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import { loginApi, noLoginApi } from '@/config/env'
    export default {
        name: 'DelistingSell',
        inject: ['reload'],
        data() {
            return {
                isShowTable: true,
                //挂单列表查询条件
                orderOneQuery: {
                    "name": "order_one_query",
                    "COI": "",
                    "BS": "1"
                },
                //摘单卖出
                entrustOrder: {
                    "name": "order",
                    "U": "",
                    "PN": "", //挂单号(摘单时必传，挂单时不传)
                    "COI": "",
                    "BS": "2",  //买卖标志：1买；2卖
                    "OP": "",  //委托价
                    "OQ": "1",  //委托量
                    "OPT": "2", //委托操作类型:1挂单;2摘单
                    "TI": "",
                    "SI": ""
                },
                isDisable: false,
                isaddDisable: false,
                //买卖挂单列表
                tableData: [],
                //表格操作列内容
                btnContent: '摘牌卖出',
            }
        },
        computed: {},
        created() {
            this.orderOneQuery.COI = this.$store.state.goodsInfos.COI
            this.entrustOrder.COI = this.$store.state.goodsInfos.COI
            this.entrustOrder.OP = this.$store.state.goodsInfos.LAST
        },
        mounted() {
            this.getOrderOneList()
            this.$bus.$on('getGoodsInfo', (data) => {
                this.orderOneQuery.COI = data.COI
                this.getOrderOneList()
            })
        },
        //解绑自定义事件
        beforeDestroy() {
            this.$bus.$off('getGoodsInfo')
        },
        methods: {
            //减数量
            minusDes(rows) {
                this.isaddDisable = false
                if (rows.SQE <= 0) {
                    this.isDisable = true
                    return this.$message.warning('请输入正确的数量')
                } else {
                    this.isDisable = false
                    rows.SQE--
                }
            },
            //加数量
            addDes(rows) {
                this.isDisable = false
                if (rows.SQT <= rows.SQE) {
                    this.isaddDisable = true
                    return this.$message.warning('数量已超出')
                } else {
                    this.isaddDisable = false
                    rows.SQE++
                }
            },
            //获取挂单列表信息
            async getOrderOneList() {
                const { data: res } = await this.$http.post(noLoginApi.orderOnceListApi, this.orderOneQuery)
                if (res.retcode >= 0) {
                    this.tableData = []
                    res.resultList.REC.forEach(table => {
                        this.tableData.push({ P: table.P, ON: table.ON, SQE: table.SQT, SQT: table.SQT })
                    })
                }
            },
            //入委托挂单或摘单
            async placeOrder() {
                const { data: result } = await this.$http.post(loginApi.entrustOrderApi, this.entrustOrder)
                if (result.retcode < 0) {
                    return this.$message.warning(result.message)
                }
                setTimeout(() => {
                    this.$message.success('摘牌卖出成功！')
                }, 500)
            },
            //操作摘牌买卖按钮
            isEnterBuy(rows) {
                if (rows.SQE <= 0) {
                    return this.$message.warning('请输入正确的数量')
                }
                const h = this.$createElement;
                this.$msgbox({
                    title: '提示',
                    message: h('p', null, [
                        h('span', null, '单号：'),
                        h('i', { style: 'color: teal' }, rows.ON),
                        h('br'),
                        h('span', null, '数量：'),
                        h('i', { style: 'color: teal' }, rows.SQE)
                    ]),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true
                            instance.confirmButtonText = '执行中...'
                            setTimeout(() => {
                                this.entrustOrder.PN = rows.ON
                                this.entrustOrder.COI = this.$store.state.goodsInfos.COI
                                this.entrustOrder.OP = this.$store.state.goodsInfos.LAST
                                this.entrustOrder.OQ = rows.SQE
                                this.placeOrder()
                                done()
                                setTimeout(() => {
                                    instance.confirmButtonLoading = false
                                }, 300)
                            }, 2000)
                        } else {
                            instance.cancelButtonLoading = true
                            instance.cancelButtonText = '取消中...'
                            setTimeout(() => {
                                done()
                                setTimeout(() => {
                                    instance.cancelButtonLoading = false
                                }, 300)
                            }, 1000)
                        }
                    }
                }).then(action => {
                    setTimeout(() => {
                        this.reload()
                        this.$bus.$emit('isReload', true)
                    }, 2000)
                }).catch(err => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    })
                })
            }
        },    
    }
</script>

<style lang="less" scoped>
    .input-with-number {
        width: 10rem;
    }
    .icon-jianhao {
        font-size: 1rem !important;
    }
    .icon-jiahao {
        font-size: 1rem !important;
    }
    .btn-danger .btn-xs {
        margin: 0 auto;
    }
</style>