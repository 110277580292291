/**
 * 根据返回码返回错误信息
 * @param string retKey 返回码
 * @return string 返回错误信息
 */
export const parseRetCode = retKey => {
    const retCode = {
        "-998100000001": "您未登录或登录已超时，请重新登录",
        "-998100000101": "对不起，服务异常，请联系客服",
        "-998100000102": "对不起，服务异常，请联系客服",
        "-998100000103": "对不起，服务异常，请联系客服",
        "-998100000104": "对不起，服务异常，请联系客服",
        "-998100000105": "对不起，服务异常，请联系客服",
        "-998100000201": "对不起，服务异常，请联系客服",
        "-998100000202": "对不起，服务异常，请联系客服",
        "-998100000203": "对不起，服务异常，请联系客服",
        "-998100000204": "对不起，服务异常，请联系客服",
        "-998100000205": "对不起，服务异常，请联系客服",
        "-998100000206": "对不起，服务异常，请联系客服",
        "-998100000207": "对不起，服务异常，请联系客服",
        "-998100000208": "对不起，服务异常，请联系客服",
        "-998100000209": "对不起，服务异常，请联系客服",
        "-998100000210": "对不起，服务异常，请联系客服",
        "-998100000211": "对不起，服务异常，请联系客服",
        "-998100000212": "对不起，服务异常，请联系客服",
        "-998100000213": "对不起，服务异常，请联系客服",
        "-998100000214": "对不起，服务异常，请联系客服",
        "-998100000215": "对不起，服务异常，请联系客服",
        "-998100000216": "对不起，服务异常，请联系客服",
        "-998100000217": "对不起，服务异常，请联系客服",
        "-998100000218": "对不起，服务异常，请联系客服",
        "-998100000219": "对不起，服务异常，请联系客服",
        "-998100000220": "对不起，服务异常，请联系客服",
        "-998100000221": "对不起，服务异常，请联系客服",
        "-998100000222": "对不起，服务异常，请联系客服",
        "-998100009996": "RMI连接异常",
        "-998100009997": "未知异常",
        "-998100009998": "数据库异常",
        "-998100009999": "未知异常",
        "998200010000": "%s",
        "998200010001": "添加成功",
        "-998200010001": "请与管理员联系",
        "998200010002": "修改成功",
        "998200010003": "删除成功",
        "998200010004": "批量删除成功",
        "-998200010005": "添加失败,当前用户下管理员到达上限",
        "998200010100": "操作成功，未知返回码【%s】",
        "-998200010100": "操作失败，未知返回码【%s】",
        "-998200010002": "数据信息不合法",
        "-998200010103": "交易密码，数据验证不合法",
        "-998200010104": "资金密码，数据验证不合法",
        "-998200019999": "未知异常【%s】",
        "998200020001": "驳回申请成功",
        "-998200020001": "没有传入申请代码",
        "-998200020002": "传入的申请代码不正确",
        "-998200020004": "数据错误，无法通过审核",
        "-998200020003": "申请人不能执行驳回操作",
        "-998200020005": "该记录不存在,请驳回申请",
        "-998200020099": "审核未知异常",
        "998200030001": "撤销申请成功",
        "-998200030001": "传入的申请代码不正确",
        "-998200030002": "传入的申请代码不正确",
        "-998200030003": "非申请人不能执行撤销操作",
        "998200040001": "批准申请成功",
        "-998200040001": "传入的申请代码不正确",
        "-998200040002": "传入的申请代码不正确",
        "-998200040004": "审核对象不是待审核状态",
        "-998200040003": "申请人不能执行审核操作",
        "-998200050001": "请登录后执行操作!",
        "-998200050002": "您无权访问本路径",
        "998200060003": "等待审核",
        "998200060002": "等待审核",
        "998200060001": "等待审核",
        "-998200070001": "交易密码，不能为空！",
        "-998200070002": "资金密码，不能为空！",
        "-998200070003": "管理员密码，不能为空！",
        "-998300000000": "对不起，未知异常，请拨打客服",
        "-998300009001": "对不起，服务异常，请拨打客服",
        "-998300019001": "对不起，服务异常，请拨打客服",
        "-998300019002": "对不起，服务异常，请拨打客服",
        "-998300019997": "对不起，服务异常，请拨打客服",
        "-998300019999": "对不起，未知异常，请拨打客服",
        "-998300029001": "对不起，服务异常，请拨打客服",
        "-998300029002": "对不起，服务异常，请拨打客服",
        "-998300029003": "对不起，服务异常，请拨打客服",
        "-998300029004": "对不起，服务异常，请拨打客服",
        "-998300029005": "对不起，服务异常，请拨打客服",
        "-998300029997": "对不起，服务异常，请拨打客服",
        "-998300029999": "对不起，未知异常，请拨打客服",
        "-998301104000": "对不起，您的请求过于频繁，已被禁止访问，请稍后再试",
        "-998301109999": "对不起，未知异常，请拨打客服",
        "-998310000000": "您的账号未登录，请重新登录",
        "-998310000001": "您的账号未登录，请重新登录",
        "-998310000002": "您的账号未登录，请重新登录",
        "-998310004000": "对不起，服务器验证失败，请先退出客户端，稍等一分钟后再尝试登录",
        "-998310009001": "对不起，服务异常，请拨打客服",
        "-998310009002": "对不起，服务异常，请拨打客服",
        "-998310009003": "对不起，服务异常，请拨打客服",
        "-998310019999": "对不起，未知异常，请拨打客服",
        "-998310020001": "获取前置机IP地址为空",
        "-998310029999": "对不起，无法获取前置机IP地址",
        "-998310110001": "对不起，登录失败，请重新登录",
        "-998310114000": "对不起，服务器验证失败，请先退出客户端，稍等一分钟后再尝试登录",
        "-998310119996": "对不起，服务异常，请拨打客服",
        "-998310119999": "对不起，未知异常，请拨打客服",
        "-998310120001": "对不起，切换模块失败，请重试",
        "-998310129996": "对不起，服务异常，请拨打客服",
        "-998310129999": "对不起，未知异常，请拨打客服",
        "-998310130001": "对不起，修改密码失败，请重试",
        "-998310130002": "新密码长度至少为6位",
        "-998310130003": "新密码必须是字母和数字组合",
        "-998310139996": "对不起，服务异常，请拨打客服",
        "-998310139999": "对不起，未知异常，请拨打客服",
        "-998310140001": "对不起，登出失败，请重试",
        "-998310149996": "对不起，服务异常，请拨打客服",
        "-998310149999": "对不起，未知异常，请拨打客服",
        "-998310210001": "对不起，为了您的安全，因此对您的数据进行了加密，发现您的加密信息不合法",
        "-998310210002": "对不起，登录失败，请重新登录",
        "-998310214000": "对不起，服务器验证失败，请先退出客户端，稍等一分钟后再尝试登录",
        "-998310219996": "对不起，服务异常，请拨打客服",
        "-998310219999": "对不起，未知异常，请拨打客服",
        "-998310220001": "对不起，切换模块失败，请重试",
        "-998310229996": "对不起，服务异常，请拨打客服",
        "-998310229999": "对不起，未知异常，请拨打客服",
        "-998310230001": "对不起，修改密码失败，请重试",
        "-998310230002": "新密码长度至少为6位",
        "-998310230003": "新密码必须是字母和数字组合",
        "-998310239996": "对不起，服务异常，请拨打客服",
        "-998310239999": "对不起，未知异常，请拨打客服",
        "-998310240001": "对不起，登出失败，请重试",
        "-998310249996": "对不起，服务异常，请拨打客服",
        "-998310249999": "对不起，未知异常，请拨打客服",
        "-998310250001": "对不起，为了您的安全，因此对您的数据进行了加密，发现您的加密信息不合法",
        "-998310250002": "对不起，获取会员编号失败，请重试",
        "-998310259996": "对不起，服务异常，请拨打客服",
        "-998310259999": "对不起，未知异常，请拨打客服",
        "-998310260001": "对不起，为了您的安全，因此对您的数据进行了加密，发现您的加密信息不合法",
        "-998310260002": "对不起，验证用户名密码失败，请重试",
        "-998310269996": "对不起，服务异常，请拨打客服",
        "-998310269999": "对不起，未知异常，请拨打客服",
        "-998310279996": "对不起，服务异常，请拨打客服",
        "-998310279999": "对不起，未知异常，请拨打客服",
        "-998310289996": "对不起，服务异常，请拨打客服",
        "-998310289999": "对不起，未知异常，请拨打客服",
        "-998320000001": "对不起，服务异常，请拨打客服",
        "-999130019996": "对不起，服务异常，请拨打客服",
        "-999130019999": "对不起，服务异常，请拨打客服",
        "-999130029996": "对不起，服务异常，请拨打客服",
        "-999130029999": "对不起，服务异常，请拨打客服",
        "-999130039996": "对不起，服务异常，请拨打客服",
        "-999130039999": "对不起，服务异常，请拨打客服",
        "-999130049996": "对不起，服务异常，请拨打客服",
        "-999130049999": "对不起，服务异常，请拨打客服",
        "-999130059996": "对不起，服务异常，请拨打客服",
        "-999130059999": "对不起，服务异常，请拨打客服",
        "-999130060001": "对不起，服务异常，请拨打客服",
        "-999130069996": "对不起，服务异常，请拨打客服",
        "-999130069999": "对不起，服务异常，请拨打客服",
        "-999130079996": "对不起，服务异常，请拨打客服",
        "-999130079999": "对不起，服务异常，请拨打客服",
        "-999130089996": "对不起，服务异常，请拨打客服",
        "-999130089999": "对不起，服务异常，请拨打客服",
        "-999130129996": "对不起，服务异常，请拨打客服",
        "-999130129999": "对不起，服务异常，请拨打客服",
        "-999130139996": "对不起，服务异常，请拨打客服",
        "-999130139999": "对不起，服务异常，请拨打客服",
        "-999130149996": "对不起，服务异常，请拨打客服",
        "-999130149999": "对不起，服务异常，请拨打客服",
        "-101100000001": "传入登录用户类型错误",
        "101101000002": "通知其他系统结果部分成功",
        "-101101000001": "传入的信息为空",
        "-101101000002": "传入的信息不完整",
        "-101101000003": "输入的客户代码[%s]有效长度不能超过[%s]位",
        "-101101000004": "客户代码不能为[%s]",
        "-101101000005": "代码为[%s]的客户信息已存在",
        "-101101000006": "传入的证件号码为空",
        "-101101000007": "证件号码[%s]已经被占用",
        "-101101000008": "费用[%s]不存在",
        "-101101000009": "客户默认交易员的交易员代码[%s]已经被占用",
        "-101101000010": "客户默认交易员的交易员代码[%s]已经被占用",
        "-101101000011": "手机号被占用",
        "-101101000012": "邮箱被占用",
        "-101101000013": "客户[%s]依附的用户主账号[%s]不存在",
        "-101101000014": "资金结算账户ID[%s]的附属账户已达到上限值[%s]",
        "-101101000015": "交易用户资金密码无效",
        "-101101000016": "会员不允许开户",
        "-101101000017": "经纪人不允许开户",
        "-101101000018": "手机号或邮箱被占用",
        "-101101000019": "邮箱输入不符合条件",
        "-101101000020": "手机号输入不符合条件",
        "-101101000021": "用户名不包含字母",
        "-101101000022": "用户名包含特殊字符",
        "-101101000023": "用户名[%s]被会员预留编号占用",
        "-101101000024": "申请客户状态不合法",
        "-101101000025": "用户昵称已存在",
        "-101101000026": "用户交易密码无效",
        "-101101000101": "客户[%s]依附的客户主账号[%s]不是已签约状态",
        "-101101000201": "客户所属会员[%s]不是已签约的经纪会员",
        "-101101000202": "客户所属会员[%s]没有经纪权限！",
        "-101101000203": "客户的权限超出所属会员[%s]经纪权限！",
        "-101101000204": "客户[%s]依附的会员主账号[%s]不是已签约状态",
        "-101101000301": "客户对应的经纪人[%s]不存在",
        "-101101000302": "客户对应的经纪人[%s]不是已签约状态",
        "-101101000303": "传入的会员代码[%s]与客户对应经纪人[%s]所属会员[%s]不一致",
        "-101101000304": "客户[%s]依附的经纪人主账号[%s]不是已签约状态",
        "-101101000305": "客户[%s]不可以附属经纪人的资金结算账户",
        "-101101000401": "调用资金系统添加客户[%s]返回失败",
        "-101101000501": "推荐人[%s]不存在",
        "-101101000502": "推荐人[%s]状态不正确",
        "-101101000503": "推荐人[%s]不是已签约经纪会员",
        "-101101000504": "推荐人[%s]没有经纪权限！",
        "-101101000505": "推荐人[%s]所属会员[%s]没有经纪权限！",
        "-101101009998": "对不起，服务异常，请拨打客服",
        "-101101009999": "对不起，服务异常，请拨打客服",
        "-101101100001": "传入的信息为空",
        "-101101100002": "客户信息不存在",
        "-101101100003": "客户状态不合法",
        "-101101100004": "传入的证件号码为空",
        "-101101100005": "证件号码[%s]已经被占用",
        "-101101100006": "客户[%s]邮箱或手机号已被占用",
        "-101101100007": "客户[%s]的默认交易员不存在",
        "-101101100008": "客户[%s]的默认交易员信息邮箱和手机号已被占用",
        "-101101100009": "邮箱输入不符合条件",
        "-101101100010": "手机号输入不符合条件",
        "-101101100011": "用户名不包含字母",
        "-101101100012": "用户名包含特殊字符",
        "-101101109998": "对不起，服务异常，请拨打客服",
        "-101101109999": "对不起，服务异常，请拨打客服",
        "101101200003": "警告信息：客户%s为已注销状态",
        "101101200002": "继续通知客户%s添加信息成功，通知其他系统部分成功",
        "-101101200001": "客户%s不存在",
        "-101101200002": "客户%s为状态不合法",
        "-101101200003": "客户%s失败记录已经处理",
        "-101101200004": "传入信息为空",
        "-101101200005": "客户%s已经通知全部添加成功",
        "-101101200006": "客户%s通知系统全部添加失败",
        "-101101209998": "对不起，服务异常，请拨打客服",
        "-101101209999": "对不起，服务异常，请拨打客服",
        "-101101300001": "传入信息为空",
        "-101101300002": "客户%s不存在",
        "-101101300003": "客户%s为未冻结状态",
        "-101101309998": "对不起，服务异常，请拨打客服",
        "-101101309999": "对不起，服务异常，请拨打客服",
        "101101400002": "冻结客户[%s]成功，强制客户下的交易员下线失败",
        "-101101400001": "传入的信息为空",
        "-101101400002": "客户[%s]不存在",
        "-101101400003": "客户[%s]为已冻结状态",
        "-101101400004": "客户[%s]下不存在交易员信息",
        "-101101409998": "对不起，服务异常，请拨打客服",
        "-101101409999": "对不起，服务异常，请拨打客服",
        "-101101500001": "传入的信息为空",
        "-101101500002": "客户[%s]不存在",
        "-101101500003": "客户[%s]为已销户状态",
        "-101101500004": "客户%s为未冻结状态",
        "-101101500005": "主账户客户状态不是已开户或已解约",
        "-101101500006": "客户%s的附属客户[%s]为未冻结状态",
        "-101101500007": "客户%s的附属客户不允许注销",
        "-101101500008": "客户%s的不允许销户",
        "-101101500009": "客户[%s]所属人经纪人主账号[%s]已注销",
        "-101101500010": "客户[%s]所属会员主账户[%s]不是经纪会员或已注销",
        "-101101500011": "客户%s是附属账户，没有主账户",
        "-101101500012": "[%s]提示：%s",
        "-101101509998": "对不起，服务异常，请拨打客服",
        "-101101509999": "对不起，服务异常，请拨打客服",
        "-101101600001": "传入的信息为空",
        "-101101600002": "交易员[%s]不存在",
        "-101101600003": "交易员[%s]邮箱或手机号已被占用",
        "-101101600004": "输入的邮箱不符合条件",
        "-101101600005": "输入的手机号不符合条件",
        "-101101600006": "用户名不包含字母",
        "-101101600007": "用户名包含特殊字符",
        "-101101600101": "交易员[%s]所属客户[%s]不存在",
        "-101101600102": "交易员[%s]所属客户[%s]为已销户状态",
        "-101101600201": "交易员[%s]所属会员[%s]不存在",
        "-101101600202": "交易员[%s]所属会员[%s]为已销户状态",
        "-101101609998": "对不起，服务异常，请拨打客服",
        "-101101609999": "对不起，服务异常，请拨打客服",
        "-101101700001": "传入的信息为空",
        "-101101700002": "交易员[%s]不存在",
        "-101101700003": "交易员为[%s]为已解冻状态",
        "-101101700004": "交易员所属客户为已注销状态",
        "-101101700005": "交易员所属会员为已注销状态",
        "-101101709998": "对不起，服务异常，请拨打客服",
        "-101101709999": "对不起，服务异常，请拨打客服",
        "101101800002": "修改客户%s系统权限部分成功",
        "-101101800001": "传入信息不完整",
        "-101101800002": "客户%s不存在",
        "-101101800003": "客户%s为已销户状态",
        "-101101800004": "请联系系统管理员",
        "-101101800005": "[%s]提示：%s",
        "-101101800006": "客户的新系统权限集合超出所属会员[%s]经纪系统权限",
        "-101101809998": "对不起，服务异常，请拨打客服",
        "-101101809999": "对不起，服务异常，请拨打客服",
        "101102000003": "调用资金系统添加会员%s账户成功",
        "101102000002": "添加会员[%s]及交易员成功，通知其他系统部分成功",
        "-101102000002": "调用资金系统添加会员账户返回失败",
        "-101102000003": "此会员已经存在",
        "-101102000004": "传入的会员信息不完整",
        "-101102000005": "传入的会员系统权限信息集合为空",
        "-101102000006": "传入的会员默认交易员信息为空",
        "-101102000007": "传入的业务权限编号%s不合法",
        "-101102000008": "会员[%s]代码长度必须为%s位",
        "-101102000009": "传入的证件号码为空",
        "-101102000010": "证件号码[%s]已经被占用",
        "-101102000011": "会员默认交易员的交易员代码[%s]已经被占用",
        "-101102000012": "用户名[%s]已经被占用",
        "-101102000013": "手机号[%s]已经被占用",
        "-101102000014": "邮箱[%s]已经被占用",
        "-101102000015": "报价系统会员[%s]业务类型设置不正确",
        "-101102000016": "传入的出入金结算账户信息为空",
        "-101102000017": "会员代码不能为[%s]",
        "-101102000018": "交易用户资金密码无效",
        "-101102000019": "手机号或邮箱占用",
        "-101102000020": "输入邮箱不符合条件",
        "-101102000021": "输入手机号不符合条件",
        "-101102000022": "输入用户名不包含字母",
        "-101102000023": "输入用户名包含特殊字符",
        "-101102000024": "用户名[%s]被会员预留编号占用",
        "-101102000025": "用户昵称已存在",
        "-101102000026": "运营中心[%s]不存在",
        "-101102000027": "运营中心[%s]已注销",
        "-101102000028": "用户交易密码无效",
        "-101102000029": "管理员密码无效",
        "-101102009998": "对不起，服务异常，请拨打客服",
        "-101102009999": "对不起，服务异常，请拨打客服",
        "101102100002": "警告信息：会员%s已销户",
        "101102100001": "继续通知会员%s添加信息部分成功",
        "-101102100001": "传入信息为空",
        "-101102100002": "会员%s不存在",
        "-101102100003": "会员%s为注销状态",
        "-101102100004": "会员%s失败记录已经处理",
        "-101102100005": "会员%s通知系统全部添加失败！",
        "-101102109998": "对不起，服务异常，请拨打客服",
        "-101102109999": "对不起，服务异常，请拨打客服",
        "-101102200001": "客户%s不存在",
        "-101102200002": "客户%s已经签约",
        "-101102200003": "客户%s不是开户状态",
        "-101102200004": "传入信息为空",
        "-101102200005": "会员%s不存在",
        "-101102200006": "会员%s已经签约",
        "-101102200007": "会员%s不是开户状态",
        "-101102200008": "未找到资金结算账号所属人或者账号所属人已冻结或已销户",
        "-101102200009": "资金结算账号所属人类型为[%s]编号[%s]被冻结",
        "-101102200010": "验证加密串失败",
        "-101102209998": "对不起，服务异常，请拨打客服",
        "-101102209999": "对不起，服务异常，请拨打客服",
        "-101102300001": "传入的信息为空",
        "-101102300002": "会员[%s]不存在",
        "-101102300003": "会员[%s]状态不合法",
        "-101102300004": "会员[%s]邮箱和手机号已被占用",
        "-101102300005": "会员[%s]默认交易员不存在",
        "-101102300006": "会员[%s]的默认交易员邮箱和手机号是否已被占用",
        "-101102300007": "会员[%s]证件号码[%s]被其他会员使用",
        "-101102300008": "输入邮箱不符合条件",
        "-101102300009": "输入手机号不符合条件",
        "-101102309998": "对不起，服务异常，请拨打客服",
        "-101102309999": "对不起，服务异常，请拨打客服",
        "-101102310001": "传入的信息为空",
        "-101102310002": "管理员[%s]不存在",
        "-101102310003": "管理员密码无效",
        "-101102319998": "对不起，服务异常，请拨打客服",
        "-101102319999": "对不起，服务异常，请拨打客服",
        "101102400002": "交易用户已经解约成功",
        "-101102400001": "客户%s不存在",
        "-101102400002": "客户%s不是正常状态",
        "-101102400003": "客户%s异常",
        "-101102400004": "调用系统%s接口失败",
        "-101102400005": "调用系统%s返回%s不可解约",
        "-101102400006": "传入信息为空",
        "-101102400007": "会员%s不存在",
        "-101102400008": "会员%s不是正常状态",
        "-101102400009": "会员%s异常",
        "-101102400010": "未找到资金结算账户所属人或者所属人状态不是已签约",
        "-101102400011": "调用系统[%s]解约 RMI 连接异常",
        "-101102400012": "询问[%s]失败，请联系管理员",
        "-101102400013": "验证加密串失败",
        "-101102400014": "经纪人[%s]下存在未销户的子经纪人或客户",
        "-101102400015": "会员下[%s]存在未销户的子经纪人或客户",
        "-101102400016": "[%s]提示：%s",
        "-101102409998": "对不起，服务异常，请拨打客服",
        "-101102409999": "对不起，服务异常，请拨打客服",
        "101102410002": "交易用户已经解约成功",
        "-101102410001": "传入信息是否为空",
        "-101102410002": "交易系统不允许解约",
        "-101102410003": "未找到资金结算账户所属人或者所属人状态不是已签约",
        "-101102419998": "对不起，服务异常，请拨打客服",
        "-101102419999": "对不起，服务异常，请拨打客服",
        "-101102500001": "传入信息为空",
        "-101102500002": "会员%s不存在",
        "-101102500003": "会员%s为未冻结状态",
        "-101102509998": "对不起，服务异常，请拨打客服",
        "-101102509999": "对不起，服务异常，请拨打客服",
        "-101102600001": "传入信息为空",
        "-101102600002": "会员%s不存在",
        "-101102600003": "会员%s未冻结",
        "-101102600004": "会员%s为已签约或已销户状态",
        "-101102600005": "会员%s为经纪会员并且有客户属于本会员",
        "-101102600006": "会员%s存在附属客户%s未冻结",
        "-101102600007": "联系系统管理员",
        "-101102600008": "[%s]提示：%s",
        "-101102600009": "会员%s下存在非注销的子经纪人或者客户",
        "-101102609998": "对不起，服务异常，请拨打客服",
        "-101102609999": "对不起，服务异常，请拨打客服",
        "101102700002": "部分成功",
        "-101102700001": "传入信息不完整",
        "-101102700002": "会员%s为已销户状态",
        "-101102700003": "修改会员交易系统权限失败",
        "-101102700004": "[%s]提示：%s",
        "-101102700005": "会员%s不存在",
        "-101102700006": "会员修改交易报价或发售等系统权限失败",
        "-101102709998": "对不起，服务异常，请拨打客服",
        "-101102709999": "对不起，服务异常，请拨打客服",
        "101102800003": "强制会员[%s]管理员下线失败",
        "101102800002": "强制会员[%s]下的交易员下线失败",
        "-101102800001": "传入的信息为空",
        "-101102800002": "会员[%s]不存在",
        "-101102800003": "会员[%s]为已冻结状态",
        "-101102800004": "会员[%s]下不存在交易员信息",
        "-101102809998": "对不起，服务异常，请拨打客服",
        "-101102809999": "对不起，服务异常，请拨打客服",
        "-101102900001": "传入的不完整",
        "-101102900002": "会员[%s]不存在",
        "-101102900003": "会员[%s]不是已开户或已签约状态",
        "-101102900004": "会员[%s]由经纪会员修改为非经纪会员",
        "-101102900005": "强制会员[%s]下的交易员下线失败",
        "-101102900006": "强制会员[%s]的管理员下线失败",
        "-101102900007": "询问系统不允许修改业务权限",
        "-101102900008": "询问系统[%s]不允许修改业务权限",
        "-101102900009": "经纪会员[%s]下存在子客户有系统[%s]的交易权限",
        "-101102900010": "会员[%s]由经纪会员修改为非经纪会员，其下存在经纪人",
        "101102900003": "设置会员[%s]在系统[%s]上的业务权限类型成功",
        "101102900002": "设置会员[%s]业务权限类型成功，通知系统部分成功",
        "-101102909998": "对不起，服务异常，请拨打客服",
        "-101102909999": "对不起，服务异常，请拨打客服",
        "-101102910001": "传入的信息为空",
        "-101102910002": "会员[%s]不存在",
        "-101102910003": "会员[%s]为注销状态",
        "-101102910004": "会员[%s]失败记录不存在或已经处理",
        "-101102910005": "通知其他交易系统失败",
        "-101102919998": "对不起，服务异常，请拨打客服",
        "-101102919999": "对不起，服务异常，请拨打客服",
        "-101102920001": "传入的信息为空",
        "-101102920002": "会员[%s]不存在",
        "-101102920003": "会员[%s]已销户",
        "-101102920004": "运营中心[%s]不存在",
        "-101102920005": "运营中心[%s]已注销",
        "-101102929998": "对不起，服务异常，请拨打客服",
        "-101102929999": "对不起，服务异常，请拨打客服",
        "101103000001": "添加交易员%s成功",
        "-101103000001": "传入信息不完整",
        "-101103000002": "交易员代码[%s]已存在",
        "-101103000003": "交易员所属人的%s不存在",
        "-101103000004": "交易员所属人的%s不是已开户或正常状态",
        "-101103000005": "交易员系统权限不是所属人%s子集",
        "-101103000006": "用户名[%s]被占用",
        "-101103000007": "邮箱[%s]被占用",
        "-101103000008": "手机号[%s]被占用",
        "-101103000009": "交易员[%s]不能是超级管理员类型",
        "-101103000010": "所属人类型不存在",
        "-101103000011": "所属人为客户时交易员代码有效长度为[%s]",
        "-101103000012": "所属人为会员时交易员代码有效长度为[%s]",
        "-101103000013": "输入邮箱不符合条件",
        "-101103000014": "输入手机号不符合条件",
        "-101103000015": "输入用户名不包含字母",
        "-101103000016": "输入用户名包含特殊字符",
        "-101103000017": "输入交易员代码必须是2位",
        "-101103000018": "用户名[%s]被会员预留编号占用！",
        "-101103009998": "对不起，服务异常，请拨打客服",
        "-101103009999": "对不起，服务异常，请拨打客服",
        "-101103010001": "交易员%s不存在",
        "-101103010002": "交易员%s不是正常状态",
        "-101103010003": "传入信息为空",
        "-101103019998": "对不起，服务异常，请拨打客服",
        "-101103019999": "对不起，服务异常，请拨打客服",
        "-101103020001": "传入信息不完整",
        "-101103020002": "交易员%s不存在",
        "-101103020003": "交易员%s为默认交易员",
        "-101103020004": "交易员系统权限不是所属人%s子集",
        "-101103029998": "对不起，服务异常，请拨打客服",
        "-101103029999": "对不起，服务异常，请拨打客服",
        "-101103030001": "传入的信息为空",
        "-101103030002": "交易员[%s]不存在",
        "-101103030003": "交易密码无效",
        "-101103039998": "对不起，服务异常，请拨打客服",
        "-101103039999": "对不起，服务异常，请拨打客服",
        "-101103100001": "传入的信息为空",
        "-101103100002": "费用分组名称为[%s]已存在",
        "-101103109998": "对不起，服务异常，请拨打客服",
        "-101103109999": "对不起，服务异常，请拨打客服",
        "-101103110001": "传入的信息为空",
        "-101103110002": "费用[%s]不存在",
        "-101103110003": "名称为[%s]的费用分组被占用",
        "-101103119998": "对不起，服务异常，请拨打客服",
        "-101103119999": "对不起，服务异常，请拨打客服",
        "-101103120001": "传入的信息为空",
        "-101103120002": "费用分组[%s]不存在",
        "-101103120003": "费用分组[%s]被其他用户使用",
        "-101103129998": "对不起，服务异常，请拨打客服",
        "-101103129999": "对不起，服务异常，请拨打客服",
        "-101103130001": "传入信息为空",
        "-101103130002": "删除费用分组异常",
        "-101103139998": "对不起，服务异常，请拨打客服",
        "-101103139999": "对不起，服务异常，请拨打客服",
        "-101103140001": "传入的信息为空",
        "-101103140002": "传入的信息不完整",
        "-101103140003": "费用分组[%s]不存在",
        "-101103140101": "分组[%s]的客户[%s]关系信息不存在",
        "-101103140102": "分组[%s]的所属人为[%s]客户不存在",
        "-101103140103": "分组[%s]的所属人为[%s]客户为注销状态",
        "-101103140201": "分组[%s]的会员[%s]关系信息不存在",
        "-101103140202": "分组[%s]的所属人为[%s]会员不存在",
        "-101103140203": "分组[%s]的所属人为[%s]会员为注销状态",
        "-101103149998": "对不起，服务异常，请拨打客服",
        "-101103149999": "对不起，服务异常，请拨打客服",
        "-101103150001": "传入的信息为空",
        "-101103150101": "客户[%s]下的费用分组不存在",
        "-101103150201": "会员[%s]下的费用分组不存在",
        "-101103159998": "对不起，服务异常，请拨打客服",
        "-101103159999": "对不起，服务异常，请拨打客服",
        "-101103160001": "传入信息为空",
        "-101103160002": "删除费用分组异常",
        "-101103169998": "对不起，服务异常，请拨打客服",
        "-101103169999": "对不起，服务异常，请拨打客服",
        "-101103170001": "传入信息为空",
        "-101103170002": "修改用户费用分组异常",
        "-101103170003": "批量修改用户费用分组失败，费用分组[%s]不存在！",
        "-101103170004": "批量修改用户费用分组部分成功，成功列表[%s]，失败列表[%s]！",
        "-101103179998": "对不起，服务异常，请拨打客服",
        "-101103179999": "对不起，服务异常，请拨打客服",
        "101104000003": "警告信息：经纪人[%s]在用户中心系统已经添加成功",
        "101104000002": "通知其他系统添加经纪人[%s]部分成功",
        "-101104000001": "传入的信息不合法",
        "-101104000002": "交易用户资金密码无效",
        "-101104000003": "经纪人代码[%s]长度必须为%s位",
        "-101104000004": "经纪人代码[%s]不能为[%s]",
        "-101104000005": "经纪人[%s]的手机号[%s]或邮箱[%s]被占用",
        "-101104000006": "证件号码[%s]已经被占用",
        "-101104000007": "邮箱输入不符合条件",
        "-101104000008": "手机号输入不符合条件",
        "-101104000009": "经纪人不是已签约状态",
        "-101104000010": "管理员密码无效",
        "-101104000201": "经纪人[%s]所属会员[%s]不是已签约的经纪会员",
        "-101104000202": "所属会员[%s]经纪人数量已经到达最大限制！",
        "-101104000203": "所属父经纪人[%s]类型为居间",
        "-101104000301": "所属父经纪人[%s]不存在",
        "-101104000302": "所属父经纪人[%s]不是已签约状态",
        "-101104000401": "调用资金系统添加经纪人[%s]返回失败",
        "-101104009998": "对不起，服务异常，请拨打客服",
        "-101104009999": "对不起，服务异常，请拨打客服",
        "-101104100001": "传入的信息为空",
        "-101104100002": "经纪人[%s]不存在",
        "-101104100003": "经纪人[%s]为注销状态",
        "-101104100004": "经纪人[%s]失败记录已经处理",
        "-101104100005": "继续通知经纪人%s添加信息失败",
        "101104100002": "警告信息：经纪人%s已销户",
        "101104100001": "继续通知经纪人%s添加信息部分成功",
        "-101104109998": "对不起，服务异常，请拨打客服",
        "-101104109999": "对不起，服务异常，请拨打客服",
        "-101104200001": "传入的信息不完整",
        "-101104200002": "经纪人[%s]不存在",
        "-101104200003": "经纪人[%s]为已注销状态",
        "-101104209998": "对不起，服务异常，请拨打客服",
        "-101104209999": "对不起，服务异常，请拨打客服",
        "101104300002": "强制经纪人[%s]管理员下线失败",
        "-101104300001": "传入的信息不完整",
        "-101104300002": "经纪人[%s]不存在",
        "-101104300003": "经纪人[%s]为已冻结状态",
        "-101104309998": "对不起，服务异常，请拨打客服",
        "-101104309999": "对不起，服务异常，请拨打客服",
        "-101104400001": "传入的信息不完整",
        "-101104400002": "经纪人[%s]不存在",
        "-101104400003": "经纪人[%s]为已解冻状态",
        "-101104409998": "对不起，服务异常，请拨打客服",
        "-101104409999": "对不起，服务异常，请拨打客服",
        "-101104410001": "传入信息不合法",
        "-101104410002": "经纪人[%s]不存在",
        "-101104410003": "经纪人[%s]已注销",
        "-101104410004": "经纪人[%s]存在子经纪人",
        "-101104419998": "对不起，服务异常，请拨打客服",
        "-101104419999": "对不起，服务异常，请拨打客服",
        "-101104500001": "传入的信息不完整",
        "-101104500002": "经纪人[%s]不存在",
        "-101104500003": "经纪人[%s]为已签约或已注销状态",
        "-101104500004": "经纪人[%s]为未冻结状态",
        "-101104500005": "经纪人[%s]下存在非注销状态的子经纪人或者客户",
        "-101104500006": "[%s]提示：%s",
        "-101104500401": "资金系统不允许注销经纪人",
        "-101104500501": "会员系统不允许注销经纪人",
        "-101104500601": "交易系统不允许注销经纪人",
        "-101104509998": "对不起，服务异常，请拨打客服",
        "-101104509999": "对不起，服务异常，请拨打客服",
        "-101104600001": "传入信息不完整",
        "-101104600002": "经纪人[%s]不存在",
        "-101104600003": "经纪人[%s]状态不是开户和正常状态",
        "-101104600004": "经纪人[%s]的手机号[%s]或邮箱[%s]被占用",
        "-101104600005": "输入邮箱不符合条件",
        "-101104600006": "输入手机号不符合条件",
        "-101104609998": "对不起，服务异常，请拨打客服",
        "-101104609999": "对不起，服务异常，请拨打客服",
        "101104700001": "通知会员系统修改客户所属经纪人（会员）失败",
        "-101104700001": "传入信息不合法",
        "-101104700002": "客户集合中客户[%s]不存在",
        "-101104700003": "传入的会员[%s]不存在",
        "-101104700004": "传入的会员[%s]状态不是已签约",
        "-101104700005": "传入的经纪人[%s]不存在",
        "-101104700006": "传入的经纪人[%s]状态不是已签约",
        "-101104700007": "传入的会员[%s]不是经纪会员",
        "-101104700008": "客户所属人不变或客户为已销户状态",
        "-101104700009": "新所属会员(新父经纪所属会员)的经纪权限不足",
        "-101104709998": "对不起，服务异常，请拨打客服",
        "-101104709999": "对不起，服务异常，请拨打客服",
        "101104800001": "通知会员系统父经纪人（会员）[%s]失败",
        "-101104800001": "传入信息不合法",
        "-101104800002": "经纪人[%s]不存在",
        "-101104800003": "经纪人[%s]已注销",
        "-101104800004": "新父会员[%s]不存在",
        "-101104800005": "新父会员[%s]状态不是正常（已签约）",
        "-101104800006": "新父会员[%s]状态不是经纪会员",
        "-101104800007": "新父经纪人[%s]不存在",
        "-101104800008": "新父经纪人[%s]状态不是正常（已签约）",
        "-101104800009": "新父经纪人[%s]类型不是机构",
        "-101104800010": "经纪人[%s]的子经纪人最大层级[%s]与新父经纪人[%s]的层级[%s]之和超出%s级",
        "-101104800011": "经纪人[%s]更换父经纪人后超出所属新会员的经纪人个数限制",
        "-101104800012": "经纪人[%s]为一级经纪人",
        "-101104800013": "新旧父机构经纪人[%s]相同",
        "-101104800014": "新所属会员经纪权限减少，经纪人下的客户存在[%s]交易系统权限",
        "-101104800015": "经纪人[%s]不能是本身的父机构",
        "-101104800016": "经纪人不能转到本身的子经纪人下",
        "-101104809998": "对不起，服务异常，请拨打客服",
        "-101104809999": "对不起，服务异常，请拨打客服",
        "101104900001": "经纪人（会员）[%s]通知会员系统修改客户所属经纪人（会员）[%s]失败",
        "-101104900001": "传入信息不合法",
        "-101104900002": "原所属会员[%s]不存在",
        "-101104900003": "原所属会员[%s]已注销",
        "-101104900004": "原所属经纪人[%s]不存在",
        "-101104900005": "原所属经纪人[%s]已注销",
        "-101104900006": "原所属经纪人(会员)[%s]下无客户！",
        "-101104900007": "新所属会员[%s]不存在",
        "-101104900008": "新所属会员[%s]状态不是已签约",
        "-101104900009": "新所属经纪人[%s]不存在",
        "-101104900010": "新所属经纪人[%s]状态不是已签约",
        "-101104900011": "新会员[%s]不是经纪会员",
        "-101104900012": "新所属会员(新父经纪所属会员)的经纪权限不足",
        "-101104900013": "新旧所属会员[%s]相同",
        "-101104900014": "新旧所属经纪人[%s]相同",
        "-101104909998": "对不起，服务异常，请拨打客服",
        "-101104909999": "对不起，服务异常，请拨打客服",
        "-101104910001": "传入的信息为空",
        "-101104910002": "管理员[%s]不存在",
        "-101104910003": "管理员密码无效",
        "-101104919998": "对不起，服务异常，请拨打客服",
        "-101104919999": "对不起，服务异常，请拨打客服",
        "-101105000001": "传入信息不完整",
        "-101105000002": "代为交易员%s已经存在",
        "-101105000003": "代为交易员%s代为指定交易用户但传入集合为空",
        "-101105000004": "代为交易员%s代为类型会员但传入会员编号为空",
        "-101105000005": "代为交易员%s代为类型会员但传入会员不存在",
        "-101105000006": "代为交易员%s代为可控交易用户集合数据不正确",
        "-101105000007": "代为委托员与代为强平员不能同时为否",
        "-101105009998": "对不起，服务异常，请拨打客服",
        "-101105009999": "对不起，服务异常，请拨打客服",
        "-101105100001": "传入信息不完整",
        "-101105100002": "代为交易员%s代为指定交易用户但传入集合为空",
        "-101105100003": "代为交易员%s不存在",
        "-101105100004": "代为交易员%s状态为冻结",
        "-101105100005": "代为交易员%s类型为会员但传入会员为空",
        "-101105100006": "代为交易员%s指定交易用户但传入可控交易用户信息为空",
        "-101105100007": "代为交易员%s代为可控交易用户集合数据不正确",
        "-101105109998": "操作失败， 对不起，服务异常，请拨打客服",
        "-101105109999": "操作失败， 对不起，服务异常，请拨打客服",
        "-101105200001": "传入信息为空",
        "-101105200002": "代为交易员%s不存在",
        "-101105200003": "代为交易员%s已经解冻",
        "-101105209998": "操作失败， 对不起，服务异常，请拨打客服",
        "-101105209999": "操作失败， 对不起，服务异常，请拨打客服",
        "101105300002": "调用强制线下代为交易员失败",
        "-101105300001": "传入信息为空",
        "-101105300002": "代为交易员%s不存在",
        "-101105300003": "代为交易员%s已经冻结",
        "-101105309998": "操作失败， 对不起，服务异常，请拨打客服",
        "-101105309999": "操作失败， 对不起，服务异常，请拨打客服",
        "-101105400001": "传入的信息为空",
        "-101105400002": "管理员[%s]不存在",
        "-101105400003": "管理员密码无效",
        "-101105409998": "操作失败， 对不起，服务异常，请拨打客服",
        "-101105409999": "操作失败， 对不起，服务异常，请拨打客服",
        "-101105500001": "传入信息不完整",
        "-101105500002": "代为交易员%s不存在",
        "-101105500003": "指定的用户为%s不存在",
        "-101105500004": "指定为会员%s下客户已处理",
        "-101105500005": "指定为经纪人%s下客户已处理",
        "-101105500006": "指定用户类型为会员[%s]下所有客户",
        "-101105500007": "指定用户类型为经纪人[%s]下所有客户",
        "-101105500008": "代为交易员的类型为会员时不能指定用户类型为指定会员",
        "-101105500009": "指定的客户[%s]不属于代为交易员的所属会员[%s]",
        "-101105500010": "代为交易员[%s]已指定过会员[%s]",
        "-101105500011": "代为交易员[%s]已指定过客户[%s]",
        "-101105500012": "代为交易员[%s]已指定过可控用户[%s]",
        "-101105509998": "操作失败， 对不起，服务异常，请拨打客服",
        "-101105509999": "操作失败， 对不起，服务异常，请拨打客服",
        "-101105600001": "传入信息不完整",
        "-101105600002": "代为交易员%s不存在",
        "-101105600003": "指定的用户为%s不存在",
        "-101105600004": "指定为会员%s下客户已处理",
        "-101105600005": "指定为经纪人%s下客户已处理",
        "-101105600006": "指定用户类型为会员[%s]下所有客户",
        "-101105600007": "指定用户类型为经纪人[%s]下所有客户",
        "-101105600008": "代为交易员的类型为会员时不能指定用户类型为指定会员",
        "-101105600009": "指定的客户[%s]不属于代为交易员的所属会员[%s]",
        "-101105600010": "可控用户[%s]与代为交易员[%s]的关系不存在！",
        "-101105609998": "操作失败， 对不起，服务异常，请拨打客服",
        "-101105609999": "操作失败， 对不起，服务异常，请拨打客服",
        "-101105700001": "传入的信息不完整",
        "-101105700002": "客户[%s]不存在或已销户",
        "-101105700003": "会员[%s]不存在或已销户",
        "-101105700004": "经纪人[%s]不存在或已销户",
        "-101105700005": "所属人类型[%s]所属人[%s]的资金账户不存在",
        "-101105700006": "资金结算账户[%s]对应主账户信息不存在或已销户",
        "-101105700007": "调用资金系统RMI异常",
        "-101105700008": "调用资金系统RMI修改用户资金密码失败",
        "-101105700009": "资金密码无效",
        "-101105709998": "操作失败， 对不起，服务异常，请拨打客服",
        "-101105709999": "操作失败， 对不起，服务异常，请拨打客服",
        "-101105800001": "传入信息为空",
        "-101105800002": "会员预留编号[%s]不足[%s]位",
        "-101105800003": "会员预留编号[%s]不可用",
        "-101105800004": "会员编号[%s]已存在",
        "-101105819998": "操作失败， 对不起，服务异常，请拨打客服",
        "-101105819999": "操作失败， 对不起，服务异常，请拨打客服",
        "-101105900001": "传入信息为空",
        "-101105900002": "会员预留编号[%s]信息不存在",
        "-101105900003": "会员预留编号[%s]已存在",
        "-101105919998": "操作失败， 对不起，服务异常，请拨打客服",
        "-101105919999": "操作失败， 对不起，服务异常，请拨打客服",
        "-101106000001": "传入信息为空",
        "-101106000002": "验证加密串失败",
        "-101106000003": "资金结算账号[%s]不存在",
        "-101106009998": "操作失败， 对不起，服务异常，请拨打客服",
        "-101106009999": "操作失败， 对不起，服务异常，请拨打客服",
        "-101106010001": "传入信息为空",
        "-101106010002": "传入信息不完整",
        "-101106010003": "验证加密串失败",
        "-101106010004": "获取手机验证码失败",
        "-1011060019996": "操作失败， 对不起，服务异常，请拨打客服",
        "-1011060019999": "操作失败， 对不起，服务异常，请拨打客服",
        "-101106020001": "传入信息为空",
        "-101106020002": "传入信息不完整",
        "-101106020003": "所属人[%s]的用户关系不存在",
        "-101106029998": "操作失败， 对不起，服务异常，请拨打客服",
        "-101106029999": "操作失败， 对不起，服务异常，请拨打客服",
        "-101106030001": "传入信息为空",
        "-101106030002": "传入信息不完整",
        "-101106030003": "加密串验证失败",
        "-101106030004": "交易用户[%s]不存在",
        "-101106030005": "所属用户[%s]不存在",
        "-101106030006": "所属用户已销户",
        "-101106030007": "用户已实名",
        "-101106030008": "证件号码已经被认证",
        "-101106030009": "已存在实名申请",
        "-101106030010": "用户已实名，不需要再次实名操作",
        "-101106030011": "上传证件照片失败",
        "-101106030012": "识别证件正面图片失败，请重新上传",
        "-101106039998": "操作失败， 对不起，服务异常，请拨打客服",
        "-101106039999": "操作失败， 对不起，服务异常，请拨打客服",
        "-101106040001": "传入信息为空",
        "-101106040002": "传入信息不完整",
        "-101106040003": "编号为[%s]的实名申请不存在",
        "-101106040004": "实名申请已审核",
        "-101106040005": "所属客户[%s]关系信息不存在",
        "-101106040006": "操作人信息不存在",
        "-101106040007": "不能审核自己的申请",
        "-101106040008": "实名信息不一致",
        "-101106049998": "操作失败， 对不起，服务异常，请拨打客服",
        "-101106049999": "操作失败， 对不起，服务异常，请拨打客服",
        "-101106050001": "传入信息为空",
        "-101106050002": "传入信息不完整",
        "-101106050003": "加密串验证失败",
        "-101106050004": "获取手机验证码失败",
        "-101106050005": "交易用户不存在",
        "-101106050006": "手机号不存在",
        "-101106059996": "操作失败， 对不起，服务异常，请拨打客服",
        "-101106059999": "操作失败， 对不起，服务异常，请拨打客服",
        "-101106060001": "传入信息为空",
        "-101106060002": "传入信息不完整",
        "-101106060003": "交易用户不存在",
        "-101106060004": "交易密码无效",
        "-101106060005": "手机号不存在",
        "-101106069998": "操作失败， 对不起，服务异常，请拨打客服",
        "-101106069999": "操作失败， 对不起，服务异常，请拨打客服",
        "-101106070001": "传入信息为空",
        "-101106070002": "传入信息不完整",
        "-101106070003": "交易用户不存在",
        "-101106070004": "用户[%s]不存在",
        "-101106070005": "用户已销户",
        "-101106070006": "用户昵称已存在",
        "-101106079998": "操作失败， 对不起，服务异常，请拨打客服",
        "-101106079999": "操作失败， 对不起，服务异常，请拨打客服",
        "-101106081996": "操作失败， 对不起，服务异常，请拨打客服",
        "-101106082001": "获取手机验证码失败",
        "-101106082996": "操作失败， 对不起，服务异常，请拨打客服",
        "-101106083001": "手机验证码不存在",
        "-101106083002": "请核对您的手机验证码",
        "-101106083003": "业务类型不一致",
        "-101106090001": "传入信息为空",
        "-101106090002": "传入信息不完整",
        "-101106090003": "交易用户不存在",
        "-101106090004": "所属用户[%s]不存在",
        "-101106090005": "所属用户已销户",
        "-101106090006": "上传头像失败",
        "-101106099998": "操作失败， 对不起，服务异常，请拨打客服",
        "-101106099999": "操作失败， 对不起，服务异常，请拨打客服",
        "-101106100001": "传入信息为空",
        "-101106100002": "传入信息不完整",
        "-101106100003": "交易用户不存在",
        "-101106100004": "所属用户[%s]不存在",
        "-101106100005": "所属用户已销户",
        "-101106109998": "操作失败， 对不起，服务异常，请拨打客服",
        "-101106109999": "操作失败， 对不起，服务异常，请拨打客服",
        "-101107000001": "传入信息为空",
        "-101107000002": "传入信息不完整",
        "-101107000003": "操作人不是市场管理员，不允许操作",
        "-101107000004": "运营中心[%s]已存在",
        "-101107009998": "操作失败， 对不起，服务异常，请拨打客服",
        "-101107009999": "操作失败， 对不起，服务异常，请拨打客服",
        "-101107010001": "传入信息为空",
        "-101107010002": "传入信息不完整",
        "-101107010003": "操作人不是市场管理员，不允许操作",
        "-101107010004": "运营中心[%s]不存在",
        "-101107010005": "运营中心[%s]已注销",
        "-101107010006": "注销运营中心失败，运营中心[%s]下存在会员",
        "-101107019998": "操作失败， 对不起，服务异常，请拨打客服",
        "-101107019999": "操作失败， 对不起，服务异常，请拨打客服",
        "-101107020001": "传入信息为空",
        "-101107020002": "传入信息不完整",
        "-101107020003": "操作人不是市场管理员，不允许操作",
        "-101107020004": "运营中心管理员[%s]不存在",
        "-101107020005": "管理员密码无效",
        "-101107029998": "操作失败， 对不起，服务异常，请拨打客服",
        "-101107029999": "操作失败， 对不起，服务异常，请拨打客服",
        "101231010101": "注册成功",
        "-101231010102": "验证码为空，请确认输入",
        "-101231010103": "验证码错误，请重新输入",
        "-101231010104": "图片上传大小超过限制，请重新上传",
        "101231010105": "申请用户成功，等待审核",
        "-101231010106": "手机验证码失效，请重新获取",
        "-101231010107": "手机验证码错误，请重新输入",
        "-101231010108": "%s不存在",
        "-101231010109": "%s不存在",
        "101231010110": "注册成功%s",
        "-101231010111": "页面停留时间过长,请刷新后重新操作!",
        "-101231010112": "注册失败，手机号码重复",
        "-101231010196": "对不起,连接服务失败,请稍候再试",
        "-101231010199": "注册用户未知错误",
        "-101300012000": "对不起，用户信息查询结果为空",
        "-101300013001": "对不起，用户未实名",
        "-101300013002": "对不起，请等待实名审核",
        "-101300013003": "实名信息错误",
        "-101300019996": "对不起，服务异常，请稍后重试",
        "-101300019999": "对不起，未知异常，请拨打客服",
        "-102130010000": "传入信息为空",
        "-102130010001": "传入信息不准确",
        "-102130010002": "传入信息不完整",
        "-102130010003": "当前不允许登录，请稍候",
        "-102130019997": "对不起，服务异常，请拨打客服",
        "-102130019999": "对不起，服务异常，请拨打客服",
        "-102130020000": "传入信息为空",
        "-102130020001": "传入的信息不完整",
        "-102130029997": "对不起，服务异常，请拨打客服",
        "-102130029999": "对不起，服务异常，请拨打客服",
        "-102130030000": "传入信息为空",
        "-102130030001": "传入的登录模式之间不能相互跳转",
        "-102130030002": "您没有交易系统的权限",
        "-102130030003": "通过来源系统编号查询来源系统对应的身份认证服务编号失败",
        "-102130030004": "对不起，内部网络连接不正常，请拨打客服",
        "-102130030005": "验证用户是否已经登录失败",
        "-102130030006": "您没有系统权限",
        "-102130030007": "当前不允许跳转，请稍候",
        "-102130039997": "对不起，服务异常，请拨打客服",
        "-102130039999": "对不起，服务异常，请拨打客服",
        "-102130040000": "传入信息为空",
        "-102130040001": "传入信息不完整",
        "-102130040002": "您的登录身份已失效，请重新登录",
        "-102130040003": "您没有系统权限",
        "-102130040004": "您的账号在其他地方登录,若不是您本人操作,请及时修改密码",
        "-102130040005": "您的账号在其他模式登录,若不是您本人操作,请及时修改密码",
        "-102130049996": "对不起，服务异常，请拨打客服",
        "-102130049997": "对不起，服务异常，请拨打客服",
        "-102130049999": "对不起，服务异常，请拨打客服",
        "-102130070000": "传入信息为空",
        "-102130070001": "传入信息不完整",
        "-102130079997": "对不起，服务异常，请拨打客服",
        "-102130079999": "对不起，服务异常，请拨打客服",
        "-102130080001": "用户%s清空失败",
        "-102130089997": "对不起，服务异常，请拨打客服",
        "-102130089999": "对不起，服务异常，请拨打客服",
        "-102180010001": "请稍后查询",
        "-102180020001": "传入的退出信息为空",
        "-102180020002": "没有登录的交易用户信息",
        "-102180029996": "强制退出用户失败",
        "-102190010000": "用户登录传入信息为空",
        "-102190010001": "用户名或密码错误",
        "-102190010002": "Key盘代码错误",
        "-102190010003": "密码输入错误次数过多，账户已锁定请联系管理员",
        "-102190010004": "用户名或密码错误，您还可以尝试%s次",
        "-102190010005": "交易员被禁用",
        "-102190010006": "查询所属客户失败",
        "-102190010007": "客户已销户",
        "-102190010008": "客户被冻结",
        "-102190010009": "所属会员不存在",
        "-102190010010": "会员已销户",
        "-102190010011": "会员被冻结",
        "-102190010012": "您没有指定系统[%s]权限",
        "-102190010013": "获取系统[%s]对应身份认证服务失败",
        "-102190010014": "用户名或密码错误",
        "-102190019996": "对不起，服务异常，请拨打客服",
        "-102190019997": "对不起，服务异常，请拨打客服",
        "-102190019998": "对不起，服务异常，请拨打客服",
        "-102190019999": "对不起，服务异常，请拨打客服",
        "-102190050000": "传入信息为空",
        "-102190050001": "用户不存在",
        "-102190050002": "修改交易密码失败，原交易密码错误",
        "-102190050003": "新密码无效",
        "-102190059997": "对不起，服务异常，请拨打客服",
        "-102190059998": "对不起，服务异常，请拨打客服",
        "-102190059999": "对不起，服务异常，请拨打客服",
        "-102190120000": "传入信息为空",
        "-102190120001": "交易员不存在",
        "-102190120002": "密码输入次数过多",
        "-102190120003": "密码错误",
        "-102190120004": "查询所属客户失败",
        "-102190120005": "所属客户已注销",
        "-102190120006": "客户被冻结",
        "-102190120007": "查询所属会员失败",
        "-102190129997": "对不起，服务异常，请拨打客服",
        "-102190129998": "对不起，服务异常，请拨打客服",
        "-102190129999": "对不起，服务异常，请拨打客服",
        "-102190139997": "对不起，服务异常，请拨打客服",
        "-102190139998": "对不起，服务异常，请拨打客服",
        "-102190139999": "对不起，服务异常，请拨打客服",
        "-102190149997": "对不起，服务异常，请拨打客服",
        "-102190149998": "对不起，服务异常，请拨打客服",
        "-102190149999": "对不起，服务异常，请拨打客服",
        "-103100000001": "操作人类型传入错误",
        "-103100000002": "系统暂停出入金业务",
        "-103100000003": "非出入金时间",
        "-103100000004": "操作失败，您还未修改过原始资金密码！",
        "-103100010101": "传入信息不完整",
        "-103100010201": "申请强制修改用户银行卡信息失败，限制类型为[%s]操作人[%s]调用",
        "-103100010202": "申请强制修改用户银行卡信息失败，验证类型为[%s]操作人[%s]失败",
        "-103100010301": "申请强制修改用户银行卡信息失败，传入的资金账号[%s]和银行编号[%s]组合不存在",
        "-103100010302": "申请强制修改用户银行卡信息失败，传入的资金账号[%s]和银行编号[%s]关系不是已签约或者预解约",
        "-103100010503": "申请强制修改用户银行卡信息失败，对于资金账号[%s]和银行编号[%s]组合修改卡号申请已经存在",
        "-103100019997": "操作失败，请联系管理员",
        "-103100019998": "操作失败，请联系管理员",
        "-103100019999": "操作失败，请联系管理员",
        "-103100020101": "审核强制修改用户银行卡信息失败，传入信息不完整",
        "-103100020201": "审核强制修改用户银行卡信息失败，限制类型为[%s]操作人[%s]调用",
        "-103100020202": "审核强制修改用户银行卡信息失败，验证类型为[%s]操作人[%s]失败",
        "-103100020301": "审核强制修改用户银行卡信息失败，审核的资金账号[%s]和银行编号[%s]关系不是已签约或者预解约",
        "-103100020501": "审核强制修改用户银行卡信息失败，传入的申请编号[%s]不存在",
        "-103100020502": "审核强制修改用户银行卡信息失败，修改申请[%s]状态已经处理",
        "-103100020503": "审核强制修改用户银行卡信息失败，申请人和审核人不能相同",
        "103100020701": "第[%s]次审核强制修改用户银行卡信息成功",
        "-103100029997": "操作失败，请联系管理员",
        "-103100029998": "操作失败，请联系管理员",
        "-103100029999": "操作失败，请联系管理员",
        "-103100030101": "传入信息不完整",
        "-103100030201": "申请强制修改用户银行卡信息失败，限制类型为[%s]操作人[%s]调用",
        "-103100030202": "申请强制修改用户银行卡信息失败，验证类型为[%s]操作人[%s]失败",
        "-103100030301": "申请强制更改用户签约银行状态失败，资金结算账号[%s]和银行编号[%s]绑定关系状态已修改",
        "-103100030302": "申请强制更改用户签约银行状态失败，状态不能强制修改为预签约或预解约",
        "-103100030501": "您未做任何修改，请修改后重试",
        "-103100030502": "申请强制更改用户签约银行状态失败，该银行当天已有出入金",
        "-103100030503": "申请强制更改用户签约银行状态失败，还有未解约的次银行",
        "-103100030504": "申请强制更改用户签约银行状态失败，如果要修改此银行状态为已签约，需先解约其他银行！",
        "-103100039997": "操作失败，请联系管理员",
        "-103100039998": "操作失败，请联系管理员",
        "-103100039999": "操作失败，请联系管理员",
        "-103100040101": "申请强制修改用户银行卡信息失败，传入信息不完整",
        "-103100040201": "限制类型为[%s]操作人[%s]调用",
        "-103100040202": "验证类型为[%s]操作人[%s]失败",
        "-103100040301": "审核强制更改用户绑定银行状态失败，资金结算账号[%s]和银行编号[%s]绑定关系状态已处理",
        "-103100040501": "审核强制更改用户绑定银行状态失败，传入的申请编号[%s]不存在",
        "-103100040502": "审核强制更改用户绑定银行状态失败，申请[%s]已经处理",
        "-103100040503": "审核强制更改用户绑定银行状态失败，状态不能强制修改为预签约或预解约",
        "-103100040504": "审核强制更改用户绑定银行状态失败，如果要修改此银行状态为已签约，需先解约其他银行！",
        "103100040701": "第[%s]次审核强制更改用户绑定银行状态成功",
        "-103100049997": "操作失败，请联系管理员",
        "-103100049998": "操作失败，请联系管理员",
        "-103100049999": "操作失败，请联系管理员",
        "-103100050101": "传入信息不完整",
        "-103100050201": "审核冲正信息处理结果失败，限制类型为[%s]操作人[%s]调用",
        "-103100050202": "审核冲正信息处理结果失败，验证类型为[%s]操作人[%s]失败",
        "-103100050501": "审核冲正信息处理结果失败，冲正记录[%s]不存在",
        "-103100050502": "审核冲正信息处理结果失败，冲正记录[%s]已处理",
        "-103100050503": "审核冲正信息处理结果失败，冲正记录[%s]对应的出入金记录不存在",
        "-103100050504": "冲正流水%s当前不能执行本次审核",
        "-103100050505": "审核失败，未查询到冲正流水的上次审核记录(第[%s]次)",
        "-103100050506": "同一笔冲正，您不能连续两次审核",
        "-103100050507": "审核冲正信息处理结果成功，其对应的出入金流水%s不是成功状态",
        "-103100050508": "审核冲正信息处理结果失败，市场不允许入金冲正在未冻结成功的情况下进行审核！",
        "103100050701": "第%s次审核冲正信息[%s]成功",
        "-103100059997": "操作失败，请联系管理员",
        "-103100059998": "操作失败，请联系管理员",
        "-103100059999": "操作失败，请联系管理员",
        "-103100060101": "审核单边账处理结果失败，传入信息不完整",
        "-103100060201": "审核单边账处理结果失败，限制类型为[%s]操作人[%s]调用",
        "-103100060202": "审核单边账处理结果失败，验证类型为[%s]操作人[%s]失败",
        "-103100060601": "审核单边账处理结果失败，出入金记录[%s]不存在",
        "-103100060602": "审核单边账处理结果失败，出入金记录[%s]不是单边账",
        "-103100060603": "审核单边账处理结果失败，交易日期[%s]该银行的银行流水[%s]的流水[%s]已存在",
        "-103100060604": "审核单边账处理结果失败，验证签名信息失败",
        "-103100069997": "操作失败，请联系管理员",
        "-103100069998": "操作失败，请联系管理员",
        "-103100069999": "操作失败，请联系管理员",
        "-103100070101": "添加入金流水失败，传入信息不完整",
        "-103100070201": "添加入金流水失败，限制类型为[%s]操作人[%s]调用",
        "-103100070202": "添加入金流水失败，验证类型为[%s]操作人[%s]失败",
        "-103100070301": "添加入金流水失败，签约账号[%s]不存在",
        "-103100070302": "添加入金流水失败，单边账已对账完成",
        "-103100070401": "申请入金流水已经处理成功，不能重新申请！",
        "-103100070402": "申请入金流水已经处理成功或正在处理中，不能重新申请！",
        "-103100070403": "申请入金流水添加失败！",
        "103100070404": "申请入金流水添加成功！",
        "-103100070405": "申请入金流水已经处理失败，验证签名信息失败！",
        "-103100070601": "添加入金流水失败，交易日期[%s]该银行流水[%s]的入金流水[%s]已存在",
        "-103100070602": "添加入金流水失败，银行流水编号已存在",
        "-103100070603": "添加入金流水失败，经纪人不能进行入金",
        "-103100070604": "添加入金流水失败，用户没有签约该银行",
        "-103100070605": "添加入金流水失败，用户没有绑定该银行[%s]",
        "-103100079997": "操作失败，请联系管理员",
        "-103100079998": "操作失败，请联系管理员",
        "-103100079999": "操作失败，请联系管理员",
        "-103100080101": "传入信息不完整",
        "-103100080201": "撤销出金流水失败，限制类型为[%s]操作人[%s]调用",
        "-103100080202": "撤销出金流水失败，验证类型为[%s]操作人[%s]失败",
        "-103100080301": "申请撤销出金流水已经处理成功或正在处理中，不能重新申请！",
        "-103100080302": "申请撤销出金流水添加失败！",
        "-103100080303": "申请撤销出金流水添加成功！",
        "103100080304": "撤销出金流水失败，原流水不存在或流水处理状态不是成功！",
        "-103100080601": "撤销出金流水失败，出金流水[%s]不存在",
        "-103100080602": "撤销出金流水失败，出入金流水[%s]转账失败或者为单边账，不需要撤销！",
        "-103100080603": "撤销出金流水失败，出入金流水[%s]不是出金",
        "-103100080604": "撤销出金流水失败，单边账已对账完成",
        "-103100089997": "操作失败，请联系管理员",
        "-103100089998": "操作失败，请联系管理员",
        "-103100089999": "操作失败，请联系管理员",
        "-103100090101": "传入信息不完整",
        "-103100090201": "更改银行信息失败，限制类型为[%s]操作人[%s]调用",
        "-103100090202": "更改银行信息失败，验证类型为[%s]操作人[%s]失败",
        "-103100090204": "更改银行信息失败，银行编号为空",
        "-103100090205": "修改银行签到退时间，通知银行[%s]适配器失败！",
        "-103100090206": "修改银行签到退时间，通知银行[%s]适配器返回失败！",
        "-103100090997": "操作失败，请联系管理员",
        "-103100090998": "操作失败，请联系管理员",
        "-103100090999": "操作失败，请联系管理员",
        "-103100100101": "修改用户入金状态失败，传入信息不完整",
        "-103100100201": "修改用户入金状态失败，限制类型为[%s]操作人[%s]调用",
        "-103100100202": "修改用户入金状态失败，验证类型为[%s]操作人[%s]失败",
        "-103100100301": "修改用户入金状态失败，资金结算账号编号[%s]不存在",
        "-103100100302": "修改用户入金状态失败，资金结算账号状态已经为要修改的状态",
        "-103100109997": "操作失败，请联系管理员",
        "-103100109998": "操作失败，请联系管理员",
        "-103100109999": "操作失败，请联系管理员",
        "-103100110101": "更改资金结算账号资金信息失败，传入信息不完整",
        "-103100110201": "更改资金结算账号资金信息失败，限制类型为[%s]操作人[%s]调用",
        "-103100110202": "更改资金结算账号资金信息失败，验证类型为[%s]操作人[%s]失败",
        "-103100110301": "更改资金结算账号资金信息失败，资金结算账号[%s]不存在",
        "-103100119997": "操作失败，请联系管理员",
        "-103100119998": "操作失败，请联系管理员",
        "-103100119999": "操作失败，请联系管理员",
        "-103100120101": "修改用户出金状态失败，传入信息不完整",
        "-103100120201": "修改用户出金状态失败，限制类型为[%s]操作人[%s]调用",
        "-103100120202": "修改用户出金状态失败，验证类型为[%s]操作人[%s]失败",
        "-103100120301": "修改用户出金状态失败，资金结算账号编号[%s]不存在",
        "-103100120302": "修改用户出金状态失败，资金结算账号编号[%s]修改的“出金状态”已经是该状态",
        "-103100129997": "操作失败，请联系管理员",
        "-103100129998": "操作失败，请联系管理员",
        "-103100129999": "操作失败，请联系管理员",
        "103100130001": "修改资金结算账户[%s]状态成功",
        "-103100130101": "传入信息不完整",
        "-103100130201": "修改资金结算账户状态失败，限制类型为[%s]操作人[%s]调用",
        "-103100130202": "修改资金结算账户状态失败，验证类型为[%s]操作人[%s]失败",
        "-103100130301": "修改资金结算账户状态失败，资金结算账号编号[%s]不存在",
        "-103100130302": "修改资金结算账户状态失败，资金结算账号[%s]修改的状态已经是该状态",
        "-103100139998": "操作失败，请联系管理员",
        "-103100139999": "操作失败，请联系管理员",
        "-103100140101": "传入信息不完整",
        "-103100140201": "更改资金结算账号用户信息失败，限制类型为[%s]操作人[%s]调用",
        "-103100140202": "更改资金结算账号用户信息失败，验证类型为[%s]操作人[%s]失败",
        "-103100140301": "更改资金结算账号用户信息失败，资金结算账户[%s]不存在",
        "-103100140302": "更改资金结算账号用户信息失败，资金结算账户[%s]已经签约过不能修改用户信息",
        "-103100149998": "操作失败，请联系管理员",
        "-103100149999": "操作失败，请联系管理员",
        "-103100150101": "修改用户状态失败，传入信息不完整",
        "-103100150201": "修改用户状态失败，限制类型为[%s]操作人[%s]调用",
        "-103100150202": "修改用户状态失败，验证类型为[%s]操作人[%s]失败",
        "-103100150301": "修改用户状态失败，所属人[%s]不存在",
        "-103100150302": "修改用户状态失败，所属人[%s]状态已经为该状态",
        "-103100159998": "操作失败，请联系管理员",
        "-103100159999": "操作失败，请联系管理员",
        "-103100160101": "重新通知用户中心失败，传入信息不完整",
        "-103100160201": "重新通知用户中心失败，限制类型为[%s]操作人[%s]调用",
        "-103100160202": "重新通知用户中心失败，验证类型为[%s]操作人[%s]失败",
        "-103100160801": "重新通知用户中心失败，不存在此条通知[%s]的记录",
        "-103100160802": "重新通知用户中心失败，此条通知[%s]已经处理",
        "-103100160901": "重新通知用户中心失败，通知返回失败",
        "-103100160902": "重新通知用户中心失败，连接用户中心失败",
        "-103100169996": "操作失败，请联系管理员",
        "-103100169998": "操作失败，请联系管理员",
        "-103100169999": "操作失败，请联系管理员",
        "-103100170101": "传入信息不完整",
        "-103100170201": "出金审核失败，限制类型为[%s]操作人[%s]调用",
        "-103100170202": "出金审核失败，验证类型为[%s]操作人[%s]失败",
        "-103100170301": "操作失败，密文验证未通过！",
        "-103100170501": "申请编号：%s，出金审核失败，原因：自动审核时：审核次数为0",
        "-103100170502": "同一笔出金，您不能连续两次审核",
        "-103100170503": "申请编号：%s，出金审核失败，原因：出入金流水表中没有此流水",
        "-103100170504": "申请编号：%s，出金审核失败，原因：出入金流水表中的状态不是“待审核”",
        "-103100170505": "申请编号：%s，出金审核失败，原因：审核流水表中没有审核记录",
        "-103100170506": "申请编号：%s，出金审核失败，原因：出入金流水表中的状态不是“待审核”",
        "-103100170507": "出金审核失败，用户被禁止出金",
        "-103100170508": "出金审核失败，用户被禁用",
        "-103100170509": "出金审核失败，银行被禁用",
        "-103100170510": "出金审核失败，银行未签到",
        "-103100170511": "出金审核失败，验证出金流水签名信息失败",
        "-103100170512": "出金审核失败，验证审核流水签名信息失败",
        "103100170701": "第[%s]次出金[%s]审核成功",
        "103100170702": "审核成功，调用适配器出金失败！",
        "103100170703": "审核成功，调用适配器处理结果未知！",
        "103100170704": "审核成功，银行处理中",
        "-103100179997": "操作失败，请联系管理员",
        "-103100179998": "操作失败，请联系管理员",
        "-103100179999": "操作失败，请联系管理员",
        "-103100180101": "添加出金流水失败，传入信息不完整",
        "-103100180201": "添加出金流水失败，限制类型为[%s]操作人[%s]调用",
        "-103100180202": "添加出金流水失败，验证类型为[%s]操作人[%s]失败",
        "-103100180301": "添加出金流水失败，签约账号[%s]不存在！",
        "-103100180302": "添加出金流水失败，用户没有绑定该银行",
        "-103100180303": "添加出金流水失败，用户没有签约该银行",
        "-103100180304": "添加出金流水失败，单边账已对账完成",
        "-103100180401": "申请出金流水已经处理成功，不能重新申请！",
        "-103100180402": "申请出金流水已经处理成功或正在处理中，不能重新申请！",
        "-103100180403": "申请出金流水添加失败！",
        "103100180404": "申请出金流水添加成功！",
        "-103100180405": "申请出金流水添加失败，签名信息验证失败！",
        "-103100180601": "交易日期[%s]该银行流水[%s]的出金流水[%s]已存在",
        "-103100180602": "可用余额不足",
        "-103100189997": "操作失败，请联系管理员",
        "-103100189998": "操作失败，请联系管理员",
        "-103100189999": "操作失败，请联系管理员",
        "-103100190101": "撤销入金流水失败，传入信息不完整",
        "-103100190201": "撤销入金流水失败，限制类型为[%s]操作人[%s]调用",
        "-103100190202": "撤销入金流水失败，验证类型为[%s]操作人[%s]失败",
        "-103100190301": "撤销入金申请流水已经处理成功或正在处理，不能重新申请！",
        "-103100190302": "添加撤销入金流水记录失败！",
        "103100190303": "添加撤销入金流水记录成功！",
        "-103100190304": "撤销入金流水失败，原流水不存在或流水状态不是成功！",
        "-103100190601": "撤销入金流水失败，出入金流水[%s]不存在",
        "-103100190602": "撤销入金流水失败，出入金流水[%s]转账失败或者为单边账，不需要撤销",
        "-103100190603": "撤销入金流水失败，出入金流水[%s]不是入金",
        "-103100190604": "撤销入金流水失败，可用余额不足",
        "-103100190605": "撤销入金流水失败，单边账已对账完成",
        "-103100199997": "操作失败，请联系管理员",
        "-103100199998": "操作失败，请联系管理员",
        "-103100199999": "操作失败，请联系管理员",
        "-103100200101": "银行签退失败，传入信息不完整",
        "-103100200201": "限制类型为[%s]操作人[%s]调用",
        "-103100200202": "验证类型为[%s]操作人[%s]失败",
        "-103100209997": "操作失败，请联系管理员",
        "-103100209998": "操作失败，请联系管理员",
        "-103100209999": "操作失败，请联系管理员",
        "-103100210101": "银行签到失败，传入信息不完整",
        "-103100210201": "银行签到失败，限制类型为[%s]操作人[%s]调用",
        "-103100210202": "银行签到失败，验证类型为[%s]操作人[%s]失败",
        "-103100210301": "银行签到退失败，调用银行[%s]适配器失败！",
        "-103100210302": "银行签到退失败，适配器处理结果未知！",
        "-103100210303": "银行签到退失败，适配器处理失败！",
        "-103100219997": "操作失败，请联系管理员",
        "-103100219998": "操作失败，请联系管理员",
        "-103100219999": "操作失败，请联系管理员",
        "-103100220101": "调拨轧差失败，传入信息不完整",
        "-103100220201": "限制类型为[%s]操作人[%s]调用",
        "-103100220202": "验证类型为[%s]操作人[%s]失败",
        "-103100220203": "来源银行或去向银行编号不存在",
        "-103100229997": "操作失败，请联系管理员",
        "-103100229998": "操作失败，请联系管理员",
        "-103100229999": "操作失败，请联系管理员",
        "-103100230101": "提取银行手续费失败，传入信息不完整",
        "-103100230201": "提取银行手续费失败，限制类型为[%s]操作人[%s]调用",
        "-103100230202": "提取银行手续费失败，验证类型为[%s]操作人[%s]失败",
        "-103100230203": "提取银行手续费失败，银行[%s]不存在！",
        "-103100239997": "操作失败，请联系管理员",
        "-103100239998": "操作失败，请联系管理员",
        "-103100239999": "操作失败，请联系管理员",
        "-103100240101": "传入信息不完整",
        "-103100240201": "审核预签约失败，限制类型为[%s]操作人[%s]调用",
        "-103100240202": "审核预签约失败，验证类型为[%s]操作人[%s]失败",
        "-103100240203": "审核预签约失败，银行不存在",
        "-103100249997": "操作失败，请联系管理员",
        "-103100249998": "操作失败，请联系管理员",
        "-103100249999": "操作失败，请联系管理员",
        "-103100250101": "审核预解约失败，传入信息不完整",
        "-103100250201": "审核预解约失败，限制类型为[%s]操作人[%s]调用",
        "-103100250202": "审核预解约失败，验证类型为[%s]操作人[%s]失败",
        "-103100250203": "审核预解约失败，银行[%s]不存在！",
        "-103100259997": "操作失败，请联系管理员",
        "-103100259998": "操作失败，请联系管理员",
        "-103100259999": "操作失败，请联系管理员",
        "-103100260101": "调整银行手续费失败，传入信息不完整",
        "-103100260201": "调整银行手续费失败，限制类型为[%s]操作人[%s]调用",
        "-103100260202": "调整银行手续费失败，验证类型为[%s]操作人[%s]失败",
        "-103100260203": "调整银行手续费失败，银行编号不存在",
        "-103100269997": "操作失败，请联系管理员",
        "-103100269998": "操作失败，请联系管理员",
        "-103100269999": "操作失败，请联系管理员",
        "-103100270101": "调整轧差失败，传入信息不完整",
        "-103100270201": "调整轧差失败，限制类型为[%s]操作人[%s]调用",
        "-103100270202": "调整轧差失败，验证类型为[%s]操作人[%s]失败",
        "-103100270203": "调整轧差失败，银行编号不存在",
        "-103100279997": "操作失败，请联系管理员",
        "-103100279998": "操作失败，请联系管理员",
        "-103100279999": "操作失败，请联系管理员",
        "-103101010101": "查询市场大账号余额失败，传入信息不完整",
        "-103101010201": "查询市场大账号余额失败，限制类型为[%s]操作人[%s]调用",
        "-103101010202": "查询市场大账号余额失败，验证类型为[%s]操作人[%s]失败",
        "-103101010301": "查询市场大账号余额失败，无结算账号与类型为[%s]的操作人[%s]对应关系",
        "-103101010302": "查询市场大账号余额失败，资金密码错误",
        "-103101010303": "查询市场大账号余额 失败，出入金业务被禁用",
        "-103101019998": "操作失败，请联系管理员",
        "-103101019999": "操作失败，请联系管理员",
        "-103101020101": "传入信息不完整",
        "-103101020201": "查询市场余额信息失败，验证类型为[%s]操作人[%s]失败",
        "-103101020202": "查询市场余额信息失败，限制类型为[%s]操作人[%s]调用",
        "-103101020301": "查询市场余额信息失败,未查到资金结算账号[%s]信息",
        "-103101020302": "查询市场余额信息失败,资金密码错误",
        "-103101020303": "查询市场余额信息失败,未查到类型为[%s]的用户[%s]结算账号信息",
        "-103101020304": "查询市场余额信息失败,未查到资金信息",
        "-103101020305": "查询市场余额信息失败,类型为[%s]的用户[%s]结算账号被禁用",
        "-103101020901": "查询市场余额信息失败,连接财务系统失败！",
        "-103101029998": "操作失败，请联系管理员",
        "-103101029999": "操作失败，请联系管理员",
        "-103101030101": "传入信息不完整",
        "-103101030202": "查询银行余额失败，限制类型为[%s]操作人[%s]调用",
        "-103101030203": "查询银行余额失败，验证类型为[%s]操作人[%s]失败",
        "-103101030301": "查询银行余额失败，无结算账号与类型为[%s]的操作人[%s]对应关系",
        "-103101030302": "查询银行余额失败，资金密码不正确",
        "-103101030303": "查询银行余额失败，类型为[%s]的操作人[%s]被禁用",
        "-103101030304": "查询银行余额失败，资金结算账号[%s]被禁用",
        "-103101030305": "查询银行余额失败，结算账号[%s]和银行[%s]无绑定关系！",
        "-103101030401": "查询银行余额失败，银行编号不存在",
        "-103101030402": "查询银行余额失败，银行[%s]无查询银行余额方法！",
        "-103101030403": "查询银行余额失败，银行不存在",
        "-103101030404": "查询银行余额失败，银行被禁用",
        "-103101030405": "查询银行余额失败，银行已签退",
        "-103101039996": "操作失败，请联系管理员",
        "-103101039998": "操作失败，请联系管理员",
        "-103101039999": "操作失败，请联系管理员",
        "-103101040101": "查询市场和银行余额失败，传入信息不完整",
        "-103101040201": "查询市场和银行余额失败，验证类型为[%s]操作人[%s]失败",
        "-103101040202": "查询市场和银行余额失败，限制类型为[%s]操作人[%s]调用",
        "103101040701": "查询类型[%s]用户[%s]市场和银行余额成功",
        "-103101041001": "查询类型[%s]用户[%s]市场和银行余额失败",
        "-103101049998": "操作失败，请联系管理员",
        "-103101049999": "操作失败，请联系管理员",
        "-103101050101": "签约失败，传入信息不完整",
        "-103101050201": "签约失败，验证类型为[%s]操作人[%s]失败",
        "-103101050202": "签约失败，限制类型为[%s]操作人[%s]调用",
        "-103101050301": "签约失败，无结算账号与类型为[%s]的操作人[%s]对应关系",
        "-103101050302": "签约失败，输入资金密码错误",
        "-103101050304": "签约失败，结算账号[%s]被禁用",
        "-103101050305": "签约失败，银行和资金结算账号[%s]绑定关系已经存在且状态不是已解约或者未签约",
        "-103101050306": "签约失败，结算账号[%s]的结算账号信息不存在",
        "-103101050307": "您已经签约[%s]，如果要签约当前银行，必须先解约已签约的银行！",
        "-103101050308": "签约失败，结算账号[%s]未实名认证！",
        "-103101050401": "签约失败，银行不是签约时间段",
        "-103101050402": "签约失败，银行[%s]不存在",
        "-103101050403": "签约失败，银行[%s]被禁用！",
        "-103101050404": "签约失败，银行[%s]不支持市场端签约",
        "-103101050405": "签约失败，当前不允许签约",
        "103101050701": "预签约成功，请等待银行处理",
        "103101050702": "签约成功，通知用户中心失败！",
        "-103101050702": "签约失败，调用适配器签约失败！",
        "103101050704": "签约部分成功，通知用户中心失败！",
        "-103101050901": "签约失败，调用财务系统接口查询资金结算账号[%s]期初余额失败",
        "-103101050902": "签约失败，系统不支持绑定多个银行",
        "-103101059996": "操作失败，请联系管理员",
        "-103101059998": "签约失败，返回错误编号[103101059998]",
        "-103101059999": "操作失败，请联系管理员",
        "-103101060101": "传入信息不完整",
        "-103101060201": "银行发起改约失败，验证类型为[%s]操作人[%s]失败",
        "-103101060202": "银行发起改约失败，限制类型为[%s]操作人[%s]调用",
        "-103101060301": "市场发起改约失败，无结算账号与类型为[%s]的操作人[%s]对应关系",
        "-103101060302": "市场发起改约失败，输入资金密码错误",
        "-103101060303": "市场发起改约失败，未签约银行[%s]！",
        "-103101060304": "市场发起改约失败，用户和银行[%s]绑定关系不是签约状态！",
        "-103101060305": "银行发起改约失败，结算账号[%s]不存在",
        "-103101060306": "银行发起改约失败，结算账号[%s]被禁用",
        "-103101060307": "市场发起改约失败，类型[%s]的用户[%s]被禁用",
        "-103101060308": "市场发起改约失败，改约传入的银行卡和原银行卡号一致！",
        "103101060309": "市场发起改约，银行[%s]处理中！",
        "-103101060401": "市场发起改约失败，银行[%s]不存在！",
        "-103101060402": "市场发起改约失败，银行[%s]被禁用！",
        "-103101060403": "市场发起改约失败，银行不是改约时间段！",
        "-103101060404": "市场发起改约失败，银行[%s]不支持市场端改约！",
        "-103101060901": "市场发起改约失败，调用银行改约失败",
        "-103101069996": "操作失败，请联系管理员",
        "-103101069998": "操作失败，请联系管理员",
        "-103101069999": "操作失败，请联系管理员",
        "103101070004": "入金已通知银行，请等待银行处理",
        "103101070005": "入金成功，资金划转失败",
        "-103101070101": "市场入金失败，传入信息不完整",
        "-103101070201": "市场入金失败，限制类型为[%s]操作人[%s]调用！",
        "-103101070202": "市场入金失败，验证类型为[%s]操作人[%s]失败！",
        "-103101070301": "结算账号不存在",
        "-103101070302": "出入金被禁用",
        "-103101070303": "资金账号被禁用",
        "-103101070304": "资金账号入金被禁用",
        "-103101070305": "用户未绑定银行",
        "-103101070306": "用户绑定银行非签约状态",
        "-103101070307": "资金密码错误",
        "-103101070401": "银行不存在",
        "-103101070402": "银行未到交易时间",
        "-103101070403": "银行交易时间已过",
        "-103101070404": "银行不在业务时间段",
        "-103101070405": "银行当前不允许入金",
        "-103101070406": "没有市场端发起入金权限",
        "-103101070601": "根据银行结果处理入金，入金流水%s不存在",
        "-103101070602": "根据银行结果处理入金，入金流水%s当前状态不可处理",
        "-103101070603": "入金金额不能小于等于入金手续费",
        "-103101070604": "用户绑定银行无银行卡号",
        "-103101070605": "签名信息不正确！",
        "-103101070901": "市场入金失败，调用银行[%s]异常！",
        "-103101071001": "市场入金失败，当天为非交易日",
        "-103101079998": "市场入金失败，返回错误编号[103101079998]！",
        "-103101079999": "市场入金失败，返回错误编号[103101079999]！",
        "-103101080101": "更改资金结算账号用户信息失败，传入信息不完整",
        "-103101080201": "更改资金结算账号用户信息失败，限制类型为[%s]操作人[%s]调用",
        "-103101080202": "更改资金结算帐号用户信息失败，验证类型为[%s]操作人[%s]失败！",
        "-103101080301": "已经签约过不能修改用户信息",
        "-103101080302": "不存在所属人的结算账户信息",
        "-103101089998": "操作失败，请联系管理员",
        "-103101089999": "操作失败，请联系管理员",
        "-103101090101": "传入信息不完整",
        "-103101090102": "新的资金密码不是6位",
        "-103101090103": "新的资金密码不全是数字",
        "-103101090201": "限制操作人[%s]调用",
        "-103101090202": "验证操作人[%s]登录失败",
        "-103101090301": "用户不存在",
        "-103101090302": "修改资金密码失败，原始密码错误",
        "-103101099998": "前台发起更改资金密码失败，返回错误编号[103101099998]！",
        "-103101099999": "前台发起更改资金密码失败，返回错误编号[103101099999]！",
        "-103101100101": "传入信息不完整",
        "-103101100201": "绑定银行失败，限制类型为[%s]操作人[%s]调用",
        "-103101100202": "绑定银行失败，验证类型为[%s]操作人[%s]失败，%s",
        "-103101100301": "查询用户%s的结算账号失败",
        "-103101100302": "您的账户当前禁止做银行业务",
        "-103101109998": "操作失败，请联系管理员",
        "-103101109999": "操作失败，请联系管理员",
        "-103101110101": "传入信息不完整",
        "-103101110201": "更改资金结算账号用户信息失败，限制类型为[%s]操作人[%s]调用",
        "-103101110202": "更改资金结算账号用户信息失败，验证类型为[%s]操作人[%s]失败，%s",
        "-103101119998": "操作失败，请联系管理员",
        "-103101119999": "操作失败，请联系管理员",
        "-103101120101": "市场出金失败，传入信息不合法！",
        "-103101120201": "市场出金失败，限制类型为[%s]操作人[%s]调用！",
        "-103101120202": "市场出金失败，验证类型为[%s]操作人[%s]失败",
        "-103101120301": "所属人的结算账号不存在",
        "-103101120302": "用户出入金被禁用",
        "-103101120303": "用户未绑定银行",
        "-103101120304": "用户绑定银行非签约状态",
        "-103101120305": "资金账号被禁用",
        "-103101120306": "资金账号出金被禁用",
        "-103101120307": "资金密码错误",
        "-103101120401": "银行不存在",
        "-103101120402": "银行未到交易时间",
        "-103101120403": "银行交易时间已过",
        "-103101120405": "银行不在业务时间段",
        "-103101120406": "银行当前不允许出金",
        "-103101120407": "银行没有市场端发起出金权限",
        "-103101120408": "银行没有市场端发起出金权限",
        "-103101120409": "出金失败，本次出金需要银行密码并且需要审核，而审核时不能记录您的银行密码！",
        "-103101120410": "银行被禁用",
        "-103101120601": "余额不足以支付出金%s和手续费%s",
        "-103101120602": "用户[%s]资金系统[%s]余额不足以支付出金[%s]和手续费[%s]！",
        "-103101120603": "银行编号%s用户%s的资金系统%s不存在",
        "-103101120604": "执行出金处理失败，出金流水%s未查到",
        "-103101120605": "执行出金处理失败，流水%s不是出金流水",
        "-103101120606": "执行出金处理失败，流水%s已处理",
        "-103101120607": "从用户%s资金池%s划转资金到大池失败！",
        "-103101120608": "用户%s在%s可出金额不足",
        "-103101120609": "查询结算账号主账户失败",
        "-103101120610": "验证加密串信息失败！",
        "103101120701": "出金申请成功，请等待审核",
        "-103101120701": "出金金额超出用户最大单笔出金金额限制",
        "-103101120702": "出金金额超出银行最大单笔出金金额限制",
        "-103101120703": "出金次数超出用户每日最大出金次数",
        "-103101120704": "出金次数超出银行每日最大出金次数",
        "-103101120705": "出金金额超出用户每日最大出金金额",
        "-103101120706": "出金金额超出银行每日最大出金金额",
        "-103101120801": "出金需要审核，而银行需要用户自己打开银行页面处理，两者不能并存，所以出金失败",
        "-103101120901": "连接银行%s适配器执行出金失败",
        "-103101120902": "用户绑定银行无银行卡号",
        "-103101121001": "当前不是交易日",
        "-103101129998": "市场出金失败，返回错误编号[103101129998]！",
        "-103101129999": "市场出金失败，返回错误编号[103101129999]！",
        "-103101130101": "执行资金划转失败，传入信息不完整",
        "-103101130201": "验证类型为[%s]操作人[%s]是否已登录失败",
        "-103101139998": "操作失败，请联系管理员",
        "10310113001": "市场端发起银行编号：%s解约成功",
        "-103101140101": "市场端发起银行解约失败，传入信息不完整！",
        "-103101140102": "调用银行[%s]适配器所需参数有空值！",
        "-103101140201": "市场端发起银行解约失败，验证类型为[%s]操作人[%s]失败！",
        "-103101140202": "市场端发起银行解约失败，限制类型为[%s]操作人[%s]调用！",
        "-103101140301": "验证资金密码失败",
        "-103101140302": "没有结算账号编号",
        "-103101140303": "没有绑定银行信息",
        "-103101140304": "最后一家银行且有余额",
        "-103101140305": "最后一家银行且用户中心不允许解约",
        "-103101140306": "此状态不是“已签约”不需解约",
        "-103101140307": "没有结算账号编号",
        "-103101140401": "银行没有市场端发起解约功能",
        "-103101140402": "禁用市场端发起解约功能",
        "-103101140403": "银行[%s]不存在！",
        "-103101140404": "银行当前时间不许市场端解约！",
        "-103101140405": "市场端发起解约失败，结算账号[%s]被禁用",
        "-103101140406": "市场端发起解约失败，银行[%s]被禁用！",
        "-103101140601": "您今天已经有出入金操作，无法进行解约",
        "-103101140602": "还有未解约的次银行",
        "-103101140603": "未结算出入金或冻结资金不为0！",
        "103101140601": "银行'处理中'，'预'解约成功！",
        "103101140602": "解约成功后，通知用户中心解约失败",
        "-103101140801": "解约失败，询问用户中心失败",
        "-103101140901": "最后一家银行且调用户中心失败",
        "-103101140902": "调用银行[%s]适配器失败！",
        "-103101140903": "调用银行[%s]适配器解约返回失败！",
        "-103101149998": "市场端发起银行解约失败，返回错误编号[103101149998]！",
        "-103101149999": "市场端发起银行解约失败，返回错误编号[103101149999]！",
        "-103101150001": "市场端发起设置主银行失败，传入信息不完整！",
        "-103101150002": "市场端发起设置主银行失败，限制类型为[%s]操作人[%s]调用！",
        "-103101150003": "市场端发起设置主银行失败，验证类型为[%s]操作人[%s]失败！",
        "-103101150101": "市场端发起设置主银行失败，无结算帐号与类型为[%s]的操作人[%s]对应关系！",
        "-103101150102": "市场端发起设置主银行失败，结算帐号[%s]的结算账号信息不存在！",
        "-103101150103": "市场端发起设置主银行失败，结算账号[%s]被禁用！",
        "-103101150104": "市场端发起设置主银行失败，银行和资金结算账号[%s]绑定关系不是已签约！",
        "-103101159997": "操作失败，请联系管理员",
        "-103101159998": "操作失败，请联系管理员",
        "-103101159999": "操作失败，请联系管理员",
        "-103102010101": "银行发起查询市场余额失败，传入信息不完整",
        "-103102010102": "银行发起查询市场余额失败，验证加密串错误",
        "-103102010301": "银行[%s]发起查询结算账号[%s]市场余额失败，资金密码不正确！",
        "-103102010302": "银行[%s]发起查询结算账号[%s]市场余额失败，结算账号[%s]不正确！",
        "-103102010303": "未查询到签约帐号信息！",
        "-103102019998": "操作失败，请联系管理员",
        "-103102019999": "操作失败，请联系管理员",
        "-103102020101": "银行发起签约失败，传入信息不完整",
        "-103102020102": "银行发起签约失败，验证加密串错误",
        "-103102020301": "银行发起签约失败，结算账号[%s]不存在",
        "-103102020302": "银行发起签约失败，结算账号[%s]被禁用",
        "-103102020303": "银行发起签约失败，要签约的结算账号[%s]资金密码错误",
        "-103102020304": "银行发起签约失败，传入的账户名不正确",
        "-103102020305": "银行发起签约失败，结算账号[%s]和银行[%s]绑定关系存在且不是 已解约或者未签约！",
        "-103102020306": "银行发起签约失败，传入的证件类型不正确",
        "-103102020307": "银行发起签约失败，传入的证件号码不正确",
        "-103102020308": "银行发起签约失败，当前时间银行不允许签约",
        "-103102020309": "银行发起签约失败，如果要签约此银行，需要先解约其他银行！",
        "-103102020310": "银行发起签约失败，结算账号[%s]未实名认证！",
        "-103102020401": "银行发起签约失败，银行[%s]没有银行端发起签约！",
        "-103102020402": "银行发起签约失败，银行编号[%s]不存在！",
        "-103102020403": "银行发起签约失败，银行[%s]被禁止！",
        "103102020701": "银行发起签约成功，通知用户中心系统失败",
        "-103102020901": "银行发起签约失败，调用财务系统接口查询资金结算账号[%s]期初余额失败！",
        "-103102020902": "银行发起签约失败，系统不支持绑定多个银行",
        "-103102029998": "操作失败，请联系管理员",
        "-103102029999": "操作失败，请联系管理员",
        "-103102030101": "银行发起改约失败，传入信息不完整",
        "-103102030102": "银行发起改约失败，验证加密串错误",
        "-103102030301": "银行发起改约失败,输入资金密码错误",
        "-103102030302": "银行发起改约失败,资金结算账号[%s]未签约银行[%s]！",
        "-103102030303": "银行发起改约失败,结算账号[%s]不存在",
        "-103102030304": "银行发起改约失败,结算账号[%s]被禁用",
        "-103102030305": "银行发起改约失败,银行不是已签约或预签约状态，不能执行改约！",
        "-103102030401": "银行发起改约失败,银行[%s]不存在！",
        "-103102030402": "银行发起改约失败,银行[%s]被禁用！",
        "-103102030403": "银行发起改约失败,银行不是改约时间段！",
        "-103102030404": "银行发起改约失败,银行[%s]不支持银行发起改约！",
        "-103102039998": "操作失败，请联系管理员",
        "-103102039999": "操作失败，请联系管理员",
        "-103102040101": "银行发起入金失败，传入信息不完整",
        "-103102040102": "银行发起入金失败，验证加密串错误",
        "-103102040301": "资金账号被禁用",
        "-103102040302": "资金账号入金被禁用",
        "-103102040303": "用户未绑定银行",
        "-103102040304": "用户绑定银行非签约状态",
        "-103102040305": "资金密码错误",
        "-103102040401": "银行不存在",
        "-103102040402": "银行未到交易时间",
        "-103102040403": "银行交易时间已过",
        "-103102040404": "银行不在业务时间段",
        "-103102040405": "银行当前不允许出金",
        "-103102040406": "银行没有市场端发起入金权限",
        "-103102040407": "银行没有市场端发起入金权限",
        "-103102040601": "银行发起入金失败，银行流水号为[%s]的入金已经处理",
        "-103102040602": "入金手续费大于入金金额",
        "-103102040603": "用户绑定银行无银行卡号",
        "-103102040604": "没有查到此签约账号信息",
        "-103102040605": "查询出金流水信息为空！",
        "-103102041001": "银行发起入金失败，当天不是交易日",
        "-103102049998": "操作失败，请联系管理员",
        "-103102049999": "操作失败，请联系管理员",
        "-103102050101": "银行发起入金冲正失败，传入信息不完整",
        "-103102050102": "银行发起入金冲正失败，验证加密串错误",
        "-103102050501": "银行发起入金冲正失败，冲正流水已经存在",
        "-103102059998": "操作失败，请联系管理员",
        "-103102059999": "操作失败，请联系管理员",
        "-103102060101": "回调核心通知核心转账结果失败，传入信息不完整",
        "-103102060102": "回调核心通知核心转账结果失败，验证加密串错误",
        "-103102060301": "回调核心通知核心转账结果失败，未查到该银行流水为[%s]的出入金流水信息",
        "-103102060302": "回调核心通知核心转账结果失败，银行结果重复通知",
        "-103102060303": "回调核心通知核心转账结果失败，验证签名失败！",
        "-103102069998": "操作失败，请联系管理员",
        "-103102069999": "操作失败，请联系管理员",
        "-103102070101": "查询是否支持从银行端发起业务失败，传入信息不完整",
        "-103102070102": "查询是否支持从银行端发起业务失败，验证加密串错误",
        "-103102079999": "操作失败，请联系管理员",
        "-103102080101": "银行发起出金冲正失败，传入信息不完整",
        "-103102080102": "银行发起出金冲正失败，验证加密串错误",
        "-103102080301": "银行发起出金冲正失败，被冲正流水号[%s]已存在",
        "-103102089998": "操作失败，请联系管理员",
        "-103102089999": "操作失败，请联系管理员",
        "-103102090101": "银行发起查询转账结果失败，传入信息不完整",
        "-103102090102": "银行发起查询转账结果失败，验证加密串错误",
        "-103102090401": "银行发起查询转账结果失败，银行没有此条银行流水号[%s]的记录",
        "-103102099998": "操作失败，请联系管理员",
        "-103102099999": "操作失败，请联系管理员",
        "-103102100098": "操作失败，请联系管理员",
        "-103102100099": "操作失败，请联系管理员",
        "-103102100101": "更新流水中的银行流水号失败，传入信息不完整",
        "-103102100102": "更新流水中的银行流水号失败，验证加密串错误",
        "-103102100401": "更新流水中的银行流水号，银行流水[%s]不存在",
        "-103102110101": "通过内部账号查询资金结算编号失败，传入信息不完整",
        "-103102110102": "通过内部账号查询资金结算编号失败，验证加密串错误",
        "-103102110301": "通过内部账号查询资金结算编号失败，未查询到结算账号[%s]绑定信息",
        "-103102119998": "操作失败，请联系管理员",
        "-103102119999": "操作失败，请联系管理员",
        "-103102120101": "查询是否可以转账失败，传入的银行编号为空",
        "-103102121001": "不可以转账，当前所有系统为非交易日",
        "-103102121002": "不可以签到，该银行未发送清算数据！",
        "-103102129998": "操作失败，请联系管理员",
        "-103102129999": "操作失败，请联系管理员",
        "-103102130301": "查询绑定信息失败，未查询到结算账号%s信息",
        "-103102130302": "查询绑定信息失败，传入结算账号为空",
        "-103102130303": "查询绑定信息失败，验证加密串错误",
        "-103102139998": "操作失败，请联系管理员",
        "-103102139999": "操作失败，请联系管理员",
        "-103102140101": "银行发起解约失败，传入信息不完整",
        "-103102140102": "银行发起解约失败，验证加密串错误",
        "-103102140301": "银行发起银行编号：%s，签约账号：%s解约失败，原因：验证资金密码失败",
        "-103102140302": "银行发起银行编号：%s，签约账号：%s解约失败，原因：用户绑定银行信息为空",
        "-103102140303": "银行发起银行编号：%s，签约账号：%s解约成功，注：用户与银行的签约状态不是'已签约'或'预解约'！",
        "-103102140401": "银行发起银行编号：%s，签约账号：%s解约失败，原因：银行没有发起解约的功能",
        "-103102140402": "银行发起银行编号：%s，签约账号：%s解约失败，原因：禁用银行发起解约的功能",
        "-103102140403": "银行发起银行编号：%s，签约账号：%s解约失败，原因：当前时间不许银行发起解约",
        "-103102140404": "银行发起银行编号：%s，签约账号：%s解约失败，原因：签约账号[%s]被禁用",
        "-103102140405": "银行发起银行编号：%s，签约账号：%s解约失败，原因：银行编号[%s]不存在",
        "-103102140406": "银行发起银行编号：%s，签约账号：%s解约失败，原因：银行[%s]被禁用！",
        "-103102140601": "银行发起银行编号：%s，签约账号：%s解约失败，原因：已有出入金",
        "-103102140602": "银行发起银行编号：%s，签约账号：%s解约失败，原因：最后一家银行且有余额",
        "-103102140603": "银行发起银行编号：%s，签约账号：%s解约失败，原因：还有未解约的次银行",
        "-103102140604": "银行发起银行编号：%s，签约账号：%s解约失败，原因：未结算出入金或冻结资金不为0！",
        "103102140701": "银行发起银行编号：%s，资金结算账户：%s解约成功后，通知用户中心系统解约成功失败！",
        "-103102140901": "银行发起银行编号：%s，签约账号：%s解约失败，原因：最后一家银行且调用用户中心通知解约失败",
        "-103102140902": "银行发起银行编号：%s，签约账号：%s解约失败，原因：最后一家银行且用户中心不让解约",
        "-103102149998": "操作失败，请联系管理员",
        "-103102149999": "操作失败，请联系管理员",
        "-103102150101": "传入信息不完整",
        "-103102150102": "验证加密串错误",
        "-103102150301": "银行发起银行编号：%s，签约账号：%s“预”解约失败，原因：验证资金密码失败！",
        "-103102150302": "银行发起银行编号：%s，签约账号：%s“预”解约失败，原因：用户绑定银行信息为空！",
        "-103102150304": "银行发起银行编号：%s，签约账号：%s“预”解约成功，注：用户与银行的签约状态不是“已签约”！",
        "-103102150401": "银行发起银行编号：%s，签约账号：%s“预”解约失败，原因：银行没有发起“预”解约的功能！",
        "-103102150402": "银行发起银行编号：%s，签约账号：%s“预”解约失败，原因：禁用银行发起“预”解约的功能！",
        "-103102150403": "银行发起银行编号：%s，签约账号：%s“预”解约失败，原因：当前时间不许银行发起“预”解约！",
        "-103102150601": "银行发起银行编号：%s，签约账号：%s“预”解约失败，原因：已有出入金！",
        "-103102150602": "银行发起银行编号：%s，签约账号：%s“预”解约失败，原因：最后一家银行且有余额！",
        "-103102150901": "银行发起银行编号：%s，签约账号：%s“预”解约失败，原因：最后一家银行且调用用户中心通知解约失败！",
        "-103102150902": "银行发起银行编号：%s，签约账号：%s“预”解约失败，原因：最后一家银行且用户中心不让“预”解约！",
        "-103102159998": "操作失败，请联系管理员",
        "-103102159999": "操作失败，请联系管理员",
        "-103102160101": "银行出金失败，传入信息不完整",
        "-103102160102": "银行出金失败，验证加密串错误",
        "-103102160301": "用户未绑定银行",
        "-103102160302": "用户绑定银行非签约状态",
        "-103102160303": "资金账号被禁用",
        "-103102160304": "资金账号出金被禁用",
        "-103102160305": "资金密码错误",
        "-103102160306": "查询结算账号主账户失败",
        "-103102160401": "银行不存在",
        "-103102160402": "银行未到交易时间",
        "-103102160403": "银行交易时间已过",
        "-103102160404": "银行不在业务时间段",
        "-103102160405": "银行当前不允许出金",
        "-103102160406": "银行没有银行端发起出金权限",
        "-103102160501": "银行发起出金不支持审核而本次出金需要审核",
        "-103102160601": "余额不足以支付出金和手续费",
        "-103102160602": "银行出金失败，本次出金已经处理生成的出金流水市场流水号为[%s]",
        "-103102160603": "用户绑定银行无银行卡号",
        "103102160701": "银行编号：%s，签约账号：%s，银行出金待审核，处理中！",
        "-103102161001": "银行编号：%s，结算账号：%s，银行出金失败，原因：系统当前不是交易日",
        "-103102169998": "操作失败，请联系管理员",
        "-103102169999": "操作失败，请联系管理员",
        "-103102170101": "传入信息不完整",
        "-103102170102": "验证加密串错误",
        "-103102170201": "银行端绑定银行卡信息失败，资金结算账号[%s]不存在",
        "-103102170203": "银行端绑定银行卡号失败，资金结算账号被禁用",
        "-103102170204": "银行端绑定银行卡号失败，资金结算账号[%s]和银行[%s]绑定关系不存在！",
        "-103102170205": "银行端绑定银行卡号失败，资金结算账号[%s]和银行[%s]绑定关系不是签约状态！",
        "-103102179998": "操作失败，请联系管理员",
        "-103102179999": "操作失败，请联系管理员",
        "-103102180101": "验证资金密码，传入信息不完整",
        "-103102180102": "验证资金密码，验证加密串错误",
        "-103102180103": "验证资金密码，签约账号%s不存在",
        "-103102180104": "验证资金密码，密码不正确",
        "-103102189998": "操作失败，请联系管理员",
        "-103102189999": "操作失败，请联系管理员",
        "-103102190101": "市场查询手续费失败，验证类型为[%s]操作人[%s]失败，原因：%s",
        "-103102199898": "操作失败，请联系管理员",
        "103102200001": "银行通知市场签约结果成功",
        "-103102200001": "银行通知市场签约结果失败，加密串不正确",
        "-103102200002": "银行通知市场签约结果失败，签约信息不存在",
        "-103102200003": "银行通知市场签约结果失败，如果要签约此银行，需要先将其他银行解约",
        "-103102209998": "操作失败，请联系管理员",
        "-103102209999": "操作失败，请联系管理员",
        "-103102210001": "银行通知市场改约结果失败，加密串不正确",
        "-103102210002": "银行通知市场改约结果失败，签约信息不存在",
        "-103102210003": "银行通知市场改约结果失败，非签约状态",
        "-103102210004": "银行通知市场改约结果失败，申请信息不存在",
        "-103102219998": "操作失败，请联系管理员",
        "-103102219999": "操作失败，请联系管理员",
        "103102220001": "银行通知市场解约结果成功，签约信息已删除",
        "103102220002": "银行通知市场解约结果成功，已经解约成功",
        "-103102220001": "银行通知市场解约结果失败，加密串不正确",
        "-103102220002": "银行通知市场解约结果失败，签约信息不是预解约状态",
        "-103102229998": "操作失败，请联系管理员",
        "-103102229999": "操作失败，请联系管理员",
        "-103102230001": "市场端发起验证资金密码，传入信息不完整",
        "-103102230002": "市场端发起验证资金密码，验证类型为[%s]操作人[%s]失败！",
        "-103102230101": "市场发起验证资金密码失败，无结算帐号与类型为[%s]的操作人[%s]对应关系！",
        "-103102230103": "市场发起验证资金密码失败，输入资金密码错误！",
        "-103102239998": "操作失败，请联系管理员",
        "-103102239999": "操作失败，请联系管理员",
        "-103102240001": "市场端发起获取市场流水号失败，传入信息不完整",
        "-103102240002": "市场端发起获取市场流水号失败，验证类型为[%s]操作人[%s]失败！",
        "-103102240003": "市场端发起获取市场流水号失败，市场流水号生成失败",
        "-103102249998": "操作失败，请联系管理员",
        "-103102249999": "操作失败，请联系管理员",
        "-103102250001": "市场端发起获取用户中心的客户信息失败，传入信息不完整",
        "-103102250101": "市场端发起获取用户中心的客户信息失败，用户信息不存在",
        "-103102250102": "市场端发起获取用户中心的客户信息失败，资金结算账号不存在",
        "-103102259998": "操作失败，请联系管理员",
        "-103102259999": "操作失败，请联系管理员",
        "-103102260001": "初始化资金密码失败，传入信息不完整！",
        "-103102260201": "初始化资金密码失败，不允许市场管理员初始化！",
        "-103102260301": "初始化资金密码失败，未查询到用户信息！",
        "-103102260302": "初始化资金密码失败，已经设置过资金密码！",
        "-103102260303": "初始化资金密码失败，初始化资金密码失败，实名信息不一致！！",
        "-103102269996": "初始化资金密码失败，返回错误编号[103102269996]！",
        "-103102269998": "操作失败，请联系管理员",
        "-103102269999": "操作失败，请联系管理员",
        "-103102270001": "重置资金密码发送验证码失败，传入信息不完整！",
        "-103102270201": "重置资金密码发送验证码失败，不允许市场管理员操作！",
        "-103102270301": "重置资金密码发送验证码失败，请先设置您的手机号！",
        "-103102271001": "重置资金密码发送验证码失败，执行发送失败！",
        "-103102279998": "操作失败，请联系管理员",
        "-103102279999": "操作失败，请联系管理员",
        "-103102280001": "重置资金密码失败，传入信息不完整！",
        "-103102280201": "重置资金密码失败，不允许市场管理员操作！",
        "-103102280301": "重置资金密码失败，实名信息不一致！",
        "-103102280302": "重置资金密码失败，未查询到用户信息！",
        "-103102281001": "重置资金密码失败，短信验证码已失效！",
        "-103102281002": "重置资金密码失败，短信验证码非本用户使用！",
        "-103102281003": "重置资金密码失败，验证码业务类型错误！",
        "-103102289996": "操作失败，请联系管理员",
        "-103102289998": "操作失败，请联系管理员",
        "-103102289999": "操作失败，请联系管理员",
        "-103102290101": "审核入金申请流水失败，传入信息不完整！",
        "-103102290102": "操作人身份不合法！",
        "-103102290201": "审核入金申请流水失败，传入信息不完整！",
        "-103102290301": "申请编号：%s，出金审核失败，原因：自动审核时：审核次数为0！",
        "-103102290302": "申请入金流水不存在！",
        "-103102290303": "申请入金流水状态不是待审核！",
        "-103102290304": "申请入金流水审核次数超过上限！",
        "-103102290305": "第一次审核申请人和审核人不能相同！",
        "-103102290306": "非第一次审核但不存在上一次审核！",
        "-103102290307": "审核次数错误！",
        "-103102290308": "同一个人不能连续审核！",
        "103102290309": "审核拒绝操作成功！",
        "-103102290310": "申请撤销资金流水密文验证错误！",
        "-103102290311": "审核流水密文验证错误！",
        "-103102299997": "操作失败，请联系管理员",
        "-103102299998": "操作失败，请联系管理员",
        "-103102299999": "操作失败，请联系管理员",
        "-103102300101": "审核出金申请流水失败，传入信息不完整！",
        "-103102300102": "操作人身份不合法！",
        "-103102300201": "审核出金申请流水失败，传入信息不完整！",
        "-103102300301": "申请编号：%s，出金审核失败，原因：自动审核时：审核次数为0！",
        "-103102300302": "申请出金流水不存在！",
        "-103102300303": "申请出金流水状态不是待审核！",
        "-103102300304": "申请出金流水审核次数超过上限！",
        "-103102300305": "第一次审核申请人和审核人不能相同！",
        "-103102300306": "非第一次审核但不存在上一次审核！",
        "-103102300307": "审核次数错误！",
        "-103102300308": "同一个人不能连续审核！",
        "103102300309": "审核拒绝操作成功！",
        "-103102300310": "申请撤销资金流水密文验证错误！",
        "-103102300311": "审核流水密文验证错误！",
        "-103102309997": "操作失败，请联系管理员",
        "-103102309998": "操作失败，请联系管理员",
        "-103102309999": "操作失败，请联系管理员",
        "-103102310101": "审核撤销入金申请流水失败，传入信息不完整！",
        "-103102310102": "操作人身份不合法！",
        "-103102310201": "审核撤销入金申请流水失败，传入信息不完整！",
        "-103102310301": "申请编号：%s，撤销入金审核失败，原因：自动审核时：审核次数为0！",
        "-103102310302": "申请撤销入金流水不存在！",
        "-103102310303": "申请撤销入金流水状态不是待审核！",
        "-103102310304": "申请撤销入金流水审核次数超过上限！",
        "-103102310305": "第一次审核申请人和审核人不能相同！",
        "-103102310306": "非第一次审核但不存在上一次审核！",
        "-103102310307": "审核次数错误！",
        "-103102310308": "同一个人不能连续审核！",
        "103102310309": "审核拒绝操作成功！",
        "-103102310310": "申请撤销资金流水密文验证错误！",
        "-103102310311": "审核流水密文验证错误！",
        "-103102319997": "操作失败，请联系管理员",
        "-103102319998": "操作失败，请联系管理员",
        "-103102319999": "审核申请撤销入金流水失败，返回错误编号[103102319999]！",
        "-103102320101": "审核撤销出金申请流水失败，传入信息不完整！",
        "-103102320102": "操作人身份不合法！",
        "-103102320201": "审核撤销出金申请流水失败，传入信息不完整！",
        "-103102320301": "申请编号：%s，撤销出金审核失败，原因：自动审核时：审核次数为0！",
        "-103102320302": "申请撤销出金流水不存在！",
        "-103102320303": "申请撤销出金流水状态不是待审核！",
        "-103102320304": "申请撤销出金流水审核次数超过上限！",
        "-103102320305": "第一次审核申请人和审核人不能相同！",
        "-103102320306": "非第一次审核但不存在上一次审核！",
        "-103102320307": "审核次数错误！",
        "-103102320308": "同一个人不能连续审核！",
        "103102320309": "审核拒绝操作成功！",
        "-103102320310": "申请撤销资金流水密文验证错误！",
        "-103102320311": "审核流水密文验证错误！",
        "-103102329997": "操作失败，请联系管理员",
        "-103102329998": "操作失败，请联系管理员",
        "-103102329999": "操作失败，请联系管理员",
        "-103102410001": "修改签约信息发送验证码失败，不允许市场初始化！",
        "-103102410002": "修改签约信息发送验证码失败，手机号为空！",
        "-103102410003": "修改签约信息发送验证码失败，发送验证码失败，执行发送失败！",
        "-103102410004": "修改签约信息失败，短信验证码已失效！",
        "-103102410005": "修改签约信息失败，短信验证码非本用户使用！",
        "-103102410006": "修改签约信息失败，业务类型错误！",
        "-103102419998": "操作失败，请联系管理员",
        "-103102419999": "操作失败，请联系管理员",
        "-103110000001": "传入信息不完整",
        "-103110000002": "传入信息不正确",
        "-103110000003": "验证操作人[%s]失败",
        "-103110009998": "操作失败，请联系管理员",
        "-103110009999": "操作失败，请联系管理员",
        "-103110010001": "结算系统状态不是结算完成",
        "-103110010002": "资金系统有用户的手续费为负值！",
        "-103110010003": "资金系统有用户的权益为负值！",
        "-103110020001": "结算系统状态不是抽取流水中",
        "-103110020002": "抽取的资金流水存储异常",
        "-103110030001": "结算系统状态不是抽取流水中",
        "-103110030002": "当前出入金中是无资金当日的流水",
        "-103110040001": "未查到系统编号和系统交易日期集合",
        "-103110040002": "结算系统状态不是抽取资金流水完成",
        "-103110040003": "会员数据已抽取！",
        "-103110050001": "结算账号存在上日权益不等于所有绑定的银行上日权益之和",
        "-103110050002": "结算账号存在上日可用不等于所有绑定的银行上日可用之和",
        "-103110050003": "结算账号存在当日入金净值不等于所有绑定银行当日入金净值之和",
        "-103110050004": "已经执行过清算处理",
        "-103110050005": "结算系统状态不是抽取资金流水完成",
        "-103110050006": "结算系统状态不是抽取资金数据完成或抽取会员数据完成",
        "-103110050007": "抽取的资金数据存在可用大于权益",
        "-103110050008": "初始化清算数据失败，结算系统状态不是抽取财务流水完成！",
        "-103110050009": "初始化清算数据失败，结算系统状态不是抽取财务数据完成或抽取会员数据完成！",
        "-103110050010": "初始化清算数据失败，存在有次银行但没有主银行的用户！",
        "-103110060001": "银行当日清算数据未生成",
        "-103110060002": "调用银行适配器返回失败",
        "-103110060003": "调用银行适配器失败",
        "-103110060004": "初始化清算数据未完成",
        "-103110060005": "当日已发送清算数据，不能再次发送",
        "-103110070001": "未抽取了资金数据",
        "-103110070002": "初始化清算数据",
        "-103110080001": "调用银行适配器返回失败",
        "-103110080002": "调用银行适配器失败",
        "103110080003": "获取银行清算数据成功，银行[%s]无不平记录！",
        "-103110090001": "通知银行开始清算失败，传入信息不完整！",
        "-103110090002": "通知银行开始清算失败，验证操作人[%s]失败！",
        "-103110090003": "通知银行开始清算失败，调用银行[%s]适配器失败！",
        "-103110099998": "操作失败，请联系管理员",
        "-103110099999": "操作失败，请联系管理员",
        "-103111010001": "银行不存在",
        "-103111010002": "银行被禁止",
        "-103111010003": "银行适配器未部署",
        "-103111010004": "调用银行适配器获取转账流水返回失败",
        "-103111010005": "当日该银行没有出入金或银行未发送流水文件",
        "-103111010006": "调用银行[%s]适配器失败",
        "-103111020001": "还有未处理的冲正记录",
        "-103111020002": "还有未处理的单边账",
        "-103111020003": "还有不平记录",
        "-103111020004": "确认对账失败，还有待审核的出金记录！",
        "-103111020005": "确认对账失败，还有未处理的银行单边账！",
        "-103111020006": "确认对账失败，还有未处理的市场单边账！",
        "-103111020007": "确认对账失败，还有转账方向不一致的流水！",
        "-103111020008": "确认对账失败，还有金额不一致流水！",
        "-103111020009": "确认对账失败，还有转账人不同的流水！",
        "-103111020010": "确认对账失败，还有银行未签退！",
        "-103111030001": "银行未签退",
        "-103111030002": "连接银行[%s]适配器失败！",
        "-103111040001": "银行出入金流水文件未准备完成",
        "-103111040002": "连接银行适配器失败",
        "-103112010001": "查询银行信息失败",
        "-103112010002": "同银行之间不能进行轧差划转",
        "-103112010003": "轧差划转失败，未完成初始化清算数据",
        "-103112010004": "轧差划转失败，该银行已发送清算数据",
        "-103112020001": "当前银行剩余佣金不足",
        "-103112020002": "资金系统返回传入佣金不符",
        "-103112020003": "资金系统返回传入银行未设置银行科目",
        "-103112020004": "资金系统返回未知错误",
        "-103112020005": "资金系统返回值[%s]未知",
        "-103112030001": "当前银行剩余利息不足",
        "-103112040001": "未查到当日清算数据",
        "-103112040002": "划出轧差银行余额不足",
        "-103112040003": "传入的轧差金额不符",
        "-103112040004": "资金系统返回划出银行不存在银行科目",
        "-103112040005": "资金系统返回划入银行不存在银行科目",
        "-103112040006": "资金系统返回未知错误",
        "-103112040007": "资金系统返回值[%s]未知",
        "-103112050001": "变更银行台账余额失败，传入信息不完整！",
        "-103112050002": "变更银行台账余额失败，验证操作人[%s]失败！",
        "-103112050101": "变更银行台账余额失败，银行余额不足！",
        "-103112059999": "变更银行台账余额失败，返回错误编号[103112059998]",
        "-103112059998": "变更银行台账余额失败，返回错误编号[103112059999]",
        "-103120101001": "查询保证金余额失败，传入信息不完整！",
        "-103120101002": "查询保证金余额失败，验证类型为[%s]操作人[%s]失败，原因：%s！",
        "-103120101998": "操作失败，请联系管理员",
        "-103120101999": "操作失败，请联系管理员",
        "-103120102001": "客户明细查询失败，传入信息不完整！",
        "-103120102002": "客户明细查询失败，验证类型为[%s]操作人[%s]失败，原因：%s！",
        "-103120102003": "客户明细查询失败，没有此用户的绑定信息！",
        "-103120102998": "操作失败，请联系管理员",
        "-103120102999": "操作失败，请联系管理员",
        "-103120103001": "市场资金明细查询失败，传入信息不完整！",
        "-103120103002": "市场资金明细查询失败，验证类型为[%s]操作人[%s]失败，原因：%s！",
        "-103120103998": "操作失败，请联系管理员",
        "-103120103999": "操作失败，请联系管理员",
        "-103120104001": "手续费用查询失败，传入信息不完整！",
        "-103120104002": "手续费用查询失败，验证类型为[%s]操作人[%s]失败，原因：%s！",
        "-103120104998": "操作失败，请联系管理员",
        "-103120104999": "操作失败，请联系管理员",
        "-103120106001": "绑定银行卡失败，传入信息不完整！",
        "-103120106002": "绑定银行卡失败，验证类型为[%s]操作人[%s]失败，原因：%s！",
        "-103120106003": "绑定银行卡失败，无结算帐号与类型为[%s]的操作人[%s]对应关系！",
        "-103120106998": "操作失败，请联系管理员",
        "-103120106999": "操作失败，请联系管理员",
        "-103120107001": "解绑银行卡失败，传入信息不完整！",
        "-103120107002": "解绑银行卡失败，验证类型为[%s]操作人[%s]失败，原因：%s！",
        "-103120107003": "解绑银行卡失败，无结算帐号与类型为[%s]的操作人[%s]对应关系！",
        "-103120107998": "操作失败，请联系管理员",
        "-103120107999": "操作失败，请联系管理员",
        "-103120108001": "轧差划拨失败，传入信息不完整！",
        "-103120108002": "轧差划拨失败，传入信息不正确！",
        "-103120108003": "轧差划拨失败，验证类型为[%s]操作人[%s]失败，原因：%s！",
        "-103120108998": "操作失败，请联系管理员",
        "-103120108999": "操作失败，请联系管理员",
        "-103120109001": "提取手续费失败，传入信息不完整！",
        "-103120109002": "提取手续费失败，传入信息不正确！",
        "-103120109003": "提取手续费失败，验证类型为[%s]操作人[%s]失败，原因：%s！",
        "-103120109998": "操作失败，请联系管理员",
        "-103120109999": "操作失败，请联系管理员",
        "-103120111001": "提取利息失败，传入信息不完整！",
        "-103120111002": "提取利息失败，传入信息不正确！",
        "-103120111003": "提取利息失败，验证类型为[%s]操作人[%s]失败，原因：%s！",
        "-103120111998": "操作失败，请联系管理员",
        "-103120111999": "操作失败，请联系管理员",
        "-103120113001": "通知银行清算失败，传入信息不完整！",
        "-103120113002": "通知银行清算失败，验证类型为[%s]操作人[%s]失败，原因：%s！",
        "-103120113003": "通知银行清算失败，银行不存在！",
        "-103120113998": "操作失败，请联系管理员",
        "-103120113999": "操作失败，请联系管理员",
        "-103120201001": "市场余额查询失败，传入信息不完整！",
        "-103120201998": "操作失败，请联系管理员",
        "-103120201999": "操作失败，请联系管理员",
        "-103120208001": "划拨轧差失败，传入信息不完整！",
        "-103120208002": "划拨轧差失败，传入信息不正确！",
        "-103120208003": "划转轧差失败，验证类型为[%s]操作人[%s]失败，原因：%s！",
        "-103120208998": "操作失败，请联系管理员",
        "-103120208999": "操作失败，请联系管理员",
        "-103120209001": "提取手续费失败，传入信息不完整！",
        "-103120209002": "提取手续费失败，传入信息不正确！",
        "-103120209003": "提取手续费失败，验证类型为[%s]操作人[%s]失败，原因：%s！",
        "-103120209998": "操作失败，请联系管理员",
        "-103120209999": "操作失败，请联系管理员",
        "-103120210001": "提取利息失败，传入信息不完整！",
        "-103120210002": "提取利息失败，传入信息不正确！",
        "-103120210003": "提取利息失败，验证类型为[%s]操作人[%s]失败，原因：%s！",
        "-103120210998": "操作失败，请联系管理员",
        "-103120210999": "操作失败，请联系管理员",
        "-103120211001": "获取成交明细失败，行情地址为空！",
        "-103120211998": "操作失败，请联系管理员",
        "-103120211999": "操作失败，请联系管理员",
        "-103120212001": "市场入金失败，传入信息不完整！",
        "-103120212002": "市场入金失败，限制类型为[%s]操作人[%s]调用！",
        "-103120212003": "市场入金失败，验证类型为[%s]操作人[%s]失败，原因：%s！",
        "-103120212004": "市场入金失败，当天为非交易日！",
        "-103120212005": "银行已经签退！",
        "-103120212006": "银行已经禁用！",
        "-103120212007": "市场入金失败，没有此资金结算账号！",
        "-103120212998": "操作失败，请联系管理员",
        "-103120212999": "操作失败，请联系管理员",
        "-103120314001": "银行发起预签约失败，传入信息不完整！",
        "-103120314002": "银行发起预签约失败，验证加密串失败！",
        "-103120314003": "银行发起预签约失败，银行编号[%s]不存在！",
        "-103120314004": "银行发起预签约失败，银行[%s]被禁止！",
        "-103120314005": "银行发起预签约失败，当前时间银行不允许签约！",
        "-103120314006": "银行发起预签约失败，结算账号[%s]不存在！",
        "-103120314007": "银行发起预签约失败，结算账号[%s]被禁用！",
        "-103120314008": "银行发起预签约失败，银行[%s]没有银行端发起签约！",
        "-103120314009": "银行发起预签约失败，您还未重置资金密码！",
        "-103120314010": "银行发起预签约失败，要签约的结算账号[%s]传入密码错误！",
        "-103120314011": "银行发起预签约失败，传入的账户名不正确！",
        "-103120314012": "银行发起预签约失败，传入的证件类型不正确！",
        "-103120314013": "银行发起预签约失败，传入的证件号码不正确！",
        "-103120314014": "银行发起预签约失败，结算账号[%s]和银行[%s]绑定关系存在且不是 已解约或者未签约！",
        "-103120314998": "操作失败，请联系管理员",
        "-103120314999": "操作失败，请联系管理员",
        "-103130000001": "交易状态未清算完成",
        "-103130000002": "请配置FTP服务配置信息",
        "-103130000003": "FTP 服务连接失败，请联系管理员",
        "-103130000004": "上报清算文件失败，请联系管理员",
        "-103130009997": "对不起，服务异常，请拨打客服",
        "-103130009998": "对不起，服务异常，请拨打客服",
        "-103130009999": "对不起，服务异常，请拨打客服",
        "-103131000001": "抽取数据失败，抽取状态不是待抽取或抽取失败",
        "-103131000002": "抽取数据失败，已发送清算数据等待清算中心稽核",
        "-103131000003": "抽取数据失败，已稽核成功",
        "-103131000004": "抽取数据失败，清空抽取及上报清算文件失败：%s",
        "-103132000001": "上报清算数据失败，未抽取数据",
        "-103132000002": "上报清算数据失败，抽取未完成",
        "-103132000003": "上报清算数据失败，抽取的交易市场文件不存在",
        "-103132000004": "上报清算数据失败，FTP 文件上传失败",
        "-103132000005": "上报清算数据失败，%s",
        "-103230000001": "请联系管理员",
        "-103230009996": "对不起，服务异常，请拨打客服",
        "-103230009999": "对不起，服务异常，请拨打客服",
        "103230019001": "修改资金密码成功",
        "-103230010001": "用户[%s]不是默认交易用户",
        "-103230019996": "对不起，服务异常，请拨打客服",
        "-103230019999": "对不起，服务异常，请拨打客服",
        "103230100001": "入金成功",
        "103230200001": "出金成功",
        "103230300001": "签约成功",
        "103230400001": "解约成功",
        "103230500001": "预绑卡成功，请等待银行处理",
        "103230600001": "预解卡成功，请等待银行处理",
        "103230700001": "改约成功",
        "-103230809996": "对不起，服务异常，请拨打客服",
        "-103230809999": "对不起，服务异常，请拨打客服",
        "-103230810001": "对不起，服务异常，请拨打客服",
        "-103230819996": "对不起，服务异常，请拨打客服",
        "-103230819999": "对不起，服务异常，请拨打客服",
        "103230900001": "设置主银行成功",
        "-103230909996": "对不起，服务异常，请拨打客服",
        "-103230909999": "对不起，服务异常，请拨打客服",
        "-103300004000": "对不起，您的账户类型是附属账户，无法使用此服务",
        "-103300009001": "对不起，未知异常，请拨打客服",
        "-103300012000": "对不起，未知账户类型",
        "-103300019996": "对不起，服务异常，请稍后重试",
        "-103300019999": "对不起，未知异常，请拨打客服",
        "-103300020001": "新密码不能为空",
        "-103300020002": "旧密码不能为空",
        "-103300020003": "新资金密码长度至少为6位",
        "-103300020004": "新资金密码必须是字母和数字的组合",
        "103300021000": "修改成功",
        "-103300021001": "修改失败",
        "-103300029996": "对不起，服务异常，请稍后重试",
        "-103300029999": "对不起，未知异常，请拨打客服",
        "-103300030001": "银行卡号不能为空",
        "-103300030002": "银行编号不能为空",
        "-103300030003": "银行密码不能为空",
        "-103300030004": "资金密码不能为空",
        "-103300030005": "电子邮箱不能为空",
        "-103300030006": "网点编号不能为空",
        "-103300030007": "绑定电话不能为空",
        "-103300030008": "支行名称不能为空",
        "103300031000": "签约成功",
        "-103300031001": "签约失败",
        "-103300039996": "对不起，服务异常，请稍后重试",
        "-103300039999": "对不起，未知异常，请拨打客服",
        "-103300040001": "银行编号不能为空",
        "-103300040002": "银行密码不能为空",
        "-103300040003": "资金密码不能为空",
        "103300041000": "解约成功",
        "-103300041001": "解约失败",
        "-103300049996": "对不起，服务异常，请稍后重试",
        "-103300049999": "对不起，未知异常，请拨打客服",
        "-103300050001": "银行编号不能为空",
        "-103300050002": "银行密码不能为空",
        "-103300050003": "资金密码不能为空",
        "-103300050004": "资金池编号不合法",
        "-103300050005": "入金金额必须大于0",
        "-103300050006": "入金金额不合法",
        "-103300050007": "入金金额数字不能超过%s位",
        "103300051000": "入金成功",
        "-103300051001": "入金失败",
        "-103300059996": "对不起，服务异常，请稍后重试",
        "-103300059999": "对不起，未知异常，请拨打客服",
        "-103300060001": "银行编号不能为空",
        "-103300060002": "银行密码不能为空",
        "-103300060003": "资金密码不能为空",
        "-103300060004": "资金池编号不合法",
        "-103300060005": "出金金额必须大于0",
        "-103300060006": "出金金额不合法",
        "-103300060007": "出金金额数字不能超过%s位",
        "103300061000": "出金成功",
        "-103300061001": "出金失败",
        "-103300069996": "对不起，服务异常，请稍后重试",
        "-103300069999": "对不起，未知异常，请拨打客服",
        "-103300070001": "资金密码不能为空",
        "-103300070002": "划出资金池编号不合法",
        "-103300070003": "划入资金池编号不合法",
        "-103300070004": "划转金额必须大于0",
        "-103300071005": "划转金额不合法",
        "-103300071006": "划转金额数字不能超过%s位",
        "103300071000": "划转成功",
        "-103300071001": "划转失败",
        "-103300079996": "对不起，服务异常，请稍后重试",
        "-103300079999": "对不起，未知异常，请拨打客服",
        "-103300080001": "资金密码不能为空",
        "-103300080002": "划出资金池编号不合法",
        "-103300080003": "划入资金池编号不合法",
        "-103300080004": "划转金额必须大于0",
        "-103300080005": "划出资金池所属人不能为空",
        "-103300080006": "划出资金池所属人ID不合法",
        "-103300080007": "划入资金池所属人不能为空",
        "-103300080008": "划入资金池所属人ID不合法",
        "-103300080009": "划转金额不合法",
        "-103300081010": "划转金额数字不能超过%s位",
        "103300081000": "划转成功",
        "-103300081001": "划转失败",
        "-103300089996": "对不起，服务异常，请稍后重试",
        "-103300089999": "对不起，未知异常，请拨打客服",
        "-103300092000": "银行信息查询结果为空",
        "-103300099996": "对不起，服务异常，请稍后重试",
        "-103300099999": "对不起，未知异常，请拨打客服",
        "-103300102000": "资金池信息查询结果为空",
        "-103300109996": "对不起，服务异常，请稍后重试",
        "-103300109999": "对不起，未知异常，请拨打客服",
        "-103300110001": "资金密码不能为空",
        "-103300111001": "资金池资金信息查询失败",
        "-103300111002": "资金池资金信息查询结果为空",
        "-103300119996": "对不起，服务异常，请稍后重试",
        "-103300119999": "对不起，未知异常，请拨打客服",
        "-103300122000": "签约信息查询结果为空",
        "-103300129996": "对不起，服务异常，请稍后重试",
        "-103300129999": "对不起，未知异常，请拨打客服",
        "-103300132000": "账户信息查询结果为空",
        "-103300139996": "对不起，服务异常，请稍后重试",
        "-103300139999": "对不起，未知异常，请拨打客服",
        "-103300142000": "出入金流水查询结果为空",
        "-103300149996": "对不起，服务异常，请稍后重试",
        "-103300149999": "对不起，未知异常，请拨打客服",
        "-103300152000": "划转流水查询结果为空",
        "-103300159996": "对不起，服务异常，请稍后重试",
        "-103300159999": "对不起，未知异常，请拨打客服",
        "-103300160001": "银行编号不能为空",
        "-103300160002": "银行密码不能为空",
        "-103300160003": "资金密码不能为空",
        "-103300161001": "银行卡余额查询失败",
        "-103300161002": "银行卡余额查询结果为空",
        "-103300169996": "对不起，服务异常，请稍后重试",
        "-103300169999": "对不起，未知异常，请拨打客服",
        "-103300172000": "证件类型查询结果为空",
        "-103300179996": "对不起，服务异常，请稍后重试",
        "-103300179999": "对不起，未知异常，请拨打客服",
        "-103300180001": "银行卡号不能为空",
        "-103300180002": "银行编号不能为空",
        "-103300180003": "资金密码不能为空",
        "-103300180004": "用户类型不合法",
        "-103300180005": "证件类型不能为空",
        "-103300180006": "证件号码不能为空",
        "-103300180007": "账户名不能为空",
        "-103300180008": "绑定电话不能为空",
        "-103300180009": "电子邮箱不能为空",
        "-103300180010": "网点编号不能为空",
        "-103300180011": "支行名称不能为空",
        "103300181000": "修改成功",
        "-103300181001": "修改失败",
        "-103300189996": "对不起，服务异常，请稍后重试",
        "-103300189999": "对不起，未知异常，请拨打客服",
        "-103300192000": "市场设置信息查询结果为空",
        "-103300199996": "对不起，服务异常，请稍后重试",
        "-103300199999": "对不起，未知异常，请拨打客服",
        "-103300202000": "客户总资金查询结果为空",
        "-103300209996": "对不起，服务异常，请稍后重试",
        "-103300209999": "对不起，未知异常，请拨打客服",
        "-103300212000": "所属人查询结果为空",
        "-103300219996": "对不起，服务异常，请稍后重试",
        "-103300219999": "对不起，未知异常，请拨打客服",
        "-103300220001": "银行ID不能为空",
        "103300221000": "设置成功",
        "-103300221001": "设置失败",
        "-103300229996": "对不起，服务异常，请稍后重试",
        "-103300229999": "对不起，未知异常，请拨打客服",
        "-103300230001": "用户类型不合法",
        "-103300230002": "证件类型不能为空",
        "-103300230003": "证件号码不能为空",
        "-103300230004": "账户名不能为空",
        "103300231000": "修改成功",
        "-103300231001": "修改失败",
        "-103300239996": "对不起，服务异常，请稍后重试",
        "-103300239999": "对不起，未知异常，请拨打客服",
        "-103300242000": "资金流水查询结果为空",
        "-103300249996": "对不起，服务异常，请稍后重试",
        "-103300249999": "对不起，未知异常，请拨打客服",
        "-103300250001": "银行编号不合法",
        "-103300250002": "出入金类型不合法",
        "-103300250003": "出金金额必须大于0",
        "-103300251004": "入金金额必须大于0",
        "103300251000": "手续费查询成功",
        "-103300251001": "手续费查询失败",
        "-103300259902": "手续费查询结果为空",
        "-103300259996": "对不起，服务异常，请稍后重试",
        "-103300259999": "对不起，未知异常，请拨打客服",
        "-103300269999": "对不起，未知异常，请拨打客服",
        "-103300272000": "特殊银行业务处理页面查询结果为空",
        "-103300279996": "对不起，服务异常，请稍后重试",
        "-103300279999": "对不起，未知异常，请拨打客服",
        "-103300282000": "出入金流水查询结果为空",
        "-103300289996": "对不起，服务异常，请稍后重试",
        "-103300289999": "对不起，未知异常，请拨打客服",
        "-103300292000": "划转流水查询结果为空",
        "-103300299996": "对不起，服务异常，请稍后重试",
        "-103300299999": "对不起，未知异常，请拨打客服",
        "-103300302000": "资金流水查询结果为空",
        "-103300309996": "对不起，服务异常，请稍后重试",
        "-103300309999": "对不起，未知异常，请拨打客服",
        "-103300310001": "新密码不能为空",
        "-103300310002": "新资金密码长度至少为8位",
        "-103300310003": "新资金密码必须是字母和数字的组合",
        "103300311000": "初始化资金密码成功",
        "-103300319996": "初始化资金密码RMI异常",
        "-103300319999": "初始化资金密码未知异常",
        "103300321000": "重置资金密码获取验证码成功",
        "-103300329996": "重置资金密码获取验证码RMI异常",
        "-103300329999": "重置资金密码获取验证码未知异常",
        "-103300330001": "新密码不能为空",
        "-103300330002": "新资金密码长度至少为8位",
        "-103300330003": "新资金密码必须是字母和数字的组合",
        "-103300330004": "用户名不能为空",
        "-103300330005": "证件类型不能为空",
        "-103300330006": "证件号码不能为空",
        "-103300330007": "手机验证码不能为空",
        "103300331000": "重置资金密码成功",
        "-103300339996": "重置资金密码RMI异常",
        "-103300339999": "重置资金密码未知异常",
        "-103619900001": "传入参数不正确",
        "-103619909999": "适配器执行失败",
        "-103600000001": "银行不是签到状态！",
        "-103600010001": "加密串验证错误",
        "-103600010099": "执行签到异常",
        "-103600020001": "当前为签到状态",
        "-103600020002": "当前签到状态未知",
        "-103600030001": "市场端签约，验证加密串失败！",
        "-103600039999": "适配器调用银行异常！",
        "-103600040001": "市场端改约，验证加密串失败！",
        "-103600049999": "执行业务时异常",
        "-103600050001": "市场端解约，验证加密串失败！",
        "-103600059999": "执行业务时异常",
        "-103600060001": "市场端查询银行卡余额和子账户余额 ，验证加密串失败！",
        "-103600069999": "执行业务时异常",
        "-103600070001": "市场端入金，验证加密串失败！",
        "-103600070002": "市场端入金，入金已存在！",
        "-103600070003": "请求回执中的市场流水号不一致",
        "-103600079999": "市场端入金异常！",
        "-103600080001": "市场端出金，验证加密串失败！",
        "-103600080002": "市场端出金，出金不存在！",
        "-103600080003": "请求回执中的市场流水号不一致",
        "-103600089999": "市场端出金异常！",
        "-103600090001": "市场端审核银行出金结果，验证加密串失败！",
        "-103600090002": "市场端审核银行出金结果，银行出金流水不存在！",
        "-103600090003": "市场端审核银行出金结果，银行出金流水不是待市场审核！",
        "103600090004": "市场端审核银行出金结果成功！",
        "-103600100001": "获取某笔转账的转账结果，验证加密串失败！",
        "-103600109999": "执行业务时异常",
        "-103600110001": "加密串验证错误",
        "-103600110002": "银行当前不是签到时间段",
        "-103600120001": "传入信息为空",
        "-103600120002": "调用适配器返回结果为空",
        "-103630019998": "数据库异常",
        "-103630019999": "系统异常",
        "-103630020000": "前台请求解包失败",
        "-103630020001": "接收后台解包失败",
        "-103630020002": "发送后台打包失败",
        "-103630020003": "发送后台接口失败",
        "-103630020004": "通讯超时",
        "-103630020005": "接收后台接口失败",
        "-103630020006": "取系统参数失败",
        "-103630020007": "平台号secuNo未启用",
        "-103630030000": "打开文件失败",
        "-103630030001": "创建文件失败",
        "-103630030002": "文件校验失败",
        "-103630030003": "FIX打开错",
        "-103630030004": "文件不存在",
        "-103630030005": "文件长度为2",
        "-103630040000": "主机取客户信息错误",
        "-103630040001": "客户类型非法",
        "-103630040002": "证件种类非法",
        "-103630040003": "证件号码非法",
        "-103630040004": "证件种类不符",
        "-103630040005": "证件号码不符",
        "-103630040006": "该客户已签约",
        "-103630040007": "该账号已签约",
        "-103630040008": "该账号未签约",
        "-103630040009": "该账号已解约",
        "-103630040010": "签约状态错",
        "-103630040011": "不是同一客户,不允许此交易",
        "-103630040012": "有在途资金,不允许换卡",
        "-103630040013": "无此客户信息",
        "-103630040014": "该账户已开户",
        "-103630040015": "电话号码非法",
        "-103630040016": "手机号码非法",
        "-103630040017": "客户号非法",
        "-103630050000": "账户余额不足",
        "-103630050001": "币种不存在",
        "-103630050002": "该商户暂停交易",
        "-103630050003": "该商户正在清算",
        "-103630050004": "该商户不支持此交易",
        "-103630050005": "可用余额不足",
        "-103630060000": "交易验证签名失败",
        "-103630060001": "验证银行签名失败",
        "-103630060002": "交易存疑",
        "-103630070000": "交易失败，原因：%s!",
        "-103630070001": "出金失败，原因：%s!",
        "-103630070002": "查询余额失败，原因：%s!",
        "-103630070003": "查询资金明细失败，原因：%s!",
        "-103630070004": "查询手续费失败，原因：%s!",
        "-103630070005": "查询BIN信息失败，原因：%s!",
        "-103630070006": "查询大额行号失败，原因：%s!",
        "-103630070007": "资金划转失败，原因：%s!",
        "-103630070008": "查询出金状态失败，原因：%s!",
        "-103630070009": "下载文件失败，原因：%s!",
        "-103630070010": "发送清算通知失败，原因：%s!",
        "-103630070011": "查看清算结果文件失败，原因：%s!",
        "-103630070012": "开户失败，原因：%s!",
        "-103630070013": "销户失败，原因：%s!",
        "-103630070014": "绑卡失败，原因：%s!",
        "-103630070015": "解绑失败，原因：%s!",
        "-103630070016": "FTP失败，原因：%s!",
        "-103640019998": "交易失败，原因：%s!",
        "-103640019999": "交易失败，原因：%s!",
        "-103640010000": "签到失败，原因：%s!",
        "-103640010001": "签退失败，原因：%s!",
        "-103640010002": "出金失败，原因：%s!",
        "-103640010003": "余额查询失败，原因：%s!",
        "-103640010004": "资金划转失败，原因：%s!",
        "-103640010005": "发送清算失败，原因：%s!",
        "-103640010006": "发送清算通知失败，原因：%s!",
        "-103640010007": "查询清算结果失败，原因：%s!",
        "-103640010008": "验证加密串失败，原因：%s!",
        "-103640010009": "FPT失败，原因：%s!",
        "-103670019998": "数据库异常",
        "-103670019999": "系统异常",
        "-103670020000": "签到退失败，原因：%s!",
        "-103670020001": "签约失败，原因：%s!",
        "-103670020002": "银行卡变更失败，原因：%S！",
        "-103670020003": "银行卡变更失败，原因：%S！",
        "-103670020004": "客户查询银行余额失败，原因：%S！",
        "-103670020005": "市场端查询出入金流水状态失败，原因：%S！",
        "-103670020006": "入金失败，原因：%S!",
        "-103670020007": "出金失败，原因：%s!",
        "-103670020008": "客户出金结果回复，原因：%s!",
        "-103670020009": "查询出入金流水失败，原因：%s!",
        "-103670020010": "",
        "-103670020011": "",
        "-103999900001": "传入信息不完整",
        "-103999900002": "银行处理失败",
        "-103999900003": "%s",
        "-103990010001": "未查询到签约信息",
        "-103990010002": "未查询到签约信息",
        "-103990020001": "未查询到签约信息",
        "-103990020002": "银行卡余额不足",
        "-103990029999": "调用模拟适配器异常！",
        "-103990030001": "未查到出入金流水信息",
        "-103990030002": "查到多条银行流水相同的出入金流水信息",
        "-103990030003": "未查到出入金流水信息，新流水号可以使用",
        "-103990040001": "签约账号[%s]和银行[%s]已经绑定了！",
        "-103990040002": "签约账号[%s]不存在",
        "-103990050001": "签约账号[%s]和银行[%s]绑定关系不存在！",
        "103991010001": "签约信息已经存在",
        "-103991010001": "当银行卡号以9开头时签约失败！",
        "-103991020001": "当银行卡号以9开头时改约失败！",
        "-103991020002": "未查到签约信息！",
        "-103991030001": "当银行卡余额以9开头时解约失败！",
        "-103991040001": "签约信息不存在！",
        "-103991050001": "未查询到市场流水对应的流水！",
        "103991060001": "入金金额以8开头时，入金银行处理中！",
        "103991060002": "入金金额以7开头时，接收不到银行返回信息！",
        "-103991060001": "签约信息不存在！",
        "-103991060002": "银行卡余额不足！",
        "-103991060003": "入金金额以9开头时，入金失败！",
        "103991070001": "出金金额以8开头时，出金银行处理中！",
        "103991070002": "出金金额以7开头时，接收不到银行返回信息！",
        "-103991070001": "签约信息不存在！",
        "-103991070002": "可用余额不足！",
        "-103991070003": "出金金额以9开头时，出金失败！",
        "-103991080001": "未查询到出金流水！",
        "-104100000001": "登录用户为空",
        "-104100000002": "登录信息不完整",
        "-104100000003": "操作人[%s]未登录或者登陆超时",
        "-104100000004": "加密串验证错误",
        "-104100000005": "操作失败",
        "-104100009996": "对不起，[%s]连接服务失败，请稍后重试",
        "-104100009997": "对不起，服务异常，请拨打客服",
        "-104100009998": "对不起，财务数据库异常，请稍后重试",
        "-104100009999": "对不起，未知异常，请拨打客服",
        "104100010001": "批量删除科目部分成功，失败的科目代码[%s]",
        "-104100010001": "科目信息为空",
        "-104100010002": "科目代码为空",
        "-104100010003": "科目名称为空",
        "-104100010004": "科目级别为空",
        "-104100010005": "借贷方向为空",
        "-104100010006": "上一级的科目不存在，操作失败",
        "-104100010007": "上一级科目代码和添加的科目的借贷方向不一致",
        "-104100010101": "没有此条记录",
        "-104100010102": "初始化科目不让操作",
        "-104100010103": "凭证分录包含该科目",
        "-104100010104": "结算日报表中判断包含该科目",
        "104100020001": "批量删除摘要部分成功,失败的摘要号[%s]",
        "-104100020001": "摘要信息为空",
        "-104100020002": "摘要号为空",
        "-104100020003": "摘要为空",
        "-104100020004": "资金借贷方向为空",
        "-104100020005": "是否涉及到交易商字段为空",
        "-104100020006": "附加帐为空",
        "-104100020007": "依据总账没有获取到系统ID",
        "-104100020008": "摘要没有涉及到交易商，科目中不能有交易商的固定科目字段",
        "-104100020101": "数据库没有此条记录",
        "-104100020102": "初始化的摘要不能操作",
        "-104100030001": "凭证信息为空",
        "-104100030002": "摘要号为空",
        "-104100030003": "凭证分录集合为空",
        "-104100030004": "凭证分录科目代码为空",
        "-104100030005": "凭证号为空",
        "-104100030101": "添加凭证失败，原因：不允许同一分录借贷金额都大于 0",
        "-104100030102": "添加凭证失败，原因：不允许同一分录无借方且无贷方发生额",
        "-104100030103": "添加凭证失败，原因：依据摘要号获取的摘要PO对象为空",
        "-104100030104": "添加凭证失败，原因：此摘要号[%s]，禁止添加凭证",
        "-104100030105": "添加凭证失败，原因：借贷方的总金额不等",
        "-104100030106": "添加凭证失败，原因：借贷双方都有交易商",
        "-104100030107": "添加凭证失败，原因：借方或贷方有重复的科目代码",
        "-104100030110": "添加凭证失败，原因：依据科目代码获得的科目对象为空",
        "-104100030111": "添加凭证失败，原因：科目代码不是叶子节点",
        "-104100030112": "添加凭证失败，原因：摘要号涉及到交易商，科目代码没有涉及交易商资金",
        "-104100030113": "添加凭证失败，原因：不能多个借方和多个贷方",
        "-104100030114": "添加凭证失败，原因：为贷方时 借方科目代码不符合摘要中的对方科目",
        "-104100030115": "添加凭证失败，原因：为借方时 贷方科目代码不符合摘要中的对方科目",
        "-104100030116": "添加凭证失败，原因：摘要号涉及到交易商且[贷方]时，贷方科目存在不是交易商的科目",
        "-104100030117": "添加凭证失败，原因：摘要号涉及到交易商且[借方]时，借方科目存在不是交易商的科目",
        "-104100030118": "添加凭证失败，原因：涉及到交易商时，且为交易系统的摘要，交易商科目应是资金账户不是结算账户",
        "-104100030201": "修改凭证失败，原因：不允许同一分录借贷金额都大于 0",
        "-104100030202": "修改凭证失败，原因：无借方或贷方发生额",
        "-104100030203": "修改凭证失败，原因：借贷方的总金额不等",
        "-104100030204": "修改凭证失败，原因：借贷双方都有交易商",
        "-104100030205": "修改凭证失败，原因：借方或贷方有重复的科目代码",
        "-104100030206": "修改凭证失败，原因：不存在此条凭证记录",
        "-104100030207": "修改凭证失败，原因：状态不是[编辑]状态，不能修改",
        "-104100030208": "修改凭证失败，原因：依据摘要号获取的摘要对象为空",
        "-104100030209": "修改凭证失败，原因：不能多个借方和多个贷方",
        "-104100030211": "修改凭证失败，原因：依据科目代码获得的科目对象为空",
        "-104100030212": "修改凭证失败，原因：科目代码不是叶子节点",
        "-104100030213": "修改凭证失败，原因：摘要号涉及到交易商且[贷方]时，贷方科目存在不是交易商的科目",
        "-104100030214": "修改凭证失败，原因：摘要号涉及到交易商且[借方]时，借方科目存在不是交易商的科目",
        "-104100030215": "修改凭证失败，原因：为贷方时 借方科目代码不符合摘要中的对方科目",
        "-104100030216": "修改凭证失败，原因：为借方时 贷方科目代码不符合摘要中的对方科目",
        "-104100030217": "修改凭证失败，原因：涉及到交易商时，且为交易系统的摘要，交易商科目应是资金账户不是结算账户",
        "-104100030301": "凭证状态不是[编辑]，不能删除",
        "104100030401": "凭证初审部分成功，失败的[%s]",
        "104100030402": "批量删除凭证失败[%s]",
        "-104100030401": "数据库没有此条凭证信息",
        "-104100030402": "凭证状态不是[编辑]，不能第一次审核",
        "-104100030403": "凭证初审全部失败，失败凭证号[%s]",
        "104100030501": "凭证财务审核部分成功，失败凭证号[%s]",
        "-104100030501": "财务审核失败，凭证信息为空",
        "-104100030502": "财务审核失败，凭证号为空",
        "-104100030503": "财务审核失败，数据库没有此条凭证信息",
        "-104100030504": "财务审核失败，状态不[待审核]状态，不能第二次审核",
        "-104100030505": "财务审核失败，凭证号没有凭证分录集合",
        "-104100030506": "财务审核失败，有交易系统时交易系统交易商资金不足或插入流水时异常",
        "-104100030507": "财务审核失败，有交易系统时在插入结算系统流水时异常",
        "-104100030508": "财务审核失败，只有结算系统时资金余额不足",
        "-104100030509": "财务审核失败，审核拒绝",
        "-104100030510": "修改财务凭证的状态为审核中失败",
        "-104100030511": "财务审核失败，交易商写流水余额不足",
        "-104100030512": "财务审核失败，当前状态不允许写流水",
        "-104100030513": "财务审核失败，凭证号[%s]",
        "104100040001": "批量删除凭证模板部分成功,失败的凭证模板号[%s]",
        "-104100040001": "凭证模板信息为空",
        "-104100040002": "凭证模板代码为空",
        "-104100040003": "摘要号为空",
        "-104100040004": "借方科目代码为空",
        "-104100040005": "贷方科目代码为空",
        "-104100040101": "借方和贷方科目代码不能同时含有[*]换位符",
        "-104100040102": "借方和贷方科目代码不能同时含有[-]换位符",
        "-104100040103": "借方和贷方科目代码相同",
        "-104100050001": "传入的封装客户信息为空",
        "-104100050002": "传入的交易商ID为空",
        "-104100050003": "传入的会员ID为空",
        "-104100050004": "传入的经纪人ID为空",
        "-104100050005": "传入的交易商，资金密码有为空的值",
        "-104100050006": "传入的经纪人ID，资金密码为空的值",
        "-104100050007": "传入的会员ID，资金密码为空的值",
        "-104100050009": "传入的交易商，资金密码，资金结算账户有为空的值",
        "-104100050010": "传入资金结算账户的值长度不符",
        "-104100050101": "传入资金结算账户不存在",
        "-104100050102": "数据库没有这条记录",
        "-104100050103": "资金结算账户中还有资金余额，操作不允许",
        "-104100050104": "结算系统资金账户中还有资金余额，操作不允许",
        "-104100050105": "交易小库资金账户中还有资金余额，操作不允许",
        "-104100050201": "没有ID为：[%s]的交易商",
        "-104100050202": "结算系统资金结算账户中还有资金余额",
        "-104100050203": "结算系统资金账户中还有资金余额",
        "-104100050204": "交易小库的资金结算账户中还有资金余额",
        "-104100060101": "商品ID为空",
        "-104100060102": "系统ID为空",
        "-104100060201": "传入信息为空",
        "-104100060202": "所属人为空",
        "-104100060203": "所属人类型为空",
        "-104100060204": "摘要号为空",
        "-104100060205": "发生额为空",
        "-104100060211": "用户资金账户不存在",
        "-104100060212": "用户无资金结算账户",
        "-104100060213": "不存在的摘要号",
        "-104100060214": "未设置摘要的资金借贷方向",
        "-104100060215": "摘要不涉及用户资金",
        "-104100060216": "占位符不能为空",
        "-104100060217": "您的资金系统余额不足，配送费需要从资金系统中收取，请您在【资金管理】模块向资金系统划转",
        "-104110000001": "登录用户为空",
        "-104110000002": "登录信息不完整",
        "-104110000003": "操作人[%s]未登录或者登陆超时",
        "-104110000004": "加密串验证错误",
        "-104110009996": "对不起，[%s]连接服务失败[]，请稍后重试",
        "-104110009997": "自定义异常",
        "-104110009998": "数据库异常",
        "-104110009999": "系统异常",
        "-104110010001": "资金划转信息信息为空",
        "-104110010002": "资金划转的金额数应大于0",
        "-104110010003": "资金划转输入的密码为空",
        "-104110010004": "没有从非结算系统到非结算系统的划转功能",
        "104110010101": "划转对账部分成功，失败记录ID[%s]",
        "-104110010101": "资金划转时来源的用户ID为空",
        "-104110010102": "资金划转时全是结算系统",
        "-104110010103": "资金划转时相同的系统且同一用户",
        "-104110010104": "依据交易日获取的结算状态为空",
        "-104110010105": "资金系统的状态不是[初始化]或[清算完成]",
        "-104110010106": "资金划转时用户没有资金结算账户",
        "-104110010107": "资金划转时两账户不是同一个资金结算账户",
        "-104110010108": "资金划转时输入的密码不正确",
        "-104110010109": "资金划转时用户类型不符",
        "-104110010111": "资金划转时输入的[划出]资金结算账户类型：%s不符",
        "-104110010112": "资金划转时输入的[划入]资金结算账户类型：%s不符",
        "-104110010113": "资金划转失败！不在资金划转时间",
        "-104110010114": "资金划转失败：配置划出摘要号不符",
        "-104110010115": "资金划转失败：配置划进摘要号不符",
        "-104110010121": "资金划转大于资金系统可划转金额",
        "-104110010122": "资金划转调存储异常",
        "-104110010123": "资金划转划出金额大于指定系统的可划转金额",
        "-104110010124": "正在流水对账，当前状态不能写交易系统流水",
        "-104110010125": "您的原始资金密码未修改，为了您的账户安全，请先修改资金密码！",
        "-104110010126": "资金系统的状态不是[初始化]或[清算完成]或[准备资金划转对账]！",
        "-104110010127": "单边账对账完成，不能资金划转！",
        "-104110010128": "划转对账全部失败，失败记录ID[%s]",
        "-104110020001": "没有状态为[处理中]的资金划转记录",
        "-104110020002": "传入的交易日期为空",
        "-104110020003": "传入的资金划转记录ID为空",
        "-104110020101": "依据交易日获取的结算状态为空",
        "-104110020102": "资金系统的状态不是[准备资金划转对账]不能操作",
        "-104110020103": "单边账对账没有完成",
        "-104110020104": "先资金流水对账，才能资金划转对账",
        "-104110020111": "资金划转记录状态[处理中]的条数不为 0",
        "-104110020112": "资金划转划入和划出的和不等",
        "-105100000001": "登录用户为空",
        "-105100000002": "登录信息不完整",
        "-105100000003": "操作人[%s]未登录或者登陆超时",
        "-105100009996": "[%s]链接RMI异常",
        "-105100009998": "对不起，数据库异常，请稍后重试",
        "-105100009999": "对不起，未知异常，请拨打客服",
        "-105100010001": "传入的结算系统类型为空",
        "-105100010002": "交易系统和结算系统类型不匹配",
        "-105100010003": "有系统返佣未完成",
        "-105100010004": "有交易系统已开市或出入金系统上一日未清算",
        "-105100010005": "数据库没有对应结算系统编号信息",
        "-105100010006": "结算系统状态不符，不能对账",
        "-105100010007": "返回对账失败",
        "-105100010008": "对账失败：原因正在获取差异流水中",
        "-105100020001": "结算系统未对账完成",
        "-105100020002": "状态不允许结算",
        "-105100020003": "单边账未对账完成",
        "-105100030001": "资金系统状态不是[初始化]操作失败",
        "-105100040001": "没有对账完成不能开市",
        "-105100040002": "状态不是初始化不能开市",
        "-105100040003": "没有对账完成不能开市",
        "-106100009999": "对不起，服务异常，请拨打客服",
        "-106101000001": "没有可用交易系统RMI连接信息",
        "-106101000101": "传入的商品分类编号为空",
        "-106101000102": "商品分类[%s]被占用",
        "-106101000201": "传入的品名编号为空",
        "-106101000202": "品名[%s]被占用",
        "-201100000001": "您想添加或修改的对象为空",
        "-201100000002": "请填写会员代码",
        "-201100000003": "请填写经纪人代码",
        "-201100000004": "经纪人编号为空",
        "-201100000005": "交易商ID为空",
        "-201100000006": "交易商集合为空",
        "-201100000007": "经纪人层级超过所允许的10层",
        "-201100000008": "没有可用特别会员",
        "-201100000009": "传入经纪人类型为空",
        "-201100000010": "不可解约，该经纪人存在未完成返佣数据",
        "-201100000011": "不可解约，该会员存在未完成返佣数据",
        "-201100000012": "不可注销，该经纪人存在未完成返佣数据",
        "-201100000013": "不可注销，该会员存在未完成返佣数据",
        "-201100009801": "对不起，数据库异常，请稍后重试",
        "-201100009996": "对不起，服务异常，请稍后重试",
        "-201100009997": "对不起，未知异常，请拨打客服",
        "-201100009998": "对不起，数据库异常，请稍后重试",
        "-201100009999": "对不起，未知异常，请拨打客服",
        "-201100010001": "您未登陆或登陆已过期，请重新登录",
        "-201100010002": "请填写会员代码",
        "-201100010003": "您想添加或修改的对象为空",
        "-201100010004": "返佣处理中",
        "-201100010005": "系统状态不正确，不允许进行返佣",
        "-201100019998": "对不起，数据库异常，请稍后重试",
        "-201100019999": "对不起，未知异常，请拨打客服",
        "-201500000001": "您想添加或修改的对象为空",
        "-201500000002": "您未登陆或登陆已过期，请重新登录",
        "-201500000003": "未找到相关数据",
        "-201500000004": "当前状态不允许进行返佣",
        "-202100000001": "传入的信息为空",
        "-202100000002": "传入信息不完整",
        "-202100000003": "发送方式为空",
        "-202100000004": "接收人类型为空",
        "-202100000005": "接收人类型信息不存在",
        "-202100000006": "归档日期必须大于到期日期",
        "-202100000101": "用户[%s]未登录或登录失败",
        "-202100009998": "对不起，服务异常，请拨打客服",
        "-202100009999": "对不起，服务异常，请拨打客服",
        "-202100010001": "没有可修改的公告信息",
        "-202100010002": "公告[%s]不存在",
        "-202100010003": "公告[%s]状态不是未生效状态",
        "-202100010004": "归档日期必须大于到期日期",
        "-202100010101": "用户[%s]未登录或登录失败",
        "-202100019998": "对不起，服务异常，请拨打客服",
        "-202100019999": "对不起，服务异常，请拨打客服",
        "-202100020002": "公告[%s]状态不合法",
        "-202100020003": "传入信息格式不正确",
        "-202100020101": "用户[%s]未登录或登录失败",
        "-202100029998": "对不起，服务异常，请拨打客服",
        "-202100029999": "对不起，服务异常，请拨打客服",
        "-202100100001": "传入的信息为空",
        "-202100100002": "传入信息不完整",
        "-202100100003": "发送方式为空",
        "-202100100004": "接收人类型为空",
        "-202100100005": "接收人类型信息不存在",
        "-202100100006": "传入的接收人[%s]不存在或已销户",
        "-202100100007": "归档日期必须大于到期日期",
        "-202100100008": "操作人IP获取失败",
        "-202100100009": "不支持群发短信功能",
        "-202100100010": "不支持发送短信",
        "-202100100101": "用户[%s]未登录或登录失败",
        "-202100109998": "对不起，服务异常，请拨打客服",
        "-202100109999": "对不起，服务异常，请拨打客服",
        "-202100110001": "没有可修改的消息",
        "-202100110002": "消息[%s]不存在",
        "-202100110003": "消息[%s]状态不合法",
        "-202100110004": "传入的接收人[%s]不存在或已销户",
        "-202100110005": "归档日期必须大于到期日期",
        "-202100110101": "用户[%s]未登录或登录失败",
        "-202100119998": "对不起，服务异常，请拨打客服",
        "-202100119999": "对不起，服务异常，请拨打客服",
        "-202100120001": "消息[%s]不存在",
        "-202100120002": "消息[%s]状态不合法",
        "-202100120003": "传入的信息为空",
        "-202100120101": "用户[%s]未登录或登录失败",
        "-202100129998": "对不起，服务异常，请拨打客服",
        "-202100129999": "对不起，服务异常，请拨打客服",
        "-202100200001": "传入信息为空",
        "-202100200002": "传入信息不完整",
        "-202100200003": "发送方式为空",
        "-202100200004": "接收人类型为空",
        "-202100200005": "接收人类型信息不存在",
        "-202100200006": "传入的接收人[%s]不存在或已销户",
        "-202100200007": "归档日期必须大于到期日期",
        "-202100200008": "操作人IP获取失败",
        "-202100200009": "不支持发送短信",
        "-202100200101": "用户[%s]未登录或登录失败",
        "-202100209998": "对不起，服务异常，请拨打客服",
        "-202100209999": "对不起，服务异常，请拨打客服",
        "-202100210001": "传入信息为空",
        "-202100210002": "会员消息[%s]不存在",
        "-202100210003": "会员消息[%s]状态不合法",
        "-202100210004": "传入的接收人[%s]不存在或已销户",
        "-202100210005": "归档日期必须大于到期日期",
        "-202100210101": "用户[%s]未登录或登录失败",
        "-202100219998": "对不起，服务异常，请拨打客服",
        "-202100219999": "对不起，服务异常，请拨打客服",
        "-202100220001": "传入信息为空",
        "-202100220002": "会员消息[%s]不存在",
        "-202100220003": "会员消息[%s]状态不合法",
        "-202100220101": "用户[%s]未登录或登录失败",
        "-202100229998": "对不起，服务异常，请拨打客服",
        "-202100229999": "对不起，服务异常，请拨打客服",
        "-202100300001": "传入信息为空",
        "-202100300002": "传入信息不完整",
        "-202100300003": "归档日期必须大于到期日期",
        "-202100300101": "用户[%s]未登录或登录失败",
        "-202100309998": "对不起，服务异常，请拨打客服",
        "-202100309999": "对不起，服务异常，请拨打客服",
        "-202100310001": "没有可修改的消息",
        "-202100310002": "仓库端消息[%s]不存在",
        "-202100310003": "仓库管理员[%s]消息[%s]所属仓库[%s]与仓库[%s]不匹配",
        "-202100310004": "仓库管理员[%s]消息[%s]状态不合法",
        "-202100310005": "归档日期必须大于到期日期",
        "-202100310101": "仓库端修改消息失败，用户[%s]未登录或登录失败",
        "-202100319998": "对不起，服务异常，请拨打客服",
        "-202100319999": "对不起，服务异常，请拨打客服",
        "-202100320001": "传入信息为空",
        "-202100320002": "仓库管理员[%s]消息[%s]所属仓库[%s]与仓库[%s]不匹配",
        "-202100320003": "仓库管理员[%s]消息[%s]状态不合法",
        "-202100329999": "对不起，服务异常，请拨打客服",
        "-202100330101": "用户[%s]未登录或登录失败",
        "-202100339998": "对不起，服务异常，请拨打客服",
        "-202100700001": "传入的信息为空",
        "-202100700002": "传入信息不完整",
        "-202100700003": "发送方式为空",
        "-202100700004": "接收人为空",
        "-202100700005": "加密信息错误",
        "-202100700006": "接收人类型信息不正确",
        "-202100700007": "系统自动发送消息失败，不支持发送短信",
        "-202100709998": "对不起，服务异常，请拨打客服",
        "-202100709999": "对不起，服务异常，请拨打客服",
        "-202100710001": "传入信息不完整",
        "-202100710002": "验证加密信息失败",
        "-202100710003": "调用短信处理器业务发送短信失败",
        "-202100719998": "对不起，服务异常，请拨打客服",
        "-202100719999": "对不起，服务异常，请拨打客服",
        "-202100720001": "传入信息为空",
        "-202100720002": "传入信息不完整",
        "-202100720003": "验证加密信息失败",
        "-202100720004": "调用短信处理器业务发送短信失败",
        "-202100720097": "连接发送短信处理器失败",
        "-202100729998": "对不起，服务异常，请拨打客服",
        "-202100729999": "对不起，服务异常，请拨打客服",
        "-202111000001": "传入的用户代码为空",
        "-202111009999": "对不起，服务异常，请拨打客服",
        "-202111100001": "传入的信息为空",
        "-202111100002": "用户[%s]消息缓存编号[%s]不大于记录中已存在的消息缓存编号[%s]",
        "-202111100003": "不需要修改",
        "-202111109998": "对不起，服务异常，请拨打客服",
        "-202111109999": "对不起，服务异常，请拨打客服",
        "-202111209999": "对不起，服务异常，请拨打客服",
        "-202111309999": "对不起，服务异常，请拨打客服",
        "-202111409999": "对不起，服务异常，请拨打客服",
        "-202111509999": "对不起，服务异常，请拨打客服",
        "-202111609999": "对不起，服务异常，请拨打客服",
        "-202111709999": "对不起，服务异常，请拨打客服",
        "-202111809999": "对不起，服务异常，请拨打客服",
        "202131900002": "部分手机号被限制发送短信",
        "-202130000000": "系统异常",
        "-202131100001": "传入的信息为空",
        "-202131100002": "系统信息配置不存在",
        "-202131109998": "对不起，服务异常，请拨打客服",
        "-202131109999": "对不起，服务异常，请拨打客服",
        "-202131200001": "传入的信息为空",
        "-202131200002": "系统信息配置[%s]不存在",
        "-202131209998": "对不起，服务异常，请拨打客服",
        "-202131209999": "对不起，服务异常，请拨打客服",
        "-202131210001": "传入的信息为空",
        "-202131210002": "接收短信人员信息[%s]不存在",
        "-202131211001": "传入的信息为空",
        "-202131211002": "接收短信人员信息[%s]不存在",
        "-202131212001": "传入的信息为空",
        "-202131212002": "接收短信人员信息[%s]不存在",
        "-202131300001": "传入的信息为空",
        "-202131300002": "限制IP地址[%s]次数已存在",
        "-202131309998": "对不起，服务异常，请拨打客服",
        "-202131309999": "对不起，服务异常，请拨打客服",
        "-202131310001": "传入的信息不完整",
        "-202131310002": "限制IP地址[%s]次数不存在",
        "-202131319998": "对不起，服务异常，请拨打客服",
        "-202131319999": "对不起，服务异常，请拨打客服",
        "-202131320001": "传入的信息为空",
        "-202131320002": "限制IP地址[%s]次数不存在",
        "-202131329998": "对不起，服务异常，请拨打客服",
        "-202131329999": "对不起，服务异常，请拨打客服",
        "-202131400001": "传入的信息为空",
        "-202131400002": "不限制次数手机号[%s]已存在",
        "-202131409998": "对不起，服务异常，请拨打客服",
        "-202131409999": "对不起，服务异常，请拨打客服",
        "-202131410001": "传入的信息为空",
        "-202131410002": "不限制次数手机号[%s]不存在",
        "-202131419998": "对不起，服务异常，请拨打客服",
        "-202131419999": "对不起，服务异常，请拨打客服",
        "-202131420001": "传入的信息为空",
        "-202131420002": "不限制次数手机号[%s]不存在",
        "-202131429998": "对不起，服务异常，请拨打客服",
        "-202131429999": "对不起，服务异常，请拨打客服",
        "-202131500001": "传入的信息为空",
        "-202131500002": "第三方短信平台[%s]已经存在",
        "-202131509998": "对不起，服务异常，请拨打客服",
        "-202131509999": "对不起，服务异常，请拨打客服",
        "-202131510001": "传入的信息为空",
        "-202131510002": "第三方短信平台[%s]不存在",
        "-202131519998": "对不起，服务异常，请拨打客服",
        "-202131519999": "对不起，服务异常，请拨打客服",
        "-202131520001": "传入的信息为空",
        "-202131520002": "第三方短信平台[%s]不存在",
        "-202131529998": "对不起，服务异常，请拨打客服",
        "-202131529999": "对不起，服务异常，请拨打客服",
        "-202131530001": "传入的信息为空",
        "-202131530002": "第三方短信平台[%s]不存在",
        "-202131539998": "对不起，服务异常，请拨打客服",
        "-202131539999": "对不起，服务异常，请拨打客服",
        "-202131540001": "传入的信息为空",
        "-202131540002": "第三方短信平台[%s]不存在",
        "-202131540003": "第三方短信平台[%s]为已禁用状态",
        "-202131549998": "对不起，服务异常，请拨打客服",
        "-202131549999": "对不起，服务异常，请拨打客服",
        "-202131550001": "传入的信息为空",
        "-202131550002": "第三方短信平台[%s]不存在",
        "-202131550003": "第三方短信平台[%s]为已启用状态",
        "-202131559998": "对不起，服务异常，请拨打客服",
        "-202131559999": "对不起，服务异常，请拨打客服",
        "-202131600001": "传入的信息为空",
        "-202131600002": "传入的信息不完整",
        "-202131600003": "编号为[%s]的短信通道不存在",
        "-202131600004": "编号为[%s]的第三方短信平台信息经不存在",
        "-202131600005": "编号为[%s]的第三方短信平台信息不可用",
        "-202131609998": "对不起，服务异常，请拨打客服",
        "-202131609999": "对不起，服务异常，请拨打客服",
        "-202131610001": "传入的信息为空",
        "-202131610002": "传入的信息不完整",
        "-202131610003": "编号为[%s]的短信通道不存在",
        "-202131610004": "编号为[%s]的第三方短信平台信息经不存在",
        "-202131610005": "编号为[%s]的第三方短信平台信息不可用",
        "-202131619998": "对不起，服务异常，请拨打客服",
        "-202131619999": "对不起，服务异常，请拨打客服",
        "-202131700001": "传入的信息为空",
        "-202131700002": "编号为[%s]的机构不存在",
        "-202131700003": "编号为[%s]的通道不存在",
        "-202131700004": "设置的机构与通道关系信息已经存在",
        "-202131709998": "对不起，服务异常，请拨打客服",
        "-202131709999": "对不起，服务异常，请拨打客服",
        "-202131710001": "传入的信息为空",
        "-202131710002": "机构[%s]与通道[%s]的关系信息不存在",
        "-202131719998": "对不起，服务异常，请拨打客服",
        "-202131719999": "对不起，服务异常，请拨打客服",
        "-202131720001": "传入的信息为空",
        "-202131720002": "机构[%s]与通道[%s]的关系信息不存在",
        "-202131729998": "对不起，服务异常，请拨打客服",
        "-202131729999": "对不起，服务异常，请拨打客服",
        "-202131800001": "传入的信息为空",
        "-202131800002": "编号为[%s]的机构已存在",
        "-202131809998": "对不起，服务异常，请拨打客服",
        "-202131809999": "对不起，服务异常，请拨打客服",
        "-202131810001": "传入的信息为空",
        "-202131810002": "编号为[%s]的机构不存在",
        "-202131819998": "对不起，服务异常，请拨打客服",
        "-202131819999": "对不起，服务异常，请拨打客服",
        "-202131820001": "传入的信息为空",
        "-202131820002": "编号为[%s]的机构不存在",
        "-202131829998": "对不起，服务异常，请拨打客服",
        "-202131829999": "对不起，服务异常，请拨打客服",
        "-202131830001": "传入的信息为空",
        "-202131830002": "编号为[%s]的机构不存在",
        "-202131839998": "对不起，服务异常，请拨打客服",
        "-202131839999": "对不起，服务异常，请拨打客服",
        "-202131900001": "传入的信息为空",
        "-202131900002": "传入的信息不完整",
        "-202131900003": "发送短信的手机号为空",
        "-202131900004": "编号为[%s]的机构不存在",
        "-202131900005": "机构[%s]的密码不正确",
        "-202131900006": "[%s]IP地址访问RMI发送短信次数[%s]超过最大限制次数[%s]",
        "-202131900007": "对不起，手机号全部被限制接收短信",
        "-202131900008": "编号为[%s]的机构没有可用的通道",
        "-202131900009": "编号为[%s]的机构没有可使用发送短信的第三方短信平台",
        "-202131900010": "调用适配器发送短信失败，%s",
        "-202131900011": "机构[%s]没有IP地址白名单",
        "-202131900012": "机构[%s]中不包含IP地址[%s]的白名单",
        "-202131900013": "对不起，当日可发短信已达上限",
        "-202131909998": "对不起，服务异常，请拨打客服",
        "-202131909999": "对不起，服务异常，请拨打客服",
        "-202132000001": "传入的信息为空",
        "-202132000002": "接收人类型[%s]不存在",
        "-202132009998": "对不起，服务异常，请拨打客服",
        "-202132009999": "对不起，服务异常，请拨打客服",
        "-202132109998": "对不起，服务异常，请拨打客服",
        "-202132109999": "对不起，服务异常，请拨打客服",
        "-202132119998": "对不起，服务异常，请拨打客服",
        "-202132119999": "对不起，服务异常，请拨打客服",
        "-202132129998": "对不起，服务异常，请拨打客服",
        "-202132129999": "对不起，服务异常，请拨打客服",
        "-202140010001": "传入的信息为空",
        "-202140010002": "传入的信息不完整",
        "-202140009999": "返回错误编号[202100209999]",
        "-202140020001": "传入的集合信息为空",
        "-202194000101": "调用发送消息之前请先启动",
        "-202194000102": "待发送消息队列已满",
        "-202194000103": "添加待发送消息队列失败",
        "-202194000104": "消息接收人信息为空",
        "-202194000105": "消息接收人信息不正确",
        "-202194000201": "连接消息服务失败",
        "-202194000202": "执行发送短信失败",
        "-202194000301": "不支持群发短信",
        "-202194000302": "不支持发送短信",
        "-202231010199": "消息公告列表查询未知错误",
        "-202231020199": "系统消息列表查询未知错误",
        "-202300009001": "对不起，服务异常，请拨打客服",
        "-202300009101": "对不起，服务异常，请拨打客服",
        "-202300009396": "对不起，服务异常，请稍后重试",
        "-202300009399": "对不起，未知异常，请拨打客服",
        "-202300019999": "对不起，未知异常，请拨打客服",
        "-202300022000": "历史消息查询结果为空",
        "-202300029996": "对不起，服务异常，请稍后重试",
        "-202300029999": "对不起，未知异常，请拨打客服",
        "-202710010001": "传入的消息内容为空",
        "-202710010002": "调用发送微信企业号服务发送消息异常",
        "-202710010099": "对不起，未知异常，请拨打客服",
        "0": "操作成功",
        "203110000001": "服务警告",
        "-203110009001": "服务错误",
        "-203110019101": "服务错误",
        "-203110019102": "服务错误",
        "-203110019103": "服务错误",
        "-203110019999": "服务错误",
        "-203110029101": "服务错误",
        "-203110029102": "服务错误",
        "-203110029103": "服务错误",
        "-203110029999": "服务错误",
        "203110030001": "服务警告",
        "-203110039001": "服务错误",
        "-203110039002": "服务错误",
        "-203110039004": "服务错误",
        "-203110039101": "服务错误",
        "-203110039997": "服务错误",
        "-203110039999": "服务错误",
        "203110040001": "服务警告",
        "-203110049999": "服务错误",
        "-203110059001": "服务错误",
        "-203110059002": "服务错误",
        "-203110059101": "服务错误",
        "-203110059999": "服务错误",
        "-203110069101": "服务错误",
        "-203110069102": "服务错误",
        "-203110069103": "服务错误",
        "-203110069999": "服务错误",
        "-203110079101": "服务错误",
        "-203110079102": "服务错误",
        "-203110079103": "服务错误",
        "-203110079999": "服务错误",
        "203110080001": "服务警告",
        "-203110089101": "服务错误",
        "-203110089201": "服务错误",
        "203120000001": "服务警告",
        "-203120009001": "服务错误",
        "-203120019001": "服务错误",
        "-203120019999": "服务错误",
        "-203120029001": "服务错误",
        "-203120029999": "服务错误",
        "-203120039001": "服务错误",
        "-203120039999": "服务错误",
        "-203120049001": "服务错误",
        "-203120049999": "服务错误",
        "-203120059001": "服务错误",
        "-203120059999": "服务错误",
        "203120060001": "服务警告",
        "-203120069001": "服务错误",
        "-203120069101": "服务错误",
        "-203120069102": "服务错误",
        "-203120069103": "服务错误",
        "-203120069999": "服务错误",
        "-203120079001": "服务错误",
        "-203120079999": "服务错误",
        "203120080001": "服务警告",
        "-203120089001": "服务错误",
        "-203120089101": "服务错误",
        "-203120089999": "服务错误",
        "-203120099001": "服务错误",
        "-203120099999": "服务错误",
        "-203120109001": "服务错误",
        "-203120109101": "服务错误",
        "-203120109999": "服务错误",
        "-203120119101": "服务错误",
        "-203120119102": "服务错误",
        "-203120119999": "服务错误",
        "203160000001": "服务警告",
        "-203160009001": "服务错误",
        "-203160019001": "服务错误",
        "-203160029001": "",
        "203160030001": "服务警告",
        "-203160039001": "服务错误",
        "-203160049001": "服务错误",
        "-203160059001": "服务错误",
        "-203160069101": "服务错误",
        "-203160069001": "服务错误",
        "-203160069999": "服务错误",
        "203180000001": "服务错误",
        "-203180009001": "",
        "203180010001": "服务警告",
        "203180010002": "服务警告",
        "-203180019001": "服务错误",
        "-203180019002": "服务错误",
        "-203180019003": "服务错误",
        "-203180019004": "服务错误",
        "-203180019005": "服务错误",
        "-203180019101": "",
        "-203180019102": "",
        "-203180019103": "服务错误",
        "-203180019104": "服务错误",
        "-203180019105": "服务错误",
        "-203180019106": "服务错误",
        "-203180019107": "服务错误",
        "-203180019108": "服务错误",
        "-203180019109": "服务错误",
        "-203180019997": "服务错误",
        "-203180019999": "服务错误",
        "-203201010199": "商品分类信息查询未知错误",
        "203201010201": "添加商品分类信息成功",
        "203201010301": "修改商品分类信息成功",
        "-203201010302": "添加的商品中有不属于该市场模式的商品",
        "203201010401": "删除商品分类信息成功",
        "-203201010402": "删除商品分类信息失败，该商品分类信息有子分类",
        "-203201010403": "删除商品分类信息失败，请先清除关联商品后再删除",
        "-203201020199": "商品信息查询未知错误",
        "203201020201": "编辑商品信息成功",
        "-203201020202": "编辑商品信息失败，图片路径不合法",
        "-203201020599": "商品排序号查询未知错误",
        "-203201020699": "商品排序号添加未知错误",
        "203201020601": "添加商品排序号成功",
        "-203201020799": "商品排序号修改未知错误",
        "203201020701": "修改商品排序号成功",
        "-203201020899": "商品排序号删除未知错误",
        "203201020801": "删除商品排序号成功",
        "-203201020999": "交易模式查询未知错误",
        "203201030199": "指数商品查询未知错误",
        "203201030201": "添加指数信息成功",
        "-203201030202": "指数所属交易节不能为空",
        "203201030301": "修改指数信息成功",
        "-203201030302": "指数所属交易节不能为空",
        "-203201030303": "该指数状态不可修改",
        "203201030401": "修改指数所辖商品成功",
        "-203201030402": "指数所辖商品不能为空",
        "-203201030599": "指数所辖商品状态查询未知异常",
        "203201030601": "指数所辖商品当前状态修改成功",
        "203201030602": "删除指数所辖下市商品成功",
        "-203201040603": "商品未下市,删除失败",
        "-203201040199": "商品属性查询未知错误",
        "203201040201": "添加商品属性成功",
        "-203201040299": "添加商品属性未知异常",
        "203201040301": "修改商品属性成功",
        "-203201040399": "修改商品属性未知异常",
        "203201040401": "删除商品属性成功",
        "-203201040499": "删除商品属性未知异常",
        "-203202010199": "白名单查询未知错误",
        "203202010201": "添加白名单成功",
        "203202010301": "修改白名单成功",
        "203202010401": "删除白名单成功",
        "203202010501": "IP地址白名单实时生效操作成功",
        "-203202010596": "连接行情系统后台业务处理服务失败，请检查服务",
        "-203202010599": "白名单实时生效未知错误",
        "-203202011099": "WEB服务信息查询未知错误",
        "203202020101": "对指定市场模式进行闭市处理操作成功",
        "-203202020196": "连接行情系统后台业务处理服务失败，请检查服务",
        "-203202020199": "对指定市场模式进行闭市处理操作未知错误",
        "203202020501": "对指定市场模式进行初始化处理操作成功",
        "-203202020596": "连接行情系统后台业务处理服务失败，请检查服务",
        "-203202020599": "对指定市场模式进行初始化处理操作未知错误",
        "203202021001": "制作历史K线成功",
        "-203202021096": "连接行情系统后台业务处理服务失败，请检查服务",
        "-203202021099": "制作历史K线未知错误",
        "-203203010199": "指数历史基准值查询未知错误",
        "-203203020199": "日志查询未知错误",
        "203260000001": "服务警告",
        "-203260009001": "服务错误",
        "-203260019001": "服务错误",
        "-203260029001": "服务错误",
        "203300000001": "服务警告",
        "-203300009001": "服务错误",
        "-203300019001": "服务错误",
        "-203300019996": "服务错误",
        "-203300019999": "服务错误",
        "-203300029001": "服务错误",
        "-203300029996": "服务错误",
        "-203300029999": "服务错误",
        "-203300039001": "服务错误",
        "-203300039996": "服务错误",
        "-203300039999": "服务错误",
        "-203300049001": "服务错误",
        "-203300049996": "服务错误",
        "-203300049999": "服务错误",
        "-203300059001": "服务错误",
        "-203300059996": "服务错误",
        "-203300059999": "服务错误",
        "-203300069001": "服务错误",
        "-203300069996": "服务错误",
        "-203300069999": "服务错误",
        "-203300079001": "服务错误",
        "-203300079996": "服务错误",
        "-203300079999": "服务错误",
        "203510000001": "服务警告",
        "-203510009001": "服务错误",
        "-203510019996": "服务错误",
        "-203510019999": "服务错误",
        "-203510029996": "服务错误",
        "-203510029999": "服务错误",
        "203510030001": "服务警告",
        "-203510039001": "服务错误",
        "-203510039101": "服务错误",
        "-203510039102": "服务错误",
        "-203510039103": "服务错误",
        "-203510039996": "服务错误",
        "-203510039997": "服务错误",
        "-203510039998": "服务错误",
        "-203510039999": "服务错误",
        "203510040001": "服务警告",
        "-203510049001": "服务错误",
        "-203510049002": "服务错误",
        "-203510049003": "服务错误",
        "-203510049004": "服务错误",
        "-203510049996": "服务错误",
        "-203510049997": "服务错误",
        "-203510049999": "服务错误",
        "203510050001": "服务警告",
        "-203510059001": "服务错误",
        "-203510059996": "服务错误",
        "-203510059999": "服务错误",
        "-203510069101": "服务错误",
        "-203510069001": "服务错误",
        "-203510069996": "服务错误",
        "-203510069997": "服务错误",
        "-203510069999": "服务错误",
        "-203510079996": "服务错误",
        "-203510079999": "",
        "-203510089101": "服务错误",
        "-203510089996": "服务错误",
        "-203510089999": "服务错误",
        "-203510099999": "服务错误",
        "-203510109001": "服务错误",
        "-203510109101": "服务错误",
        "-203510109996": "服务错误",
        "-203510109999": "服务错误",
        "203510110001": "服务警告",
        "-203510119996": "服务错误",
        "-203510119999": "服务错误",
        "-203510129996": "服务错误",
        "-203510129999": "服务错误",
        "-203510139996": "服务错误",
        "-203510139999": "服务错误",
        "-203510149001": "服务错误",
        "-203510149101": "服务错误",
        "-203510149996": "服务错误",
        "-203510149999": "服务错误",
        "203520000001": "服务警告",
        "-203520009001": "服务错误",
        "-203520019101": "服务错误",
        "-203520019102": "服务错误",
        "-203520029101": "服务错误",
        "-203520029102": "服务错误",
        "-203520029997": "服务错误",
        "-203520039001": "服务错误",
        "-203520039101": "服务错误",
        "-203520039102": "服务错误",
        "-203520039997": "服务错误",
        "-203520049001": "服务错误",
        "-203520049101": "服务错误",
        "-203520049102": "服务错误",
        "-203520049997": "服务错误",
        "-203520059101": "服务错误",
        "-203520059102": "服务错误",
        "-203520069001": "服务错误",
        "-203520069002": "服务错误",
        "-203520069101": "服务错误",
        "-203520069102": "服务错误",
        "-203520079001": "服务错误",
        "-203520079101": "服务错误",
        "-203520079102": "服务错误",
        "-203520079997": "服务错误",
        "-204101009999": "对不起，服务异常，请拨打客服",
        "-204101010099": "对不起，服务异常，请拨打客服",
        "-204101020099": "对不起，服务异常，请拨打客服",
        "-204101100001": "日志类型不存在",
        "-204101110001": "传入的系统编号或类型为空",
        "-204101110002": "传入的日志级别信息为空",
        "-204101110003": "没有可用监控插件RMI信息",
        "-204101110004": "调用监控插件RMI失败",
        "-204101119999": "对不起，服务异常，请拨打客服",
        "-204101209901": "传入的信息为空",
        "-204101209902": "传入的不完整",
        "-204101209903": "比率[%s]已存在",
        "-204101209998": "对不起，服务异常，请拨打客服",
        "-204101209999": "对不起，服务异常，请拨打客服",
        "-204101300001": "传入的信息为空",
        "-204101300002": "传入的不完整",
        "-204101300003": "用户资金等级[%s]不存在",
        "-204101300004": "比率[%s]被占用",
        "-204101309998": "对不起，服务异常，请拨打客服",
        "-204101309999": "对不起，服务异常，请拨打客服",
        "-204101400001": "传入的信息为空",
        "-204101400002": "用户资金等级[%s]不存在",
        "-204101400003": "用户资金等级[%s]被其他业务使用",
        "-204101409998": "对不起，服务异常，请拨打客服",
        "-204101409999": "对不起，服务异常，请拨打客服",
        "-204101500001": "传入的信息为空",
        "-204101500002": "资金业务等级[%s]不存在",
        "-204101500003": "用户资金等级[%s]不存在",
        "-204101509998": "对不起，服务异常，请拨打客服",
        "-204101509999": "对不起，服务异常，请拨打客服",
        "204101600002": "调用系统[%s]下前置机RMI失败",
        "-204101600001": "传入的信息不完整",
        "-204101600002": "没有可用的前置机工作模式信息",
        "-204101600003": "系统[%s]下没有可用的前置机",
        "-204101600004": "设置系统[%s]下前置机工作模式失败，调用前置机RMI失败",
        "-204101609999": "对不起，服务异常，请拨打客服",
        "-204101619999": "对不起，服务异常，请拨打客服",
        "-204101700001": "传入的信息为空",
        "-204101700002": "传入不完整",
        "-204101700003": "调用前置机 RMI 连接异常",
        "-204101700004": "调用前置机 RMI 非 RemoteException 异常",
        "-204101700005": "调用前置机处理时失败",
        "-204101700006": "系统[%s]下没有可用的监控插件信息",
        "-204101709999": "对不起，服务异常，请拨打客服",
        "-204101800001": "传入的信息为空",
        "-204101800002": "传入不完整",
        "-204101800003": "调用前置机 RMI 连接异常",
        "-204101800004": "调用前置机 RMI 非 RemoteException 异常",
        "-204101800005": "调用前置机处理时失败",
        "-204101800006": "系统[%s]下没有可用的监控插件信息",
        "-204101809999": "对不起，服务异常，请拨打客服",
        "-204101900001": "传入的信息为空",
        "-204101900002": "市场管理员已存在",
        "-204101909998": "对不起，服务异常，请拨打客服",
        "-204101909999": "对不起，服务异常，请拨打客服",
        "-204102000001": "传入的信息为空",
        "-204102009998": "对不起，服务异常，请拨打客服",
        "-204102009999": "对不起，服务异常，请拨打客服",
        "-205100000000": "传入登录用户类型错误",
        "-205100000001": "操作人为[%s]的用户在[%s]未登录或登录超时",
        "-205100000002": "交易用户[%s]在[%s]以[%s]未登录或登录超时",
        "-205100000003": "加密串验证失败",
        "-205100000004": "传入信息为空",
        "-205100000005": "有未完成的仓单",
        "-205100009998": "对不起，服务异常，请拨打客服",
        "-205100009999": "对不起，服务异常，请拨打客服",
        "-205100010101": "传入的信息为空",
        "-205100010102": "传入的信息不完整",
        "-205100010103": "传入的仓单属性信息为空",
        "-205100010104": "编号为[%s]的仓库信息不存在",
        "-205100010105": "仓库[%s]不可用",
        "-205100010106": "品名[%s]开启标码时仓单数量必须为整数",
        "-205100010107": "品名[%s]开启标码需要传入编码区间信息",
        "-205100010108": "品名[%s]开启标码录入数量[%s]与选号数量[%s]不相等",
        "-205100010109": "区间[%s]~[%s]范围内的编号与品名[%s]下的编号冲突",
        "-205100010110": "品名[%s]开启标码，选号[%s]已被占用",
        "-205100010201": "传入的信息不完整",
        "-205100010202": "仓单[%s]不存在",
        "-205100010203": "仓单[%s]不是未注册状态",
        "-205100010301": "传入的信息不完整",
        "-205100010302": "仓单%s]不存在",
        "-205100010303": "仓单[%s]不是注册状态",
        "-205100010304": "仓单[%s]正在参与其他仓单业务",
        "-205100010401": "传入的信息不完整",
        "-205100010402": "仓单[%s]不存在",
        "-205100010403": "仓单[%s]不是未注册状态",
        "-205100010404": "普通仓单[%s]至少需要拆分2条新的仓单信息",
        "-205100010405": "仓单[%s]的可用数量[%s]小于拆单数量[%s]",
        "-205100010406": "普通仓单仓单[%s]的没有全部拆单",
        "-205100010407": "仓单[%s]对应的品名[%s]开启标码，拆单数量必须是整数",
        "-205100010408": "仓单[%s]对应的品名[%s]开启标码，拆单总数量小数部分的值大于0",
        "-205100010409": "传入重复的选号",
        "-205100010410": "库存仓单[%s]的拆单数量必须小于可用数量",
        "-205100010411": "拆单数量必须大于0",
        "-205100010501": "传入的信息不完整",
        "-205100010502": "仓单号为[%s]的仓单不存在",
        "-205100010503": "仓单[%s]的不是拆单处理中状态",
        "-205100010504": "仓单[%s]没有待处理的拆单申请信息",
        "-205100010505": "传入的拆单信息与仓单[%s]的实际拆单信息不匹配",
        "-205100010506": "仓单[%s]拆单后仓库仓单号不能为空",
        "-205100010507": "拆单数量必须大于0",
        "-205100020101": "传入的信息为空",
        "-205100020102": "仓单[%s]不存在",
        "-205100020103": "仓单[%s]的不是未注册状态",
        "-205100020201": "传入的信息为空",
        "-205100020202": "出库[%s]信息不存在",
        "-205100020203": "出库[%s]为自提出库方式",
        "-205100020204": "出库[%s]不是出库申请状态",
        "-205100020301": "传入的信息为空",
        "-205100020302": "出库[%s]信息不存在",
        "-205100020303": "出库[%s]为自提出库方式",
        "-205100020304": "出库[%s]不是设置配送费用状态",
        "-205100020305": "配送费用设置信息[%s]不存在",
        "-205100020306": "调用财务系统RMI添加配送费用流水失败",
        "-205100020401": "传入的信息为空",
        "-205100020402": "出库[%s]信息不存在",
        "-205100020403": "出库[%s]不是出库申请状态",
        "-205100020404": "仓单出库密钥错误",
        "-205100020405": "提货人错误",
        "-205100020406": "提货人身份证号错误",
        "-205100020407": "出库[%s]对应的仓单信息不存在",
        "-205100020408": "出库[%s]中的数量[%s]与出库对应仓单关系中的数量[%s]不相等",
        "-205100020409": "出库[%s]对应的仓单[%s]不存在",
        "-205100020410": "出库[%s]对应的仓单[%s]不是出库申请状态",
        "-205100020411": "出库[%s]不是用户确定出库状态",
        "-205100020501": "传入的信息为空",
        "-205100020502": "出库[%s]信息不存在",
        "-205100020503": "自提出库[%s]不是出库申请状态",
        "-205100020504": "出库[%s]对应的仓单关系信息不存在",
        "-205100020505": "出库[%s]对应的仓单[%s]不存在",
        "-205100020506": "出库[%s]对应的仓单[%s]不是出库申请中状态",
        "-205100020507": "配送出库[%s]不是出库申请或设置配送费用状态",
        "-205100030101": "传入的仓单号信息为空",
        "-205100030102": "传入的信息不完整",
        "-205100030103": "传入的仓单[%s]不存在",
        "-205100030201": "传入的信息为空",
        "-205100030202": "仓单[%s]不存在",
        "-205100030203": "仓单[%s]不是注册仓单",
        "-205100030204": "仓单[%s]正在参与其他仓单业务",
        "-205100030301": "传入的信息为空",
        "-205100030302": "系统[%s]的委托[%s]下没有待处理的卖仓单信息",
        "-205100030401": "传入的信息不完整",
        "-205100030402": "系统[%s]的委托[%s]下没有待处理的卖仓单信息！",
        "-205100030403": "仓单[%s]不存在",
        "-205100030501": "传入的信息不完整",
        "-205100030502": "仓单[%s]不存在",
        "-205100030503": "仓单[%s]所属人或所属人类型不匹配",
        "-205100030504": "仓单[%s]不是注册仓单",
        "-205100030505": "仓单[%s]正在参与其他仓单业务",
        "-205100030601": "传入的信息为空",
        "-205100030602": "传入的信息为空",
        "-205100030701": "传入的信息为空",
        "-205100030702": "系统[%s]下参与合同[%s]交易的待释放交收冻结仓单信息为空",
        "-205100030703": "货权转移失败",
        "-205100030801": "传入的信息不完整",
        "-205100030802": "融资[%s]已经存在",
        "-205100030803": "仓单[%s]不存在",
        "-205100030804": "仓单[%s]不是注册仓单",
        "-205100030805": "仓单[%s]正在参与其他仓单业务",
        "-205100030901": "融资仓单[%s]不存在",
        "-205100030902": "融资仓单[%s]已释放",
        "-205100031001": "融资仓单[%s]不存在",
        "-205100031002": "融资仓单[%s]已释放",
        "-205100031101": "传入的信息为空",
        "-205100031102": "单[%s]不存在",
        "-205100031103": "仓单[%s]不是注册仓单",
        "-205100031104": "仓单[%s]正在参与其他仓单业务",
        "-205100031201": "传入的信息为空",
        "-205100031202": "仓单[%s]没有相关的冻结仓单或者已解冻",
        "-205100031203": "系统[%s]下不存在冻结仓单或者已全部解冻！",
        "-205100031204": "仓单[%s]正在参与其他仓单业务",
        "-205100031301": "传入的信息为空",
        "-205100031302": "仓单[%s]不存在",
        "-205100031303": "仓单[%s]所属人与传入的所属人信息不符合",
        "-205100031304": "仓单[%s]不是注册状态",
        "-205100031305": "仓单[%s]正在参与其他仓单业务",
        "-205100031401": "传入的信息为空",
        "-205100031402": "没有待释放的托管申请仓单信息",
        "-205100031501": "传入的信息为空",
        "-205100031502": "仓单[%s]不存在",
        "-205100031503": "仓单[%s]不是注册状态",
        "-205100031504": "仓单[%s]正在参与其他仓单业务",
        "-205100031505": "没有可用仓单信息",
        "-205100031601": "传入的信息为空",
        "-205100031602": "代码为[%s]的商品下没有待释放的发行业务仓单",
        "-205100031603": "商品[%s]下的仓单存在未释放的选号信息",
        "205100031701": "部分货权转移成功",
        "-205100031701": "传入的信息为空",
        "-205100031702": "没有可用的未注册仓单信息",
        "-205100040101": "传入的信息为空",
        "-205100040102": "传入的信息不完整",
        "-205100040103": "品名[%s]不存在",
        "-205100040104": "品名[%s]开启标码，交收申请数量小数部分的值大于0",
        "-205100040105": "品名[%s]开启标码，传入的选号为空",
        "-205100040106": "品名[%s]开启标码，选号数量[%s]与交收申请数量[%s]不相等",
        "-205100040107": "交收申请数量[%s]大于所选仓单可用总数量[%s]",
        "-205100040108": "品名[%s]下没有可用的库存仓单信息",
        "-205100040109": "货权转移失败",
        "-205100040110": "拆单/货权转移流程失败",
        "-205100040201": "传入的品名或仓库或交收数量为空",
        "-205100040202": "传入的交收数量不是有效的整数",
        "-205100040203": "品名[%s]没有可用的选号信息",
        "-205100040204": "品名下的[%s]可用选号数量[%s]小于交收数量[%s]",
        "-205100040205": "已选号数量[%s]大于交收数量[%s]",
        "-205100040206": "品名下的[%s]可用选号数量[%s]小于已选号数量[%s]",
        "-205100040207": "传入的选号信息中存在不可使用的编号",
        "-205100040208": "品名[%s]未开启标码选号，不需要选号",
        "-205100040209": "传入的选号中存在不属于仓库[%s]的编号",
        "-205100040301": "传入的信息为空",
        "-205100040302": "交收数量[%s]与选号数量[%s]不匹配",
        "-205100040303": "加密串验证失败",
        "-205100040304": "品名[%s]不存在",
        "-205100040305": "提品名[%s]未开启标码选号，不需要选号",
        "-205100040401": "托管申请编号为空",
        "-205100040402": "传入的仓单号为空",
        "-205100040403": "加密串验证失败",
        "-205100040404": "托管申请[%s]下没有待释放的托管业务系统仓单",
        "-205100040405": "仓单[%s]不是待释放的托管业务系统仓单",
        "-205100040406": "托管申请[%s]下的仓单存在未释放的选号信息",
        "-205100040407": "仓单下存在未释放的选号信息",
        "-205100040503": "确认交收申请失败，品名[%s]不存在",
        "-205100040504": "一键交收失败，品名[%s]开启标码，品名不支持一键交收",
        "-205100040505": "一键交收失败，交收申请数量[%s]大于所选仓单可用总数量[%s]",
        "-205100040506": "一键交收失败，品名[%s]下没有可用的库存仓单信息",
        "-205100040507": "一键交收失败，拆单/货权转移流程失败",
        "-205100040508": "确认交收申请失败，货权转移流程失败",
        "-205100040509": "出库申请操作失败，仓单[%s]不存在",
        "-205100040510": "一键交收失败，配送费用有误",
        "-205100050101": "传入的商品分类为空",
        "-205100050102": "商品分类[%s]下存在可用的品名信息",
        "-205100050201": "传入的品名编号为空",
        "-205100050202": "品名[%s]下存在未结束的仓单信息",
        "-205100060101": "传入的信息不完整",
        "-205100060102": "传入的加密串错误",
        "-205100060103": "仓单[%s]不存在",
        "-205100060104": "仓单[%s]不是注册仓单",
        "-205100060105": "仓单[%s]正在参与其他仓单业务",
        "-205100060201": "传入的信息不完整",
        "-205100060202": "传入的加密串错误",
        "-205100060203": "抵顶仓单数量已发生变化",
        "-205100060301": "传入的信息不完整",
        "-205100060302": "传入的加密串错误",
        "-205100060303": "系统[%s]下参与合同[%s]交易的待释放交收仓单信息为空",
        "-205100060304": "货权转移失败",
        "-205100060305": "品名[%s]不存在",
        "-205100060306": "品名[%s]已删除",
        "-205100060307": "品名[%s]开启标码时仓单数量必须为整数",
        "-205100060308": "已冻结的抵顶数量[%s]小于交收数量[%s]",
        "-205100060309": "没有可用的抵顶交收仓单",
        "-205100070001": "传入的信息为空",
        "-205100070002": "传入的信息不完整",
        "-205100070003": "不能是系统仓单",
        "-205100070004": "融资仓单数量不能为0",
        "-205100070005": "加密串验证失败",
        "-205100070006": "融资[%s]已有融资业务",
        "-205100070007": "融资仓单数量为空",
        "-205100070008": "融资仓单数量必须大于0",
        "-205100070009": "编号为[%s]仓单不存在",
        "-205100070010": "仓单[%s]不是注册仓单",
        "-205100070011": "仓单所属人与融资参与人不是同一人",
        "-205100070012": "品名[%s]不存在",
        "-205100070013": "仓单[%s]参与的融资数量不是有效整数",
        "-205100070014": "仓单仓单数量与仓单参与融资数量不一致",
        "-205100070015": "仓单[%s]正在参与其他业务",
        "-205100080001": "传入的信息为空",
        "-205100080002": "传入的信息不完整",
        "-205100080003": "加密串验证失败",
        "-205100080004": "融资[%s]仓单[%s]不存在",
        "-205100080005": "融资[%s]仓单[%s]已释放",
        "-205100080006": "仓单[%s]不存在",
        "-205100080007": "仓单[%s]不是注册仓单",
        "-205100080008": "仓单[%s]没有融资业务",
        "-205100090001": "传入的信息为空",
        "-205100090002": "传入的信息不完整",
        "-205100090003": "加密串验证失败",
        "-205100090004": "融资[%s]仓单[%s]不存在",
        "-205100090005": "融资[%s]仓单[%s]已释放",
        "-205100090006": "仓单[%s]不存在",
        "-205100090007": "仓单[%s]不是注册仓单",
        "-205100090008": "品名[%s]不存在",
        "-205100090009": "仓单[%s]参与的融资数量不是有效整数",
        "-205100090010": "仓单[%s]没有融资业务",
        "-205100090011": "融资释放数量[%s]大于仓单可用数量[%s]",
        "-205100100001": "传入的信息为空",
        "-205100100002": "传入的信息不完整",
        "-205100100003": "加密串验证失败",
        "-205100100004": "融资[%s]仓单[%s]不存在",
        "-205100100005": "融资[%s]仓单[%s]已释放",
        "-205100100006": "仓单[%s]不存在",
        "-205100100007": "仓单[%s]不是注册仓单",
        "-205100100008": "新货权所属人不能是自己",
        "-205100100009": "品名[%s]不存在",
        "-205100100010": "仓单[%s]参与的融资数量不是有效整数",
        "-205100100011": "仓单[%s]没有融资业务",
        "-205100100012": "融资释放数量[%s]大于仓单可用数量[%s]",
        "-205100110001": "传入的信息为空",
        "-205100110002": "传入的信息不完整",
        "-205100110003": "加密串验证失败",
        "-205100110004": "融资[%s]仓单[%s]不存在",
        "-205100110005": "融资[%s]仓单[%s]已释放",
        "-205100110006": "仓单[%s]不存在",
        "-205100110007": "仓单[%s]不是注册仓单",
        "-205100110008": "新货权所属人不能是自己",
        "-205100110009": "仓单[%s]没有融资业务",
        "-205100120001": "传入信息为空",
        "-205100120002": "传入信息不完整",
        "-205100120003": "收货地址不属于该客户",
        "-205231010199": "未注册仓单列表查询未知异常",
        "205231010201": "注册仓单成功",
        "-205231010296": "连接仓单系统核心服务失败，请检查仓单系统核心服务",
        "-205231010299": "注册仓单失败未知异常",
        "205231010301": "拆仓单申请成功",
        "-205231010396": "连接仓单系统核心服务失败，请检查仓单系统核心服务",
        "-205231010399": "拆仓单申请未知异常",
        "205231010401": "仓单出库申请成功",
        "-205231010496": "连接仓单系统核心服务失败，请检查仓单系统核心服务",
        "-205231010499": "仓单出库申请未知异常",
        "-205231020199": "可用仓单列表查询未知异常",
        "205231020201": "注销注册仓单成功",
        "-205231020296": "连接仓单系统核心服务失败，请检查仓单系统核心服务",
        "-205231020299": "注销注册仓单未知异常",
        "-205231020399": "卖仓单查询未知异常",
        "-205231020499": "交收仓单查询未知异常",
        "-205231020599": "冻结仓单查询未知异常",
        "-205231030199": "拆单申请管理列表查询未知异常",
        "-205231030299": "已拆仓单列表查询未知异常",
        "-205231030399": "拆单失败列表查询位置异常",
        "-205231040199": "仓单出库申请查询未知异常",
        "205231040201": "仓单出库撤销成功",
        "-205231040296": "连接仓单系统核心服务失败，请检查仓单系统核心服务",
        "-205231040299": "仓单出库撤销未知异常",
        "-205231040399": "已出库仓单列表查询未知异常",
        "-205300019996": "对不起，服务异常，请稍后重试",
        "-205300019999": "对不起，未知异常，请拨打客服",
        "-205300020001": "仓单号不能为空",
        "205300021000": "申请成功",
        "-205300021001": "申请失败",
        "-205300029996": "对不起，服务异常，请稍后重试",
        "-205300029999": "对不起，未知异常，请拨打客服",
        "-205300030001": "仓单号不能为空",
        "-205300030002": "拆分后数量不能为空",
        "205300031000": "申请成功",
        "-205300031001": "申请失败",
        "-205300039001": "参数不合法",
        "-205300039996": "对不起，服务异常，请稍后重试",
        "-205300039999": "对不起，未知异常，请拨打客服",
        "-205300040001": "仓单号不能为空",
        "-205300042000": "获取选号区间结果为空",
        "-205300049996": "对不起，服务异常，请稍后重试",
        "-205300049999": "对不起，未知异常，请拨打客服",
        "-205300050001": "仓单号不能为空",
        "-205300052000": "获取区间所有可用选号为空",
        "-205300059996": "对不起，服务异常，请稍后重试",
        "-205300059999": "对不起，未知异常，请拨打客服",
        "-205300069996": "对不起，服务异常，请稍后重试",
        "-205300069999": "对不起，未知异常，请拨打客服",
        "-205300070001": "仓单号不能为空",
        "205300071000": "注销成功",
        "-205300071001": "注销失败",
        "-205300079996": "对不起，服务异常，请稍后重试",
        "-205300079999": "对不起，未知异常，请拨打客服",
        "-205300089996": "对不起，服务异常，请稍后重试",
        "-205300089999": "对不起，未知异常，请拨打客服",
        "-205300099996": "对不起，服务异常，请稍后重试",
        "-205300099999": "对不起，未知异常，请拨打客服",
        "-205300109996": "对不起，服务异常，请稍后重试",
        "-205300109999": "对不起，未知异常，请拨打客服",
        "-205300119996": "对不起，服务异常，请稍后重试",
        "-205300119999": "对不起，未知异常，请拨打客服",
        "-205300129996": "对不起，服务异常，请稍后重试",
        "-205300129999": "对不起，未知异常，请拨打客服",
        "-205300139996": "对不起，服务异常，请稍后重试",
        "-205300139999": "对不起，未知异常，请拨打客服",
        "-205300140001": "仓单状态不合法",
        "-205300149996": "对不起，服务异常，请稍后重试",
        "-205300149999": "对不起，未知异常，请拨打客服",
        "-205300150001": "仓单号不能为空",
        "-205300150002": "提货人不能为空",
        "-205300150003": "提货人证件号不能为空",
        "-205300150004": "出库方式不正确",
        "-205300150005": "收获地址不能为空",
        "-205300150006": "电话不能为空",
        "205300151000": "申请成功",
        "-205300151001": "申请失败",
        "-205300159996": "对不起，服务异常，请稍后重试",
        "-205300159999": "对不起，未知异常，请拨打客服",
        "-205300169996": "对不起，服务异常，请稍后重试",
        "-205300169999": "对不起，未知异常，请拨打客服",
        "-205300170001": "出库ID不能为空",
        "205300171000": "撤销成功",
        "-205300171001": "撤销失败",
        "-205300179996": "对不起，服务异常，请稍后重试",
        "-205300179999": "对不起，未知异常，请拨打客服",
        "-205300180001": "出库ID不能为空",
        "205300181000": "操作成功",
        "-205300181001": "操作失败",
        "-205300189996": "对不起，服务异常，请稍后重试",
        "-205300189999": "对不起，未知异常，请拨打客服",
        "-205300199996": "对不起，服务异常，请稍后重试",
        "-205300199999": "对不起，未知异常，请拨打客服",
        "-205300200001": "仓单号不合法",
        "-205300202000": "所选号查询结果为空",
        "-205300209996": "对不起，服务异常，请稍后重试",
        "-205300209999": "对不起，未知异常，请拨打客服",
        "-205300219999": "对不起，服务异常，请拨打客服",
        "-205300229996": "对不起，服务异常，请稍后重试",
        "-205300229999": "对不起，未知异常，请拨打客服",
        "-205300230001": "操作类型不合法",
        "-205300230002": "收货人姓名不能为空",
        "-205300230003": "收货人身份证号码不能为空",
        "-205300230004": "收货人电话号码不能为空",
        "-205300230005": "收货人地址不能为空",
        "-205300230006": "收货人邮政编码不能为空",
        "-205300230007": "提货地址ID不合法",
        "205300231000": "操作成功",
        "-205300239996": "对不起，服务异常，请稍后重试",
        "-205300239999": "对不起，未知异常，请拨打客服",
        "-205300240001": "操作类型不合法",
        "-205300240002": "提货地址ID不合法",
        "205300241000": "操作成功",
        "-205300249996": "对不起，服务异常，请稍后重试",
        "-205300249999": "对不起，未知异常，请拨打客服",
        "-205300250001": "查询类型不合法",
        "-205300259996": "对不起，服务异常，请稍后重试",
        "-205300259999": "对不起，未知异常，请拨打客服",
        "-205300269996": "对不起，服务异常，请稍后重试",
        "-205300269999": "对不起，未知异常，请拨打客服",
        "-208100000001": "行情服务器信息为空，请检查配置",
        "-208100000101": "身份验证失败",
        "-208100009999": "对不起，服务异常，请稍后重试",
        "-209300019999": "对不起，未知异常，请拨打客服",
        "-209300022000": "资产数据查询结果为空",
        "-209300029996": "对不起，服务异常，请稍后重试",
        "-209300029999": "对不起，未知异常，请拨打客服",
        "-209301012000": "委托报表查询结果为空",
        "-209301019996": "对不起，服务异常，请稍后重试",
        "-209301019999": "对不起，未知异常，请拨打客服",
        "-209301022000": "成交明细报表查询结果为空",
        "-209301029996": "对不起，服务异常，请稍后重试",
        "-209301029999": "对不起，未知异常，请拨打客服",
        "-209301032000": "持仓明细报表查询结果为空",
        "-209301039996": "对不起，服务异常，请稍后重试",
        "-209301039999": "对不起，未知异常，请拨打客服",
        "-209301042000": "资金报表查询结果为空",
        "-209301049996": "对不起，服务异常，请稍后重试",
        "-209301049999": "对不起，未知异常，请拨打客服",
        "-209301052000": "交收报表查询结果为空",
        "-209301059996": "对不起，服务异常，请稍后重试",
        "-209301059999": "对不起，未知异常，请拨打客服",
        "-209302012000": "委托报表查询结果为空",
        "-209302019996": "对不起，服务异常，请稍后重试",
        "-209302019999": "对不起，未知异常，请拨打客服",
        "-209302022000": "成交报表查询结果为空",
        "-209302029996": "对不起，服务异常，请稍后重试",
        "-209302029999": "对不起，未知异常，请拨打客服",
        "-209302032000": "持仓汇总报表查询结果为空",
        "-209302039996": "对不起，服务异常，请稍后重试",
        "-209302039999": "对不起，未知异常，请拨打客服",
        "-209302042000": "持仓明细报表查询结果为空",
        "-209302049996": "对不起，服务异常，请稍后重试",
        "-209302049999": "对不起，未知异常，请拨打客服",
        "-209302052000": "托管发行申购报表查询结果为空",
        "-209302059996": "对不起，服务异常，请稍后重试",
        "-209302059999": "对不起，未知异常，请拨打客服",
        "-209302062000": "托管发行申购中签报表查询结果为空",
        "-209302069996": "对不起，服务异常，请稍后重试",
        "-209302069999": "对不起，未知异常，请拨打客服",
        "-209302072000": "定向配售记录报表查询结果为空",
        "-209302079996": "对不起，服务异常，请稍后重试",
        "-209302079999": "对不起，未知异常，请拨打客服",
        "-209302082000": "定向配售成交报表查询结果为空",
        "-209302089996": "对不起，服务异常，请稍后重试",
        "-209302089999": "对不起，未知异常，请拨打客服",
        "-209302092000": "比例配售记录报表查询结果为空",
        "-209302099996": "对不起，服务异常，请稍后重试",
        "-209302099999": "对不起，未知异常，请拨打客服",
        "-209302102000": "比例配售成交报表查询结果为空",
        "-209302109996": "对不起，服务异常，请稍后重试",
        "-209302109999": "对不起，未知异常，请拨打客服",
        "-209302112000": "发行申购报表查询结果为空",
        "-209302119996": "对不起，服务异常，请稍后重试",
        "-209302119999": "对不起，未知异常，请拨打客服",
        "-209302122000": "发行申购中签报表查询结果为空",
        "-209302129996": "对不起，服务异常，请稍后重试",
        "-209302129999": "对不起，未知异常，请拨打客服",
        "-209302132000": "托管记录报表查询结果为空",
        "-209302139996": "对不起，服务异常，请稍后重试",
        "-209302139999": "对不起，未知异常，请拨打客服",
        "-209302142000": "商品交收报表查询结果为空",
        "-209302149996": "对不起，服务异常，请稍后重试",
        "-209302149999": "对不起，未知异常，请拨打客服",
        "-209302152000": "商品过户报表查询结果为空",
        "-209302159996": "对不起，服务异常，请稍后重试",
        "-209302159999": "对不起，未知异常，请拨打客服",
        "-209302162000": "资金状况报表查询结果为空",
        "-209302169996": "对不起，服务异常，请稍后重试",
        "-209302169999": "对不起，未知异常，请拨打客服",
        "-209302170001": "交收申请ID不合法",
        "-209302172000": "获取交收所选号结果为空",
        "-209302179996": "对不起，服务异常，请稍后重试",
        "-209302179999": "对不起，未知异常，请拨打客服",
        "-209303012000": "总账汇总查询结果为空",
        "-209303019996": "对不起，服务异常，请稍后重试",
        "-209303019999": "对不起，未知异常，请拨打客服",
        "-209303022000": "总账明细查询结果为空",
        "-209303029996": "对不起，服务异常，请稍后重试",
        "-209303029999": "对不起，未知异常，请拨打客服",
        "-209303030001": "传入资金池编号不合法",
        "-209303032000": "资金流水报表查询结果为空",
        "-209303039996": "对不起，服务异常，请稍后重试",
        "-209303039999": "对不起，未知异常，请拨打客服",
        "-209303040001": "获取的资金池信息为空",
        "-209303049999": "对不起，未知异常，请拨打客服",
        "-209304012000": "操作分析报表查询结果为空",
        "-209304019996": "对不起，服务异常，请稍后重试",
        "-209304019999": "对不起，未知异常，请拨打客服",
        "-209305012000": "订单委托查询结果为空",
        "-209305019996": "对不起，服务异常，请稍后重试",
        "-209305019999": "对不起，未知异常，请拨打客服",
        "-209305022000": "订单成交明细查询结果为空",
        "-209305029996": "对不起，服务异常，请稍后重试",
        "-209305029999": "对不起，未知异常，请拨打客服",
        "-209305032000": "订单持仓汇总报表查询结果为空",
        "-209305039996": "对不起，服务异常，请稍后重试",
        "-209305039999": "对不起，未知异常，请拨打客服",
        "-209305042000": "订单持仓明细报表查询结果为空",
        "-209305049996": "对不起，服务异常，请稍后重试",
        "-209305049999": "对不起，未知异常，请拨打客服",
        "-209305052000": "订单交收申报查询结果为空",
        "-209305059996": "对不起，服务异常，请稍后重试",
        "-209305059999": "对不起，未知异常，请拨打客服",
        "-209305060001": "传入委托单号为NULL",
        "-209305060002": "传入委托单号需大于0",
        "-209305062000": "订单交收申报交收属性查询结果为空",
        "-209305069996": "对不起，服务异常，请稍后重试",
        "-209305069999": "对不起，未知异常，请拨打客服",
        "-209305072000": "订单交收持仓数据查询结果为空",
        "-209305079996": "对不起，服务异常，请稍后重试",
        "-209305079999": "对不起，未知异常，请拨打客服",
        "-209305082000": "资金状况表查询结果为空",
        "-209305089996": "对不起，服务异常，请稍后重试",
        "-209305089999": "对不起，未知异常，请拨打客服",
        "-209306012000": "委托报表查询结果为空",
        "-209306019996": "对不起，服务异常，请稍后重试",
        "-209306019999": "对不起，未知异常，请拨打客服",
        "-209306022000": "成交报表查询结果为空",
        "-209306029996": "对不起，服务异常，请稍后重试",
        "-209306029999": "对不起，未知异常，请拨打客服",
        "-209306032000": "持仓汇总报表查询结果为空",
        "-209306039996": "对不起，服务异常，请稍后重试",
        "-209306039999": "对不起，未知异常，请拨打客服",
        "-209306042000": "持仓明细报表查询结果为空",
        "-209306049996": "对不起，服务异常，请稍后重试",
        "-209306049999": "对不起，未知异常，请拨打客服",
        "-209306052000": "托管发行申购报表查询结果为空",
        "-209306059996": "对不起，服务异常，请稍后重试",
        "-209306059999": "对不起，未知异常，请拨打客服",
        "-209306062000": "托管发行申购中签报表查询结果为空",
        "-209306069996": "对不起，服务异常，请稍后重试",
        "-209306069999": "对不起，未知异常，请拨打客服",
        "-209306072000": "定向配售记录报表查询结果为空",
        "-209306079996": "对不起，服务异常，请稍后重试",
        "-209306079999": "对不起，未知异常，请拨打客服",
        "-209306082000": "定向配售成交报表查询结果为空",
        "-209306089996": "对不起，服务异常，请稍后重试",
        "-209306089999": "对不起，未知异常，请拨打客服",
        "-209306092000": "比例配售记录报表查询结果为空",
        "-209306099996": "对不起，服务异常，请稍后重试",
        "-209306099999": "对不起，未知异常，请拨打客服",
        "-209306102000": "比例配售成交报表查询结果为空",
        "-209306109996": "对不起，服务异常，请稍后重试",
        "-209306109999": "对不起，未知异常，请拨打客服",
        "-209306112000": "发行申购报表查询结果为空",
        "-209306119996": "对不起，服务异常，请稍后重试",
        "-209306119999": "对不起，未知异常，请拨打客服",
        "-209306122000": "发行申购中签报表查询结果为空",
        "-209306129996": "对不起，服务异常，请稍后重试",
        "-209306129999": "对不起，未知异常，请拨打客服",
        "-209306132000": "托管记录报表查询结果为空",
        "-209306139996": "对不起，服务异常，请稍后重试",
        "-209306139999": "对不起，未知异常，请拨打客服",
        "-209306142000": "商品交收报表查询结果为空",
        "-209306149996": "对不起，服务异常，请稍后重试",
        "-209306149999": "对不起，未知异常，请拨打客服",
        "-209306152000": "商品过户报表查询结果为空",
        "-209306159996": "对不起，服务异常，请稍后重试",
        "-209306159999": "对不起，未知异常，请拨打客服",
        "-209306162000": "资金状况报表查询结果为空",
        "-209306169996": "对不起，服务异常，请稍后重试",
        "-209306169999": "对不起，未知异常，请拨打客服",
        "-209306170001": "交收申请ID不合法",
        "-209306172000": "获取交收所选号结果为空",
        "-209306179996": "对不起，服务异常，请稍后重试",
        "-209306179999": "对不起，未知异常，请拨打客服",
        "-209307012000": "摘牌报表查询结果为空",
        "-209307019996": "对不起，服务异常，请稍后重试",
        "-209307019999": "对不起，未知异常，请拨打客服",
        "-209307022000": "成交明细报表查询结果为空",
        "-209307029996": "对不起，服务异常，请稍后重试",
        "-209307029999": "对不起，未知异常，请拨打客服",
        "-209307032000": "持仓明细报表查询结果为空",
        "-209307039996": "对不起，服务异常，请稍后重试",
        "-209307039999": "对不起，未知异常，请拨打客服",
        "-209307042000": "资金报表查询结果为空",
        "-209307049996": "对不起，服务异常，请稍后重试",
        "-209307049999": "对不起，未知异常，请拨打客服",
        "-209307052000": "交收报表查询结果为空",
        "-209307059996": "对不起，服务异常，请稍后重试",
        "-209307059999": "对不起，未知异常，请拨打客服",
        "-209308012000": "现货购销委托查询结果为空",
        "-209308019996": "对不起，服务异常，请稍后重试",
        "-209308019999": "对不起，未知异常，请拨打客服",
        "-209308022000": "现货购销成交明细查询结果为空",
        "-209308029996": "对不起，服务异常，请稍后重试",
        "-209308029999": "对不起，未知异常，请拨打客服",
        "-209308032000": "现货购销持仓汇总报表查询结果为空",
        "-209308039996": "对不起，服务异常，请稍后重试",
        "-209308039999": "对不起，未知异常，请拨打客服",
        "-209308042000": "现货购销持仓明细报表查询结果为空",
        "-209308049996": "对不起，服务异常，请稍后重试",
        "-209308049999": "对不起，未知异常，请拨打客服",
        "-209308052000": "现货购销交收申报查询结果为空",
        "-209308059996": "对不起，服务异常，请稍后重试",
        "-209308059999": "对不起，未知异常，请拨打客服",
        "-209308060001": "传入委托单号为NULL",
        "-209308060002": "传入委托单号需大于0",
        "-209308062000": "现货购销交收申报交收属性查询结果为空",
        "-209308069996": "对不起，服务异常，请稍后重试",
        "-209308069999": "对不起，未知异常，请拨打客服",
        "-209308072000": "现货购销交收持仓数据查询结果为空",
        "-209308079996": "对不起，服务异常，请稍后重试",
        "-209308079999": "对不起，未知异常，请拨打客服",
        "-209308082000": "资金状况表查询结果为空",
        "-209308089996": "对不起，服务异常，请稍后重试",
        "-209308089999": "对不起，未知异常，请拨打客服",
        "-209309012000": "竞价版块查询结果为NULL",
        "-209309019996": "对不起，服务异常，请稍后重试",
        "-209309019999": "对不起，服务异常，请稍后重试",
        "-209309022000": "竞价委托查询结果为NULL",
        "-209309029996": "对不起，服务异常，请稍后重试",
        "-209309029999": "对不起，服务异常，请稍后重试",
        "-209309032000": "竞价成交查询结果为NULL",
        "-209309039996": "对不起，服务异常，请稍后重试",
        "-209309039999": "对不起，服务异常，请稍后重试",
        "-209309042000": "竞价资金查询结果为NULL",
        "-209309049996": "对不起，服务异常，请稍后重试",
        "-209309049999": "对不起，服务异常，请稍后重试",
        "-209309052000": "竞价合同查询结果为NULL",
        "-209309059996": "对不起，服务异常，请稍后重试",
        "-209309059999": "对不起，服务异常，请稍后重试",
        "-209309062000": "竞价合同成交查询结果为NULL",
        "-209309069996": "对不起，服务异常，请稍后重试",
        "-209309069999": "对不起，服务异常，请稍后重试",
        "-209500019998": "对不起，数据库异常，操作失败",
        "-210100000001": "请您先登陆",
        "-210100000002": "操作失败，传入的加密串不能为空",
        "-210100000003": "操作失败，传入的加密串[%s]与系统加密串[%s]不一致",
        "-210100009995": "对不起，数据库异常，请稍后重试",
        "-210100009996": "对不起，连接服务失败，请稍后重试",
        "-210100009998": "对不起，数据库异常，请稍后重试",
        "-210100009999": "对不起，未知异常，请拨打客服",
        "-210100010001": "积分所属人代码不能为空",
        "-210100010002": "该系统暂不支持积分功能",
        "-210100010003": "积分所属人类型不能为空",
        "-210100010004": "该持仓不属于该用户",
        "-210100010005": "系统编号有误",
        "-210100010006": "不存在系统编号[%s]对应回调接口",
        "-210100010101": "积分消费信息不能为空",
        "-210100010102": "积分消费数量不能小于等于0",
        "-210100010103": "未知的积分业务类型编号[%s]",
        "-210100010104": "未知的积分类型[%s]",
        "-210100010105": "用户[%s]用户类型[%s]不存在此种类型积分",
        "-210100010106": "用户[%s]积分数量不足",
        "-210100010107": "业务编号不能为空",
        "-210100010108": "传入的积分类型[%s]与积分业务类型[%s]不匹配",
        "-210100010109": "不支持的积分消费方式",
        "-210100010110": "不存在[%s]对应的可用积分冻结记录",
        "-210100010111": "该笔记录[%s]冻结积分数量和消费积分数量[%s]不等",
        "-210100010201": "积分冻结信息不能为空",
        "-210100010202": "积分冻结数量不能小于等于0",
        "-210100010203": "传入的积分解冻信息不能为空",
        "-210100010204": "解冻积分时，业务编号和冻结编号至少需有一个不为空",
        "-210100010205": "用户不存在相关的未解冻记录",
        "-210100010206": "积分冻结编号不能为空",
        "-210100010207": "不存在业务类型[%s]积分业务类型[%s]对应冻结记录",
        "-210100010208": "不存在冻结编号[%s]对应冻结记录",
        "-210100010209": "不存在业务类型[%s]积分业务类型[%s]对应积分消费记录",
        "-210100010210": "不存在消费编号[%s]对应积分消费记录",
        "-210100010301": "积分添加信息不能为空",
        "-210100010302": "添加积分数必须大于0",
        "-210100010303": "积分业务类型编号[%s]不存在或不属于增加积分的类型",
        "-210100010304": "不存在业务类型[%s]积分业务类型[%s]对应添加积分记录",
        "-210100010305": "不存在编号[%s]对应添加积分记录",
        "-210100020201": "持仓转积分申请信息不能为空",
        "-210100020202": "持仓单号不能为空",
        "-210100020203": "系统编号不能为空",
        "-210100020204": "该持仓已经申请过交收申请",
        "-210100020205": "该持仓已经申请持仓转积分",
        "-210100020206": "调存储其他异常",
        "-210100020207": "非持仓转积分申请时间",
        "-210100020301": "撤销申请ID不合法",
        "-210100020302": "不是申请状态，不能撤销",
        "-210100020303": "调存储其他异常",
        "-210100020401": "审核申请信息不能为空",
        "-210100020402": "申请ID不合法",
        "-210100020403": "审核积分不合法",
        "-210100020404": "审核状态不合法",
        "-210100020405": "申请不是\"申请\"状态",
        "-210100020406": "申请数量发生变化",
        "-210100020407": "用户资金不足",
        "-210100020408": "调存储其他异常",
        "-210100020409": "非持仓转积分申请审核时间",
        "-210100020410": "积分发生数不符合要求",
        "-210100030001": "积分记录集合不能为空",
        "-210300010001": "持仓单号不能为空",
        "-210300010002": "系统编号不能为空",
        "-210300019996": "对不起，服务异常，请稍后重试",
        "-210300019999": "对不起，服务异常，请稍后重试",
        "-210300020001": "系统编号不能为空",
        "-210300029996": "对不起，服务异常，请稍后重试",
        "-210300029999": "对不起，服务异常，请稍后重试",
        "-210300030001": "持仓单号不能为空",
        "-210300030002": "系统编号不能为空",
        "210300031000": "申请成功",
        "-210300039996": "对不起，服务异常，请稍后重试",
        "-210300039999": "对不起，服务异常，请稍后重试",
        "-210300040001": "持仓转积分申请编号不能为空",
        "-210300040002": "系统编号不能为空",
        "210300041000": "撤销成功",
        "-210300049996": "对不起，服务异常，请稍后重试",
        "-210300049999": "对不起，服务异常，请稍后重试",
        "-210300050001": "系统编号不能为空",
        "-210300059996": "对不起，服务异常，请稍后重试",
        "-210300059999": "对不起，服务异常，请稍后重试",
        "-211100000001": "请您先登陆",
        "-211100000002": "操作失败，传入的加密串不能为空",
        "-211100000003": "操作失败，传入的加密串[%s]与系统加密串[%s]不一致",
        "-211100009995": "对不起，数据库异常，请稍后重试",
        "-211100009996": "对不起，连接服务失败，请稍后重试",
        "-211100009998": "对不起，数据库异常，请稍后重试",
        "-211100009999": "对不起，未知异常，请拨打客服",
        "-211100010001": "系统代码不能为空",
        "-211100010002": "商品代码不能为空",
        "-211100010003": "用户收藏商品的操作信息参数异常，请检查",
        "-211100010004": "用户代码不能为空",
        "-211100010005": "用户[%s]已收藏此商品",
        "-211100020001": "用户代码不能为空",
        "-211100020002": "对不起，服务异常，请稍后重试",
        "-211100020003": "对不起，服务异常，请稍后重试",
        "-211300010001": "系统编号不能为空",
        "-211300010002": "商品代码不能为空",
        "-211300010003": "当前商品代码不存在",
        "-211300010004": "COOKIE加密串不能为空",
        "-211300019996": "对不起，服务异常，请稍后重试",
        "-211300019999": "对不起，服务异常，请稍后重试",
        "-211300020001": "用户代码不能为空",
        "-211300020002": "商品代码不能为空",
        "-211300020003": "用户[%s]尚未收藏[%s]系统中的商品",
        "-211300030001": "用户[%s]没有前台系统权限",
        "-211300040001": "分页请求参数错误",
        "-211300040002": "请求参数错误",
        "-211300049999": "对不起，服务异常，请稍后重试",
        "-307100000001": "请您先登陆",
        "-307100000002": "请您先登陆",
        "-307100009995": "对不起，数据库异常，请稍后重试",
        "-307100009996": "对不起，连接服务失败，请稍后重试",
        "-307100009998": "对不起，数据库异常，请稍后重试",
        "-307100009999": "对不起，未知异常，请拨打客服",
        "-307100010001": "请输入完整信息",
        "-307100010002": "非交易时间",
        "-307100010003": "请输入商品信息",
        "-307100010004": "数量应大于0",
        "-307100010005": "价格应大于0",
        "-307100010006": "不能替他人下单",
        "-307100010007": "清输入完整的摘单信息",
        "-307100010008": "清输入完整信息",
        "-307100010101": "非交易时间",
        "-307100010102": "交易用户代码与代为委托员代码同时存在",
        "-307100010103": "非交易时间",
        "-307100010104": "未找到账户所属人信息",
        "-307100010105": "您被禁止交易",
        "-307100010106": "处理该商品的进程已停止，请稍后重试",
        "-307100010107": "不允许进行买挂单操作",
        "-307100010108": "该挂单数量不足",
        "-307100010109": "不能摘自己的挂单",
        "-307100010110": "该挂单数量不足",
        "-307100010111": "存在更优的价格",
        "-307100010112": "买挂单托价必须小于卖一价",
        "-307100010113": "卖挂单价格必须大于买一价",
        "-307100010201": "商品不在当前交易节",
        "-307100010202": "超过单笔最大挂单量",
        "-307100010203": "商品处于暂停交易状态",
        "-307100010204": "商品未上市",
        "-307100010205": "最小变动价位不能为0",
        "-307100010206": "价格超过价格变动上限",
        "-307100010207": "价格低于价格变动下限",
        "-307100010208": "价格不是最小变动价位的整数倍",
        "-307100010209": "最小变动数量不能为0",
        "-307100010210": "不是最小变动数量整数倍",
        "-307100010211": "非第一交易节的商品不允许集合竞价",
        "-307100010301": "对不起，未知异常，请拨打客服",
        "-307100010302": "对不起，未知异常，请拨打客服",
        "-307100010303": "资金余额不足",
        "-307100010304": "超过商品所属分类的最大订货量",
        "-307100010305": "超过商品最大订货量",
        "-307100010401": "存货不足",
        "-307100010402": "指定存货不足",
        "-307100010403": "不能卖他人商品",
        "-307100010404": "不满足T+N交易条件",
        "-307100010405": "存货单号不存在",
        "-307100010406": "指定存货的商品数量不足",
        "-307100010501": "被撤单挂单不存在",
        "-307100010502": "该挂单正在执行撤单指令，请勿重复提交",
        "-307100010503": "挂单已成交或已撤销",
        "-307100010504": "不允许撤销他人挂单",
        "-307100010601": "代为交易员无给该用户下单的权限",
        "-307100020001": "对不起，未知异常，请拨打客服",
        "-307100020002": "对不起，未知异常，请拨打客服",
        "-307100020003": "系统状态不允许交易结算",
        "-307110000001": "请先登陆",
        "-307110009995": "对不起，数据库异常，请稍后重试",
        "-307110009996": "对不起，连接服务失败，请稍后重试",
        "-307110009998": "对不起，数据库异常，请稍后重试",
        "-307110009999": "对不起，未知异常，请拨打客服",
        "-307110010001": "请输入交收申请信息",
        "-307110010002": "请输入交收申请商品信息",
        "-307110010003": "请输入交收申请人信息",
        "-307110010004": "交收申请人类型不符合要求",
        "-307110010005": "请输入交收仓库编号信息",
        "-307110010006": "交收申请件数必须大于0",
        "-307110010007": "最晚提货日期不能早于今天",
        "-307110010008": "过户方与受让方不能是同一个人",
        "-307110010101": "未到交收开始日期",
        "-307110010102": "商品库存不足",
        "-307110010103": "存货不足",
        "-307110010104": "资金不足",
        "-307110010105": "最晚提货日期不能早于当前交易日",
        "-307110010106": "非交收申请时间",
        "-307110010107": "该商品已添加拆分设置，无法进行此项操作",
        "-307110010108": "商品不存在",
        "-307110010201": "仅能确定或撤销交收申请",
        "-307110010202": "已处理过",
        "-307110010203": "已过最晚提货日期",
        "-307110010204": "未选号",
        "-307110010205": "对不起，服务异常，请拨打客服",
        "-307110010206": "非交收确认时间",
        "-307110010207": "对不起，服务异常，请拨打客服",
        "-307110010208": "商品已下市，无法确认交收",
        "-307110010301": "选号列表不能为空",
        "-307110010302": "交收申请不存在",
        "-307110010303": "交收申请不需要选号",
        "-307110010304": "对不起，服务异常，请拨打客服",
        "-307110010305": "对不起，服务异常，请拨打客服",
        "-307110010306": "选号个数不等于商品交收个数",
        "-307110010307": "选号个数不等于商品交收个数",
        "-307110010308": "交收申请已选号，请勿重复选号",
        "-307110010309": "交收申请已确定或已撤销，无法选号",
        "-307110010408": "选号商品不支持一键交收",
        "-307110010499": "申请一键交收异常，待会重试或拔打客服",
        "-307110020001": "请输入过户申请信息",
        "-307110020002": "请输入过户商品信息",
        "-307110020003": "请您先登陆",
        "-307110020004": "请您先登陆",
        "-307110020005": "请输入受让方信息",
        "-307110020006": "受让方类型不符合要求",
        "-307110020007": "过户申请件数必须大于0",
        "-307110020101": "存货不足",
        "-307110020102": "非过户申请时间",
        "-307110020103": "受让方不存在",
        "-307110020104": "该商品已添加拆分设置，无法进行此项操作",
        "-307110020105": "商品不存在",
        "-307110020106": "商品预下市，不能申请过户",
        "-307110020201": "仅能确定或撤销过户申请",
        "-307110020202": "过户申请已处理过",
        "-307110020203": "对不起，服务异常[返回码]，请拨打客服",
        "-307110020204": "资金不足",
        "-307110020205": "非过户确认时间",
        "-307110020206": "存货不足",
        "-307110020207": "商品已下市，无法确认过户",
        "-307110020208": "商品预下市，无法确认过户",
        "-307120000001": "用户名未输入",
        "-307120000002": "登陆的信息已过时,请重新登陆",
        "-307120000003": "操作人[%s]未登录或者登陆超时",
        "-307120000004": "商品不存在或已下市",
        "-307120009996": "对不起，连接服务失败,请稍后重试",
        "-307120009997": "对不起，服务异常，请拨打客服",
        "-307120009998": "对不起，数据库异常，请稍后重试",
        "-307120009999": "对不起，系统异常，请稍后重试",
        "-307120010001": "未发现订购记录数据",
        "-307120010002": "未发现订购人",
        "-307120010003": "未发现订购人类型",
        "-307120010004": "订购数量不正确",
        "-307120010005": "未发现订购商品代码",
        "-307120010006": "未发现订购记录单号",
        "-307120010101": "当前系统状态不能订购",
        "-307120010102": "不能重复订购",
        "-307120010103": "会员不能订购自己预拍的商品",
        "-307120010104": "已经销此商品，不能订购",
        "-307120010105": "订购数量不满足变动量",
        "-307120010106": "订购的数量不在有效范围内",
        "-307120010107": "账户资金不足,请充值后再行操作",
        "-307120010108": "商品已告罄,请选择其他商品",
        "-307120010109": "该商品未被经销,暂不能订购",
        "-307120010110": "订购需要在预拍开始与结束的时间之内进行",
        "-307120010111": "连接数据库服务失败,请联系客服",
        "-307120010112": "该记录有异常请联系客服",
        "-307120010113": "当前订购记录的状态不是未成交不能撤销",
        "-307120010114": "当前的日期不在预拍时间段内不能撤销",
        "-307120010115": "商品已经摇号或取消预拍",
        "-307120010116": "没有该商品订购权限",
        "-307120010117": "不在订购时间段内",
        "-307120010118": "用户[%s]在该托管计划下已经添加过托管申请，无法重复添加",
        "-307120010119": "仓单[%s]已被使用",
        "-307120010120": "用户[%s]不存在",
        "-307120020001": "未发现该比例配货的数据",
        "-307120020002": "未发现所属人",
        "-307120020003": "所属人类型不明",
        "-307120020004": "未填入有效的配货数量",
        "-307120020005": "未发现配货代码",
        "-307120020101": "商品已经配货",
        "-307120020102": "填入数量超出预配货数量",
        "-307120020103": "资金不足",
        "-307120020104": "超出比列配货的开始和结束时间",
        "-307120020105": "当前交易系统状态不允许此操作",
        "-307120020106": "调存储其他异常",
        "-307120030001": "未发现该二次预拍的申请信息",
        "-307120030002": "未发现所属人",
        "-307120030003": "未发现所属人类型",
        "-307120030004": "未发现申请数量",
        "-307120030005": "未发现该二次预拍的申请代码",
        "-307120030101": "系统暂时不允许交易,请关注交易时间",
        "-307120030102": "每名用户只能订购一次",
        "-307120030103": "会员不能订购自己预拍的商品",
        "-307120030104": "已经销此商品，不能订购",
        "-307120030105": "订购数量不满足变动量",
        "-307120030106": "订购数量不在有效可订购数量之间",
        "-307120030107": "账户资金不足,请充值后再行操作",
        "-307120030108": "商品已告罄,请选择其他商品",
        "-307120030109": "该商品未被经销,暂不能订购",
        "-307120030110": "订购需要在预拍开始与结束日期之内进行",
        "-307120030111": "未找到相关数据,请稍后再试",
        "-307120030112": "该记录有异常请联系客服",
        "-307120030113": "该记录的状态不能撤销",
        "-307120030114": "当前的日期不在预拍时间段内不能撤销",
        "-307120030115": "商品已经摇号或取消预拍",
        "-307120030116": "没有该商品订购权限",
        "-307120030117": "不在订购时间段内",
        "-307120040001": "未发现该托管信息",
        "-307120040002": "未发现托管申请人",
        "-307120040003": "申请人类型不明",
        "-307120040004": "未发现仓库代码",
        "-307120040005": "未发现托管计划代码",
        "-307120040006": "未发现托管申请数量",
        "-307120040007": "未发现仓单信息",
        "-307120040008": "撤销申请时,申请代码未找到",
        "-307120040101": "该托管代码,没有对应的托管计划",
        "-307120040102": "托管代码对应的托管计划无效",
        "-307120040103": "托管的日期不在托管开始和托管结束的日期内",
        "-307120040104": "托管申请数量和传入的对应的数量和不等",
        "-307120040105": "未发现该商品的合约因子,请查看商品设置",
        "-307120040106": "添加托管申请时,调用仓单服务失败,请检查仓单系统",
        "-307120040111": "当前托管状态不能撤销",
        "-307120040112": "撤销托管申请时,调用仓单服务失败,请检查仓单系统",
        "-307120040113": "调存储数据库异常",
        "-307300009001": "对不起，服务异常，请拨打客服",
        "-307300009196": "对不起，服务异常，请稍后重试",
        "-307300009199": "对不起，服务异常，请拨打客服",
        "-307300003001": "对不起，服务异常，请拨打客服",
        "-307300009396": "对不起，服务异常，请稍后重试",
        "-307300009399": "对不起，未知异常，请拨打客服",
        "-307300013000": "用户资金查询结果为空",
        "-307300019996": "对不起，服务异常，请稍后重试",
        "-307300019999": "对不起，未知异常，请拨打客服",
        "-307300020001": "商品代码不能为空",
        "-307300020002": "买卖方向不合法",
        "-307300020003": "价格必须大于0",
        "-307300020004": "数量必须大于0",
        "-307300020005": "商品代码不存在",
        "-307300020006": "操作类型不合法",
        "-307300020007": "没有对应的挂单信息",
        "-307300020008": "该委托的状态以为部分撤单或撤单状态",
        "-307300020009": "摘单不能是自己的挂单信息",
        "-307300020010": "摘单价格与挂单价格不等",
        "-307300020011": "摘单数量超出挂单数量",
        "-307300020012": "非交易时间",
        "-307300024000": "对不起，您的账号被禁止交易",
        "-307300024001": "您的账号没有该分类买权限",
        "-307300024002": "您的账号没有该商品买权限",
        "-307300024003": "您没有该分类买权限",
        "-307300024004": "您没有该商品买权限",
        "-307300024005": "您的账号没有该分类卖权限",
        "-307300024006": "您的账号没有该商品卖权限",
        "-307300024007": "您没有该分类卖权限",
        "-307300024008": "您没有该商品卖权限",
        "307300021000": "挂单成功",
        "-307300021001": "挂单失败",
        "-307300029996": "对不起，服务异常，请稍后重试",
        "-307300029999": "对不起，未知异常，请拨打客服",
        "-307300030001": "挂单单号参数不能为空",
        "-307300030002": "挂单单号参数不能为空",
        "-307300030003": "无此挂单单号，无法撤单",
        "-307300034000": "对不起，您的账号被禁止交易，无法撤单",
        "307300031000": "您的撤单请求全部发送成功",
        "-307300031001": "您的撤单请求全部发送失败",
        "-307300031002": "您的撤单请求部分发送失败",
        "-307300035000": "无挂单数据，请重新登录",
        "-307300039001": "请求撤销挂单单号不合法",
        "-307300039996": "对不起，服务异常，请稍后重试",
        "-307300039999": "对不起，未知异常，请拨打客服",
        "-307300045000": "用户无成交记录数据，请重新登录",
        "-307300049999": "对不起，未知异常，请拨打客服",
        "-307300054000": "对不起，服务器验证失败，请先退出客户端，稍等一分钟后再尝试登录",
        "-307300059999": "对不起，未知异常，请拨打客服",
        "-307300065000": "用户无挂单记录数据，请重新登录",
        "-307300069999": "对不起，未知异常，请拨打客服",
        "-307300075000": "用户无存货合计数据，请重新登录",
        "-307300079999": "对不起，未知异常，请拨打客服",
        "-307300085000": "用户无存货明细数据，请重新登录",
        "-307300089999": "对不起，未知异常，请拨打客服",
        "-307300095000": "该商品没有行情信息",
        "-307300095001": "当前系统无行情数据",
        "-307300099999": "对不起，未知异常，请拨打客服",
        "-307300105000": "该商品代码不存在",
        "-307300105001": "当前系统无商品数据",
        "-307300109999": "对不起，未知异常，请拨打客服",
        "-307300110001": "商品代码不能为空",
        "-307300110002": "未明确买卖方向",
        "-307300110003": "价格必须大于0",
        "-307300113000": "用户资金查询结果为空",
        "-307300115000": "当前商品代码不存在",
        "-307300119996": "对不起，服务异常，请稍后重试",
        "-307300119999": "对不起，未知异常，请拨打客服",
        "-307300125000": "无成交记录缓存数据，需要重新登录",
        "-307300129999": "对不起，未知异常，请拨打客服",
        "-307300135000": "无法获取系统状态",
        "-307300139996": "对不起，服务异常，请稍后重试",
        "-307300139999": "对不起，未知异常，请拨打客服",
        "-307300143000": "用户资金查询结果为空",
        "-307300145000": "无存货合计缓存数据，需要重新登录",
        "-307300149996": "对不起，服务异常，请稍后重试",
        "-307300149999": "对不起，未知异常，请拨打客服",
        "-307300150001": "行情更新时间不合法",
        "-307300159999": "对不起，未知异常，请拨打客服",
        "-307300160001": "时间不合法",
        "-307300160002": "起始号不合法",
        "-307300160003": "结束号不合法",
        "-307300160004": "商品代码为空",
        "-307300160005": "买卖标识符不合法",
        "-307300160006": "操作类型不合法",
        "-307300169999": "对不起，未知异常，请拨打客服",
        "-307300180001": "买卖各查询多少档参数不符",
        "-307300180002": "每档查询多少挂单记录参数不符",
        "-307300180003": "买卖标识买卖标志参数不符",
        "-307300189999": "对不起，未知异常，请拨打客服",
        "-307300190001": "查询商品历史价格买卖参数非法",
        "-307300190002": "查询商品历史价格记录条数参数非法",
        "-307300199999": "对不起，未知异常，请拨打客服",
        "-307300200001": "时间不合法",
        "-307300200002": "起始号不合法",
        "-307300200003": "结束号不合法",
        "-307300200004": "商品代码不能为空",
        "-307300200005": "买卖标识符不合法",
        "-307300200006": "买卖各查询多少档参数不合法",
        "-307300200007": "价格必须大于0",
        "-307300200008": "没有改商品的行情数据信息",
        "-307300200009": "卖挂单价格不能低于买一价",
        "-307300200010": "买挂单价格不能高于卖一价",
        "-307300209999": "对不起，未知异常，请拨打客服",
        "-307310012000": "托管计划申请查询结果为空",
        "-307310019996": "对不起，服务异常，请稍后重试",
        "-307310019999": "对不起，未知异常，请拨打客服",
        "-307310020001": "商品代码不能为空",
        "-307310022000": "仓库查询结果为空",
        "-307310029996": "对不起，服务异常，请稍后重试",
        "-307310029999": "对不起，未知异常，请拨打客服",
        "-307310030001": "商品代码不能为空",
        "-307310030002": "仓库ID不能为空",
        "-307310032000": "可用托管申请仓单查询结果为空",
        "-307310039996": "对不起，服务异常，请稍后重试",
        "-307310039999": "对不起，未知异常，请拨打客服",
        "-307310040001": "托管计划ID不合法",
        "-307310040002": "仓库ID不能为空",
        "-307310040003": "申请托管数量必须大于0",
        "-307310040004": "仓单号和数量参数不能为空",
        "-307310040005": "仓单号和数量不合法",
        "-307310040006": "仓单号和数量不合法",
        "-307310040007": "托管总数量与仓单数量求和不一致",
        "307310041000": "托管申请成功",
        "-307310041001": "托管申请失败",
        "-307310049001": "仓单和数量参数格式不正确",
        "-307310049996": "对不起，服务异常，请稍后重试",
        "-307310049999": "对不起，未知异常，请拨打客服",
        "-307310052000": "托管申请查询结果为空",
        "-307310059996": "对不起，服务异常，请稍后重试",
        "-307310059999": "对不起，未知异常，请拨打客服",
        "-307310060001": "托管申请ID不合法",
        "307310061000": "撤销托管申请成功",
        "-307310061001": "撤销托管申请失败",
        "-307310069996": "对不起，服务异常，请稍后重试",
        "-307310069999": "对不起，未知异常，请拨打客服",
        "-307311012000": "托管预拍商品查询结果为空",
        "-307311019996": "对不起，服务异常，请稍后重试",
        "-307311019999": "对不起，未知异常，请拨打客服",
        "-307311020001": "托管预拍计划ID不合法",
        "-307311029996": "对不起，服务异常，请稍后重试",
        "-307311029999": "对不起，未知异常，请拨打客服",
        "-307311030001": "商品代码不能为空",
        "-307311030002": "订购数量必须大于0",
        "-307311031003": "托管预拍计划ID不合法",
        "307311031000": "订购成功",
        "-307311031001": "订购失败",
        "-307311039996": "对不起，服务异常，请稍后重试",
        "-307311039999": "对不起，未知异常，请拨打客服",
        "-307311040001": "订购ID不合法",
        "307311041000": "撤销订购成功",
        "-307311041001": "撤销订购失败",
        "-307311049996": "对不起，服务异常，请稍后重试",
        "-307311049999": "对不起，未知异常，请拨打客服",
        "-307311052000": "托管预拍订购查询结果为空",
        "-307311059996": "对不起，服务异常，请稍后重试",
        "-307311059999": "对不起，未知异常，请拨打客服",
        "-307311062000": "托管预拍订购中签查询结果为空",
        "-307311069996": "对不起，服务异常，请稍后重试",
        "-307311069999": "对不起，未知异常，请拨打客服",
        "-307312012000": "比例配货查询结果为空",
        "-307312019996": "对不起，服务异常，请稍后重试",
        "-307312019999": "对不起，未知异常，请拨打客服",
        "-307312020001": "配货ID不能为空",
        "-307312029996": "对不起，服务异常，请稍后重试",
        "-307312029999": "对不起，未知异常，请拨打客服",
        "-307312030001": "配货ID不合法",
        "-307312030002": "配货数量必须大于0",
        "307312031000": "确认成功",
        "-307312031001": "确认失败",
        "-307312039996": "对不起，服务异常，请稍后重试",
        "-307312039999": "对不起，未知异常，请拨打客服",
        "-307312042000": "比例配货成交查询结果为空",
        "-307312049996": "对不起，服务异常，请稍后重试",
        "-307312049999": "对不起，未知异常，请拨打客服",
        "-307312052000": "定向配货记录查询结果为空",
        "-307312059996": "对不起，服务异常，请稍后重试",
        "-307312059999": "对不起，未知异常，请拨打客服",
        "-307312062000": "定向配货成交查询结果为空",
        "-307312069996": "对不起，服务异常，请稍后重试",
        "-307312069999": "对不起，未知异常，请拨打客服",
        "-307312072000": "预拍商品查询结果为空",
        "-307312079996": "对不起，服务异常，请稍后重试",
        "-307312079999": "对不起，未知异常，请拨打客服",
        "-307312080001": "预拍计划ID不合法",
        "-307312089996": "对不起，服务异常，请稍后重试",
        "-307312089999": "对不起，未知异常，请拨打客服",
        "-307312090001": "预拍计划ID不合法",
        "-307312090002": "订购数量必须大于0",
        "307312091000": "订购成功",
        "-307312091001": "订购失败",
        "-307312099996": "对不起，服务异常，请稍后重试",
        "-307312099999": "对不起，未知异常，请拨打客服",
        "-307312100001": "撤销订购ID不合法",
        "307312101000": "撤销成功",
        "-307312101001": "撤销失败",
        "-307312109996": "对不起，服务异常，请稍后重试",
        "-307312109999": "对不起，未知异常，请拨打客服",
        "-307312112000": "预拍订购查询结果为空",
        "-307312119996": "对不起，服务异常，请稍后重试",
        "-307312119999": "对不起，未知异常，请拨打客服",
        "-307312122000": "预拍中签查询结果为空",
        "-307312129996": "对不起，服务异常，请稍后重试",
        "-307312129999": "对不起，未知异常，请拨打客服",
        "-307320012000": "可交收申请查询结果为空",
        "-307320019996": "对不起，服务异常，请稍后重试",
        "-307320019999": "对不起，未知异常，请拨打客服",
        "-307320020001": "商品代码不能为空",
        "-307320022000": "商品仓库查询结果为空",
        "-307320029996": "对不起，服务异常，请稍后重试",
        "-307320029999": "对不起，未知异常，请拨打客服",
        "-307320039996": "对不起，服务异常，请稍后重试",
        "-307320039999": "对不起，未知异常，请拨打客服",
        "-307320040001": "商品代码不能为空",
        "-307320040002": "交货仓库ID不能为空",
        "-307320040003": "交收数量必须大于0",
        "-307320040004": "交收日期不能为空",
        "-307320042000": "交收资金查询结果为空",
        "-307320049996": "对不起，服务异常，请稍后重试",
        "-307320049999": "对不起，未知异常，请拨打客服",
        "-307320050001": "商品代码不能为空",
        "-307320050002": "交货仓库ID不能为空",
        "-307320050003": "交收数量必须大于0",
        "-307320050004": "交收日期不能为空",
        "307320051000": "申请成功",
        "-307320051001": "申请失败",
        "-307320059996": "对不起，服务异常，请稍后重试",
        "-307320059999": "对不起，未知异常，请拨打客服",
        "-307320062000": "交收申请查询结果为空",
        "-307320069996": "对不起，服务异常，请稍后重试",
        "-307320069999": "对不起，未知异常，请拨打客服",
        "-307320070001": "交收申请ID不合法",
        "307320071000": "确认成功",
        "-307320071001": "确认失败",
        "-307320079996": "对不起，服务异常，请稍后重试",
        "-307320079999": "对不起，未知异常，请拨打客服",
        "-307320080001": "交收申请ID不合法",
        "307320081000": "撤销成功",
        "-307320081001": "撤销失败",
        "-307320089996": "对不起，服务异常，请稍后重试",
        "-307320089999": "对不起，未知异常，请拨打客服",
        "-307320090001": "交收申请ID不合法",
        "-307320092000": "获取选号区间结果为空",
        "-307320099996": "对不起，服务异常，请稍后重试",
        "-307320099999": "对不起，未知异常，请拨打客服",
        "-307320100001": "仓库ID不能为空",
        "-307320100002": "交收申请ID不合法",
        "-307320102000": "获取区间所有可用选号结果为空",
        "-307320109996": "对不起，服务异常，请稍后重试",
        "-307320109999": "对不起，未知异常，请拨打客服",
        "-307320112000": "可过户申请查询结果为空",
        "-307320119996": "对不起，服务异常，请稍后重试",
        "-307320119999": "对不起，未知异常，请拨打客服",
        "-307320120001": "商品代码不能为空",
        "-307320120002": "过户数量必须大于0",
        "-307320122000": "过户资金查询结果为空",
        "-307320129996": "对不起，服务异常，请稍后重试",
        "-307320129999": "对不起，未知异常，请拨打客服",
        "-307320130001": "接受过户所属人ID不能为空",
        "-307320130002": "接受过户所属人ID不合法",
        "-307320130003": "商品代码不能为空",
        "-307320130004": "过户数量必须大于0",
        "-307320130005": "受让方未实名",
        "307320131000": "申请成功",
        "-307320131001": "申请失败",
        "-307320139996": "对不起，服务异常，请稍后重试",
        "-307320139999": "对不起，未知异常，请拨打客服",
        "-307320142000": "过户查询结果为空",
        "-307320149996": "对不起，服务异常，请稍后重试",
        "-307320149999": "对不起，未知异常，请拨打客服",
        "-307320150001": "过户申请ID不合法",
        "307320151000": "确认成功",
        "-307320151001": "确认失败",
        "-307320159996": "对不起，服务异常，请稍后重试",
        "-307320159999": "对不起，未知异常，请拨打客服",
        "-307320160001": "过户申请ID不合法",
        "307320161000": "撤销成功",
        "-307320161001": "撤销失败",
        "-307320169996": "对不起，服务异常，请稍后重试",
        "-307320169999": "对不起，未知异常，请拨打客服",
        "-307320170001": "交收申请ID不合法",
        "-307320170002": "所选号不能为空",
        "307320171000": "提交成功",
        "-307320171001": "提交失败",
        "-307320179000": "所选号不合法",
        "-307320179996": "对不起，服务异常，请稍后重试",
        "-307320179999": "对不起，未知异常，请拨打客服",
        "-307320180001": "交收申请ID不合法",
        "-307320182000": "获取交收所选号结果为空",
        "-307320189996": "对不起，服务异常，请稍后重试",
        "-307320189999": "对不起，未知异常，请拨打客服",
        "-405110010001": "验证码为空，请重新登录",
        "-405110010002": "验证码输入错误，请重新登录",
        "-405110010003": "用户名或密码错误，请重新登录",
        "405110010005": "管理员登录成功",
        "405110010006": "管理员退出成功",
        "-405110010007": "key盘身份验证失败，请确认",
        "-405110010008": "用户被禁用，请联系系统管理员",
        "-405110010009": "用户名或密码错误",
        "-405110010020": "修改密码失败，新密码与原密码一致",
        "405110010021": "修改密码成功",
        "-405110010022": "短信验证码为空，请重新获取",
        "-405110010023": "短信验证码输入错误，请重新登录",
        "-405110010024": "短信验证码超时，请重新获取",
        "405110010031": "修改风格成功",
        "405110010041": "修改资金密码成功",
        "-405110010041": "修改资金密码失败，退出登录",
        "-405110010042": "您没有修改资金密码权限",
        "-405110010043": "修改交易密码成功，%s",
        "405110020103": "删除角色【%s】成功",
        "-405110020103": "删除角色【%s】失败，角色不存在",
        "-405110020199": "删除角色操作未知异常",
        "405110020204": "设置角色【%s】权限成功",
        "-405110020204": "设置角色【%s】权限失败，角色不存在",
        "-405110020299": "设置角色权限操作未知异常",
        "-405110020205": "角色名称已存在",
        "-405110020301": "查询角色列表信息失败",
        "405110030101": "修改管理员【%s】密码成功",
        "405110030201": "修改管理员【%s】权限成功",
        "-405110030201": "修改管理员【%s】权限失败，管理员【%s】不存在",
        "-405110030299": "修改管理员权限未知异常",
        "405110030301": "管理员【%s】修改角色权限成功",
        "-405110030401": "查询管理员列表信息失败",
        "-405110030501": "查询管理员操作日志列表信息失败",
        "-405110030601": "管理员已存在",
        "405110040001": "设置我的菜单成功",
        "-405110040001": "设置我的菜单失败，我的菜单最多不能多于【%s】个",
        "-405110099199": "您不能对此管理员进行此操作"
    }
    if (!retCode.hasOwnProperty(retKey)) return
    //2、遍历Json串获取其属性  
    for(var item in retCode){  
        if(item == retKey){ //item 表示Json串中的属性  
            var jValue = retCode[item];//key所对应的value  
            return jValue
        }  
    } 
}