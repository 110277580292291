import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
//引入vue-router
import VueRouter from 'vue-router'
import router from './router'

//引入bootstrap
import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap/dist/css/bootstrap.css'

//引入axios
import axios from 'axios'
//axios请求基础地址
// axios.defaults.baseURL = ''
//引入插件
import plugins from "./plugins/plugins"
//引入icon
import './assets/iconfont/iconfont.css'
import './assets/iconfont-less/iconfont.css'
//引入store
import store from '@/store'

//引入echarts核心模块
import * as echarts from 'echarts/core'
//引入树形图标
import { BarChart, TreeChart } from 'echarts/charts'
//引入提示框，标题，数据集，内置数据转换器组件等
import { 
    TitleComponent, 
    TooltipComponent, 
    LegendComponent,
    GridComponent, 
    DatasetComponent,
    TransformComponent
} from 'echarts/components'
// 标签自动布局，全局过渡动画等特性
import { LabelLayout, UniversalTransition } from 'echarts/features'
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers'

// 注册必须的组件
echarts.use([
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    BarChart,
    TreeChart,
    LabelLayout,
    UniversalTransition,
    CanvasRenderer
])

Vue.config.productionTip = false
//设置axios请求头字符集编码以及格式
axios.defaults.headers['Content-type'] = 'application/json;charset=UTF-8'
Vue.prototype.$http = axios
Vue.prototype.$echarts = echarts
Vue.use(VueRouter)
Vue.use(ElementUI)
Vue.use(plugins)


//创建vue实例挂载页面
var vm = new Vue({
  render: h => h(App),
  router: router,
  store,
  beforeCreate() {
    Vue.prototype.$bus = this
  }
}).$mount('#app')

