import { render, staticRenderFns } from "./DeliveryList.vue?vue&type=template&id=189a1140&scoped=true&"
import script from "./DeliveryList.vue?vue&type=script&lang=js&"
export * from "./DeliveryList.vue?vue&type=script&lang=js&"
import style0 from "./DeliveryList.vue?vue&type=style&index=0&id=189a1140&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "189a1140",
  null
  
)

export default component.exports