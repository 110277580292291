<template>
    <div class="goods-exchange">
        <div class="exchange-head">
            <el-page-header @back="goBack" content="提交订单" style="font-weight: bolder;">
            </el-page-header>
            <el-divider></el-divider>
        </div>
        <div class="exchange-main">
            <el-card @click.native="isShowAddr = true">
                <p>收货信息</p>
                <div class="addr-title">
                    <span>{{ default_addr.RVN }}</span>
                    <span>{{ default_addr.RVP }}</span>
                    <span class="iconfont icon-youbianjiantou"></span>
                </div>
                <div class="addr-detail">
                    {{ default_addr.RVAD }}
                </div>
                <!-- 抽屉打开地址选择 -->
                <el-drawer size="50%" direction="btt"
                    :append-to-body="true"
                    :modal-append-to-body="false"
                    title="请选择收货地址"
                    :with-header="true"
                    :visible.sync="isShowAddr">
                    <el-card :id="'addrId_'+item.ADI" class="addr-lists" v-for="item in addrLists" :key="item.ADI" @click.native="selectMe(item)">
                        <el-row :gutter="10">
                            <el-col :span="2">
                                <span :id="'icon_hg'+item.ADI" class="iconfont " style="color: #DF2625"></span>
                            </el-col>
                            <el-col :span="22">
                                <div><span>{{ item.RVN }}</span><span>{{ item.RVP }}</span></div>
                                <div><p>{{ item.RVAD }}</p></div>
                            </el-col>
                        </el-row>
                    </el-card>
                </el-drawer>
            </el-card>
            <el-card>
                <el-descriptions title="商品信息" direction="vertical" :column="3" border>
                    <el-descriptions-item label="商品名称">{{ goodsInfo.product_name }}</el-descriptions-item>
                    <el-descriptions-item label="商品序号">{{ goodsInfo.product_id }}</el-descriptions-item>
                    <!-- <el-descriptions-item label="商品编号">{{ goodsInfo.product_id }}</el-descriptions-item> -->
                    <!-- <el-descriptions-item label="仓量兑换">{{ goodsInfo.product_price_position | keepTwoDecimal }}</el-descriptions-item> -->
                    <el-descriptions-item label="所需积分">{{ goodsInfo.product_price_point | keepTwoDecimal }}</el-descriptions-item>
                </el-descriptions>
            </el-card>
            <el-card>
                <div class="exchange-foot-type">
                    <div>兑换类型：</div>
                    <div>
                        <el-select size="small" disabled v-model="exchange_type" placeholder="请选择">
                            <el-option v-for="item in options"
                                :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    
                </div>
                <div class="exchange-foot-num">
                    <div>兑换数量：</div>
                    <div>
                        <el-input-number v-model="exchange_num" size="small" :min="1"></el-input-number>
                    </div>
                </div>
            </el-card>
        </div>
        <button @click="exchangeOrder" type="button" class="btn btn-danger btn-lg btn-block">确认</button>
    </div>
</template>

<script>
    import { loginApi, noLoginApi } from '@/config/env'
    import { isFailureLogin } from '@/config/mUtils'
    export default {
        name: 'GoodsExchange',
        data() {
            return {
                queryAddrParams: {
                    "name": "delivery_address_query",
                    "U": "",
                    "SI": "",
                    "QF": 2
                },
                exchange_num: 1,
                options: [
                    //{ label: '仓量兑换', value: 'position' },
                    { label: '积分兑换', value: 'point' }
                ],
                exchange_type: 'point',
                goodsInfo: {},
                default_addr: {},
                addrLists: [],
                id: '',
                isShowAddr: false,
                //兑换参数
                exchangeOrderParams: {
                    "delivery_adress": "",
                    "delivery_user_name": "",
                    "delivery_user_phone": "",
                    "order_type": "",
                    "product_count": 1,
                    "product_id": "",
                    "product_total_price": 0,
                    "user_id": ""
                }
            }
        },
        created() {
            this.id = this.$route.query.id ? this.$route.query.id : ''
        },
        mounted() {
            //获取用户地址信息
            this.getUserAddrInfos()
            //获取商品信息
            this.getGoodsDetails()
        },
        beforeDestroy() {
            
        },
        methods: {
            //返回
            goBack() {
                this.$router.go(-1)
            },
            //是否显示地址容器
            isShowAddrCase() {
                this.isShowAddr = this.isShowAddr ? false : true
            },
            //获取用户地址信息
            async getUserAddrInfos() {
                const { data: res } = await this.$http.post(loginApi.queryUserAddressApi, this.queryAddrParams)
                if (isFailureLogin(res.retcode)) {
                    this.addrLists = res.resultList.REC
                    if (res.resultList.REC.length > 0) {
                        res.resultList.REC.forEach((val, index) => {
                            if (val.IDAD == 1) {
                                this.default_addr = val
                            }
                        })
                    }
                } else {
                    return this.$message.warning(res.message)
                }
            },
            //获取商品详情信息
            async getGoodsDetails() {
                const { data: res } = await this.$http.post(noLoginApi.queryGoodsDetailsApi+'?product_id='+this.id)
                if (res.code !== 'E000000') {
                    return
                }
                this.goodsInfo = res.data
            },
            //选中
            selectMe(value) {
                $('.iconfont').removeClass("icon-honggou")
                $('#addrId_' + value.ADI).find(".iconfont").addClass("icon-honggou")
                //保存地址信息到vuex中
                this.$store.commit('GET_ADDR_INFOS', JSON.stringify(value))
                this.isShowAddr = false
                this.default_addr = this.$store.state.addressInfos
            },
            //确认订单按钮
            exchangeOrder() {
                if (this.exchange_type == '') {
                    this.$message.warning('请选择兑换类型')
                    return
                }
                this.checkBuyExchange()
            },
            //通兑点数购买商品
            async exchangeBuyOrder() {
                this.exchangeOrderParams.delivery_adress = this.default_addr.RVAD
                this.exchangeOrderParams.delivery_user_name = this.default_addr.RVN
                this.exchangeOrderParams.delivery_user_phone = this.default_addr.RVP
                this.exchangeOrderParams.order_type = this.exchange_type
                this.exchangeOrderParams.product_count = this.exchange_num
                this.exchangeOrderParams.product_id = this.id
                this.exchangeOrderParams.product_total_price = this.exchange_type === 'position' ? this.exchange_num*this.goodsInfo.product_price_position : this.exchange_num*this.goodsInfo.product_price_point
                const { data: res } = await this.$http.post(loginApi.userMallBuyApi, this.exchangeOrderParams)
                if (res.code !== 'E000000') {
                    this.$message.error(res.message)
                    return
                } else {
                    this.$message.success(res.message)
                    this.$router.push('goodslists')
                }
            },
            //购买校验
            async checkBuyExchange() {
                this.exchangeOrderParams.order_type = this.exchange_type
                this.exchangeOrderParams.product_count = this.exchange_num
                this.exchangeOrderParams.product_id = this.id
                this.exchangeOrderParams.product_total_price = this.exchange_type === 'position' ? this.exchange_num*this.goodsInfo.product_price_position : this.exchange_num*this.goodsInfo.product_price_point
                const { data: res } = await this.$http.post(loginApi.mallExchangeCheckApi, this.exchangeOrderParams)
                if (res.code !== 'E000000') {
                    return this.$message.error(res.message)
                }
                if (res.data.state == 2) {
                    return this.$message.error('您当前账户的积分不足以兑换该商品！')
                } else {
                    const h = this.$createElement
                    this.$msgbox({
                        title: '订单提示',
                        message: h('p', null, [
                            h('span', null, '商品名称：'),
                            h('i', { style: 'color: teal' }, this.goodsInfo.product_name),
                            h('br'),
                            h('span', null, '商品数量：'),
                            h('i', { style: 'color: teal' }, this.exchange_num),
                            h('br'),
                            // h('span', null, '兑换：'),
                            // h('i', { style: 'color: teal' }, this.exchange_num+'x'+(this.exchange_type === 'position' ? this.goodsInfo.product_price_position+'（仓量）' : this.goodsInfo.product_price_point+'（积分）')),
                            // h('br'),
                            h('span', null, '扣减积分：'),
                            h('i', { style: 'color: teal' }, res.data.point+'（积分）'),
                            h('br'),
                            h('span', null, '扣减仓量：'),
                            h('i', { style: 'color: teal' }, res.data.position+'（仓量）')
                        ]),
                        showCancelButton: true,
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        beforeClose: (action, instance, done) => {
                            if (action === 'confirm') {
                                instance.confirmButtonLoading = true
                                instance.confirmButtonText = '执行中...'
                                setTimeout(() => {
                                    this.exchangeBuyOrder()
                                    done()
                                    setTimeout(() => {
                                        instance.confirmButtonLoading = false
                                    }, 300)
                                }, 2000)
                            } else {
                                instance.cancelButtonLoading = true
                                instance.cancelButtonText = '取消中...'
                                setTimeout(() => {
                                    done()
                                    setTimeout(() => {
                                        instance.cancelButtonLoading = false
                                    }, 300)
                                }, 1000)
                            }
                        }
                    }).then(action => {
                        // this.reload()
                    }).catch(err => {
                        this.$message({
                            type: 'info',
                            message: '已取消'
                        })
                    })
                }
            }
        }        
    }
</script>

<style lang="less" scoped>
    .goods-exchange {
        width: 100%;
        height: 100%;
        .exchange-head {
            width: 100%;
            height: 100%;
            padding: 1rem;
        }
        .exchange-main {
            width: 100%;
            height: 100%;
            margin-bottom: 5rem;
            .el-card {
                margin-bottom: 1rem;
            }
            div:nth-child(1) {
                p {
                    font-size: 16px;
                    font-weight: 600;
                    color: #303133;
                }
                .addr-title {
                    width: 100%;
                    padding: 0.5rem;
                    display: flex;
                    flex-direction: row;
                    flex-flow: nowrap;
                    justify-content: space-between;
                    cursor: pointer;
                    span:first-child {
                        font-size: 15px;
                        font-weight: 600;
                    }
                    span:nth-child(2) {
                        font-size: 15px;
                        font-weight: 600;
                    }
                }
                .addr-detail {
                    width: 100%;
                    padding: 0.5rem;
                    margin-top: 0.2rem;
                }
            }
            div:nth-child(3) {
                width: 100%;
                font-size: 15px;
                .exchange-foot-type {
                    width: 100%;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: space-between;
                    align-items: center;
                    div:first-child {
                        width: 30%;
                        font-weight: 600;
                    }
                    div:last-child {
                        width: 70%;
                        .el-select {
                            width: 100%;
                        }
                    }
                }
                .exchange-foot-num {
                    width: 100%;
                    margin-top: 0.5rem;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: space-between;
                    align-items: center;
                    div:first-child {
                        width: 30%;
                        font-weight: 600;
                    }
                    div:last-child {
                        width: 70%;
                        .el-input-number {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    .addr-lists {
        width: 100%;
        background-color: #F7F7F7;
        .el-row {
            width: 100%;
            height: 100%;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            padding: 0;
            .el-col:first-child {
                margin-right: 1rem;
            }
            .el-col:last-child {
                font-size: 1.6rem;
                color: #333333;
                div:first-child {
                    width: 100%;
                    height: 2.5rem;
                    span {
                        margin-right: 1rem;
                    }
                }
                div:last-child {
                    width: 100%;
                    height: 5rem;
                    padding-left: 0.5rem;
                    font-size: 1.5rem;
                }
            }
        }
    }
</style>