<template>
    <div class="message-main">
        <div class="message-content">
            <div class="message-title">
                <h3>{{ msgDetail.BT }}</h3>
                <span>发布时间：{{ msgDetail.BPT }}</span>
            </div>
            <div class="message-detail">
                {{ msgDetail.BC }}
            </div>
        </div>
    </div>
</template>

<script>
import { noLoginApi } from "@/config/env"
import { parseRetCode } from "@/config/retcode"

    export default({
        name: 'Details',
        data() {
            return {
                queryData: {
                    "name": "broadcast_detail_query", 
                    "BI": ""
                },
                msgDetail: {}
            }
        },
        beforeCreate() {
            if (!this.$route.query.mid) {
                this.$message.error("参数非法，即将返回主页。。。")
                setTimeout(() => {this.$router.push('/home')}, 3000)
            }
        },
        mounted() {
            this.getMessageDetails()
        },
        methods: {
            //获取消息详情
            async getMessageDetails() {
                this.queryData.BI = this.$route.query.mid
                const {data: res} = await this.$http.post(noLoginApi.announcementDetailApi, this.queryData)
                if (res.result.RETCODE < 0) {
                    this.$message.warning(parseRetCode(res.result.RETCODE))
                    return
                }
                this.msgDetail = res.result
            }
        }
    })
</script>

<style lang="less" scoped>
    .message-main {
        margin: 0 auto;
        width: 100%;
        margin-top: 20px;
    }
    .message-content {
        width: 90%;
        padding: 10px;
        margin: 0 auto;
        .message-title {
            width: 100%;
            span {
                font-size: 12px;
                margin: 0 auto;
            }
        }
        .message-detail {
            width: 100%;
            margin-top: 30px;
            font-size: 15px;
            text-align: left;
        }
    }
</style>
