<template>
    <div>
        <el-descriptions v-if="positionData && positionData.length > 0" title="我的持仓" size="small" direction="vertical" :column="1" border :colon="true">
            <el-descriptions-item label="名称">[{{ positionData[0].COI }}] {{ positionData[0].CON }}</el-descriptions-item>
            <el-descriptions-item label="数量">{{ positionData[0].HQ }}</el-descriptions-item>
            <el-descriptions-item label="可卖">{{ positionData[0].TDE }}</el-descriptions-item>
            <el-descriptions-item label="可用">{{ positionData[0].CUQ }}</el-descriptions-item>
            <el-descriptions-item label="盈亏">{{ positionData[0].HPL | keepTwoDecimal }}</el-descriptions-item>
            <el-descriptions-item label="均价">¥{{ positionData[0].HAP }}</el-descriptions-item>
            <el-descriptions-item label="市值">¥{{ positionData[0].MV | keepTwoDecimal }}</el-descriptions-item>
            <!-- <el-descriptions-item label="操作">
                <el-input-number v-model="returnNum" :min="0" style="width: 100%" label="退货数量" size="small"></el-input-number>
                <el-button @click="returnGoods(positionData[0])" style="width: 100%;margin-top: 0.5rem" size="small" type="success">退回提货</el-button>
            </el-descriptions-item> -->
        </el-descriptions>
        <el-empty v-else :image-size="150"></el-empty>
    </div>
</template>

<script>
    import { loginApi } from '@/config/env'
    import { isFailureLogin } from '@/config/mUtils'
    export default {
        name: 'PositionTotal',
        data() {
            return {
                holdGoodsQuery: {
                    "name": "hold_query_mobile",
                    "U": "",
                    "COI": 979999,
                    "SI": ""
                },
                //退回提货参数
                returnParams: {
                    "commodity_count": 0,
                    "commodity_id": "",
                    "commodity_name": ""
                },
                positionData: {}, //持仓
                returnNum: 1  //退回提货数量
            }
        },
        created() {
            
        },
        mounted() {
            this.getPositionGoods()
        },
        props: {
            COI: {
                type: Number,
                default: 979999
            }
        },
        //解绑自定义事件
        beforeDestroy() {
            
        },
        methods: {
            //获取持仓汇总信息
            async getPositionGoods() {
                const { data: res } = await this.$http.post(loginApi.positionTotalApi, this.holdGoodsQuery)
                if (isFailureLogin(res.retcode)) {
                    this.positionData = res.resultList.REC
                } else {
                    return this.$message.error(res.message)
                }
            },
            //退回提货
            async returnGoods(item) {
                if (this.returnNum > item.TDE) {
                    this.returnNum = item.TDE
                    this.$message.warning('退回提货数量超出限制！')
                    return
                }
                const h = this.$createElement;
                this.$msgbox({
                    title: '退回提货提示',
                    message: h('p', null, [
                        h('span', null, '名称：'),
                        h('i', { style: 'color: teal' }, item.CON+'['+item.COI+']'),
                        h('br'),
                        h('span', null, '数量：'),
                        h('i', { style: 'color: teal' }, this.returnNum)
                    ]),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true
                            instance.confirmButtonText = '执行中...'
                            setTimeout(() => {
                                this.returnParams.commodity_count = this.returnNum
                                this.returnParams.commodity_id = item.COI
                                this.returnParams.commodity_name = item.CON
                                this.returnGoodsDo()
                                done()
                                setTimeout(() => {
                                    instance.confirmButtonLoading = false
                                }, 300)
                            }, 2000)
                        } else {
                            instance.cancelButtonLoading = true
                            instance.cancelButtonText = '取消中...'
                            setTimeout(() => {
                                done()
                                setTimeout(() => {
                                    instance.cancelButtonLoading = false
                                }, 300)
                            }, 1000)
                        }
                    }
                }).then(action => {
                    setTimeout(() => {
                        this.$router.go(0)
                    }, 1000)
                }).catch(err => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    })
                })
            },
            //确认退回提货
            async returnGoodsDo(item) {
                const { data: res } = await this.$http.post(loginApi.returnGoodsApi, this.returnParams)
                if (res.code !== 'E000000') {
                    return this.$message.warning(res.message)
                }
                return this.$message.success(res.message)
            },
        },
        // watch: {
        //     'returnNum': {
        //         handler(newVal, oldVal) {
        //             if (newVal > this.positionData[0].TDE) {
        //                 this.returnNum = this.positionData[0].TDE
        //                 return this.$message.error('退回提货数量超出限制！')
        //             }
        //         }
        //     }
        // }
    }
</script>