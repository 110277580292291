import { render, staticRenderFns } from "./PositionLists.vue?vue&type=template&id=88d464e4&scoped=true&"
import script from "./PositionLists.vue?vue&type=script&lang=js&"
export * from "./PositionLists.vue?vue&type=script&lang=js&"
import style0 from "./PositionLists.vue?vue&type=style&index=0&id=88d464e4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88d464e4",
  null
  
)

export default component.exports