<template>
    <div class="spot-rules">
        <div class="rules-title">
            <el-page-header @back="goBack" content="风险提示" title="返回">
            </el-page-header>
        </div>
        <div class="rules-content">
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:0;text-align:center">
                <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 24px">风险揭示书</span></strong>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:0;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">尊敬的拍卖客户</span>:</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">为了让您充分了解在使用拍卖软件系统参与拍卖时可能存在的相关风险，特提供本风险揭示书。请您慎重考虑自身情况后再参与拍卖活动，客观理性地认识到您在进行拍卖活动过程中可能产生的风险，包括但不限于</span>:</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">一、宏观经济风险</span>:拍品的价格受多种宏观经济因素的影响，经济政策变化、经济的周期性波动以及国际经济因素的变化都会对拍品价格产生不可预知的复杂影响。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">二、政策法规及市场风险</span>:由于国家法律、法规、政策的变化及相关政府部门的要求，均可能会对您拍卖的拍品价值产生影响或造成相关拍品流通的禁止、限制。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">三、规章制度调整及变动的风险</span>:根据国家法律法规要求和市场需要，拍卖人有权对各项规章制度进行调整或制定新的规章制度，可能会对您造成一定的损失。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">四、鉴定评估风险：拍卖人不对拍品的相关信息</span>(包括其委托的如评估、鉴定、仓储、保险等服务方)提供的信息进行实质性审查且不承担任何责任。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">五、业务风险：若您参拍的拍品价格因市场变动导致与您预期不符，会造成一定的损失。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">六、拍品拍卖截止</span>/终止风险:若您拍卖的拍品因临近拍卖截止日期或者因为突发状况终止拍卖的，您必须在拍卖人发布的提货日之前完成对自身已购得的拍品进行提货，如您未在规定时间内申请办理提货的，由此引起的仓储费、保险费等额外费用,以及其他一切法律后果概由您承担，拍卖人不承担任何责任，同时拍卖人依法取得拍品的留置权。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">七、毁损灭失风险</span>:货物的保管、运输、展示等过程中会产生破损、遗失等情形，可能导致实物贬值或影响拍品如期拍卖。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">八、流通性风险：您购入的拍品会因市场，政策，需求等各类因素而变化，亦可能导致您购入的拍品在拍卖市场中流通性较差的问题，拍卖人可对此类产品执行停拍并要求您在公告期限内提货，由此带来的后果概由您承担，拍卖人不承担任何责任。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">九、技术风险</span>:用于进行拍卖的电子通讯设备可能被网络黑客和计算机病毒攻击，这些均可能给您带来一定的风险。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">十、不可抗力导致的风险</span>:诸如地震、台风、火灾、战争、瘟疫、社会动乱等不可抗力因素或拍卖人及服务方无法控制和不可预测的系统故障、设备故障、通讯故障、电力故障等可能导致系统无法正常运行。结算银行无法控制和不可预测的系统故障、设备故障、通讯故障、电力故障等也可能导致资金转账系统非正常运行甚至瘫痪，这些都会使您的拍卖无法成交或者无法全部成交，或者转账资金不能即时到账。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">十一、数据传输风险</span>:由于业务指令是通过互联网进行输送，因互联网故障等原因使业务指令出现中断、中止、延迟等情况，导致拍卖无法成交、无法全部成交或延迟成交。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">十二、信息泄露风险</span>:由于包括但不限于您对自己相关信息保管不善，或您的计算机被病毒侵入、黑客攻击等原因，导致账户密码泄露或您身份被冒用，造成无法正确下达业务指令，或下达业务指定延迟、错误等。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">十三、软硬件系统风险</span>:您的设备及软件系统与拍卖系统不相匹配，导致您无法正常下达业务指令。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">十四、网络故障风险</span>:因网络设备或网络问题导致您重复参拍的风险。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">十五、其他风险</span>:操作不当、决策失误、网上业务未及时退出导致遭遇黑客攻击，均会给您造成损失。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">由于上述所提及的风险而产生的损失均由您自行承担。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px"><span style="font-family:微软雅黑">特别提示</span>:本风险揭示书的揭示事项仅为列举性质，未能详尽列明您参与本业务所面临的全部风险和可能导致您资产损失的所有因素。拍卖人敬告您，应当根据自身的经济条件和承受能力认真谨慎思考后自愿参与业务，且未受第三人欺诈胁迫。请认真阅读并谨记以下劝导:拍卖人仅为您提供拍卖相关服务。拍卖人没有任何投资或理财产品或属性，您在拍卖软件参与的拍卖活动均是以真实商品、与委托人或其他购买人之间达成的商业行为。您参与商业行为可能盈利或亏损，该盈利与亏损均属于参与商业行为产生的正常商业风险，拍卖人不对您参与商业行为可能产生的盈亏做任何承诺、保证和担保。</span>
            </p>
            <p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-indent:32px;text-align:justify;text-justify:inter-ideograph">
                <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">您已阅读并同意接受以上风险揭示书的全部内容，接受本文件中载明的所有条款，充分了解并愿意承担由此可能产生的一切损失。</span>
            </p>
            <p>
                <span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
            </p>
            <p>
                <br/>
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SpotRiskRules',
        data() {
            return {}
        },
        methods: {
            //返回
            goBack() {
                this.$router.go(-1)
            }
        }
    }
</script>

<style lang="less" scoped>
    .spot-rules {
        width: 100%;
        height: 100%;
        padding: 0.5rem;
        position: absolute;
        top: 0;
        left: 0;
        overflow-y: hidden;
        overflow-x: hidden;
        .rules-title {
            width: 100%;
            height: 3rem;
            position: fixed;
        }
        .rules-content {
            width: 100%;
            height: 90%;
            position: absolute;
            margin-top: 4rem;
            overflow: auto;
            padding: 2rem;
        }
    }
</style>