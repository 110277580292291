<template>
    <div class="trade-main">
        <div class="trade-title">
            <h4>商品交易</h4>
            <el-row class="trade-tabs">
                <el-col :span="24">
                    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                        <el-tab-pane label="摘牌买入" name="first"></el-tab-pane>
                        <el-tab-pane label="摘牌卖出" name="second"></el-tab-pane>
                        <el-tab-pane label="委托买入" name="third"></el-tab-pane>
                        <el-tab-pane label="委托卖出" name="fourth"></el-tab-pane>
                        <el-tab-pane label="委托撤单" name="five"></el-tab-pane>
                    </el-tabs>
                </el-col>
            </el-row>
        </div>
        <div class="trade-areas">
            <el-row class="trade-goods">
                <el-col :span="24">
                    <Goods v-bind:COI="goodsId" />
                </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row class="trade-info">
                <el-col :span="24">
                    <!-- 子路由 -->
                    <router-view v-if="isRouterAlive"></router-view>
                </el-col>
            </el-row>
            <!-- <el-divider></el-divider>
            <el-row class="trade-position">
                <el-col :span="24">
                    <PositionTotal :COI="goodsId" />
                </el-col>
            </el-row> -->
        </div>
        
        <FooterNavigation />
    </div>
</template>

<script>
    import Goods from './children/Goods.vue'
    import FooterNavigation from '../../common/footer/FooterNavigation.vue'
    import PositionTotal from './children/PositionTotal.vue'
    import { loginApi } from '@/config/env'
    export default {
        name: 'Trade',
        provide() {
            return {
                reload: this.reload
            }
        },
        components: {
            Goods,
            //PositionTotal,
            FooterNavigation
        },
        data() {
            return {
                activeName: 'first',
                goodsId: '',
                COI: '',
                isRouterAlive: true,
            }
        },
        created() {
            this.goodsId = isNaN(parseInt(this.$route.query.gid)) ? 979999 : parseInt(this.$route.query.gid)
            // this.$router.push('/trade/delistingbuy')
        },
        mounted() {
            
        },
        methods: {
            handleClick(tab, event) {
                this.reload()
                switch (tab._props.name) {
                    case 'first':
                        this.$router.push('/trade/delistingbuy')
                        break;
                    case 'second':
                        this.$router.push('/trade/delistingsell')
                        break;
                    case 'third':
                        this.$router.push('/trade/entrustbuy')
                        break;
                    case 'fourth':
                        this.$router.push('/trade/entrustsell')
                        break;
                    case 'five':
                        this.$router.push('/trade/cancelorder')
                        break;
                    default:
                        break;
                }
            },
            reload() {
                this.isRouterAlive = false
                this.$nextTick(function() {
                    this.isRouterAlive = true
                })
            }
        },
        watch: {
            '$route.path': {
                deep: true,
                handler(newVal, oldVal) {
                    console.log(newVal, oldVal)
                    if (newVal === '/trade/delistingbuy') {
                        this.activeName = 'first'
                    } else if (newVal === '/trade/delistingsell') {
                        this.activeName = 'second'
                    } else if (newVal === '/trade/entrustbuy') {
                        this.activeName = 'third'
                    } else if (newVal === '/trade/entrustsell') {
                        this.activeName = 'fourth'
                    } else if (newVal === '/trade/cancelorder') {
                        this.activeName = 'five'
                    }
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .trade-main {
        width: 100%;
        padding: 0.5rem;
        overflow: hidden;
        .trade-title {
            color: #333333;
            text-align: center;
            padding: 0;
            width: 100%;
            h4 {
                line-height: 3rem;
                font-weight: bolder;
            }
        }
        .trade-areas {
            width: 100%;
            height: 60rem;
            overflow-y: auto;
            padding: 0;
        }
    }
    /deep/ .el-tabs__nav-scroll {
        overflow-x: auto;
    }
</style>