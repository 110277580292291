<template>
    <div class="position-lists">
        <div class="head-delivery">
            <el-page-header @back="goBack" content="仓量明细" style="font-weight: bolder;">
            </el-page-header>
            <el-divider></el-divider>
        </div>
        
        <div class="infinite-list-wrapper" v-if="list.length > 0">
            <el-table :data="list" stripe style="width: 100%" size="small" height="100%">
                <el-table-column label="日期" width="">
                    <template slot-scope="scope">
                        {{ scope.row.time | strCutout(0, 10) }}
                    </template>
                </el-table-column>
                <!-- <el-table-column label="上次仓量" width="">
                    <template slot-scope="scope">
                        {{ scope.row.pre | keepTwoDecimal(2) }}
                    </template>
                </el-table-column> -->
                <el-table-column label="仓量动态">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.stp>0" type="success" size="mini">+{{ scope.row.stp }}</el-tag>
                        <el-tag v-else type="danger" size="mini">{{ scope.row.stp }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="剩余仓量">
                    <template slot-scope="scope">
                        {{ scope.row.nxt | keepTwoDecimal(2) }}
                    </template>
                </el-table-column>
                <el-table-column label="备注" prop="remark"></el-table-column>
            </el-table>
        </div>
        <el-empty class="infinite-list-wrapper" v-else :image-size="150"></el-empty>
        <!-- 分页 -->
        <div class="foot-delivery">
            <el-pagination v-if="num_none" background small
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange" 					
                layout="prev, pager, next"
                :current-page="current_page"
                :page-size="pagesize"
                :page-count="pagecount"
                :pager-count="5"
            ></el-pagination>
        </div>
        
    </div>
</template>

<script>
    import { loginApi } from '@/config/env'
    import { isFailureLogin } from '@/config/mUtils'
    export default {
        name: 'MyPositionRecord',
        data() {
            return {
                queryParams: {
                    filter: {},
                    page: {
                        "begin": 0,
                        "end": 0,
                        "pageNo": 1,
                        "pageSize": 20,
                        "start": 0
                    }
                },
                total: 0,
                current_page: 1,
                pagecount: 0,
                num_none: true,
                pagesize: 20,
                list: [], //后端返回的数组
            }
        },
        mounted() {
            this.getPositionLists()
        },
        methods: {
            //返回
            goBack() {
                this.$router.push('/user')
            },
            //每页数据条数发生改变时触发
            handleSizeChange() {},
            //当前页发生改变时触发
            handleCurrentChange(val) {
                this.queryParams.page.pageNo = val
                this.getPositionLists()
            },
            async getPositionLists() {
                const { data: res } = await this.$http.post(loginApi.positionRecordApi, this.queryParams)
                if (res.code == 'E000000') {
                    this.total = res.data.totalCount
                    this.pagecount = res.data.totalPage
                    this.current_page = res.data.pageNo
                    this.pagesize = res.data.pageSize
                    this.list = res.data.data
                } else {
                    return this.$message.warning(res.message)
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .position-lists {
        width: 100%;
        height: 100%;
        padding: 10px;
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        .head-delivery {
            position: fixed;
            top: 1rem;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
        }
        .foot-delivery {
            width: 100%;
            text-align: center;
            position: fixed;
            bottom: 0;
            width: 100%;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
        }
    }
    .infinite-list-wrapper {
        width: 100%;
        height: 85%;
        position: absolute;
        top: 7rem;
        //bottom: 14rem;
        left: 50%;
        transform: translateX(-50%);
        overflow-y: scroll;
        overflow-x: hidden;
        
    }
</style>