<template>
    <div class="error-msg">
        <el-result icon="error" title="错误提示：404" subTitle="抱歉！当前页面不存在，请返回。">
            <template slot="extra">
                <el-button type="primary" size="medium" @click.native="goBack">返回</el-button>
            </template>
        </el-result>
    </div>
</template>

<script>
    export default {
        name: 'ErrorMsg',
        data() {
            return {}
        },
        methods: {
            //返回
            goBack() {
                this.$router.go(-1)
            }
        }    
    }
</script>

<style lang="less" scoped>
    .error-msg {
        width: 100%;
        height: 100%;
        position: absolute;
        .el-result {
            width: 100%;
            position: absolute;
            top: 25%;
            transform: translateY(-25%);
        }
    }
</style>