<template>
    <div class="message-center">
        <el-page-header @back="goBack" content="消息中心" style="font-weight: bolder;">
        </el-page-header>
        <el-divider></el-divider>
        <router-view></router-view>
    </div>
</template>

<script>
    import Details from "./children/Details.vue";
    export default {
        name: 'Message',
        components: {
            Details
        },
        data() {
            return {

            }
        },
        methods: {
            goBack() {
                this.$router.go(-1)
            }
        }
    }
</script>

<style scoped>
    .message-center {
        width: 100%;
        text-align: center;
        padding: 10px;
    }
</style>
