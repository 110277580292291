<template>
    <div class="position">
        <el-page-header @back="goBack" content="存货汇总" style="font-weight: bolder;">
        </el-page-header>
        <el-divider></el-divider>
        <PositionTotal></PositionTotal>
    </div>
</template>

<script>
    import PositionTotal from './PositionTotal.vue'
    export default {
        name: 'PositionSummary',
        data() {
            return {}
        },
        components: {
            PositionTotal
        },
        methods: {
            //返回
            goBack() {
                this.$router.go(-1)
            },
        }
    }
</script>

<style lang="less" scoped>
    .position {
        width: 95%;
        margin: 0 auto;
        padding: 10px;
    }
</style>