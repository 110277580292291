<template>
    <div class="error-msg">
        <el-result v-if="order_state==0" icon="success" title="温馨提示" subTitle="已完成支付，请稍后查询支付结果...">
            <template slot="extra">
                <el-button type="success" @click="goBack">返回</el-button>
            </template>
        </el-result>
        <el-result v-else-if="order_state==1" icon="success" title="支付成功" subTitle="订单支付成功，请返回查看订单详情">
            <template slot="extra">
                <div class="pay-text">
                    <p>支付金额：￥{{ total_amount }}</p>
                </div>
                <el-button type="success" @click="goBack">返回</el-button>
            </template>
        </el-result>
        <el-result v-else-if="order_state==2" icon="info" title="订单提示" subTitle="抱歉！您还未完成支付，请返回">
            <template slot="extra">
                <el-button type="default" @click="goBack">返回</el-button>
            </template>
        </el-result>
        <el-result v-else-if="order_state==3" icon="error" title="订单提示" subTitle="抱歉！订单已超时关闭，请返回">
            <template slot="extra">
                <el-button type="default" @click="goBack">返回</el-button>
            </template>
        </el-result>
        <el-result v-else-if="order_state==4" icon="warning" title="订单提示" subTitle="订单已取消，请返回">
            <template slot="extra">
                <el-button type="default" @click="goBack">返回</el-button>
            </template>
        </el-result>
        <el-result v-else icon="error" title="温馨提示" subTitle="抱歉！发生未知错误，请返回">
            <template slot="extra">
                <el-button type="default" @click="goBack">返回</el-button>
            </template>
        </el-result>
    </div>
</template>

<script>
    import { loginApi } from '@/config/env'
    export default {
        name: 'Results',
        data() {
            return {
                order_id: '',
                total_amount: '',
                order_state: '',
                timer: null
            }
        },
        created() {
            this.order_id = this.$route.query.out_trade_no ? this.$route.query.out_trade_no : ''
            this.total_amount = this.$route.query.total_amount ? this.$route.query.total_amount : ''
        },
        mounted() {
            this.timer = setInterval(() => {
                this.getOrderState()
            }, 5000)
            this.getOrderState()
        },
        methods: {
            //返回
            goBack() {
                this.$router.push('/activity/activitylist')
            },
            //获取订单状态
            async getOrderState() {
                const { data: res } = await this.$http.post(loginApi.getSalonOrderStateApi+"?salon_order_id="+this.order_id)
                if(res.code === 'E000000') {
                    this.order_state = res.data.salon_order_state
                } else {
                    return this.$message.error(res.message)
                }
            }
        },
        beforeDestroy() {
            clearInterval(this.timer)
            this.timer = null
        }
    }
</script>

<style lang="less" scoped>
    .error-msg {
        width: 100%;
        height: 100%;
        position: absolute;
        .el-result {
            width: 100%;
            position: absolute;
            top: 25%;
            transform: translateY(-25%);
        }
    }
    .pay-text {
        width: 100%;
        border-radius: 10px;
        margin-bottom: 2rem;
    }
</style>