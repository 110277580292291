<template>
    <div class="forget-pwd">
        <el-page-header @back="goBack" content="重置资金密码" style="font-weight: bolder;">
        </el-page-header>
        <el-divider></el-divider>
        <el-form ref="passwordForm" :model="passwordForm" :rules="rules" label-width="100px">
            <el-form-item label="手机号">
                <el-input :disabled="true" :value="userInfos.UM"></el-input>
            </el-form-item>
            <el-form-item label="真实姓名" prop="UN">
                <el-input v-model="passwordForm.UN" placeholder="请输入您的真实姓名"></el-input>
            </el-form-item>
            <el-form-item label="身份证号码" prop="CDN">
                <el-input v-model="passwordForm.CDN" placeholder="请输入您的证件号码"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="IC">
                <el-input v-model="passwordForm.IC" placeholder="请输入验证码">
                    <el-button type="info" slot="append" :disabled="isSend" @click.prevent="sendMsg">
                        <span style="font-size:10px">{{ codeText }}</span>
                    </el-button>
                </el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="NPWD">
                <el-input v-model="passwordForm.NPWD" placeholder="设置您的支付密码" show-password></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="confirmPwd">
                <el-input v-model="passwordForm.confirmPwd" placeholder="请再次输入密码" show-password></el-input>
            </el-form-item>
            <el-button class="submit-btn" type="success" @click="onSubmit">确认</el-button>
        </el-form>
    </div>
</template>

<script>
    import { loginApi, noLoginApi } from '@/config/env'
    import { mapState } from 'vuex'
    import { isFailureLogin } from '@/config/mUtils'
    export default {
        name: 'ResetMoneyPwd',
        data() {
            //校验确认密码是否一致
            var validateConfirmPass = (rule, value, callback) => {
                if (value === '') {
                    return callback(new Error('请再次输入密码'))
                } else if (value !== this.passwordForm.NPWD) {
                    return callback(new Error('两次输入密码不一致!'))
                } else {
                    callback()
                }
            }
            //校验身份ID
            const checkIdcard = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('证件号码不能为空！'))
                } else {
                    const regId = /^(\d{18,18}|\d{15,15}|\d{17,17}X)$/
                    if (!regId.test(value)) {
                        return callback(new Error('证件号码不合法！'))
                    } else {
                        callback()
                    }
                }
            }
            return {
                passwordForm: {
                    "name": "reset_fundspwd",
                    "U": "",
                    "SI": "",
                    "UN": "",
                    "CDT": "1",
                    "CDN": "",
                    "IC": "",
                    "NPWD": "",
                    "confirmPwd": ""
                },
                sendPhoneMsg: {
                    "name": "reset_fundspwd_icode",
                    "U": "",
                    "SI": ""
                },
                count: 300,
                codeText: '获取验证码',
                isSend: false,
                lock: null,
                timer: null,
                rules: {
                    UN: [
                        { required: true, message: '请输入您的真实姓名', trigger: 'blur' }
                    ],
                    CDN: [
                        { required: true, message: '请输入您的有效证件号码', trigger: 'blur' },
                        { validator: checkIdcard, trigger: 'blur' }
                    ],
                    IC: [
                        { required: true, message: '请输入短信验证码', trigger: 'blur' }
                    ],
                    NPWD: [
                        { required: true, message: '请输入您的支付密码', trigger: 'blur' },
                        { min: 6, max: 30, message: "密码长度至少6位以上", trigger: 'blur'}
                    ],
                    confirmPwd: [
                        { required: true, message: '请再次输入确认密码', trigger: 'blur' },
                        { validator: validateConfirmPass, trigger: 'blur' }
                    ]
                }
            }
        },
        computed: {
            ...mapState(['userInfos'])
        },
        beforeDestroy() {
            clearInterval(this.lock)
            this.timer = null
        },
        mounted() {

        },
        methods: {
            //返回
            goBack() {
                this.$router.go(-1)
            },
            //提交设置新密码
            onSubmit() {
                this.$refs.passwordForm.validate( async (valid) => {
                    if(!valid) return false
                    const { data: res } = await this.$http.post(loginApi.resetMoneyPasswordApi, this.passwordForm)
                    //判断是否登录失效，以及返回错误信息
                    if (isFailureLogin(res.retcode)) {
                        this.$message.success(res.message)
                        this.$router.push('/user')
                    } else {
                        return this.$message.warning(res.message)
                    }
                    
                })
            },
            //发送短信验证
            async sendMsg() {
                const { data: res } = await this.$http.post(loginApi.resetMoneyPwdCodeApi, this.sendPhoneMsg)
                //是否登录失效
                if (!isFailureLogin(res.retcode)) {
                    this.$router.push('/login')
                    return this.$message.warning(res.message)
                }
                this.$message.success(res.message)
                this.codeText = this.count + 's后重新发送'
                this.lock = setInterval( () => {
                    this.isSend = true
                    this.count--
                    this.codeText = this.count + 's后重新发送'
                    if (this.count < 0) {  //当倒计时小于0时清除定时器
                        clearInterval(this.lock)
                        this.codeText = '重新发送验证码'
                        this.count = 300
                        this.isSend = false
                    }
                }, 1000)
            }
        }
    }
</script>

<style lang="less" scoped>
    .forget-pwd {
        width: 98%;
        //padding: 10px;
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);

        form {
            margin-top: 5%;
        }
    }
    .submit-btn {
        width: 100%;
    }
</style>