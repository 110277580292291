<template>
    <div class="goods">
        <div @click.prevent="isShowWarp"><span class="iconfont icon-zhankai"></span></div>
        <el-descriptions class="margin-top" :title="`${goodsInfos.CON}[${goodsInfos.COI}]`" :column="4" size="small" :colon="false" direction="vertical">
            <el-descriptions-item label="当前价格">￥{{ goodsInfos.LAST | keepTwoDecimal }}</el-descriptions-item>
            <el-descriptions-item label="持仓总数">{{ positionData.HQ?positionData.HQ:0 }}</el-descriptions-item>
            <el-descriptions-item label="可卖数量">{{ positionData.TDE?positionData.TDE:0 }}</el-descriptions-item>
            <el-descriptions-item label="冻结数量">{{ positionData.OFQ?positionData.OFQ:0 }}</el-descriptions-item>
        </el-descriptions>
        <el-drawer
            size="65%"
            direction="ltr"
            title="请选择商品"
            :visible.sync="isShowGoods"
            :modal-append-to-body="false"
            :with-header="true"
            >
            <el-card class="box-card-list">
                <!-- <div slot="header" class="clearfix">
                    <el-input placeholder="请输入商品名称" v-model="inputSearch" class="input-with-select">
                        <el-button slot="append" @click="searchGoods"><span class="iconfont icon-sousuo"></span></el-button>
                    </el-input>
                </div> -->
                <div class="list-group">
                    <button :id="'goodsId_' + item.resultList.REC[0].COI" @click="selectMe(item.resultList.REC[0])" v-for="item in goodsLists" 
                        :key="item.resultList.REC[0].COI" 
                        type="button" 
                        class="list-group-item">
                        <span class="badge"><span class="iconfont "></span></span>
                        [{{ item.resultList.REC[0].COI }}]&nbsp;{{ item.resultList.REC[0].CON }}
                    </button>
                </div>
            </el-card>
        </el-drawer>
    </div>
</template>

<script>
    import { noLoginApi, loginApi } from '@/config/env'
    import { isFailureLogin } from '@/config/mUtils'
    import { mapState } from 'vuex'
    export default {
        name: 'Goods',
        data() {
            return {
                queryData: {
                    "name": "commodity_data_query",
                    "U": "",
                    "COI": "",
                    "SI": ""
                },
                holdGoodsQuery: {
                    "name": "hold_query_mobile",
                    "U": "",
                    "COI": '',
                    "SI": ""
                },
                isShowGoods: false,
                inputSearch: '',  //搜索商品内容
                goodsLists: [],  //商品列表信息
                positionData: {} //持仓
            }
        },
        computed: {
            ...mapState(['goodsInfos'])
        },
        created() {
            this.$bus.$on('isReload', (val) => {
                if (val) {
                    this.getPositionGoods()
                }
            })
            this.queryData.COI = this.COI
            this.holdGoodsQuery.COI = this.COI
        },
        beforeMount() {

        },
        mounted() {
            this.getAuction()
            this.getCommodityData()
            this.getPositionGoods()
        },
        beforeDestroy() {
            this.$bus.$off('isReload')
        },
        //接收父组件传入的参数值：COI 表示商品统一代码
        props: {
            COI: {
                type: Number,
                default: 979797
            }
        },
        methods: {
            //获取商品交易行情
            async getCommodityData() {
                const { data: res } = await this.$http.post(loginApi.commodityDataQueryApi, this.queryData)
                if (isFailureLogin(res.retcode)) {
                    this.goodsData = res.resultList.REC[0]
                    //将列表信息保存到vuex
                    this.$store.dispatch('getGoodsInfos', JSON.stringify(res.resultList.REC[0]))
                } else {
                    return this.$message.error(res.message)
                }
            },
            //获取持仓汇总信息
            async getPositionGoods() {
                const { data: res } = await this.$http.post(loginApi.positionTotalApi, this.holdGoodsQuery)
                if (isFailureLogin(res.retcode)) {
                    if (res.result.TC > 0) {
                        this.positionData = res.resultList.REC[0]
                    } else {
                        this.positionData = {}
                    }
                } else {
                    return this.$message.error(res.message)
                }
            },
            //拍场列表查询
            async getAuction() {
                const { data: res } = await this.$http.post(noLoginApi.auctionListApi)
                if (res.code !== 'E000000') {
                    return this.$message.warning(res.message)
                }
                this.goodsLists = res.data
            },
            //是否显示抽屉
            isShowWarp() {
                this.isShowGoods = !this.isShowGoods
            },
            //选中
            selectMe(objItem) {  
                $('.iconfont').removeClass("icon-honggou")
                $('#goodsId_' + objItem.COI).find(".iconfont").addClass("icon-honggou")
                //保存商品信息到vuex中
                this.queryData.COI = objItem.COI
                this.getCommodityData()
                this.isShowGoods = false
            }
        },
        watch: {
            '$store.state.goodsInfos': {
                deep: true,
                handler(newVal, oldVal) {
                    this.holdGoodsQuery.COI = newVal.COI
                    this.getPositionGoods()
                }
            }
        }  
    }
</script>

<style lang="less" scoped>
    .goods {
        width: 100%;
        min-height: 10rem;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: baseline;
        padding: 0;
        div:nth-child(1) {
            min-height: 100%;
            cursor: pointer;
            margin: 0 auto;
        }
        div:nth-child(2) {
            margin-left: 0.5rem;
            min-height: 100%;
        }
    }
    .badge {
        background-color: #FFFFFF !important;
        padding: 0;
        span {
            color: #DF2625;
        }
    }
    button.list-group-item {
        font-weight: 700;
    }
    /deep/ .el-descriptions__body {
        background-color: #EFEFF4;
    }
</style>