<template>
    <div class="goods-lists">
        <el-header style="padding: 0; height: auto;">
            <el-input placeholder="请输入商品名称搜索" v-model="params.filter.key_word" class="input-with-select">
                <el-button slot="append" icon="el-icon-search" @click.prevent="searchGoods"></el-button>
            </el-input>
        </el-header>
        
        <el-main style="padding: 0.2rem;height: 90%;overflow-y: auto;">
            <div class="shop-item" @click="showDetails(item.product_id)" v-for="(item, index) in list" :key="index">
                <div class="item-left">
                    <img v-if="item.product_pic_path" :src="item.product_pic_path" alt="" class="img-thumbnail">
                </div>
                <div class="item-right">
                    <div>
                        <span>{{ item.product_name }}</span>
                    </div>
                    <div>
                        <span>价&nbsp;格：￥{{ item.product_price | keepTwoDecimal }}</span>
                    </div>
                    <!-- <div>
                        <span>仓量兑换：{{ item.product_price_position }}</span>
                    </div> -->
                    <div>
                        <span>积分兑换：{{ item.product_price_point }}</span>
                    </div>
                </div>
            </div>
        </el-main>
                
        <!-- 分页 -->
        <div class="paginate-goods">
            <el-pagination v-if="num_none" background small
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange" 					
                layout="prev, pager, next"
                :current-page="current_page"
                :page-size="pagesize"
                :page-count="pagecount"
                :pager-count="5">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import imgUrl from '@/assets/images/xiandairen.jpg'
    import { noLoginApi, loginApi } from '@/config/env'
    export default {
        name: 'GoodsLists',
        data() {
            return {
                //查询参数
                params: {
                    filter: {
                        type_id: '',
                        key_word: ''
                    },
                    page: {
                        pageNo: 1,
                        pageSize: 10
                    }
                },
                imgUrl: imgUrl,
                search: '',
                list: [],
                total: 0, //总数据条数
                current_page: 1, //当前页码
                pagecount: 0, //总页码数
                num_none: true, //是否显示分页
                pagesize: 10, //每页显示数据条数
            }
        },
        created() {
            this.params.filter.type_id = this.$route.query.tid ? this.$route.query.tid : ''
        },
        mounted() {
            this.params.filter.type_id = this.$route.query.tid ? this.$route.query.tid : ''
            this.$bus.$on('getCateId', data => {
                if (isNaN == parseInt(data)) {
                    this.params.filter.type_id = ''
                } else {
                    this.params.filter.type_id = data
                }
                this.getMallGoodsLists()
            })
            //获取商城商品列表
            this.getMallGoodsLists()
        },
        beforeDestroy() {
            this.$bus.$off('getCateId')
        },
        methods: {
            //每页数据条数发生改变时触发
            handleSizeChange() {},
            //当前页发生改变时触发
            handleCurrentChange(val) {
                this.params.page.pageNo = val
                this.getMallGoodsLists()
            },
            //搜索商品
            searchGoods() {
                this.getMallGoodsLists()
            },
            async getMallGoodsLists() {
                const { data: res } = await this.$http.post(noLoginApi.mallGoodsListsApi, this.params)
                if (res.code !== 'E000000') {
                    return this.$message.warning(res.message)
                } else {
                    this.total = res.data.totalCount
                    this.current_page = res.data.pageNo
                    this.pagesize = res.data.pageSize
                    this.pagecount = res.data.totalPage
                    this.list = res.data.data
                }
            },
            //查询商品详情
            // showDetails(gid) {
            //     this.$router.push({ path: '/mall/goodslists/goodsdetails', query: {
            //         id: gid
            //     } })
            // },
            //查询商品详情
            showDetails(gid) {
                this.$router.push({ path: '/mall/productdetails', query: {
                    product_id: gid
                } })
            }
        },
        watch: {
            'params.filter': {
                //深度监视
                deep: true,
                handler(newVal, oldVal) {
                    this.params.page.pageNo = 1
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .goods-lists {
        width: 100%;
        height: 100%;
        
    }
    .shop-item {
        width: 100%;
        background-color: #FFFFFF;
        margin: 0 0 0.5rem 0;
        border-radius: 0.5rem;
        display: flex;
        flex-wrap: row nowrap;
        justify-content: flex-start;
        padding: 0.3rem;
        cursor: pointer;
        .item-left {
            width: 12rem;
            height: 10rem;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .item-right {
            width: 15rem;
            display: flex;
            flex-direction: column;
            padding: 1rem;
            font-weight: 600;
            justify-content: space-between;
            align-items: flex-start;
            align-content: center;
            font-weight: 600;
            div:first-child {
                font-size: 1.5rem;
                color: #FF0000;
            }
        }
    }
    .paginate-goods {
        width: 100%;
        height: auto;
        text-align: center;
    }
</style>