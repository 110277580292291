<template>
    <div class="box">
        <div class="top">
            <h4 align="center">商城</h4>
            <el-input placeholder="请输入内容" v-model="params.filter.key_word">
                <span @click="explaneMenu" class="iconfont icon-caidan" slot="prepend"></span>
                <el-button slot="append" icon="el-icon-search" @click="searchGoods"></el-button>
            </el-input>
        </div>
        <div class="content-continer" v-bind:style="{ height: scrollHeights }" id="scrollInfos" ref="scollInfos" @scroll="scollHandel">
            <el-row :gutter="5" >
                <el-col :span="12" v-for="(item, index) in list" :key="index">
                    <div class="continer-left"  @click.prevent="queryGoods(item.product_id)">
                        <div class="image-info">
                            <el-image style="max-width: 100%;height: auto;border-radius: 5px 5px 0 0;" :src="'https://yuanjunguoji.com'+item.product_pic_path"></el-image>
                        </div>
                        <div class="goods-info">
                            <div class="goods-name">
                                <div>{{ item.product_name }}</div>
                            </div>
                            <div class="goods-row">
                                <el-row>
                                    <el-col :span="24">
                                        <div class="left-price">
                                            <p>￥<em>{{ item.product_price | keepTwoDecimal }}</em></p>
                                            <p>积分：<em>{{ item.product_price_point | keepTwoDecimal }}</em></p>
                                        </div>
                                    </el-col>
                                    <!-- <el-col :span="10">
                                        <div class="right-salesvolume">
                                            <em style="color: #999;">销量：</em>
                                            <em style="color: #999;">250000+</em>
                                        </div>
                                    </el-col> -->
                                </el-row>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-empty :image-size="200" v-if="list.length==0" description="暂无任何商品信息"></el-empty>
        </div>
        <div class="empty-info"></div>
        <FooterNavigation />
    </div>
</template>

<script>
import images_url from '@/assets/images/mall01.png'
import FooterNavigation from '@/components/common/footer/FooterNavigation.vue'
import { noLoginApi, loginApi } from '@/config/env'
export default {
    components: { FooterNavigation },
    name: 'MallIndex',
    data() {
        return {
            //查询参数
            pageNo: 1,  //默认第几页
            pageSize: 20,  //默认每页显示数据量
            params: {
                filter: {
                    type_id: '',
                    key_word: ''
                },
                page: {
                    pageNo: 1,
                    pageSize: 20
                }
            },
            url_infos: images_url,
            screenHeight: 0,
            list: [],
        }
    },
    computed: {
        scrollHeights() {
            return this.screenHeight - 150 + 'px'
        }
    },
    beforeCreate() {
        
    },
    created() {
        
    },
    mounted() {
        this.screenHeight = window.innerHeight
        //获取商城商品列表
        this.getMallGoodsLists()
    },
    methods: {
        // 监听滚动条事件
        scollHandel() {
            const scrollTop = this.$refs.scollInfos.scrollTop
            // console.log(scrollTop)
            //判断滚动边界
            if (this.$refs.scollInfos.clientHeight + scrollTop >= this.$refs.scollInfos.scrollHeight) {
                //console.log("已经滚动到底部");
                // 滚动到底部调用分页接口

            } else {
                //console.log("还未滚动到底部");
            }
        },
        // 获取商品列表信息
        async getMallGoodsLists() {
            const { data: res } = await this.$http.post(noLoginApi.mallGoodsListsApi, this.params)
            if (res.code !== 'E000000') {
                return this.$message.warning(res.message)
            } else {
                this.list = res.data.data
            }
        },
        //搜索按钮
        searchGoods() {
            this.params.filter.type_id = ''
            this.params.page.pageNo = this.pageNo
            this.params.page.pageSize = this.pageSize
            this.getMallGoodsLists()
        },
        // 查看商品详情
        queryGoods(goodsId) {
            //console.log(goodsId);
            this.$router.push({ path: '/mall/productdetails', query: { goodsNo: goodsId } })
        },
        //展开我的订单
        explaneMenu() {
            this.$router.push('/mall')
        }
    }
}
</script>

<style lang="less" scoped>
    body {
        overflow: hidden;
    }
    .box {
        width: 100%;
        margin: 0;
        padding: 0;
        height: 100%;
    }
    #scrollInfos {
        width: 100%;
        //height: 100%;
        overflow-y: scroll;
    }
    .el-col-12 {
        margin-top: 0.5rem;
        height: 280px;
    }
    .content-continer {
        padding: 0.5rem;
        margin-bottom: 20px;
    }
        .continer-left {
            width: 100%;
            background-color: #ffffff;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            border-radius: 10px;
            height: 280px;
            //padding: 0.2rem;
            //margin: 0.2rem;
            cursor: pointer;
            .image-info {
                width: 100%;
                height: 100%;
                margin: 0 auto;
                //padding: 0.2rem;
            }
            .goods-info {
                width: 100%;
                height: 100%;
                //padding: 0.2rem;
                text-align: left;
                margin: 0 auto;
                .goods-name {
                    width: 100%;
                    padding-left: 0.3rem;
                    div {
                        width: 100%;
                        font-size: 14px;
                        font-family: -apple-system, Helvetica, sans-serif;
                        color: #434343;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        word-break: break-all;
                        -webkit-box-orient: vertical;
                        
                    }
                }
                .left-price {
                    width: 100%;
                    height: 40px;
                    color: #FF4142;
                    font-style: normal;
                    font-family: JDZH-Regular, sans-serif;
                    font-size: 1.25rem;
                    p:nth-child(1) {
                        padding-left: 0.3rem;
                        text-decoration: line-through;
                        margin: 0;
                    }
                    p:nth-child(2) {
                        margin: 0;
                        padding-left: 0.3rem;
                        font-weight: 600;
                    }
                }
            }
    }
    .empty-info {
        width: 100%;
        height: 50px;
    }
</style>