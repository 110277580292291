import { render, staticRenderFns } from "./DealRecord.vue?vue&type=template&id=9a97bd8e&scoped=true&"
import script from "./DealRecord.vue?vue&type=script&lang=js&"
export * from "./DealRecord.vue?vue&type=script&lang=js&"
import style0 from "./DealRecord.vue?vue&type=style&index=0&id=9a97bd8e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a97bd8e",
  null
  
)

export default component.exports