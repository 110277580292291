<template>
    <div class="order-lists">
        <div class="order-content">
            <el-card class="box-card">
                <el-descriptions title="" direction="horizontal" :column="1" size="small" border>
                    <el-descriptions-item label="会员编号">{{ profileData.user_id }}</el-descriptions-item>
                    <el-descriptions-item label="名称">{{ profileData.user_name }}</el-descriptions-item>
                    <el-descriptions-item label="我的仓量">{{ profileData.position }}</el-descriptions-item>
                    <el-descriptions-item label="我的积分">{{ pointInfo }}</el-descriptions-item>
                    <el-descriptions-item label="操作">
                        <el-form ref="ex_form" :model="ex_form" label-width="auto" size="mini">
                            <el-form-item label="兑换量：">
                                <el-input v-model="ex_form.ex_number"></el-input>
                            </el-form-item>
                        </el-form>
                        <el-button @click.native="exchangeIntegral" type="danger" size="mini" style="width: 100%;">仓量兑换积分</el-button>
                    </el-descriptions-item>
                </el-descriptions>
            </el-card>
        </div>
    </div>
</template>

<script>
    import imgUrl from '@/assets/images/xiandairen.jpg'
    import { noLoginApi, loginApi } from '@/config/env'
    export default {
        name: 'ProfileInfo',
        data() {
            return {
                //查询用户扩展信息参数
                queryUserExtendParams: {
                    "name": "userInfo",
                    "U": "",
                    "SI": ""
                },
                //查询商城点数信息参数
                queryPointParams: {
                    "name": "mall_point",
                    "U": "",
                    "SI": ""
                },
                profileData: {},
                pointInfo: '',
                ex_form: {
                    ex_number: ''
                }
            }
        },
        mounted() {
            this.getProfileInfos()
            this.getProfilePoint()
        },
        beforeDestroy() {
            
        },
        methods: {
            //获取订单记录
            async getProfileInfos() {
                const { data: res } = await this.$http.post(loginApi.userInfoExtendApi, this.queryUserExtendParams)
                if (res.code !== 'E000000') {
                    return this.$message.warning(res.message)
                }
                this.profileData = res.data
            },
            //获取通兑点数
            async getProfilePoint() {
                const { data: res } = await this.$http.post(loginApi.queryPointExchangeApi, this.queryPointParams)
                if (res.code !== 'E000000') {
                    return this.$message.warning(res.message)
                }
                this.pointInfo = res.data ? res.data.point : 0
            },
            //仓量兑换积分按钮
            exchangeIntegral() {
                if (!this.ex_form.ex_number) {
                    return this.$message.error('请您输入需要兑换的仓量！')
                }
                const h = this.$createElement
                this.$msgbox({
                    title: '兑换提示',
                    message: h('p', null, [
                        h('span', null, '扣减仓量：'),
                        h('i', { style: 'color: teal' }, this.ex_form.ex_number),
                        // h('br'),
                        // h('span', null, '商品数量：'),
                        // h('i', { style: 'color: teal' }, this.exchange_num),
                        
                        
                    ]),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true
                            instance.confirmButtonText = '执行中...'
                            setTimeout(() => {
                                this.positionExchangeIntegral()
                                done()
                                setTimeout(() => {
                                    instance.confirmButtonLoading = false
                                }, 300)
                            }, 2000)
                        } else {
                            instance.cancelButtonLoading = true
                            instance.cancelButtonText = '取消中...'
                            setTimeout(() => {
                                done()
                                setTimeout(() => {
                                    instance.cancelButtonLoading = false
                                }, 300)
                            }, 1000)
                        }
                    }
                }).then(action => {
                    setTimeout(this.$router.go(0), 1000)
                }).catch(err => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    })
                })
            },
            //仓量兑换积分
            async positionExchangeIntegral() {
                const { data: res } = await this.$http.post(loginApi.mallPositionExInteApi+'?point='+this.ex_form.ex_number)
                if (res.code !== 'E000000') {
                    return this.$message.warning(res.message)
                }
                return this.$message.success(res.message)
            }
        }
    }
</script>

<style lang="less" scoped>
    .order-lists {
        width: 100%;
        height: 100%;
        .order-content {
            width: 100%;
            height: 95%;
            padding: 0.2rem;
            margin-bottom: 0.5rem;
            overflow-y: auto;
            .el-card {
                margin-bottom: 0.5rem;
            }
            /deep/ .el-card__body {
                padding: 0;
            }
        }
    }
    
    .paginate-goods {
        width: 100%;
        height: auto;
        text-align: center;
    }
</style>