<template>
    <div>
        <div class="title">
            <p style="margin-top:10px;margin-bottom:10px;text-align:center;line-height:150%">
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 20px;"><strong><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; line-height: 150%;">湖北元钧国际数字科技有限公司简介</span></strong></span>
            </p>
            <p style="text-indent: 32px; line-height: 2em;">
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;"><span style="line-height: 150%; font-size: 16px;">湖北元钧国际数字科技有限公司成立于2021年7月，注册资金500万元，主营五粮液</span><span style="line-height: 150%; font-size: 16px;">·</span><span style="line-height: 150%; font-size: 16px;">现代人</span><span style="line-height: 150%; font-size: 16px;">·</span><span style="line-height: 150%; font-size: 16px;">敬辉煌现货交易平台经济和乡村振兴电商增值业务，致力于在我国流通领域深化供给侧结构性改革、推动经济高质量发展过程中构建平台产业生态，努力成为白酒行业现货交易平台的标杆企业，力争为政府乡村振兴业务提供专业的市场增值服务。</span></span>
            </p>
            <p style="text-indent: 32px; line-height: 2em;">
                <span style="line-height: 150%; font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">公司的主要部门包括行政管理部、财务管理部、产品管理部、市场管理部、渠道管理部、乡村振兴部、物流管理部、风控管理部、技术管理部、证券管理部等，拥有规范的产品研发和管理体系，目前已有分支机构为湖北省各地市服务中心，可为用户提供及时、专业的本地化市场指导和商品流通服务。</span>
            </p>
            <p style="text-indent: 32px; line-height: 2em;">
                <span style="line-height: 150%; font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">公司坚持以人为本的经营思想，高度重视核心竞争力、软件产品质量、项目实施品质以及售后服务声誉，愿为行业平台经济和乡村振兴业务而不懈努力！</span>
            </p>
            <p style="text-indent: 32px; line-height: 2em;">
                <span style="line-height: 150%; font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">企业理念：诚信立足，创新致远；</span>
            </p>
            <p style="text-indent: 32px; line-height: 2em;">
                <span style="line-height: 150%; font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">企业宗旨：做精算型、技术型的白酒现货交易和乡村振兴产品电商公司；</span>
            </p>
            <p style="text-indent: 32px; line-height: 2em;">
                <span style="line-height: 150%; font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">企业使命：成为我国白酒供给侧千亿级平台经济，做乡村振兴电商平台的践行者。</span>
            </p>
            <p>
                <br/>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutThree',
    data() {
        return {

        }
    },
}
</script>

<style scoped lang="less">
.title {
    width: 100%;
}
</style>