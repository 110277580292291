<template>
    <div class="order-lists">
        <div class="order-content" v-if="list.length > 0">
            <el-card class="box-card" v-for="(item, index) in list" :key="index">
                <el-descriptions title="" direction="horizontal" :column="1" size="mini" border>
                    <el-descriptions-item label="上次积分">{{ item.pre | keepTwoDecimal }}</el-descriptions-item>
                    <el-descriptions-item label="本次积分">
                        <el-tag v-if="item.stp < 0" type="danger" size="small">{{ item.stp | keepTwoDecimal }}</el-tag>
                        <el-tag v-else type="success" size="small">+{{ item.stp | keepTwoDecimal }}</el-tag>
                    </el-descriptions-item>
                    <el-descriptions-item label="积分剩余">{{ item.nxt | keepTwoDecimal }}</el-descriptions-item>
                    <el-descriptions-item label="备注">
                        <el-tag type="warning" size="small">{{ item.remark }}</el-tag>
                    </el-descriptions-item>
                    <el-descriptions-item label="时间">{{ item.time }}</el-descriptions-item>
                </el-descriptions>
            </el-card>

            
        </div>
        <div class="order-content" v-else>
            <el-empty :image-size="200"></el-empty>
        </div>
        <!-- 分页 -->
        <div class="paginate-goods">
            <el-pagination v-if="num_none" background small
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange" 					
                layout="prev, pager, next"
                :current-page="current_page"
                :page-size="pagesize"
                :page-count="pagecount"
                :pager-count="5">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import imgUrl from '@/assets/images/xiandairen.jpg'
    import { noLoginApi, loginApi } from '@/config/env'
    export default {
        name: 'ExchangeLists',
        data() {
            return {
                //查询参数
                params: {
                    filter: {},
                    page: {
                        pageNo: 1,
                        pageSize: 10
                    }
                },
                list: [],
                total: 0, //总数据条数
                current_page: 1, //当前页码
                pagecount: 0, //总页码数
                num_none: true, //是否显示分页
                pagesize: 10, //每页显示数据条数
            }
        },
        mounted() {
            this.getExRecordLists()
        },
        beforeDestroy() {
            
        },
        methods: {
            //每页数据条数发生改变时触发
            handleSizeChange() {},
            //当前页发生改变时触发
            handleCurrentChange(val) {
                this.params.page.pageNo = val
                this.getExRecordLists()
            },
            handleClick() {

            },
            //获取订单记录
            async getExRecordLists() {
                const { data: res } = await this.$http.post(loginApi.mallExchangeRecordApi, this.params)
                if (res.code !== 'E000000') {
                    return this.$message.warning(res.message)
                }
                this.total = res.data.totalCount
                this.current_page = res.data.pageNo
                this.pagesize = res.data.pageSize
                this.pagecount = res.data.totalPage
                this.list = res.data.data
            }
        }
    }
</script>

<style lang="less" scoped>
    .order-lists {
        width: 100%;
        height: 100%;
        .order-content {
            width: 100%;
            height: 95%;
            padding: 0.2rem;
            margin-bottom: 0.5rem;
            overflow-y: auto;
            .el-card {
                margin-bottom: 0.5rem;
            }
            /deep/ .el-card__body {
                padding: 0;
            }
        }
    }
    
    .paginate-goods {
        width: 100%;
        height: auto;
        text-align: center;
    }
</style>