<template>
    <div class="forget-pwd">
        <el-page-header @back="goBack" content="新增地址" style="font-weight: bolder;">
        </el-page-header>
        <el-divider></el-divider>
        <el-form ref="addUserAddrForm" :model="addUserAddrForm" :rules="rules" label-width="100px">
            <el-form-item label="姓名" prop="RVN">
                <el-input v-model="addUserAddrForm.RVN" placeholder="请输入收货人姓名"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="RVP">
                <el-input v-model="addUserAddrForm.RVP" placeholder="请输入联系电话"></el-input>
            </el-form-item>
            <!-- <el-form-item label="所在地区">
                <div class="selAddr" @click="selAddress">
                    <div>{{ selAddrTxt }}</div>
                    <div><span class="iconfont icon-youbianjiantou"></span></div>
                </div>
                <v-distpicker type="mobile" v-show="showAddr"
                    :province="selectItem_province"
                    :city="selectItem_city"
                    :area="selectItem_county"
                    @selected="onSelected">
                </v-distpicker>
            </el-form-item> -->
            <el-form-item label="详细地址" prop="RVAD">
                <el-input type="textarea" v-model="addressDetail"></el-input>
            </el-form-item>
            <el-form-item label="设为默认地址">
                <el-switch v-model="isDefAddress" @change="changeState"></el-switch>
            </el-form-item>
            <el-button class="submit-btn" type="success" @click="onSubmit">保存</el-button>
        </el-form>
    </div>
</template>

<script>
    import { noLoginApi, loginApi } from '@/config/env'
    import VDistpicker from 'v-distpicker'
    import { isFailureLogin } from '@/config/mUtils'
    export default {
        name: 'AddUserAddress',
        data() {
            return {
                addUserAddrForm: {
                    "name": "delivery_address_update",
                    "U": "",
                    "SI": "",
                    "OPR": "1",
                    "ADI": "",
                    "RVN": "",  //收货人姓名
                    "RID": "",  //收货人身份证号
                    "RVP": "",  //收货人电话号码
                    "RVAD": "",  //收货人地址
                    "RVPC": "",  //收货人邮编
                    "ISDEF": 1  //是否为默认 1：是 2：不是
                },
                rules: {
                    RVN: [
                        { required: true, message: '请输入姓名', trigger: 'blur' },
                    ],
                    RVP: [
                        { required: true, message: '请输入联系电话', trigger: 'blur' },
                    ]
                },
                selectItem_province:'',  /**省*/
				selectItem_city:'',      /**市*/
				selectItem_county:'',    /**县*/
				addressPCA: '',
                showAddr: false,  //是否显示
                selAddrTxt: '请选择地区',
                addressDetail: '',
                isDefAddress: true
            }
        },
        components: { VDistpicker },
        methods: {
            //返回
            goBack() {
                this.$router.push('/delivery/useraddress')
            },
            //提交新增地址保存
            onSubmit() {
                // if (this.selectItem_province == '' || this.selectItem_city == '' || this.selectItem_county == '') {
                //     return this.$message.warning('请选择地区')
                // }
                // this.addUserAddrForm.RVAD = this.addressPCA + ' ' + this.addressDetail
                if (this.addressDetail == '') {
                    return this.$message.warning('请填写详细地址')
                }
                this.addUserAddrForm.RVAD = this.addressDetail
                this.$refs.addUserAddrForm.validate(async (valid) => {
                    if(!valid) return
                    const { data: res } = await this.$http.post(loginApi.saveUserAddressApi, this.addUserAddrForm)
                    //判断是否登录失效，以及返回错误信息
                    if (isFailureLogin(res.retcode)) {
                        this.$alert('添加成功', '提示', {
                            confirmButtonText: '确定',
                            callback: action => {
                                setTimeout(() => {this.$router.push('/delivery/useraddress')}, 800)
                            }
                        })
                    } else {
                        return this.$message.error(res.message)
                    }
                })
            },
            //选择省市区
            onSelected(data) {
                this.showAddr = false
                this.selectItem_province = data.province.value
				this.selectItem_city = data.city.value
				this.selectItem_county = data.area.value
                this.selAddrTxt = data.province.value + ' ' + data.city.value +' ' + data.area.value
                this.addressPCA = data.province.value + ' ' + data.city.value +' ' + data.area.value
            },
            selAddress() {
                this.showAddr = true
            },
            changeState(val) {
                if (val) {
                    this.addUserAddrForm.ISDEF = 1
                } else {
                    this.addUserAddrForm.ISDEF = 2
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .forget-pwd {
        width: 100%;
        padding: 1rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        form {
            margin-top: 5%;
        }
    }
    .submit-btn {
        width: 100%;
    }
    .selAddr {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        color: #c0c4cc;
        background-color: #FFFFFF;
        cursor: pointer;
        div:first-child {
            width: 80%;
            font-size: inherit;
            padding: 0 15px;
        }
        div:last-child {
            width: 20%;
            text-align: right;
            padding: 0 10px;
        }
    }
</style>