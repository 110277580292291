<template>
    <div class="real-auth">
        <el-page-header @back="goBack" content="实名认证" style="font-weight: bolder;">
        </el-page-header>
        <el-divider></el-divider>
        <el-form ref="realnameForm" :model="realnameForm" :rules="rules" label-width="90px">
            <el-form-item label="真实姓名" prop="RN">
                <el-input v-model="realnameForm.RN" placeholder="请输入真实姓名"></el-input>
            </el-form-item>
            <el-form-item label="证件号码" prop="CDN">
                <el-input v-model="realnameForm.CDN" placeholder="请输入证件号码"></el-input>
            </el-form-item>
        </el-form>
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <el-alert title="证件照片" :closable="false" type="warning" description="（注：图片大小不能超过5M）"></el-alert>
            </div>
            <el-row :gutter="10" class="imageIdendity">
                <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-upload
                        :action="uploadURL"
                        list-type="picture-card"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess1"
                        :on-error="handleErrorCase1"
                        :before-upload="beforeUploadAuth1"
                    >
                    <el-image v-if="imageUrl1" 
                        style="width: 100%; height: 100%" 
                        :src="imageUrl1"
                        fit="contain"
                    >
                    </el-image>
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <div class="el-upload__tip">身份证人像面</div>
                </el-col>
                <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-upload
                        :action="uploadURL"
                        list-type="picture-card"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess2"
                        :on-error="handleErrorCase2"
                        :before-upload="beforeUploadAuth2"
                    >
                    <el-image v-if="imageUrl2" 
                        style="width: 100%; height: 100%" 
                        :src="imageUrl2" 
                        fit="contain"
                    >
                    </el-image>
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <div class="el-upload__tip">身份证国徽面</div>
                </el-col>
            </el-row>
            
        </el-card>
        <el-alert v-if="isShowErrorMsg"
            :title="errMsgInfo"
            :closable="false"
            type="error"
            show-icon>
        </el-alert>

        <el-alert
            :closable="false"
            title=""
            type="info"
            description="请上传您的二代身份证的正反面，确保照片清晰，四角完整，建议图片最大5M，格式：.jpg/.png/.jpeg。"
            show-icon class="info-message">
        </el-alert>
        <el-button class="submit-btn" type="success" @click="onSubmit">申请认证</el-button>
    </div>
</template>

<script>
    import { noLoginApi, loginApi } from '@/config/env'
    import { parseRetCode } from '@/config/retcode'
    import { isFailureLogin } from '@/config/mUtils'
    export default {
        name: 'RealnameAuth',
        data() {
            //自定义规则
            var checkIdcard = (rule, value, callback) => {
                var regId = /^(\d{18,18}|\d{15,15}|\d{17,17}X)$/
                if (!value) {
                    return callback(new Error('证件号码不能为空！'))
                } else if (!regId.test(value)) {
                    return callback(new Error('证件号码不合法！'))
                } else {
                    callback()
                }
            }
            return {
                realnameForm: {
                    "name": "userauth_apply",
                    "U": "",
                    "SI": "",
                    "RN": "",
                    "CDT": "1",
                    "CDN": "",
                    "IDF": "",
                    "IDB": ""
                },
                //表单验证规则
                rules: {
                    RN: [
                        { required: true, message: '请输入真实姓名', trigger: 'blur' },
                    ],
                    CDN: [
                        { required: true, message: '请输入您的证件号码', trigger: 'blur' },
                        { validator: checkIdcard, trigger: 'blur' }
                    ]
                },
                merIdenImgFront: '', //正面
                merIdenImgBack: '', //反面
                imageUrl1: '',
                imageUrl2: '',
                uploadURL: noLoginApi.uploadImageApi,
                queryUser: {
                    "name": "userinfo_query",
                    "U": "",
                    "SI": ""
                },
                //实名查询条件
                queryState: {
                    "name": "authstatus_query",
                    "U": "",
                    "SI": ""
                },
                state: 0,
                isShowErrorMsg: false,
                errMsgInfo: '',
            }
        },
        methods: {
            //返回
            goBack() {
                this.$router.push('/user/information')
            },
            //实名状态查询
            async getRealnameState() {
                const { data: res } = await this.$http.post(loginApi.realnameAuthQueryApi, this.queryState)
                if (res.retcode < 0) {
                    return this.$message.warning(res.message)
                }
                this.state = res.result.RST
            },
            //提交申请
           async onSubmit() {
                if (this.realnameForm.RN == '') {
                    return this.$message.error('请输入您的真实姓名！')
                }
                if (this.realnameForm.CDN == '') {
                    return this.$message.error('请输入您的证件号码！')
                }
                if (this.realnameForm.IDF == '') {
                    return this.$message.error('请上传您的身份证人像面！')
                }
                if (this.realnameForm.IDB == '') {
                    return this.$message.error('请上传您的身份证国徽面！')
                }
                if (this.state == 1) {
                    return this.$message.error('实名状态审核中...，勿需重复申请！')
                } else if (this.state == 2) {
                    return this.$message.error('实名状态已审核通过，勿需重复申请！')
                } else {
                    // this.$confirm('您确定提交实名认证申请?', '提示', {
                    //     confirmButtonText: '确定',
                    //     cancelButtonText: '取消',
                    //     type: 'warning'
                    // }).then( () => {
                    //     this.doRealNameApply()
                    // }).catch(() => {
                    //     this.$message({
                    //         type: 'info',
                    //         message: '已取消'
                    //     })
                    // })

                    const h = this.$createElement
                    this.$msgbox({
                        title: '实名认证提示',
                        message: h('p', null, [
                            h('span', null, '真实姓名：'),
                            h('i', { style: 'color: teal' }, this.realnameForm.RN),
                            h('br'),
                            h('span', null, '证件号码：'),
                            h('i', { style: 'color: teal' }, this.realnameForm.CDN)
                        ]),
                        showCancelButton: true,
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        beforeClose: (action, instance, done) => {
                            if (action === 'confirm') {
                                instance.confirmButtonLoading = true
                                instance.confirmButtonText = '执行中...'
                                //this.doRealNameApply()

                                this.isShowErrorMsg = false
                                this.errMsgInfo = ''
                                this.$refs.realnameForm.validate(async (valid) => {
                                    if (!valid) return
                                    const { data: res } = await this.$http.post(loginApi.realnameAuthNewApi, this.realnameForm)
                                    done()
                                    //判断是否登录失效，以及返回错误信息
                                    if (isFailureLogin(res.retcode)) {
                                        instance.confirmButtonLoading = false
                                        this.$message.success(res.message)
                                        this.errMsgInfo = ''
                                        this.getUserInfos()
                                        setTimeout(() => {
                                            this.$router.push('/user')
                                        }, 300)
                                    } else {
                                        instance.confirmButtonLoading = false
                                        this.isShowErrorMsg = true
                                        this.errMsgInfo = res.message
                                        return this.$message.warning(res.message)
                                    }
                                })
                                // setTimeout(() => {
                                //     this.doRealNameApply()
                                //     done()
                                //     setTimeout(() => {
                                //         instance.confirmButtonLoading = false
                                //     }, 300)
                                // }, 1000)
                            } else {
                                instance.cancelButtonLoading = true
                                instance.cancelButtonText = '取消中...'
                                setTimeout(() => {
                                    done()
                                    setTimeout(() => {
                                        instance.cancelButtonLoading = false
                                    }, 300)
                                }, 1000)
                            }
                        }
                    }).then(res => {
                        //console.log(res)
                    }).catch(err => {
                        this.$message({
                            type: 'info',
                            message: '已取消'
                        })
                    })
                }
            },
            //实名认证申请
            async doRealNameApply() {
                this.isShowErrorMsg = false
                this.errMsgInfo = ''
                this.$refs.realnameForm.validate(async (valid) => {
                    if (!valid) return
                    const { data: res } = await this.$http.post(loginApi.realnameAuthNewApi, this.realnameForm)
                    //判断是否登录失效，以及返回错误信息
                    if (isFailureLogin(res.retcode)) {
                        this.$message.success(res.message)
                        this.errMsgInfo = ''
                        this.getUserInfos()
                        setTimeout(() => {
                            this.$router.push('/user')
                        }, 300)
                    } else {
                        this.isShowErrorMsg = true
                        this.errMsgInfo = res.message
                        return this.$message.warning(res.message)
                    }
                })
            },
            //获取用户信息并更新vuex保存的信息
            async getUserInfos() {
                //发送请求获取用户信息
                const { data: res } = await this.$http.post(loginApi.userInfoApi, this.queryUser)
                if (res.retcode < 0) {
                    return this.$message.warning(res.message)
                }
                this.$store.dispatch('getUserInfos', res.result)
            },
            //上传前验证
            beforeUploadAuth1(file) {
                const isJPG = file.type === 'image/png' || 'image/jpeg' || 'image/jpg'
                const isLt5M = file.size / 1024 / 1024 <= 5
                
                if (!isJPG) {
                    this.$message.error('上传图片格式不正确!')
                }
                if (!isLt5M) {
                    return this.$message.error('上传图片大小不能超过 5MB!')
                }
            },
            //上传成功回调
            handleAvatarSuccess1(res, file) {
                this.imageUrl1 = file.url
                //this.imageToBase64(file.raw, 1)
                if (res.code == "E000000") {
                    this.realnameForm.IDF = res.data.id
                } else {
                    return this.$message.error('图片上传失败！')
                }
            },
            //上传失败时回调
            handleErrorCase1(err, file, fileList) {
                //this.imageToBase64(file.raw, 1)
                return this.$message.warning(err)
            },
            //上传前验证
            beforeUploadAuth2(file) {
                const isJPG = file.type === 'image/png' || 'image/jpeg' || 'image/jpg'
                const isLt5M = file.size / 1024 / 1024 <= 5
                
                if (!isJPG) {
                    this.$message.error('上传图片格式不正确!')
                }
                if (!isLt5M) {
                    this.$message.error('上传图片大小不能超过 5MB!')
                }
                //this.imageToBase64(file, this.realnameForm.IDB)
                return isJPG && isLt5M
            },
            //上传成功回调
            handleAvatarSuccess2(res, file) {
                this.imageUrl2 = file.url
                //this.imageToBase64(file.raw, 2)
                //this.realnameForm.IDB = file
                // if (res.code == "E000000") {
                //     this.imageUrl2 = res.data.base64
                //     this.realnameForm.IDB = res.data.base64
                // } else {
                //     return this.$message.error('图片上传失败！')
                // }
                if (res.code == "E000000") {
                    this.realnameForm.IDB = res.data.id
                } else {
                    return this.$message.error('图片上传失败！')
                }
            },
            //上传失败时回调
            handleErrorCase2(err, file, fileList) {
                //this.imageToBase64(file.raw, 2)
                return this.$message.warning(err)
            },
            //图片转base64
            imageToBase64 (file, type) {
                var reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => {
                    //console.log('file 转 base64结果：' + reader.result)
                    if (type == 1) {
                        this.realnameForm.IDF = reader.result.substr(23)
                        this.imageUrl1 = reader.result
                    } else {
                        this.realnameForm.IDB = reader.result.substr(23)
                        this.imageUrl2 = reader.result
                    }
                }
                reader.onerror =  (error) => {
                    //console.log('Error: ', error)
                    if (type == 1) {
                        this.realnameForm.IDF = ''
                        this.imageUrl1 = ''
                    } else {
                        this.realnameForm.IDB = ''
                        this.imageUrl2 = ''
                    }
                }
            }
        },
        mounted() {
            this.getRealnameState()
        },
        computed: {}
    }
</script>

<style lang="less" scoped>
    .real-auth {
        width: 90%;
        padding: 10px;
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        form {
            margin-top: 5%;
        }
    }
    .submit-btn {
        width: 100%;
    }
    .box-card {
        width: 100%;
        margin-bottom: 20px;
        .imageIdendity {
            text-align: center;
        }
    }
    .info-message {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .img-thumbnail {
        height: 100% !important;
    }
</style>