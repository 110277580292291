<template>
    <div class="order-main">
        <div class="top-info">
            <el-page-header @back="goBack" content="订单列表" style="font-weight: bolder;padding: 10px;">
            </el-page-header>
            <el-divider></el-divider>
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick" style="padding: 10px;">
                <el-tab-pane label="所有" name="first"></el-tab-pane>
                <el-tab-pane label="待付款" name="second"></el-tab-pane>
                <el-tab-pane label="待发货" name="third"></el-tab-pane>
                <el-tab-pane label="待收货" name="four"></el-tab-pane>
                <el-tab-pane label="已完成" name="five"></el-tab-pane>
            </el-tabs>
        </div>
        <!-- 订单列表区域 -->
        <div class="order-lists">
            <el-card class="box-card" v-if="orderLists.length>0" v-for="(item, index) in orderLists" :key="index" style="margin-bottom: 0.5rem">
                <div class="goodsinfos">
                    <el-row :gutter="10">
                        <el-col :span="8">
                            <div>
                                <el-image
                                    style="width: 100%; height: 100px"
                                    :src="imageUrl"></el-image>
                            </div>
                        </el-col>
                        <el-col :span="16">
                            <div class="price-info">
                                <div style="font-weight: 600">{{ item.salon_product_name }}</div>
                                <div>
                                    <div>
                                        <p>金额：￥{{ item.salon_order_price|keepTwoDecimal }}</p>
                                        <p>数量：x3</p>
                                        <!-- <p>时间：{{ item.salon_order_time }}</p> -->
                                    </div>
                                
                                    <div>
                                        <el-tag size="mini" v-if="item.salon_order_state==2" style="margin-top: 3rem;">未支付</el-tag>
                                        <el-tag type="success" v-else-if="item.salon_order_state==1" size="mini">已支付</el-tag>
                                        <el-tag type="success" v-else-if="item.salon_order_state==5&&item.salon_delivery_state==3" size="mini">交易完成</el-tag>
                                        <el-tag type="info" v-else-if="item.salon_order_state==4" size="mini">交易取消</el-tag>
                                        <el-tag type="warning" v-else-if="item.salon_order_state==3" size="mini">交易关闭</el-tag>
                                        <el-tag type="danger" v-else-if="item.salon_delivery_state==1" size="mini">待发货</el-tag>
                                        <el-tag type="danger" v-else-if="item.salon_delivery_state==2" size="mini">已发货</el-tag>
                                        <el-tag type="success" v-else-if="item.salon_delivery_state==3" size="mini">已收货</el-tag>
                                    </div> 
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <div class="button-power">
                    <el-row>
                        <!-- <el-button round size="mini">查看物流</el-button> -->
                        <el-button type="default" round size="mini" @click.native="showOrderDetails(item.salon_order_id)">查看详情</el-button>
                        <el-button v-if="item.salon_order_state==0||item.salon_order_state==2" type="primary" round size="mini" @click="paySalon(item.salon_order_id)">订单支付</el-button>
                        <el-button v-if="item.salon_delivery_state==2" type="danger" size="mini" round v-on:click="confirmDelivery(item.salon_order_id)">确认收货</el-button>
                    </el-row>
                </div>
            </el-card>
            <el-card class="box-card" v-if="orderLists.length==0">
                <el-empty description="暂无任何订单信息"></el-empty>
            </el-card>
            <!-- dialog对话框显示订单详情 -->
            <el-dialog
                :visible.sync="dialogVisible"
                top="5vh"
                center
                :show-close="false"
                :append-to-body="true"
                width="100%"
                fullscreen
                >
                <el-descriptions title="" size="small" :column="1" direction="vertical" border>
                    <el-descriptions-item label="商品信息">
                        <p><el-tag size="small">名称：{{ detailsPruct.salon_product_name }}</el-tag></p>
                        <p><el-tag size="small">数量：x3</el-tag></p>
                        <p><el-tag size="small">金额：￥{{ detailsPruct.salon_order_price | keepTwoDecimal }}</el-tag></p>
                    </el-descriptions-item>
                    <el-descriptions-item label="收货地址" :span="2">
                        <p><span>{{ detailsDelivery.salon_delivery_name }}   {{ detailsDelivery.salon_delivery_phone }}</span></p>
                        <p><span>{{ detailsDelivery.salon_delivery_address }}</span></p>
                    </el-descriptions-item>
                    <el-descriptions-item label="快递信息">
                        <p><span>快递公司：{{ detailsDelivery.salon_express_company==null?'暂无':detailsDelivery.salon_express_company }}</span></p>
                        <p><span>快递单号：{{ detailsDelivery.salon_express_id==null?'暂无':detailsDelivery.salon_express_id }}</span></p>
                    </el-descriptions-item>
                    <el-descriptions-item label="订单状态">
                        <p>
                            <el-tag type="default" v-if="detailsPruct.salon_order_state==0" size="small">订单已确认，等待支付...</el-tag>
                            <el-tag type="success" v-else-if="detailsPruct.salon_order_state==1" size="small">已支付</el-tag>
                            <el-tag type="info" v-else-if="detailsPruct.salon_order_state==2" size="small">未支付</el-tag>
                            <el-tag type="warning" v-else-if="detailsPruct.salon_order_state==3" size="small">超时关闭</el-tag>
                            <el-tag type="danger" v-else-if="detailsPruct.salon_order_state==4" size="small">已取消</el-tag>
                            <el-tag type="success" v-else-if="detailsPruct.salon_order_state==5" size="small">交易完成</el-tag>
                            <el-tag v-else size="small">未知状态</el-tag>
                        </p>
                    </el-descriptions-item>
                    <el-descriptions-item label="发货状态">
                        <p>
                            <el-tag type="default" v-if="detailsDelivery.salon_delivery_state==0" size="small">未发货</el-tag>
                            <el-tag type="info" v-else-if="detailsDelivery.salon_delivery_state==1" size="small">待发货</el-tag>
                            <el-tag type="info" v-else-if="detailsDelivery.salon_delivery_state==2" size="small">已发货</el-tag>
                            <el-tag type="success" v-else-if="detailsDelivery.salon_delivery_state==3" size="small">已收货</el-tag>
                            <el-tag type="danger" v-else-if="detailsDelivery.salon_delivery_state==4" size="small">已取消</el-tag>
                            <el-tag v-else size="small">未知状态</el-tag>
                        </p>
                    </el-descriptions-item>
                    <el-descriptions-item label="创建时间">
                        <p><span>{{ detailsPruct.salon_order_time }}</span></p>
                    </el-descriptions-item>
                    <el-descriptions-item label="发货时间">
                        <p><span>{{ detailsDelivery.salon_delivery_time==null?'暂无':detailsDelivery.salon_delivery_time }}</span></p>
                    </el-descriptions-item>
                    <el-descriptions-item label="收货时间">
                        <p><span>{{ detailsDelivery.salon_receive_time==null?'暂无':detailsDelivery.salon_receive_time }}</span></p>
                    </el-descriptions-item>
                </el-descriptions>
                <span slot="footer" class="dialog-footer">
                    <el-button style="width: 100%" type="danger" size="small" @click="dialogVisible = false">返回</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { noLoginApi, loginApi } from '@/config/env'
import { isFailureLogin, formatTime } from '@/config/mUtils'
import { mapState } from 'vuex'
import imgUrl from '@/assets/images/xiandairen.jpg'

export default {
    name: 'AcOrderLists',
    inject: ['reload'],
    data() {
        return {
            activeName: 'first',
            imageUrl: imgUrl,
            orderLists: [],
            order_state: 0,
            dialogVisible: false,
            detailsPruct: {},  //商品详情
            detailsDelivery: {}  //发货详情
        }
    },
    computed: {
        ...mapState(['userInfos'])
    },
    created() {
        
    },
    mounted() {
        this.getOrderLists()
    },
    methods: {
        goBack() {
            this.$router.push('/activity/activitylist')
        },
        //获取订单列表
        async getOrderLists() {
            const { data: res } = await this.$http.post(loginApi.getSalonOrderListsApi+"?order_state_front="+this.order_state)
            if(res.code === 'E000000') {
                this.orderLists = res.data
            } else {
                return this.$message.warning(res.message)
            }
        },
        //查看订单详情按钮
        async showOrderDetails(item) {
            const { data: res } = await this.$http.post(loginApi.getSalonOrderDetailsApi+'?salon_order_id='+item)
            if (res.code !== 'E000000') {
                return this.$message.error(res.message)
            } else {
                this.detailsPruct = res.data.salonOrder
                this.detailsDelivery = res.data.salonDelivery
                this.dialogVisible = true
            }
        },
        //支付按钮发起支付
        paySalon(val) {
            window.open(loginApi.repaySalonApi+'?salon_order_id='+val)
        },
        //确认收货按钮
        confirmDelivery(order_id) {
            this.$confirm('您确认是否收货？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                //执行确认事件
                this.confirmRecevieDelivery(order_id)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        //确认收货事件
        async confirmRecevieDelivery(orderId) {
            const { data: res } = await this.$http.post(loginApi.confirmSalonOrderApi+'?salon_order_id='+orderId)
            if (res.code !== 'E000000') {
                return this.$message.error(res.message)
            } else {
                this.$message.success(res.message)
                this.$router.go(0)
            }
        },
        //切换tabs标签
        handleClick(tab, event) {
            switch (tab._props.name) {
                case 'first':
                    this.order_state = 0
                    this.orderLists = []
                    this.getOrderLists()
                    break;
                case 'second':
                    this.order_state = 1
                    this.orderLists = []
                    this.getOrderLists()
                    break;
                case 'third':
                    this.order_state = 2
                    this.orderLists = []
                    this.getOrderLists()
                    break;
                case 'four':
                    this.order_state = 3
                    this.orderLists = []
                    this.getOrderLists()
                    break;
                case 'five':
                    this.order_state = 4
                    this.orderLists = []
                    this.getOrderLists()
                    break;
                default:
                    break;
            }
        }
    }
}
</script>

<style lang="less" scoped>
    .order-main {
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        position: absolute;
        top: 0;
        left: 0;
        .top-info {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .order-lists {
        width: 100%;
        height: 85%;
        padding-left: 10px;
        padding-right: 10px;
        overflow-y: auto;
        position: absolute;
        top: 10rem;
        left: 0;

        /deep/ .el-card__body {
            padding: 1rem;
        }
        .box-card .goodsinfos {
            width: 100%;
            height: 100%;
        }
        .box-card .button-power {
            width: 100%;
            height: 100%;
            // margin-top: 2rem;
        }
    }
    /deep/ .el-tabs__header {
        margin: 0;
    }
    .price-info {
        width: 100%; 
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        div:first-child {
            width: 50rem;
            height: 4rem;
        }
        div:last-child {
            font-size: 12px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }
        
    }
    .button-power .el-row {
        width: 100%;
        text-align: right;
    }
    /deep/ .el-dialog__header {
        padding: 0;
    }
    /deep/ .el-divider--horizontal {
        margin: 0;
    }
    /deep/ .el-tabs__item {
        padding: 0 10px;
    }
    /deep/ .el-dialog--center .el-dialog__body {
        padding: 10px 10px 10px;
        height: 90%;
        overflow-y: auto;
    }
</style>