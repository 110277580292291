<template>
    <div class="mall">
        <div class="mall-title">
            <h4>活动沙龙</h4>
            <el-divider></el-divider>
        </div>
        <div class="activity-list" v-loading="isLoading">
            <el-card class="box-card">
                <router-link to="/activity/activitylist"><el-image :src="image_url"></el-image></router-link>
            </el-card>
        </div>
        <FooterNavigation />
    </div>
</template>

<script>
    import FooterNavigation from '@/components/common/footer/FooterNavigation.vue'
    import { noLoginApi } from '@/config/env'
    import imgUrl from '@/assets/images/shalong1.jpg'
    
    export default {
        components: { FooterNavigation },
        name: 'Activity',
        data() {
            return {
                image_url: imgUrl,
                isLoading: true
            }
        },
        mounted() {
            this.isLazyLoading()
        },
        methods: {
            //懒加载图片
            isLazyLoading() {
                setTimeout(() => {
                    this.isLoading = false
                }, 500)
            }
        }    
    }
</script>

<style lang="less" scoped>
    .mall {
        width: 100%;
        height: 100%;
        position: absolute;
        overflow: hidden;
        .mall-title {
            color: #333333;
            text-align: center;
            width: 100%;
            position: fixed;
            top: 0;
            left: 0;
            h4 {
                line-height: 3rem;
                font-weight: bolder;
            }
            /deep/ .el-divider--horizontal {
                margin: 0;
            }
        }
        .activity-list {
            width: 100%;
            position: absolute;
            top: 6rem;
        }
        /deep/ .el-card__body {
            padding: 5px;
        }
        .el-image {
            width: 100%;
            // height: 20rem;
            cursor: pointer;
        }
    }
        
</style>