<template>
    <div class="team">
        <div class="team-head">
            <el-page-header @back="goBack" content="团队信息" style="font-weight: bolder;">
            </el-page-header>
            <el-divider></el-divider>
        </div>
        <div class="team-main">
            <div id="main"></div>
        </div>
    </div>
</template>

<script>
    import { loginApi, noLoginApi } from '@/config/env'
    export default {
        name: 'TeamDetails',
        data() {
            return {
                chart: null,
                userId: '',
            }
        },
        created() {
            this.userId = this.$store.state.userInfos.BI ? this.$store.state.userInfos.BI : ''
        },
        mounted() {
            this.drawChart()
        },
        methods: {
            //返回
            goBack() {
                this.$router.push('/user')
            },
            //初始化echarts
            drawChart() {
                // 基于准备好的dom，初始化echarts实例
                var myChart = this.$echarts.init(document.getElementById('main'))
                window.onresize = () => {
                    myChart.resize()
                }
                var option;
                //加载loading
                myChart.showLoading()
                this.$http.get(noLoginApi.queryTeamTreesApi+'?user_id='+this.userId).then((datas) => {
                    const res = datas.data
                    if (res.code !== 'E000000') {
                        return
                    }

                    myChart.hideLoading()
                    let data = res.data
                    //console.log(data)
                    myChart.setOption(
                        (option = {

                            title: {
                                text: '团队总览表',
                                textAlign: 'auto',
                                padding: 10
                            },
                            textStyle: {
                                fontFamily: "Microsoft YaHei",
                                fontSize: 12,
                                fontStyle: "normal",
                                fontWeight: "normal"
                            },
                            tooltip: {
                                enterable: true,
                                trigger: 'item',
                                triggerOn: 'mousemove|click',
                                confine: true,
                                textStyle: {
                                    color: '#888',
                                    fontWeight: 'bold',
                                    fontSize: 8
                                },
                
                                formatter: (data) => {
                                    return `<div class="label-first">
                                        <p>姓名：${data.data.user.user_name}</p>
                                        <p>手机号：${data.data.user.phone}</p>
                                        <p>推荐人：${data.data.user.intro_user_name}</p>
                                        <p>服务人：${data.data.user.server_user_name}</p>
                                        <p>会员等级：${data.data.user.level_name}</p>
                                        <p>个人提货业绩：${data.data.user.commodity_count}</p>
                                        <p>团队提货业绩：${data.data.user.team_commodity_count}</p>
                                        <p>团队总人数：${data.data.user.team_count}</p>
                                    </div>`
                                }
                            },
                            series: [
                                {
                                    type: 'tree',
                                    data: [data],
                                    // left: '15%',
                                    // right: '15%',
                                    // top: '10%',
                                    // bottom: '10%',
                                    left: '2%',
                                    right: '2%',
                                    top: '12%',
                                    bottom: '2%',
                                    symbol: 'emptyCircle',
                                    symbolSize: 20,
                                    orient: 'vertical',
                                    expandAndCollapse: true,
                                    initialTreeDepth: 4,
                                    roam: false,
                                    itemStyle: {
                                        color: '#1A73E8',
                                    },
                                    label: {
                                        position: 'top',
                                        color: '#1E1E1E',
                                        fontSize: 12,
                                        fontWeight: 600, 
                                        fontFamily: 'Microsoft YaHei',
                                        backgroundColor: '#EFEFF4',
                                        borderRadius: [50, 50, 50, 50],
                                        padding: 0,
                                        formatter: (result) => {
                                            return result.data.user.user_name+'\n'+result.data.user.level_name
                                        }
    
                                    },
                                    lineStyle: {
                                        color: '#1A73E8',
                                        width: 1,
                                        type: 'solid'
                                    },
                                    leaves: {
                                        label: {
                                            position: 'center',
                                            rotate: 0,
                                            align: 'center',
                                            verticalAlign: 'center',
                                            fontSize: 10,
                                            padding: 0,
                                        }
                                    },
                                    emphasis: {
                                        focus: 'descendant'
                                    },
                                    expandAndCollapse: true,
                                    animationDuration: 550,
                                    animationDurationUpdate: 750
                                }
                            ]
                        })
                    )
                }).catch(error => {
                    console.log(error)
                })
                
            }
        }  
    }
</script>

<style lang="less" scoped>
    .team {
        width: 100%;
        overflow-y: hidden;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        .team-head {
            width: 100%;
            position: fixed;
            padding: 1rem;
        }
    }
    .team-main {
        width: 100%;
        position: absolute;
        top: 6rem;
        height: 90%;
        padding: 1rem;
        #main {
            width: 100%;
            height: 100%;
        }
    }
</style>