<template>
    <div>
        <el-row>
            <el-col :span="24">
                <el-card class="box-card">
                    <el-carousel indicator-position="outside" :height="bannerHeight+'px'">
                        <el-carousel-item v-for="item in imgUrl" :key="item">
                            <img ref="bannerHeight" :src="item" @load="imgLoad" alt="" style="width: 100%">
                        </el-carousel-item>
                    </el-carousel>
                </el-card>
            </el-col>
        </el-row>
        <el-row class="notice-lists">
            <el-col :span="24">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span class="iconfont icon-gonggao"></span>&nbsp;
                        <span>市场公告</span>
                    </div>
                    <div v-for="item in commentList" :key="item.BI" class="comments">
                        <el-row id="text-contents">
                            <el-col :span="18" >
                                <div style="width: 100$;" @click="toMsgDetails(item.BI)">
                                    <router-link :to="`/messages/details?mid=${item.BI}`">{{ item.BT }}</router-link>
                                </div>
                            </el-col>
                            <el-col :span="6">{{ item.BPT | sliceString(0, 10) }}</el-col>
                        </el-row>
                    </div>
                </el-card>
            </el-col>
        </el-row>

        <FooterNavigation />
    </div>
</template>

<script>
import FooterNavigation from '@/components/common/footer/FooterNavigation.vue'
import { parseRetCode } from '@/config/retcode'
import { noLoginApi } from '@/config/env'
import Scroll from '@/components/common/dropdown/DropdownScroll.vue'
import imgUrl_ban1 from '@/assets/images/banner1.png'
import imgUrl_ban2 from '@/assets/images/banner2_huadi.png'
import imgUrl_ban3 from '@/assets/images/banner3_wuliangye_big.png'

 export default {
     components: { 
         FooterNavigation
     },
     name: 'Home',
     data() {
         return {
            noticeData: {
                "name": "mobile_notice_query",
                "U": "",
                "LTI": 0,
                "RECCNT": 20
            },
            commentList: [],
            totalNum: 0,
            //走马灯高度
            bannerHeight: '',
            imgUrl: [
                imgUrl_ban1,
                imgUrl_ban2,
                imgUrl_ban3
            ]
         }
     },
     beforeCreate() {
        
     },
     created() {
        //加载图片
        this.imgLoad()
        //监听图片高度
        window.addEventListener('resize', this.getImageHeight)
     },
     mounted() { 
        //获取公告
        this.getNoticeList()
     },
     methods: {
        //获取公告
        async getNoticeList() {
            const { data: res } = await this.$http.post(noLoginApi.mobileCommentApi, this.noticeData)
            if (res.retcode < 0) {
                this.$message.warning(parseRetCode(res.retcode))
                return
            }
            this.commentList = this.commentList.concat(res.resultList.REC)
        },
        //加载图片
        imgLoad() {
            this.$nextTick( () => {
                var h = this.$refs.bannerHeight
                this.bannerHeight = this.$refs.bannerHeight[0].height
            })
        },
        //获取图片高度
        getImageHeight() {
            this.bannerHeight = this.$refs.bannerHeight[0].height
            this.imgLoad()
        },
        //查询公告详情
        toMsgDetails(val) {
            this.$router.push("/messages/details?mid="+val)
        }
     },
     beforeDestroy() {
        window.removeEventListener('resize', this.getImageHeight)
     }
 }
</script>

<style lang="less" scoped>
    .el-row {
        margin: 5px;
    }
    .notice-lists {
        margin-bottom: 10rem !important;
        /deep/ .el-card__header {
            padding: 5px;
        }
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #EFEFF4;
    }
  
    .el-carousel__item:nth-child(2n+1) {
        background-color: #d3dce6;
    }

    .comments {
        height: 30px;
        line-height: 30px;
        max-height: 30px;
        font-size: 12px;
        font-family: 'FZLanTingHeiS-R-GB';
        color: rgba(0, 0, 0, 0.8);
        padding: 0;
        margin-bottom: 20px;
    }

    #text-contents {
        margin-left: 0;
        width: 100%;
    }

    #text-contents > .el-col:nth-child(1) {
        text-align: left;
        a {
            color: #606266 !important;
            text-decoration: none;
            vertical-align: middle;
        }
        
    }

    #text-contents > .el-col:nth-child(2) {
        text-align: right;
    }
    /deep/ .el-card__body {
        padding: 5px;
    }
</style>