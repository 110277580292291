<template>
    <div>
        <el-button type="danger" v-on:click="logout()">退出登录</el-button>
    </div>
</template>

<script>
    import { loginApi } from '@/config/env'
    import Cookies from 'js-cookie'
    export default {
        name: 'Logout',
        data() {
            return {
                logoutParams: {
                    "name": "logoff",
                    "U": "",
                    "SI": "",
                    "LT": "web"
                }
            }
        },
        methods: {
            //退出登录
            logout() {
                this.$confirm('您确定要退出登录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then( async () => {
                    const  { data: res }  = await this.$http.post(loginApi.userLogoutApi, this.logoutParams)
                    if (res.retcode >= 0) {
                        //清空登录态
                        Cookies.remove('U')
                        Cookies.remove('SI')
                        Cookies.remove('SYI')
                        Cookies.remove('LT')
                        this.$router.push('/login')
                    } else {
                        this.$message.error(res.message)
                        this.$router.push('/login')
                    }
                }).catch(() => {
                    
                })
            }
        }   
    }
</script>

<style lang="less" scoped>
    div {
        width: 100%;
        height: 4.5rem;
        line-height: 4.5rem;
        margin-top: 4rem;
        .el-button {
            width: 100%;
        }
    }
</style>